$ddl-list-line-height: 30px !default;
$ddl-header-font-weight: $font-weight-medium !default;
$ddl-last-child-bottom-border: 0 !default;
$ddl-default-font-family: $font-family !default;
$ddl-line-height: 32px !default;
$ddl-list-border-size: 0 !default;
$ddl-list-bottom-border: $ddl-list-border-size !default;

$ddl-list-font-size: $text-sm !default;
$ddl-list-font-family: $font-family !default;
$ddl-list-focus-border: 1px solid $border-focus !default;
$ddl-group-list-padding-left: 12px !default;
$ddl-group-list-small-padding-left: 8px !default;
$ddl-group-list-bigger-padding-left: 16px !default;
$ddl-group-list-bigger-small-padding-left: 14px !default;
$ddl-list-header-padding-left: 12px !default;
$ddl-list-header-small-padding-left: 8px !default;
$ddl-list-header-bigger-small-padding-left: 14px !default;
$ddl-list-header-bigger-padding-left: 16px !default;
$ddl-list-text-indent: 12px !default;
$ddl-list-rtl-padding-right: 0 !default;
$ddl-list-padding-right: 16px !default;
$ddl-list-rtl-padding-left: 16px !default;
$ddl-multi-column-border-width: 0 0 1px 0 !default;
$ddl-bigger-list-header-font-size: $text-base !default;
$ddl-group-list-font-size: $text-sm !default;
$ddl-list-header-font-size: $text-sm !default;

// Small Size

$ddl-small-icon-font-size: $font-icon-14 !default;
$ddl-small-line-height: 26px !default;
$ddl-small-list-text-indent: 8px !default;
$ddl-small-list-header-font-size: $text-xxs !default;

// Touch Small

$ddl-bigger-small-icon-font-size: $font-icon-18 !default;
$ddl-bigger-small-line-height: 30px !default;
$ddl-bigger-small-list-text-indent: 16px !default;
$ddl-bigger-text-indent: 16px !default;
$ddl-bigger-small-list-header-font-size: $text-xs !default;
$ddl-group-list-item-text-intent: 0 !default;

$ddl-list-border-color: $border-light !default;
$ddl-list-gradient-color: $border-light !default;
$mention-gradient-color: $white !default;
$mention-list-box-shadow-color: rgba(0, 0, 0, .21) !default;
$mention-chip-bg-color: $content-bg-color-alt2 !default;
$mention-chip-border-radius: 2px !default;
$mention-chip-border: none !default;
$ddl-list-bg-color: $flyout-bg-color !default;
$ddl-list-header-bg-color: $transparent !default;
$ddl-list-tap-color: transparent !default;
$ddl-list-header-border-color: $border-light !default;
$ddl-nodata-font-color: $content-text-color !default;
$ddl-list-default-font-color: $content-text-color !default;
$ddl-list-active-border-color: $content-bg-color !default;
$mention-popup-bg-color: $content-bg-color !default;
$ddl-list-active-font-color: $content-text-color-selected !default;
$mention-active-font-color: $primary !default;
$ddl-list-active-bg-color: $content-bg-color-selected !default;
$ddl-list-hover-border-color: $content-bg-color !default;
$ddl-list-hover-bg-color: $flyout-bg-color-hover !default;
$ddl-list-hover-font-color: $flyout-text-color-hover !default;
$ddl-list-header-font-color: $content-text-color-alt3 !default;
$ddl-default-header-font-color: $content-text-color-alt3 !default;
$ddl-list-focus-color: $flyout-text-color-hover !default;
$ddl-multi-column-border-color: $flyout-border !default;

// Small Size color
$ddl-small-list-font-color: $content-text-color !default;

// Touch Small color
$ddl-bigger-small-list-font-color: $content-text-color !default;

@include export-module('dropdownbase-bootstrap5') {

  .e-dropdownbase .e-list-group-item,
  .e-fixed-head {
    font-size: $text-xs;
  }

  .e-small .e-dropdownbase .e-list-group-item,
  .e-small .e-fixed-head,
  .e-small.e-dropdownbase .e-list-group-item,
  .e-small.e-fixed-head {
    font-size: $text-xxs;
  }

  .e-bigger .e-dropdownbase .e-list-group-item,
  .e-bigger .e-fixed-head,
  .e-bigger.e-dropdownbase .e-list-group-item,
  .e-bigger.e-fixed-head {
    font-size: $text-sm;
  }

  .e-bigger.e-small .e-dropdownbase .e-list-group-item,
  .e-bigger.e-small .e-fixed-head,
  .e-bigger.e-small.e-dropdownbase .e-list-group-item,
  .e-bigger.e-small.e-fixed-head {
    font-size: $text-xs;
  }

  .e-dropdownbase .e-list-item .e-list-icon {
    padding: 0 8px 0 0;
  }

  .e-bigger .e-dropdownbase .e-list-item .e-list-icon,
  .e-bigger.e-small .e-dropdownbase .e-list-item .e-list-icon {
    padding: 0 12px 0 0;
  }

  .e-small .e-dropdownbase .e-list-item .e-list-icon {
    padding: 0 6px 0 0;
  }
}
