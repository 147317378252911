@include export-module('dropdownlist-bootstrap5-icons') {
  .e-ddl .e-search-icon::before {
    content: '\e754';
  }

  .e-ddl .e-back-icon::before {
    content: '\e773';
  }

  .e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
    content: '\e729';
    font-family: 'e-icons';
  }
}
