// size variables
$range-btn-font-weight: $font-weight-medium !default;
$range-btn-width: 50% !default;
$range-btn-normal-height: 30px !default;
$range-btn-bigger-height: 36px !default;
$range-btn-normal-line-height: 24px !default;
$range-btn-bigger-line-height: 32px !default;
$range-max-width: 730px !default;
$range-device-max-width: 294px !default;
$range-bigger-max-width: 770px !default;
$range-max-height: 500px !default;
$range-normal-nav-icon-width: 24px !default;
$range-control-border-right: 0 !default;
$range-normal-nav-icon-height: 24px !default;
$range-calendar-medium-font-size: $text-sm !default;
$range-calendar-btn-font-size: $text-sm !default;
$range-presets-normal-list-font-size: $text-xs !default;
$range-presets-bigger-list-font-size: $text-sm !default;
$range-range-btn-font-weight: $font-weight-medium !default;
$range-header-label-size: $text-lg !default;
$range-calendar-small-font-size: $text-xs !default;
$range-calendar-bigger-font-size: $text-xl !default;
$range-calendar-header-padding: 8px 5px !default;
$range-e-bigger-header-padding: 10px 5px !default;
$range-device-control-header-margin: 0 !default;
$range-device-control-header-padding: 12px !default;
$range-control-header-margin: 12px 0 !default;
$range-control-bigger-header-margin: 12px 0 !default;
$range-indicator-margin: 0 !default;
$range-lg-day-header-format-width: 100% !default;
$range-start-end-container-height: 28px !default;
$range-calendar-margin: 0 !default;
$range-start-end-label-width: 48% !default;
$range-change-icon-width: 4% !default;
$range-font-weight-bold: $font-weight-medium !default;
$range-font-size: $text-sm !default;
$range-bigger-change-icon-size: $font-icon-18 !default;
$range-indicator-label-width: 100% !default;
$range-device-indicator-margin: 10px 0 !default;
$range-separator-height: 1px !default;
$range-border-value: 1px solid $border-light !default;
$range-separator-margin: 0 !default;
$range-footer-height: 48px !default;
$range-bigger-footer-height: 58px !default;
$range-btn-height: 36px !default;
$range-btn-padding: 0 16px !default;
$range-normal-footer-margin: 0 8px 0 8px !default;
$range-bigger-footer-margin: 0 8px 0 8px !default;
$range-normal-rtl-footer-margin: 0 8px 0 8px !default;
$range-bigger-rtl-footer-margin: 0 8px 0 8px !default;
$range-presets-height: 192px !default;
$range-bigger-presets-height: 240px !default;
$range-calendar-container-height: 100% !default;
$range-list-item-height: 48px !default;
$range-list-item-padding: 0 24px !default;
$range-listview-padding: 4px 0 !default;
$range-list-item-line-height: 47px !default;
$range-device-medium-font-size: $text-sm !default;
$range-device-medium-header-font-size: $text-sm !default;
$range-device-small-font-size: $text-xs !default;
$range-week-header-font-size: $text-sm !default;
$range-device-header-container-height: 36px !default;
$range-device-header-container-width: 100% !default;
$range-device-list-item-padding: 0 16px !default;
$range-value-zero: 0 !default;
$range-btn-border-value: 1px solid $primary !default;
$range-btn-left-radius: 2px 0 0 2px !default;
$range-btn-right-radius: 0 2px 2px 0 !default;
$range-day-span-height: 18px !default;
$range-indicator-size: 1px !default;
$range-preset-min-width: 625px !default;
$range-header-btn-max-width: 116px !default;
$range-header-btn-bigger-max-width: 141px !default;
$range-presets-width: 160px !default;
$range-hover-start-radius: 50% 0 0 50% !default;
$range-hover-end-radius: 0 50% 50% 0 !default;
$range-btn-height: 36px !default;
$range-device-btn-line-height: 34px !default;
$range-icon-hover-color: $content-bg-color-hover !default;
$range-icon-border-radius: 50% !default;
$range-icon-container-min-height: 18px !default;
$range-icon-container-min-width: 30px !default;
$range-icon-bottom-right-radius: 0 !default;
$range-icon-top-right-radius: 0 !default;
$range-bigger-icon-container-min-height: 18px !default;
$range-bigger-icon-container-min-width: 36px !default;
$range-icon-margin: 0 !default;
$range-icon-bigger-margin: 0 !default;
$range-sart-end-btn-padding: 1px 6px !default;
$range-popup-padding: 0 !default;
$range-calendar-normal-table-padding: 0 !default;
$range-calendar-bigger-table-padding: 0 !default;
$range-popup-border-radius: 0 !default;
$range-list-border-radius: 0 !default;
$range-bigger-nav-icon-width: 24px !default;
$range-bigger-nav-icon-height: 24px !default;
$range-calendar-popup-padding: 0 8px !default;
$range-bigger-calendar-popup-padding: 0 8px !default;
$range-icon-bigger-padding: 0 !default;
$range-icon-normal-padding: 0 !default;
$range-preset-normal-list-height: 36px !default;
$range-preset-bigger-list-height: 48px !default;
$range-calendar-bigger-max: 296px !default;
$range-calendar-landscape-height: 130px !default;

// new variable
$range-icon: '\e245' !default;
$range-icon-font-size: $font-icon-16 !default;
$range-bigger-icon-font-size: $font-icon-18 !default;
$range-icon-next: '\e913' !default;
$range-icon-prev: '\e921' !default;
$range-change-icon: '\e85f' !default;
$range-rtl-icon-next: '\e921' !default;
$range-rtl-icon-prev: '\e913' !default;
$range-rtl-icon-change: '\e85b' !default;

// Mouse small size
$range-small-header-label-size: 16px !default;
$range-small-font-size: $text-xs !default;
$range-start-end-container-small-height: 32px !default;
$range-calendar-mouse-small-font-size: $text-xs !default;
$range-indicator-small-margin: 0 0 10px 0 !default;
$range-control-small-header-margin: 10px 10px 0 10px !default;
$range-separator-small-margin: 0 10px !default;
$range-small-footer-margin: 10px 10px 10px 8px !default;
$range-preset-small-list-height: 26px !default;
$range-presets-small-list-font-size: $text-xs !default;
$calendar-bigger-small-max-width: 246px !default;
$range-control-header-width: auto !default;
$range-font-weight-normal: $font-weight-normal !default;
$range-flex-style: flex !default;
$range-flex-justify-content: flex-end !default;
$range-inline-block-style: inline-block !default;
$range-block-style: block !default;
$range-table-style: table !default;
$range-display-none:  none !default;
$range-align-center: center !default;
$range-float-right: right !default;
$range-float-left: left !default;
$range-float-none: none !default;
$range-float-clear: both !default;
$range-calendar-border: none !default;
$range-calendar-selection-border: none !default;
$range-calendar-hover-border: none !default;
$range-calendar-other-month-border: none !default;
$range-cursor-default-style: default !default;
$range-cursor-pointer-style: pointer !default;
$range-browser-select-none:  none !default;
$range-flex-direction-row-reverse: row-reverse !default;
$range-flex-direction-row: row !default;
$range-width-auto: auto !default;
$range-value-none: none !default;
$range-visibility-hidden: hidden !default;
$range-text-nowrap: nowrap !default;
$range-direction-left: ltr !default;
$range-text-overflow: ellipsis !default;
$range-calendar-other-normal-border: none !default;

// mouse small icon
$range-small-icon-font-size: $font-icon-14 !default;

// touch small icon
$range-bigger-small-icon-font-size: $font-icon-18 !default;

// color variables
$range-bg-color: $flyout-bg-color !default;
$range-primary-color: $primary !default;
$range-primary-font-color: $primary-text-color !default;
$range-presets-bg: $content-bg-color !default;
$range-preset-normal-font-color: $content-text-color !default;
$range-font-color: $content-text-color !default;
$range-background: $flyout-bg-color !default;
$range-footer-background: $flyout-bg-color !default;
$range-header-bg-color: $flyout-bg-color !default;
$range-active-icon-color: $content-text-color !default;
$range-active-state-icon-color: $content-text-color !default;
$range-hover-color: $content-bg-color-alt3 !default;
$range-hover-content-color: $content-bg-color-alt4 !default;
$range-today-color: $content-bg-color-hover !default;
$range-selection-bg: $primary !default;
$range-other-hover-color: $content-bg-color !default;
$range-other-month-date: $content-text-color-alt2 !default;
$range-box-shadow: $shadow-lg !default;
$range-box-shadow-none: none !default;
$range-calendar-dark-color: $content-text-color-alt1 !default;
$range-calendar-header-text-color: $content-text-color-alt2 !default;
$range-calendar-header-dark-color: $content-text-color-alt1 !default;
$range-calendar-medium-color: $content-text-color-alt3 !default;
$range-calendar-light-color: $content-text-color-alt2 !default;
$range-btn-border-transparent: 1px solid transparent !default;
$range-calendar-hover-border-color: none !default;
$range-change-icon-color: $icon-color !default;
$range-separator-color: $border-light !default;
$range-popup-border: 1px solid $border-light !default;
$range-overlay: $flyout-bg-color !default;
$range-presets-bg-color: $transparent !default;
$range-presets-font-color: $primary !default;
$range-presets-hover-bg: $content-bg-color-hover !default;
$range-list-hover-color: $content-text-color-hover !default;
$range-calendar-active-border: 1px solid $primary !default;
$range-today-border-color: $range-calendar-active-border !default;
$range-calendar-today-color: $content-text-color !default;
$range-calendar-today-start-color: $range-calendar-active-border !default;
$range-active-font-color: $primary-text-color !default;
$range-button-font-color: $blue !default;

// apply and cancel button styles
$range-apply-disabled-background-style: $primary-bg-color-disabled !default;
$range-apply-disabled-border-style: $primary-border-color-disabled !default;
$range-apply-disabled-color: $primary-text-disabled !default;
$apply-buuton-bg: $primary-bg-color !default;
$apply-buuton-bg-border-color: $primary-border-color !default;
$apply-buuton-text-color: $primary-text-color !default;
$apply-buuton-hover-bg: $primary-bg-color-hover !default;
$apply-buuton-bg-border-hover-color: $primary-border-color-hover !default;
$apply-buuton-text-hover-color: $primary-text-hover !default;
$apply-buuton-active-bg: $primary-bg-color-pressed !default;
$apply-buuton-bg-border-active-color: $primary-border-color-pressed !default;
$apply-buuton-text-active-color: $primary-text-pressed !default;
$range-cancel-disabled-background-style: $secondary-bg-color-disabled !default;
$range-cancel-disabled-border-style: $secondary-border-color-disabled !default;
$range-cancel-disabled-color: $secondary-text-color-disabled !default;
$cancel-buuton-bg: $secondary-bg-color !default;
$cancel-buuton-bg-border-color: $secondary-border-color !default;
$cancel-buuton-text-color: $secondary-text-color !default;
$cancel-buuton-hover-bg: $secondary-bg-color-hover !default;
$cancel-buuton-bg-border-hover-color: $secondary-border-color-hover !default;
$cancel-buuton-text-hover-color: $secondary-text-color-hover !default;
$cancel-buuton-active-bg: $secondary-border-color-pressed !default;
$cancel-buuton-bg-border-active-color: $secondary-border-color-pressed !default;
$cancel-buuton-text-active-color: $secondary-text-color-pressed !default;

// modal full-screen styles
$modal-range-portrait-calendar-min-height: 100% !default;
$modal-range-portrait-calendar-min-width: 100% !default;
$modal-range-portrait-calendar-height: 100% !default;
$modal-range-portrait-header-height: 20vh !default;
$modal-range-portrait-header-padding: 2vh 2vw !default;
$modal-range-portrait-month-header-padding: 2vh 2vw !default;
$modal-range-portrait-icon-float: right !default;
$modal-range-portrait-header-month-height: 10vh !default;
$modal-range-month-header-line-height: 5vh !default;
$modal-range-month-landscape-title-line-height: 8vh !default;
$modal-range-month-header-title-line-height: inherit !default;
$modal-range-portrait-calendar-content-height: 69vh !default;
$modal-range-portrait-calendar-tabel-height: 69vh !default;
$modal-range-landscape-header-big-height: 25vh !default;
$modal-range-portrait-calendar-width: 100% !default;
$modal-range-start-end-margin: 3vh 0 0 0 !default;
$modal-range-start-end-tablet-margin: 5vh 0 0 0 !default;
$modal-range-portrait-calendar-height: 70vh !default;
$modal-range-landscape-calendar-height: 80vh !default;
$modal-range-landscape-container-height: 75vh !default;
$modal-range-header-height: 60vh !default;
$modal-range-tablet-header-height: 65vh !default;
$modal-range-calendar-padding: 0 !default;
$modal-range-calendar-overflow: unset !default;
$modal-range-calendar-header-border-style: solid !default;
$modal-range-calendar-header-border-width: 0 0 1px 0 !default;
$modal-range-prev-next-icon-size: 36px !default;
$modal-range-prev-next-icon-padding: 10px !default;
$modal-range-prev-next-icon-line-height: 1 !default;
$modal-range-calendar-th-size: 48px !default;
$modal-range-tablet-content-size: 64px !default;
$modal-range-range-hover-radius: 0 !default;
$modal-range-table-padding: 0 2vw !default;
$modal-range-footer-display: none !default;
$modal-range-landscape-header-height: 27vh !default;
$modal-range-landscape-conetent-overflow: auto !default;
$modal-range-table-display: table !default;
$modal-range-start-end-size: 38px !default;

// header styles for presets
$modal-range-presets-header-height: 10% !default;
$modal-range-presets-portrait-height: 90% !default;
$modal-range-presets-landscape-height: 85% !default;
$modal-range-header-padding: 2.5vh 2.5vw !default;
$modal-range-header-display-style: flex !default;
$modal-range-header-content-align: center !default;
$modal-range-header-portrait-font-size: 3vh !default;
$modal-close-icon-float: left !default;
$modal-portrait-content-padding: 1vh 2vw !default;
$modal-range-header-title-transform: capitalize !default;
$modal-range-header-border-bottom: 1px solid $border-light !default;
$modal-range-header-landscape-height: 15% !default;
$modal-range-header-landscape-font-size: 2vw !default;
$modal-landscape-padding: 1vh 1vw !default;

// modal dialog colors
$modal-range-header-bg-color: $flyout-bg-color !default;
$modal-range-header-text-color: $content-text-color-alt1 !default;

// tablet device style changes for modal popup
$modal-range-tablet-font-size: 24px !default;

$range-header-font-size: 16px !default;
