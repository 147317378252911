//layout variables
$listbox-font-family: $font-family !default;
$listbox-icon-margin-right: 16px !default;
$listbox-icon-line-height: 34px !default;
$listbox-icon-back-margin: 2px !default;
$listbox-rtl-icon-back-margin: -2px !default;
$listbox-item-line-height: 1 !default;
$listbox-item-padding: 10px 12px !default;
$listbox-bigger-item-padding: 8px 16px !default;
$listbox-header-text-padding: 12px !default;
$listbox-header-font-size: $text-xs !default;
$listbox-header-height: 18px !default;
$listbox-header-font-weight: $font-weight-medium !default;
$listbox-header-line-height: 18px !default;
$listbox-header-padding-bottom: 0 !default;
$listbox-touch-back-icon-padding: 0 !default;
$listbox-border-bottom: 0 !default;
$listbox-border-top: 0 !default;
$listbox-border-left: 0 !default;
$listbox-border-right: 0 !default;
$listbox-group-first-border-bottom: 0 !default;
$listbox-group-border-top: 1px !default;
$listbox-group-border-bottom: 0 !default;
$listbox-groupheader-item-height: 30px !default;
$listbox-groupheader-item-line-height: 10px !default;
$listbox-groupheader-font-size: $text-xs !default;
$listbox-border-size: 0 !default;
$listbox-font-size: $text-sm !default;
$listbox-back-padding-right: 8px !default;
$listbox-touch-item-height: 48px !default;
$listbox-touch-item-line-height: 1.6 !default;
$listbox-touch-item-font-size: $text-base !default;
$listbox-touch-groupheader-height: 40px !default;
$listbox-touch-groupheader-line-height: 25px !default;
$listbox-touch-header-height: 22px !default;
$listbox-touch-header-font-weight: $font-weight-medium !default;
$listbox-touch-header-font-size: $text-sm !default;
$listbox-touch-header-line-height: 22px !default;
$listbox-touch-header-icon-margin-top: 2px !default;
$listbox-checkbox-right-margin: -2px 0 0 8px !default;
$listbox-checkbox-left-margin: -2px 8px 0 0 !default;
$listbox-rtl-checkbox-left-margin: -2px 0 0 8px !default;
$listbox-rtl-checkbox-right-margin: -2px 8px 0 0 !default;

//ListView In-built template variables
$listbox-template-padding: 8px 16px !default;
$listbox-template-avatar-padding-right: 16px !default;
$listbox-template-avatar-padding-left: 68px !default;
$listbox-template-avatar-rightposition-padding-right: 68px !default;
$listbox-template-avatar-rightposition-padding-left: 16px !default;
$listbox-template-avatar-size: 40px !default;
$listbox-template-avatar-top: 0 !default;
$listbox-template-avatar-left: 0 !default;
$listbox-template-avatar-rightposition-right: 0 !default;
$listbox-template-badge-height: 18px !default;
$listbox-template-badge-width: 32px !default;
$listbox-template-badge-line-height: 16px !default;
$listbox-template-badge-font-size: $text-xxs !default;
$listbox-template-badge-right: 12px !default;
$listbox-template-avatar-badge-padding-right: 10px !default;
$listbox-template-avatar-badge-padding-left: 68px !default;
$listbox-template-badgewithoutavatar-padding-right: 10px !default;
$listbox-template-badgewithoutavatar-padding-left: 16px !default;
$listbox-template-item-padding: 8px 0 !default;
$listbox-template-multiline-header-padding: 0 !default;
$listbox-template-multiline-content-padding: 2px 0 0 0 !default;
$listbox-template-multiline-header-font-size: $text-sm !default;
$listbox-template-multiline-padding: 4px !default;
$listbox-template-multiline-content-font-size: $text-sm !default;
$listbox-template-multiline-avatar-top: 0 !default;
$listbox-filter-height: calc(100% - 47px) !default;
$listbox-filter-small-height: calc(100% - 45px) !default;
$listbox-filter-bigger-small-height: calc(100% - 45px) !default;
$listbox-filter-bigger-height: calc(100% - 55px) !default;
$listbox-item-height: 36px !default;

$listbox-border-color: $border-light;
$badge-color: $primary-text-color !default;
$badge-bgcolor: $primary !default;

$select-all-border-color: $border-light !default;
$listbox-text-color: $content-text-color !default;
$listbox-text-disabled: $content-text-color-disabled !default;
$listbox-icon-color: $icon-color !default;
$listbox-icon-disabled: $icon-color-disabled !default;
$listbox-item-hover-bg: $content-bg-color-alt2 !default;
$listbox-background: $transparent !default;
$listbox-line-color: $border-light !default;
$listbox-hover-border-color: transparent !default;
$listbox-border-bottom-color: transparent !default;
$listbox-border-top-color: transparent !default;
$listbox-border-right-color: transparent !default;
$listbox-border-left-color: transparent !default;
$listbox-text-hover-color: $content-text-color !default;
$listbox-item-active-bg: $content-bg-color-alt2 !default;
$listbox-text-active-color: rgba($content-text-color, .87) !default;
$listbox-header-text-color: $content-text-color-alt2 !default;
$listbox-header-text-disabled: $content-text-color-disabled !default;
$listbox-header-bg: $content-bg-color-alt2 !default;
$listbox-header-icon-color: $icon-color !default;
$listbox-header-icon-disabled: $icon-color-disabled !default;
$listbox-header-border: rgba($black, .12) !default;
$checkmark-bgcolor: $primary !default;
$checkmark-border-color: transparent !default;
$checkmark-color: $primary-text-color !default;
$listbox-groupheader-text-color: $black !default;
$listbox-groupheader-bg: $content-bg-color-alt2 !default;
$listbox-groupheader-border: $black !default;
$listbox-groupheader-icon: $icon-color !default;
$listbox-template-multiline-header-color: $content-text-color !default;
$listbox-template-multiline-content-color: $content-text-color-alt2 !default;

$listbox-select-height: calc(100% - 36px) !default;
$listbox-select-bigger-height: calc(100% - 40px) !default;
$listbox-filterselect-height: calc(100% - 86px) !default;
$listbox-filterselect-bigger-height: calc(100% - 96px) !default;
$listbox-filter-height: calc(100% - 50px) !default;
$listbox-filter-bigger-height: calc(100% - 56px) !default;
