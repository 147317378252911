@use '@angular/material' as mat;
@import 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Symbols+Outlined';
@import './partials/cerrix-primary-palette';
@import './partials/cerrix-accent-palette';
@import './partials/cerrix-warn-palette';
@import './partials/colors.scss';
@import './partials/variables.scss';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400..700&display=swap');
@import '@angular/material/prebuilt-themes/indigo-pink.css';

// // Import Montserrat font
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

// // Define your typography configuration
// $custom-typography: mat-typography-config(
//   $font-family: 'Montserrat',
// );

// // Include the typography configuration in your Angular Material theme
// @include mat.core($custom-typography);

$cerrix-primary: mat.m2-define-palette($cerrix-primary-palette);
$cerrix-accent: mat.m2-define-palette($cerrix-accent-palette);
$cerrix-warn: mat.m2-define-palette($cerrix-warn-palette);

$font-typography: mat.m2-define-typography-config(
  $font-family: $font-family,
);

$cerrix-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $cerrix-primary,
      accent: $cerrix-accent,
      warn: $cerrix-warn,
    ),
    typography: $font-typography,
  )
);

@include mat.core-theme($cerrix-theme);
@include mat.all-component-themes($cerrix-theme);
