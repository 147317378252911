@include export-module('input-bootstrap5-icons') {
  .e-float-input,
  .e-float-input.e-control-wrapper {
    .e-clear-icon::before {
      content: '\e7e7';
      font-family: 'e-icons';
    }
  }

  .e-input-group,
  .e-input-group.e-control-wrapper {
    .e-clear-icon::before {
      content: '\e7e7';
      font-family: 'e-icons';
    }
  }
}
