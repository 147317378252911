@include export-module('rating-theme') {
  .e-rating-container {
    .e-rating-item-list {
      &:focus-visible:not(:hover) .e-rating-focus {
        outline-color: $rating-li-focus;
        border-radius: $rating-focus-border-radius;
      }
    }

    .e-reset {
      color: $rating-reset-icon-color;

      &:hover {
        color: $rating-reset-hover-icon-color;
      }

      &.e-disabled {
        color: $rating-reset-disabled-icon-color;
        opacity: 1;
      }
    }

    .e-rating-item-container {
      .e-rating-icon {
        -webkit-text-fill-color: transparent;
        @if ($skin-name == 'Material3') {
          -webkit-text-stroke: $rating-border-width transparent;
        }
        @else {
          -webkit-text-stroke: $rating-border-width $rating-unrated-bg-color;
        }
        @if ($skin-name == 'Material3' or $skin-name =='tailwind') {
          background: $rating-unrated-bg-color;
          background-clip: text;
          /* stylelint-disable property-no-vendor-prefix */
          -webkit-background-clip: text;
          /* stylelint-enable property-no-vendor-prefix */
        }
      }

      &.e-rating-selected .e-rating-icon,
      &.e-rating-intermediate .e-rating-icon {
        background: linear-gradient(to right, $rating-selected-bg-color var(--rating-value), transparent var(--rating-value));
        background-clip: text;
        /* stylelint-disable property-no-vendor-prefix */
        -webkit-background-clip: text;
        /* stylelint-enable property-no-vendor-prefix */
        -webkit-text-stroke: $rating-border-width $rating-selected-bg-color;
      }

      &.e-selected-value .e-rating-icon {
        -webkit-text-stroke: $rating-border-width $rating-hover-bg-color;
        @if ($skin-name =='bootstrap5') {
          -webkit-text-stroke: $rating-border-width $rating-selected-bg-color;
        }
      }
    }

    .e-rating-item-list {
      &:hover .e-rating-item-container {
        &.e-rating-selected .e-rating-icon,
        &.e-rating-intermediate .e-rating-icon {
          background: linear-gradient(to right, $rating-hover-bg-color var(--rating-value), transparent var(--rating-value));
          background-clip: text;
          /* stylelint-disable property-no-vendor-prefix */
          -webkit-background-clip: text;
          /* stylelint-enable property-no-vendor-prefix */
          @if ($skin-name == 'Material3') {
            -webkit-text-stroke: $rating-border-width $rating-selected-bg-color;
          }
          @else {
            -webkit-text-stroke: $rating-border-width $rating-hover-bg-color;
          }
        }
      }

      .e-rating-item-container {
        &.e-rating-selected:active .e-rating-icon,
        &.e-rating-intermediate:active .e-rating-icon {
          background: linear-gradient(to right, $rating-pressed-bg-color var(--rating-value), transparent var(--rating-value));
          background-clip: text;
          /* stylelint-disable property-no-vendor-prefix */
          -webkit-background-clip: text;
          /* stylelint-enable property-no-vendor-prefix */
          @if ($skin-name == 'bootstrap4') {
            -webkit-text-stroke: $rating-border-width $rating-hover-bg-color;
          }
          @else {
            -webkit-text-stroke: $rating-border-width $rating-pressed-bg-color;
          }
        }
      }
    }

    &.e-rtl {
      .e-rating-item-container {
        &.e-rating-selected .e-rating-icon,
        &.e-rating-intermediate .e-rating-icon {
          background: linear-gradient(to left, $rating-selected-bg-color var(--rating-value), transparent var(--rating-value));
          background-clip: text;
          /* stylelint-disable property-no-vendor-prefix */
          -webkit-background-clip: text;
          /* stylelint-enable property-no-vendor-prefix */
        }
      }

      .e-rating-item-list {
        &:hover .e-rating-item-container {
          &.e-rating-selected .e-rating-icon,
          &.e-rating-intermediate .e-rating-icon {
            background: linear-gradient(to left, $rating-hover-bg-color var(--rating-value), transparent var(--rating-value));
            background-clip: text;
            /* stylelint-disable property-no-vendor-prefix */
            -webkit-background-clip: text;
            /* stylelint-enable property-no-vendor-prefix */
          }
        }

        .e-rating-item-container {
          &.e-rating-selected:active .e-rating-icon,
          &.e-rating-intermediate:active .e-rating-icon {
            background: linear-gradient(to left, $rating-pressed-bg-color var(--rating-value), transparent var(--rating-value));
            background-clip: text;
            /* stylelint-disable property-no-vendor-prefix */
            -webkit-background-clip: text;
            /* stylelint-enable property-no-vendor-prefix */
          }
        }
      }
    }

    &.e-disabled {
      opacity: 1;
      .e-rating-item-container {
        .e-rating-icon {
          @if ($skin-name == 'Material3') {
            -webkit-text-stroke: $rating-border-width transparent;
            background: $rating-unrated-disabled-bg-color;
            background-clip: text;
            /* stylelint-disable property-no-vendor-prefix */
            -webkit-background-clip: text;
            /* stylelint-enable property-no-vendor-prefix */
          }
          @else{
            -webkit-text-stroke: $rating-border-width $rating-unrated-disabled-bg-color;
          }
        }

        &.e-rating-selected .e-rating-icon,
        &.e-rating-intermediate .e-rating-icon {
          background: linear-gradient(to right, $rating-selected-disabled-bg-color var(--rating-value), transparent var(--rating-value));
          background-clip: text;
          /* stylelint-disable property-no-vendor-prefix */
          -webkit-background-clip: text;
          /* stylelint-enable property-no-vendor-prefix */
          @if ($skin-name == 'Material3') {
            -webkit-text-stroke: $rating-border-width transparent;
          }
          @else{
            -webkit-text-stroke: $rating-border-width $rating-selected-disabled-bg-color;
          }
        }
      }

      &.e-rtl {
        .e-rating-item-container {
          .e-rating-icon {
            @if ($skin-name == 'Material3') {
              background: $rating-unrated-disabled-bg-color;
            }
            @else{
              background: linear-gradient(to left, $rating-unrated-disabled-bg-color var(--rating-value), transparent var(--rating-value));
            }
            background-clip: text;
            /* stylelint-disable property-no-vendor-prefix */
            -webkit-background-clip: text;
            /* stylelint-enable property-no-vendor-prefix */
          }

          &.e-rating-selected .e-rating-icon,
          &.e-rating-intermediate .e-rating-icon {
            background: linear-gradient(to left, $rating-selected-disabled-bg-color var(--rating-value), transparent var(--rating-value));
            background-clip: text;
            /* stylelint-disable property-no-vendor-prefix */
            -webkit-background-clip: text;
            /* stylelint-enable property-no-vendor-prefix */
          }
        }
      }
    }
  }
}
