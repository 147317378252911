/*! component's theme wise override definitions and variables */
$vscroll-skin: 'bootstrap5' !default;
$vscroll-nav-nrml-height: 38px !default;
$vscroll-hover-font: $content-text-color-alt2 !default;
$vscroll-active-font-color: $content-text-color-alt2 !default;
$vscroll-border-size: 1px !default;
$vscroll-border-type: solid !default;

$vscroll-default-bg: $content-bg-color-alt2 !default;
$vscroll-nav-nrml-minheight: 38px !default;
$vscroll-nav-bgr-minheight: 48px !default;
$vscroll-nav-nrml-width: 28px !default;
$vscroll-nav-bgr-width: 48px !default;
$vscroll-nrml-padding: 0 $vscroll-nav-nrml-width !default;
$vscroll-bgr-padding: $vscroll-nav-bgr-width 0 !default;
$vscroll-box-shadow: none !default;
$vscroll-overlay-opacity: .5 !default;
$vscroll-overlay-bg: $content-bg-color-alt2 !default;
$vscroll-overlay-start: rgba($vscroll-overlay-bg, 0) !default;
$vscroll-overlay-end: rgba($vscroll-overlay-bg, 1) !default;
$vscroll-right-bg: linear-gradient(-270deg, $vscroll-overlay-start 0%, $vscroll-overlay-end 100%) !default;
$vscroll-left-bg:  linear-gradient(-270deg, $vscroll-overlay-end 0%, $vscroll-overlay-start 100%) !default;

$vscroll-device-arrow-box-shadow: $shadow !default;
$vscroll-device-arrow-rtl-box-shadow: $shadow !default;
$vscroll-device-arrow-bg: $content-bg-color-alt2 !default;
$vscroll-device-arrow-border-size: 1px !default;
$vscroll-device-arrow-border-color: $icon-color !default;
$vscroll-device-arrow-color: $icon-color !default;
$vscroll-device-arrow-size: 14px !default;
$vscroll-device-arrow-width: 48px !default;

$vscroll-default-icon-color: $icon-color !default;
$vscroll-hover-bg: $content-bg-color-alt3 !default;
$vscroll-press-bg: $content-bg-color-alt3 !default;
$vscroll-default-border: $icon-color !default;
$vscroll-focus-border: 0 !default;
$vscroll-active-border: 0 !default;
$vscroll-hover-border: 0 !default;
$vscroll-hover-border-color: transparent !default;
$vscroll-active-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !default;

@mixin vscroll-btn-animation {
  content: '';
}

@mixin vscroll-btn-animation-after {
  content: '';
}
