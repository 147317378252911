// Default styling for Cerrix Detail pages using the new styling system
.cerrix-detail-page {
    --default-gap: 20px;
    --page-margin: 20px;
    --header-color: #0c2952;

    --new-blue: #0c66e4;
    --new-green: #66c664;
    --new-orange: #f39a14;
    --new-red: #e34935;

    .row {
        &.row-gap {
            row-gap: var(--default-gap);
        }

        &.column-gap {
            column-gap: var(--default-gap);
        }
    }

    .cerrix-row {
        display: flex;
        flex-flow: row;
        gap: var(--default-gap);

        &.header {
            border-radius: 3px;
            font-weight: bold;

            background-color: #fbfbfb;
            border: 2px solid #e5f1fd;
            padding: 10px 15px;
        }
    }

    .cerrix-column {
        display: flex;
        flex-flow: column;
        gap: var(--default-gap);
    }

    // This ensures the button does not have an underline when it is configured with the '<a>' tag.
    a.cerrix-button {
        text-decoration: none;
    }

    .cerrix-button {
        padding: 5px 15px;
        border: none;
        border-radius: 3px;

        span + i,
        i + span {
            margin-left: 10px;
        }

        &.small {
            font-size: 0.8em;
        }

        &.large {
            padding: 10px 25px;
        }

        &.primary {
            background-color: var(--new-blue);
            color: white;
        }

        &.secondary {
            background-color: transparent;
            color: var(--new-blue);
            border: 2px solid var(--new-blue);
        }

        &.green {
            background-color: var(--new-green);
            color: white !important;
        }

        &.orange {
            background-color: var(--new-orange);
            color: white !important;
        }

        &.red {
            background-color: var(--new-red);
            color: white !important;
        }

        &.grey {
            background-color: var(--bs-gray-300);
        }

        &.link-button {
            background-color: transparent;

            font-weight: bold;
            color: var(--accent-color);
            text-decoration: underline;
            cursor: pointer;
            padding: 0;
        }
    }

    .title-bar {
        display: flex;
        flex-flow: row;
        gap: 0 10px;

        .title {
            font-size: 30px;
            font-weight: bold;
        }

        .help {
            display: flex;
            flex-flow: column;
            justify-content: center;
        }
    }

    .button-bar {
        display: flex;
        flex-flow: row;
        gap: 0 15px;

        .separator {
            width: 2px;
            height: 100%;

            background-color: #e5f1fd;
        }
    }

    .bottom-button-bar {
        display: flex;
        flex-flow: row;
        gap: 0 15px;
        justify-content: flex-end;

        padding: 20px;
        border: 2px solid rgba(9, 30, 66, 14%);
        border-radius: 3px;
    }

    .page-area {
        margin: var(--page-margin);
    }

    .workflow-area {
        margin: var(--page-margin);
    }

    .breadcrumb-area {
        font-size: 12px;
        margin: var(--page-margin);

        .section {
            &.bold {
                font-weight: bold;
            }

            span:not(:first-child) {
                margin-left: 5px;
            }

            span:not(:last-child) {
                margin-right: 5px;
            }
        }

        .separator {
            margin: 0 10px;
        }

        &.wide-separators {
            .separator {
                margin: 0 20px;
            }
        }
    }

    .main-area {
        margin: var(--page-margin);

        display: flex;
        flex-flow: row;
        gap: var(--default-gap) 5vw;
        flex-wrap: wrap;

        .main-content {
            display: flex;
            flex-flow: column;
            gap: var(--default-gap);

            // This ensures that the main content area is always at least 800px wide and takes up 2/3 of the available space
            flex: 2;
            min-width: 800px;
        }
    }

    .side-area {
        display: flex;
        flex-flow: column;
        gap: var(--default-gap);

        // This ensures that the side area is always at least 400px wide and takes up 1/3 of the available space
        flex: 1;
        min-width: 400px;
    }

    .data-grid {
        display: flex;
        flex-direction: row;
        gap: var(--default-gap);
        flex-wrap: wrap;

        .header {
            flex: 0 0 auto;
            width: calc(40% - (var(--default-gap) / 2));

            color: var(--header-color);
            font-weight: bold;
        }

        .value {
            flex: 0 0 auto;
            width: calc(60% - (var(--default-gap) / 2));
            word-wrap: break-word;
        }

        .full-width {
            width: 100%;
        }
    }
}
