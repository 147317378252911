/*! Tab's bootstrap5 theme wise override definitions and variables */
/* stylelint-disable */
/*! Horizontal Tab */
$tab-skin: 'bootstrap5' !default;

// font definitions
$tab-font-family: $font-family !default;
$tab-big-vertical-nav-arrow-font-size: $text-xs !default;
$tab-nrml-vertical-nav-arrow-font-size: $text-xxs !default;
$tab-content-font-color: $content-text-color !default;
$tab-big-font-size: $text-base !default;
$tab-nrml-font-size: $text-sm !default;
$tab-font-weight: $font-weight-normal !default;
$tab-semi-font-weight: $font-weight-normal !default;
$tab-light-font-weight: $font-weight-normal !default;

// dimension definitions
$tab-big-height: 42px !default;
$tab-nrml-height: 32px !default;
$tab-focus-big-height: 42px !default;
$tab-focus-nrml-height: 32px !default;
$tab-active-big-height: 42px !default;
$tab-active-nrml-height: 32px !default;
$tab-active-focus-big-height: 42px !default;
$tab-active-focus-nrml-height: 32px !default;
$tab-big-fill-height: 42px !default;
$tab-nrml-fill-height: 32px !default;
$tab-mob-fill-height: 42px !default;
$tab-focus-big-fill-height: 42px !default;
$tab-focus-nrml-fill-height: 32px !default;
$tab-disable-cnt-opacity: .38 !default;
$tab-big-tb-icon-height: 72px !default;
$tab-nrml-tb-icon-height: 62px !default;
$tab-big-vertical-scroll-nav-top-bottom: 23px !default;
$tab-big-vertical-nav-arrow-size: 24px !default;
$tab-nrml-vertical-scroll-nav-top-bottom: 15px !default;
$tab-nrml-vertical-nav-arrow-size: 16px !default;
$tab-text-transform: capitalize !default;
$tab-border-width: 2px !default;
$tab-border-style: $tab-border-width Solid !default;
$tab-focus-outline: 0 !default;
$tab-active-focus-outline: 0 !default;
$tab-active-text-item-border: 0 !default;
$tab-pop-up-icon-hover-border: $tab-active-text-item-border !default;
$tab-pop-up-icon-focus-border: $tab-active-text-item-border !default;
$tab-pop-up-down-icon-hover-border: $tab-active-text-item-border !default;
$tab-pop-up-icon-active-border: $tab-active-text-item-border !default;
$tab-disable-active-text-item-border: 0 !default;
$tab-fill-hdr-border: 0 !default;
$tab-fill-disable-hdr-border: 0 !default;
$tab-anim-border-display: none !default;
$tab-border: none !default;
$tab-focus-hscroll-content-padding: 1px 0 !default;
$tab-hdr-border-width: 0 0 1px 0 !default;
$tab-alt-hdr-border: 0 !default;
$tab-items-border: 1px solid $primary-text-color !default;
$tab-big-item-margin: 0 !default;
$tab-nrml-item-margin: 0 !default;
$tab-big-item-padding: 0 !default;
$tab-nrml-item-padding: 0 !default;
$tab-big-icon-top-bottom-item-height: 72px !default;
$tab-nrml-icon-top-bottom-item-height: 62px !default;
$tab-pop-big-icon-top-bottom-item-height: 50px !default;
$tab-pop-nrml-icon-top-bottom-item-height: 40px !default;
$tab-big-focus-icon-top-bottom-item-height: 72px !default;
$tab-nrml-focus-icon-top-bottom-item-height: 62px !default;
$tab-big-fill-icon-top-bottom-item-height: 72px !default;
$tab-nrml-fill-icon-top-bottom-item-height: 62px !default;
$tab-big-focus-fill-icon-top-bottom-item-height: 72px !default;
$tab-nrml-focus-fill-icon-top-bottom-item-height: 62px !default;
$tab-big-first-item-margin: 0 !default;
$tab-nrml-first-item-margin: 0 !default;
$tab-big-last-item-margin: 0 2px 0 0 !default;
$tab-nrml-last-item-margin: 0 !default;
$tab-big-active-last-item-margin: 0 2px 0 0 !default;
$tab-nrml-active-last-item-margin: 0 2px 0 0 !default;
$tab-big-scroll-last-item-margin: 0 2px 0 0 !default;
$tab-nrml-scroll-last-item-margin: 0 2px 0 0 !default;
$tab-nrml-active-scroll-last-item-margin: 0 2px 0 0 !default;
$tab-mob-last-item-margin: 0 2px 0 0 !default;
$tab-big-even-last-item-margin: 0 2px 0 0 !default;
$tab-nrml-even-last-item-margin: 0 2px 0 0 !default;
$tab-mob-even-last-item-margin: 0 2px 0 0 !default;
$tab-big-alt-first-item-margin: 0 !default;
$tab-nrml-alt-first-item-margin: 0 !default;
$tab-pop-item-margin: 0 !default;
$tab-big-pop-item-height: 40px !default;
$tab-nrml-pop-item-height: 26px !default;
$tab-alt-item-margin: 0 2px 0 0 !default;
$tab-big-rtl-item-margin: 0 !default;
$tab-nrml-rtl-item-margin: 0 !default;
$tab-big-rtl-alt-item-margin: 0 0 0 2px !default;
$tab-nrml-rtl-alt-item-margin: 0 0 0 2px !default;
$tab-mob-pop-item-height: 50px !default;
$tab-item-border: 1px solid $transparent !default;
$tab-active-item-border: 1px solid $border-light !default;
$tab-active-item-hide-border: 1px solid $content-bg-color !default;
$tab-active-item-border-color: $content-bg-color !default;
$tab-big-boot-item-margin: 0 !default;
$tab-nrml-boot-item-margin: 0 !default;
$tab-big-scroll-boot-item-margin: 0 2px 0 0 !default;
$tab-nrml-scroll-boot-item-margin: 0 2px 0 0 !default;
$tab-mob-boot-item-margin: 0 2px 0 0 !default;
$tab-big-even-boot-item-margin: 0 2px 0 0 !default;
$tab-nrml-even-boot-item-margin: 0 2px 0 0 !default;
$tab-mob-even-boot-item-margin: 0 2px 0 0 !default;
$tab-big-boot-active-item-padding-bottom: 0 !default;
$tab-nrml-boot-active-item-padding-bottom: 0 !default;
$tab-big-even-boot-active-item-padding-bottom: 1px !default;
$tab-nrml-even-boot-active-item-padding-bottom: 0 !default;
$tab-active-item-border-radius: 4px !default;
$tab-item-wrap-border-radius: 4px !default;
$tab-top-active-item-border-radius: 4px 4px 0 0 !default;
$tab-bottom-active-item-border-radius: 0 0 4px 4px !default;
$tab-left-active-item-border-radius: 4px 0 0 4px !default;
$tab-right-active-item-border-radius: 0 4px 4px 0 !default;
$tab-hscroll-item-padding: 0 !default;
$tab-big-item-last-child-padding-bottom: 3px !default;
$tab-nrml-item-last-child-padding-bottom: 1px !default;
$tab-big-wrap-padding: 0 16px !default;
$tab-nrml-wrap-padding: 0 12px !default;
$tab-mob-wrap-padding: 0 15px !default;
$tab-pop-padding: 5px 0 !default;
$tab-big-pop-item-wrap-padding: 0 24px !default;
$tab-nrml-pop-item-wrap-padding: 0 20px !default;
$tab-mob-pop-item-wrap-padding: 0 24px !default;
$tab-big-io-text-wrap-width: auto !default;
$tab-nrml-io-text-wrap-width: auto !default;
$tab-big-io-wrap-padding: 0 20px !default;
$tab-nrml-io-wrap-padding: 0 15px !default;
$tab-wrap-focus-margin-right: 0 !default;
$tab-wrap-focus-margin-left: 0 !default;
$tab-big-even-ileft-active-text-wrap-margin: 0 !default;
$tab-nrml-even-ileft-active-text-wrap-margin: 0 !default;
$tab-big-fill-tb-wrap-height: 50px !default;
$tab-nrml-fill-tb-wrap-height: 44px !default;
$tab-fill-active-wrap-corner: 3px 3px 0 0 !default;
$tab-fill-even-active-wrap-corner: 0 0 3px 3px !default;
$tab-alt-tb-active-bg-color: rgba($primary, 0) !default;
$tab-fill-big-wrap-padding: 0 20px !default;
$tab-fill-nrml-wrap-padding: 0 15px !default;
$tab-mob-fill-wrap-padding: 0 15px !default;
$tab-bg-big-wrap-padding: 0 20px !default;
$tab-bg-nrml-wrap-padding: 0 15px !default;
$tab-alt-disable-active-item-opacity: .65 !default;
$tab-big-active-item-wrap-margin-bottom: 1px !default;
$tab-nrml-active-item-wrap-margin-bottom: 0 !default;
$tab-fill-big-active-item-wrap-margin-bottom: 0 !default;
$tab-fill-nrml-active-item-wrap-margin-bottom: 0 !default;
$tab-bg-big-active-item-wrap-margin-bottom: 0 !default;
$tab-bg-nrml-active-item-wrap-margin-bottom: 0 !default;
$tab-alt-big-active-item-padding: 0 !default;
$tab-alt-nrml-active-item-padding: 0 !default;
$tab-active-text-container-margin: 0 !default;
$tab-big-text-container-margin-right: 22px !default;
$tab-nrml-text-container-margin-right: 20px !default;
$tab-rtl-big-text-container-margin-right: 0 !default;
$tab-rtl-nrml-text-container-margin-right: 0 !default;
$tab-rtl-big-text-container-margin-left: 22px !default;
$tab-rtl-nrml-text-container-margin-left: 20px !default;
$tab-big-active-text-container-height: 42px !default;
$tab-big-active-it-text-container-height: 42px !default;
$tab-fill-big-active-it-text-container-height: 42px !default;
$tab-fill-nrml-active-it-text-container-height: 32px !default;
$tab-big-even-active-text-container-height: 42px !default;
$tab-nrml-even-active-text-container-height: 32px !default;
$tab-even-active-text-container-margin: 0 !default;
$tab-big-it-text-margin: 0 0 0 8px !default;
$tab-nrml-it-text-margin: 0 0 0 8px !default;
$tab-mob-it-text-margin: 0 0 0 10px !default;
$tab-rtl-mob-it-text-margin: 0 10px 0 0 !default;
$tab-rtl-big-it-text-margin: 0 10px 0 0 !default;
$tab-rtl-nrml-it-text-margin: 0 8px 0 0 !default;
$tab-big-icon-top-margin: 8px 0 0 !default;
$tab-nrml-icon-top-margin: 8px 0 0 !default;
$tab-big-icon-bottom-margin: 0 0 10px !default;
$tab-nrml-icon-bottom-margin: 0 0 8px !default;
$tab-big-pop-text-padding-right: 12px !default;
$tab-nrml-pop-text-padding-right: 10px !default;
$tab-rtl-big-pop-text-padding-left: 12px !default;
$tab-rtl-nrml-pop-text-padding-left: 10px !default;
$tab-big-even-active-text-height: 50px !default;
$tab-nrml-even-active-text-height: 40px !default;
$tab-fill-big-even-active-text-height: 50px !default;
$tab-fill-nrml-even-active-text-height: 40px !default;
$tab-icon-container-width: auto !default;
$tab-big-nav-icon-container-size: 38px !default;
$tab-nrml-nav-icon-container-size: 32px !default;
$tab-big-nav-icon-size: 12px !default;
$tab-nrml-nav-icon-size: 10px !default;
$tab-mob-nav-icon-size: 16px !default;
$tab-mob-nav-vicon-size: 12px !default;
$tab-big-icon-size: 16px !default;
$tab-nrml-icon-size: 14px !default;
$tab-mob-icon-size: 20px !default;
$tab-big-icon-container-size: 24px !default;
$tab-nrml-icon-container-size: 24px !default;
$tab-mob-icon-container-size: 20px !default;
$tab-big-nav-icon-top: 11px !default;
$tab-nrml-nav-icon-top: 5px !default;
$tab-big-nav-pop-icon-top: 13px !default;
$tab-nrml-nav-pop-icon-top: 8px !default;
$tab-big-safari-nav-icon-top: 4px !default;
$tab-nrml-safari-nav-icon-top: 4px !default;
$tab-big-safari-pop-icon-top: 6px !default;
$tab-nrml-safari-pop-icon-top: 6px !default;
$tab-big-ie-nav-icon-line-height: 15px !default;
$tab-nrml-ie-nav-icon-line-height: 14px !default;
$tab-big-ie-pop-icon-line-height: 15px !default;
$tab-nrml-ie-pop-icon-line-height: 15px !default;
$tab-big-edge-nav-icon-line-height: 13px !default;
$tab-nrml-edge-nav-icon-line-height: 11px !default;
$tab-big-edge-pop-icon-line-height: 15px !default;
$tab-nrml-edge-pop-icon-line-height: 14px !default;
$tab-close-icon-size: 12px !default;
$tab-big-close-icon-size: 12px !default;
$tab-nrml-close-icon-size: 10px !default;
$tab-mob-close-icon-size: 12px !default;
$tab-close-icon-minwidth: 12px !default;
$tab-big-close-icon-margin: 2px 0 0 8px !default;
$tab-nrml-close-icon-margin: 0 0 0 6px !default;
$tab-mob-close-icon-margin: 0 0 0 8px !default;
$tab-rtl-mob-close-icon-margin: 0 8px 0 0 !default;
$tab-rtl-big-close-icon-margin: 0 10px 0 0 !default;
$tab-rtl-nrml-close-icon-margin: 0 8px 0 0 !default;
$tab-rtl-big-pop-close-icon-margin: 0 !default;
$tab-rtl-nrml-pop-close-icon-margin: 0 !default;
$tab-big-close-icon-top-bottom-right: 20px !default;
$tab-nrml-close-icon-top-bottom-right: 15px !default;
$tab-big-pop-close-icon-top-bottom-right: 24px !default;
$tab-nrml-pop-close-icon-top-bottom-right: 20px !default;
$tab-rtl-big-close-icon-top-bottom-right: 0 !default;
$tab-rtl-nrml-close-icon-top-bottom-right: 0 !default;
$tab-rtl-big-close-icon-top-bottom-left: 20px !default;
$tab-rtl-nrml-close-icon-top-bottom-left: 15px !default;
$tab-rtl-big-pop-close-icon-top-bottom-left: 24px !default;
$tab-rtl-nrml-pop-close-icon-top-bottom-left: 20px !default;
$tab-pop-close-icon-right: 16px !default;
$tab-rtl-pop-close-icon-left: 16px !default;
$tab-mob-close-icon-top-bottom-right: 12px !default;
$tab-rtl-mob-close-icon-top-bottom-left: 12px !default;
$tab-big-pop-close-top: 0 !default;
$tab-nrml-pop-close-top: 0 !default;
$tab-big-safari-close-icon-top: 0 !default;
$tab-nrml-safari-close-icon-top: -1px !default;
$tab-pop-border: 1px $black !default;
$tab-pop-border-radius: 4px !default;
$tab-big-nav-right-icon-padding: 0 !default;
$tab-nrml-nav-right-icon-padding: 0 !default;
$tab-mob-nav-right-icon-padding: 0 !default;
$tab-rtl-big-nav-right-icon-padding: 0 !default;
$tab-rtl-nrml-nav-right-icon-padding: 0 !default;
$tab-big-nav-left-icon-padding: 0 !default;
$tab-nrml-nav-left-icon-padding: 0 !default;
$tab-rtl-big-nav-left-icon-padding: 0 !default;
$tab-rtl-nrml-nav-left-icon-padding: 0 !default;
$tab-big-nav-tb-icon-top: 0 !default;
$tab-nrml-nav-tb-icon-top: 0 !default;
$tab-big-nav-icons-top: -2px !default;
$tab-nrml-nav-icons-top: 0 !default;

// color definitions
$tab-border-color: $border-light !default;
$tab-hover-border-color: $border-light !default;
$tab-disable-border-color: rgba($border-light, .38) !default;
$tab-alt-border-color: $primary-text-color !default;
$tab-alt-disable-border-color: $border-light !default;
$tab-bg-color: $transparent !default;
$tab-focused-wrap-focus-border-color: $icon-color !default;
$tab-alt-hdr-bg-color: $primary !default;
$tab-hdr-bdr-color: $border-light !default;
$tab-hdr-border: 1px solid $tab-hdr-bdr-color !default;
$tab-ripple-bg-color: rgba($transparent, .12) !default;
$tab-fill-ripple-bg-color: rgba($transparent, .05) !default;
$tab-alt-ripple-bg-color: rgba($transparent, .1) !default;
$tab-focus-bg-color: rgba($content-bg-color-alt2, .12) !default;
$tab-fill-focus-bg-color: rgba($content-bg-color-alt2, .8) !default;
$tab-hover-bg-color: $content-bg-color-alt3 !default;
$tab-active-bg-color: $content-bg-color-alt2 !default;
$tab-fill-active-bg-color: $primary !default;
$tab-fill-disable-active-bg-color: rgba($primary, .38) !default;
$tab-alt-active-bg-color: $primary !default;
$tab-alt-disable-active-bg-color: $primary !default;
$tab-alt-item-bg-color: inherit !default;
$tab-alt-active-focus-bg-color: $content-bg-color !default;
$tab-key-alt-active-focus-bg-color: rgba($primary, .12) !default;
$tab-alt-hover-bg-color: $primary-bg-color-hover !default;
$tab-alt-focus-top-bottom-bg-color: inherit !default;
$tab-item-hover-bg-color: $transparent !default;
$tab-bg-item-wrap-hover-bg-color: $tab-item-hover-bg-color !default;
$tab-mob-item-hover-bg-color: initial !default;
$tab-pop-text-color: $content-text-color !default;
$tab-text-color: $primary !default;
$tab-disable-text-color:  $content-text-color-disabled !default;
$tab-mob-text-color: $content-text-color-alt2 !default;
$tab-active-text-color: $content-text-color-alt2 !default;
$tab-disable-active-text-color:  rgba($border-light, .38) !default;
$tab-focus-text-color: $primary-bg-color-pressed !default;
$tab-hover-text-color: $primary-bg-color-pressed !default;
$tab-pop-active-item-text-color: $tab-active-text-color !default;
$tab-alt-text-color: $primary-lighter !default;
$tab-alt-disable-text-color: rgba($border-light, .38) !default;
$tab-alt-active-style-hover-text-color: $content-text-color !default;
$tab-alt-active-style-hover-icon-color: $tab-alt-active-style-hover-text-color !default;
$tab-alt-disable-active-text-color: $content-text-color-alt3 !default;
$tab-alt-hover-text-color: $primary-text-hover !default;
$tab-alt-active-hover-text-color: $content-text-color-alt2 !default;
$tab-clone-item-bg-color: $content-bg-color-alt3 !default;
$tab-clone-text-color: $content-text-color-alt1 !default;
$tab-comb-icon-color:  $icon-color !default;
$tab-wrap-comb-icon-color: $tab-comb-icon-color !default;
$tab-comb-text-color: $content-text-color-alt2 !default;
$tab-close-icon-color: $icon-color !default;
$tab-mob-close-icon-color: rgba($icon-color, .87) !default;
$tab-active-close-icon-color: $icon-color !default;
$tab-close-icon-hover-color: $tab-hover-text-color !default;
$tab-close-icon-active-color: $primary !default;
$tab-alt-hover-text-color: $tab-alt-hover-text-color !default;
$tab-alt-active-text-color: $tab-alt-hover-text-color !default;
$tab-alt-close-icon-color: $tab-alt-hover-text-color !default;
$tab-alt-active-wrap-close-icon-color: $tab-alt-close-icon-color !default;
$tab-alt-active-close-icon-color: $primary-text-color !default;
$tab-alt-active-hover-close-icon-color: rgba($primary, 1) !default;
$tab-pop-bg-color: $flyout-bg-color !default;
$tab-hover-nav-bg-color: $content-bg-color-alt3 !default;
$tab-bg-hover-nav-bg-color: inherit !default;
$tab-nav-icon-color: $icon-color !default;
$tab-alt-nav-icon-color: $tab-alt-text-color !default;
$tab-active-nav-icon-color: $tab-active-text-color !default;
$tab-hover-nav-icon-color: $tab-hover-text-color !default;
$tab-alt-hover-nav-icon-color: $tab-alt-hover-text-color !default;
$tab-disable-nav-icon-color: $content-text-color-alt2 !default;
$tab-nav-focus-bg-color: $content-bg-color-alt2 !default;
$tab-nav-focus-icon-color: $icon-color !default;
$tab-bg-nav-focus-icon-color: $icon-color !default;
$tab-pop-icon-color: $content-text-color-alt2 !default;
$tab-pop-icon-hover-bg-color: $content-bg-color-alt3 !default;
$tab-pop-icon-hover-border: 1px solid $border-light !default;
$tab-pop-icon-focus-border: 1px solid $border-light !default;
$tab-pop-icon-active-border: 1px solid $border-light !default;
$tab-pop-border-box-shadow: inset $shadow-sm !default;
$tab-bg-hover-nav-icon-color: $icon-color !default;

$tab-nav-pop-press-border: 3px solid $transparent !default;
$tab-nav-pop-press-active-border: 3px solid $border-dark !default;
$tab-pop-box-shadow: $shadow-lg !default;
$tab-pop-icon-transition: none !default;
$tab-pop-up-icon-transform: rotate(0deg) !default;
$tab-pop-down-icon-transform: rotate(0deg) !default;

$tab-bgr-active-last-margin: 1px 2px 0 0 !default;
$tab-bgr-hscroll-items-nav-width: 38px !default;
$tab-bgr-item-textwrap-height: $tab-big-height !default;
$tab-bgr-active-icon-top: -.5px !default;
$tab-bgr-icon-line-height: $tab-big-icon-container-size !default;
$tab-bgr-mob-item-iconright-margin: 0 !default;
$tab-bgr-pop-item-textwrap-height: $tab-big-pop-item-height !default;
$tab-bgr-scroll-nav-arrow-before-top: 0 !default;
$tab-bgr-hor-pop-lineheight: 38px !default;
$tab-bgr-hor-focus-pop-hover-top: -2px !default;
$tab-bgr-bottom-active-close-top: .5px !default;
$tab-bgr-bottom-active-itop-close-top: 1.5px !default;
$tab-bgr-bottom-active-text-padding-top: .5px !default;
$tab-bgr-bottom-active-last-close-top: 0 !default;
$tab-bgr-vertical-icon-min-height: 38px !default;
$tab-bgr-vertical-icon-min-width: auto !default;
$tab-bgr-rtl-bottom-item-margin: 0 0 0 2px !default;
$tab-bgr-rtl-bottom-active-margin: 0 0 0 2px !default;
$tab-bgr-fill-first-last-padding: 0 !default;
$tab-bgr-fill-item-textwrap-height: $tab-big-fill-height !default;
$tab-bgr-fill-active-ileft-textwrap-height: 42px !default;
$tab-bgr-fill-focused-wrap-height: $tab-focus-big-fill-height !default;
$tab-bgr-rtl-icon-right-margin: 0 !default;
$tab-bgr-background-active-padding: $tab-alt-big-active-item-padding !default;
$tab-bgr-fill-active-item-padding: 0 !default;
$tab-bgr-item-text-margin-bottom: 0 !default;

$tab-nrml-hscroll-items-nav-width: 32px !default;
$tab-nrml-hscroll-items-bdr-radius: 4px !default;
$tab-nrml-item-text-wrap-height: $tab-nrml-height !default;
$tab-nrml-item-tabicon-line-height: $tab-nrml-icon-container-size !default;
$tab-nrml-pop-item-textwrap-height: $tab-nrml-pop-item-height !default;
$tab-nrml-scroll-nav-arrow-before-top: 0 !default;
$tab-nrml-hor-pop-lineheight: 32px !default;
$tab-nrml-mob-hor-pop-lineheight: 43px !default;
$tab-nrml-hor-focus-pop-lineheight: 32px !default;
$tab-nrml-hor-focus-pop-hover-top: -.5px !default;
$tab-nrml-vertical-icon-min-height: 32px !default;
$tab-nrml-vertical-icon-min-width: 33px !default;
$tab-nrml-vertical-tabicon-margin: 0 !default;
$tab-nrml-vertical-rtl-active-margin: 0 0 0 2px !default;
$tab-nrml-fill-item-textwrap-height: $tab-nrml-fill-height !default;
$tab-nrml-fill-focused-wrap-height: $tab-focus-nrml-fill-height !default;
$tab-nrml-rtl-icon-right-margin: 0 !default;
$tab-nrml-hdr-before-display: block !default;
$tab-nrml-item-active-position: unset !default;
$tab-nrml-item-active-hover-before-left: unset !default;
$tab-nrml-item-active-hover-before-right: unset !default;
$tab-nrml-item-active-before-bg-font: unset !default;
$tab-nrml-item-active-before-bottom: unset !default;
$tab-nrml-item-active-before-content: unset !default;
$tab-nrml-item-active-before-height: unset !default;
$tab-nrml-item-active-before-left: unset !default;
$tab-nrml-item-active-before-position: unset !default;
$tab-nrml-item-active-before-transition: unset !default;
$tab-nrml-item-active-after-font: unset !default;
$tab-nrml-item-active-after-content: unset !default;
$tab-nrml-item-active-after-display: unset !default;
$tab-nrml-item-active-after-font-weight: $font-weight-normal !default;
$tab-nrml-item-active-after-height: unset !default;
$tab-nrml-item-active-after-overflow: unset !default;
$tab-nrml-hscroll-bar-padding: 0 !default;
$tab-nrml-vertical-indicator-bdr-radius: 0 !default;
$tab-nrml-indicator-transition: left .125s cubic-bezier(.35, 0, .25, 1), right .25s cubic-bezier(.35, 0, .25, 1) !default;
$tab-nrml-active-item-tabicon-before-top: -1px !default;
$tab-nrml-bottom-active-before-top: unset !default;
$tab-nrml-vertical-indicator-transition: top .125s cubic-bezier(.35, 0, .25, 1), bottom .25s cubic-bezier(.35, 0, .25, 1) !default;
$tab-nrml-vertical-hover-before-height: unset !default;
$tab-nrml-vertical-before-bottom: unset !default;
$tab-nrml-vertical-before-height: unset !default;
$tab-nrml-vertical-before-left: unset !default;
$tab-nrml-vertical-before-transition: unset !default;
$tab-nrml-vertical-text-position: unset !default;
$tab-nrml-vertical-wrap-before-content: unset !default;
$tab-nrml-vertical-wrap-before-display: unset !default;
$tab-nrml-vertical-wrap-before-position: unset !default;
$tab-nrml-vertical-wrap-before-top: unset !default;
$tab-nrml-vertical-wrap-before-width: unset !default;
$tab-nrml-vertical-wrap-before-border: unset !default;
$tab-nrml-vertical-indicator-width: 2px !default;
$tab-nrml-bottom-wrap-before-top: unset !default;
$tab-nrml-background-vertical-indicator-display: none !default;
$tab-nrml-background-active-padding: $tab-alt-nrml-active-item-padding !default;
$tab-nrml-item-text-margin-bottom: 0 !default;

@mixin tbar-alt-btn-animation-after {
  animation: none;
}

@mixin hscroll-alt-btn-animation-after {
  animation: none;
}

/*! Bootstrap specific themes definition's */
$tab-alt-focused-active-wrap-focus-or-hover-color: $content-text-color-alt2 !default;
$tab-alt-focused-active-wrap-focus-and-hover-color: $content-text-color-alt2 !default;
$tab-even-active-item-bottom-border-color: 1px solid $border-light !default;
$tab-fill-focused-active-wrap-focus-color: $content-bg-color-alt5 !default;
$tab-alt-active-wrap-hover-close-color: $primary !default;
$tab-alt-pop-wrap-hover-color: $content-text-color-alt2 !default;
$tab-alt-wrap-hover-color: darken($secondary, 15%) !default;
$tab-nav-active-box-shadow: inset 0 0 0 3px rgba($secondary, .5) !default;
$tab-nav-focus-border-color: $secondary !default;
$tab-alt-nav-hover-bg-color: $secondary !default;

/*! Vertical Tab */
$tab-vscroll-nrml-padding: 16px 0 !default;
$tab-vscroll-big-padding: 24px 0 !default;
$tab-big-v-wrap-padding: 0 16px !default;
$tab-nrml-v-wrap-padding: 0 12px !default;
$tab-indicator-display: none !default;
$tab-nrml-more-btn-line-height: 36px !default;
$tab-big-more-btn-line-height: 48px !default;
$tab-pop-more-icon-margin: 0 0 0 8px !default;
$tab-rtl-pop-more-icon-margin: 0 8px 0 0 !default;
$tab-vscroll-nav-border: 1px solid $transparent !default;
$tab-v-popup-box-shadow: $tab-pop-box-shadow !default;
$tab-v-nav-icon-border-width: 0 !default;
$tab-v-big-close-icon-top-bottom-right: -20px !default;
$tab-v-nrml-close-icon-top-bottom-right: -15px !default;
$tab-v-rtl-big-close-icon-top-bottom-right: 0 !default;
$tab-v-rtl-nrml-close-icon-top-bottom-right: 0 !default;
$tab-v-rtl-big-close-icon-top-bottom-left: -20px !default;
$tab-v-rtl-nrml-close-icon-top-bottom-left: -15px !default;
$tab-v-pop-focus-outline: 0 !default;
$tab-vscroll-nav-border-color: rgba($content-bg-color-alt3, .12) !default;
$tab-v-nav-focus-bg-color: rgba($content-bg-color-alt2, .12) !default;
$tab-v-nav-hover-bg-color: rgba($content-bg-color-alt2, .12) !default;

$tab-header-font-weight: $font-weight-normal !default;
$tab-header-before-border-color: $tab-hdr-bdr-color !default;
$tab-header-before-border-width: 0 0 1px !default;
$tab-header-border-bottom: 0 !default;
$tab-item-hover-bg: inherit !default;
$tab-item-wrap-border: $tab-vscroll-nav-border !default;
$tab-clone-item-wrap-border: $tab-vscroll-nav-border !default;
$tab-item-wrap-hover-border: 1px solid $tab-hover-border-color !default;
$tab-item-wrap-hover-border-radius: $tab-top-active-item-border-radius !default;
$tab-item-active-hover-bg: inherit !default;
$tab-item-active-wrap-border: 0 !default;
$tab-active-wrap-position: unset !default;
$tab-active-wrap-before-border: unset !default;
$tab-active-wrap-before-content: unset !default;
$tab-active-wrap-before-display: unset !default;
$tab-active-wrap-before-position: unset !default;
$tab-active-wrap-before-top: unset !default;
$tab-active-wrap-before-width: unset !default;
$tab-focused-wrap-focus-border: 1px solid $tab-focused-wrap-focus-border-color !default;
$tab-focused-wrap-focus-icon-color: $tab-comb-icon-color !default;
$tab-content-font-size: $text-xs !default;
$tab-item-active-tabwrap-border: 1px solid transparent !default;
$tab-item-left-icon-before-top: 0 !default;
$tab-item-icon-before-top: 1px !default;
$tab-item-icon-before-left: 5px !default;
$tab-item-active-border-bottom: 1px solid $content-bg-color !default;

$tab-pop-wrap-hover-border: $tab-vscroll-nav-border !default;
$tab-pop-wrap-hover-border-radius: 0 !default;
$tab-pop-wrap-hover-close-color: $tab-hover-text-color !default;
$tab-pop-wrap-active-border-color: transparent !default;
$tab-pop-wrap-active-icon-font: $tab-alt-active-text-color !default;
$tab-pop-icon-border: $tab-active-text-item-border !default;
$tab-pop-focus-icon-active-bg: $tab-pop-icon-hover-bg-color !default;
$tab-pop-focus-icon-active-color: $tab-nav-focus-icon-color !default;
$tab-pop-focus-bg: transparent !default;
$tab-pop-hover-bg: transparent !default;
$tab-pop-nav-active-bg: $tab-nav-focus-bg-color !default;
$tab-pop-nav-active-border: $tab-nav-pop-press-active-border !default;
$tab-pop-nav-active-border-radius: $tab-active-item-border-radius !default;
$tab-pop-active-icons-color: $content-text-color-alt2 !default;

$tab-scroll-nav-bg: inherit !default;
$tab-scroll-nav-arrow-font: $tab-nav-icon-color !default;
$tab-scroll-arrow-hover-border: $tab-item-active-wrap-border !default;
$tab-scroll-arrow-active-border: $tab-item-active-wrap-border !default;
$tab-scroll-focus-arrow-border: $tab-item-active-wrap-border !default;
$tab-scroll-focus-arrow-active-bg: $tab-nav-focus-bg-color !default;
$tab-scroll-focus-arrow-active-border-color: unset !default;
$tab-scroll-focus-arrow-active-color: $tab-nav-focus-icon-color !default;
$tab-scroll-active-border: $tab-nav-pop-press-active-border !default;
$tab-scroll-overlay-arrow-color: $tab-disable-nav-icon-color !default;
$tab-scroll-overlay-arrow-hover-color: $tab-disable-nav-icon-color !default;
$tab-scroll-overlay-arrow-hover-bg: $tab-hover-nav-bg-color !default;
$tab-scroll-overlay-arrow-hover-border-color: unset !default;
$tab-scroll-focused-nav-focus-icon-bg: $tab-nav-focus-bg-color !default;
$tab-scroll-focused-nav-focus-icon-border: $tab-pop-up-icon-focus-border !default;

$tab-bottom-border-color: unset !default;
$tab-bottom-border-width: 0 !default;
$tab-bottom-hidden-items-border-color: $primary-text-color !default;
$tab-bottom-hidden-items-border-width: 1px 0 0 !default;
$tab-bottom-active-border-color: $tab-active-item-border-color $border-light $border-light !default;
$tab-bottom-active-border-width: 1px !default;
$tab-bottom-active-margin-top: 0 !default;

$tab-vertical-scroll-hover-border-color: $tab-vscroll-nav-border-color transparent transparent !default;
$tab-vertical-scroll-hover-active-bg: $tab-v-nav-focus-bg-color !default;
$tab-vertical-focus-active-arrow-color: none !default;
$tab-vertical-pop-icon-hover-color: $tab-hover-text-color !default;
$tab-vertical-pop-icon-active-color: $tab-nav-focus-icon-color !default;
$tab-vertical-focused-nav-focus-border-color: $tab-vertical-scroll-hover-border-color !default;
$tab-vertical-before-border-width: 0 1px 0 0 !default;

$tab-fill-wrap-hover-icon-color: $tab-hover-text-color !default;
$tab-fill-active-tabwrap-border-color: unset !default;
$tab-fill-active-text-before-border: unset !default;
$tab-fill-active-close-hover-color: $tab-alt-active-close-icon-color !default;
$tab-fill-pop-active-icon-color: $tab-pop-text-color !default;
$tab-fill-pop-wrap-hover-bg: $tab-active-bg-color !default;
$tab-fill-pop-wrap-hover-text-color: $tab-alt-active-close-icon-color !default;
$tab-fill-pop-wrap-hover-icon-color: $tab-alt-active-close-icon-color !default;
$tab-fill-pop-wrap-hover-close-color: $tab-alt-active-close-icon-color !default;
$tab-fill-focused-active-tabwrap-focus-border-color: $tab-focused-wrap-focus-border-color !default;
$tab-fill-focused-active-wrap-focus-text-color: $tab-fill-focused-active-wrap-focus-color !default;
$tab-fill-focused-active-wrap-focus-close-color: $tab-fill-focused-active-wrap-focus-color !default;

$tab-background-indicator-bottom: 0 !default;
$tab-background-wrap-border-color: transparent !default;
$tab-background-close-hover-color: $tab-alt-active-wrap-close-icon-color !default;
$tab-background-pop-icon-hover-bg: $tab-pop-icon-hover-bg-color !default;
$tab-background-pop-icon-hover-border-color: unset !default;
$tab-background-focused-active-wrap-focus-bg: $tab-key-alt-active-focus-bg-color !default;
$tab-background-focused-active-wrap-focus-border-color: $tab-alt-active-bg-color !default;
$tab-background-wrap-hover-text-color: $tab-alt-active-text-color !default;
$tab-background-wrap-hover-icon-color: $tab-alt-active-text-color !default;
$tab-background-wrap-hover-close-color: $tab-alt-active-text-color !default;
$tab-background-close-hover-active-color: $tab-background-wrap-hover-close-color !default;
$tab-background-wrap-hover-bg-color: $tab-bg-item-wrap-hover-bg-color !default;
$tab-background-wrap-hover-border-color: $tab-hover-border-color !default;
$tab-background-active-bg: inherit !default;
$tab-background-active-text-color: $tab-active-text-color !default;
$tab-background-active-icon-color: $icon-color !default;
$tab-background-active-close-color: $tab-active-text-color !default;
$tab-background-active-wrap-bg: $tab-active-item-border-color !default;
$tab-background-active-wrap-border-color: $tab-alt-active-bg-color !default;
$tab-background-active-wrap-border-radius: $tab-top-active-item-border-radius !default;
$tab-background-active-wrap-border-width: 1px 1px 0 !default;
$tab-background-active-hover-text-color: $tab-active-text-color !default;
$tab-background-active-hover-icon-color: $icon-color !default;
$tab-background-active-hover-close-color: $tab-active-text-color !default;
$tab-background-active-hover-bg: $tab-background-active-wrap-bg !default;
$tab-background-active-hover-border-color: $tab-background-active-wrap-border-color !default;
$tab-background-active-close-hover-color: $tab-background-active-hover-close-color !default;
$tab-background-activeitem-close-active-color: $tab-background-active-hover-close-color !default;
$tab-background-active-wrap-focus-bg: $tab-active-item-border-color !default;
$tab-background-active-wrap-focus-text-color: $tab-alt-active-hover-text-color !default;
$tab-background-active-wrap-focus-icon: $tab-alt-active-hover-text-color !default;
$tab-background-active-wrap-focushover-text-color: $tab-active-text-color !default;
$tab-background-active-wrap-focushover-close-color: $tab-active-text-color !default;
$tab-background-active-text-icon-color: $icon-color !default;
$tab-background-active-close-icon-color: $tab-alt-active-close-icon-color !default;
$tab-background-active-close-text-hover-color: $tab-active-text-color !default;
$tab-background-pop-text-color: $tab-alt-text-color !default;
$tab-background-pop-wrap-hover-bg: $tab-active-bg-color !default;
$tab-background-pop-wrap-hover-text-color: $tab-alt-active-text-color !default;
$tab-background-pop-wrap-active-text-color: $tab-background-pop-text-color !default;
$tab-background-pop-wrap-active-icon-color: $tab-background-pop-text-color !default;
$tab-background-pop-wrap-active-close-color: $tab-background-pop-text-color !default;
$tab-background-scroll-bg: inherit !default;
$tab-background-scroll-arrow-border: 0 !default;
$tab-background-scroll-arrow-hover-bg: $tab-bg-hover-nav-bg-color !default;
$tab-background-scroll-arrow-hover-border-color: unset !default;
$tab-background-scroll-arrow-active-bg: unset !default;
$tab-background-scroll-arrow-active-color: $tab-alt-nav-icon-color !default;
$tab-background-scroll-focus-arrow-border: 0 !default;
$tab-background-scroll-focus-arrow-bg: $tab-nav-focus-bg-color !default;
$tab-background-nav-focus-active-bg: $tab-nav-focus-bg-color !default;
$tab-background-nav-focus-active-border-color: unset !default;
$tab-background-nav-focus-active-color: $tab-bg-hover-nav-icon-color !default;
$tab-background-hor-nav-bg: transparent !default;
$tab-background-pop-icon-color: $tab-alt-nav-icon-color !default;
$tab-background-pop-icon-border: 0 !default;
$tab-background-pop-active-hover-bg: $tab-background-pop-icon-hover-bg !default;
$tab-background-pop-active-hover-color: $tab-bg-hover-nav-icon-color !default;
$tab-background-focus-pop-bg: $tab-nav-focus-bg-color !default;
$tab-background-focus-pop-border: $tab-pop-up-icon-focus-border !default;
$tab-background-focus-pop-hover-bg: $tab-background-pop-icon-hover-bg !default;
$tab-background-focused-wrap-focus-bg: $tab-active-bg-color !default;
$tab-background-focused-wrap-focus-border-color: $tab-focused-wrap-focus-border-color !default;
$tab-background-popicon-focus-active-bg: $tab-pop-focus-icon-active-bg !default;
$tab-background-popicon-focus-active-border-color: unset !default;
$tab-background-popicon-focus-active-color: $tab-bg-hover-nav-icon-color !default;
$tab-background-bottom-active-wrap-border-color: $tab-alt-active-bg-color !default;
$tab-background-bottom-active-wrap-border-radius: 0 0 4px 4px !default;
$tab-background-bottom-active-wrap-border-width: 0 2px 2px !default;
$tab-background-vertical-left-active-border-color: unset !default;
$tab-background-vertical-left-active-border-width: 0 !default;
$tab-background-vertical-left-active-wrap-border-radius: 4px 0 0 4px !default;
$tab-background-vertical-left-active-wrap-border-width: 2px 0 2px 2px !default;
$tab-background-vertical-right-active-border-width: 0 !default;
$tab-background-vertical-right-active-wrap-border-radius: 0 4px 4px 0 !default;
$tab-background-vertical-right-active-wrap-border-width: 2px 2px 2px 0 !default;
$tab-background-focused-active-wrap-hover-bg: $tab-background-active-wrap-bg !default;
$tab-background-focused-active-wrap-hover-border: 1px solid $tab-hover-border-color !default;
$tab-background-focused-active-wrap-hover-border-radius: $tab-top-active-item-border-radius !default;
$tab-background-focused-active-wrap-focus-text-color: $tab-alt-focused-active-wrap-focus-or-hover-color !default;
$tab-background-focused-active-wrap-focus-icon-color: $tab-alt-active-hover-text-color !default;
$tab-background-focused-active-wrap-focus-close-color: $tab-alt-focused-active-wrap-focus-or-hover-color !default;
$tab-background-focused-active-wrap-hover-text-color: $tab-alt-focused-active-wrap-focus-or-hover-color !default;
$tab-background-focused-active-wrap-hover-icon-color: $tab-background-active-hover-icon-color !default;
$tab-background-focused-active-wrap-hover-close-color: $tab-alt-focused-active-wrap-focus-or-hover-color !default;
$tab-background-focused-active-focus-hover-text-color: $tab-alt-focused-active-wrap-focus-and-hover-color !default;
$tab-background-focused-active-focus-hover-close-color: $tab-alt-focused-active-wrap-focus-and-hover-color !default;
$tab-background-focused-wrap-focus-close-color: $tab-alt-text-color !default;
$tab-background-focused-active-wrap-icon-color: $tab-alt-active-hover-text-color !default;
$tab-background-accent-indicator-bg: $tab-border-color !default;
$tab-background-active-text-font-weight: $tab-light-font-weight !default;
$tab-background-text-color: $tab-alt-text-color !default;

$tab-fill-background-disable-text-color: $tab-disable-text-color !default;
