$input-skin-name: $skin-name !default;
$zero-value: 0 !default;
$one-value: 1px !default;
$border-type: solid !default;
$border-size: 1px !default;
$input-child-padding-bottom: $zero-value !default;
$input-child-small-bigger-padding-bottom: $zero-value !default;
$input-child-small-padding-bottom: $zero-value !default;
$input-child-bigger-padding-bottom: $zero-value !default;
$input-group-full-border: $border-size $border-type !default;
$input-child-small-bigger-padding-top: $zero-value !default;
$input-child-small-padding-top: $zero-value !default;
$input-child-padding-top: $zero-value !default;
$input-child-bigger-padding-top: $zero-value !default;
$textarea-padding: 4px 8px 4px 8px !default;
$textarea-bigger-padding: 6px 12px 6px 12px !default;
$textarea-small-padding: 4px 8px 4px 8px !default;
$textarea-bigger-small-padding: 6px 12px 6px 12px !default;
$textarea-min-height: 32px !default;
$textarea-bigger-min-height: 36px !default;
$textarea-bigger-small-min-height: 32px !default;
$textarea-small-min-height: 26px !default;
$input-text-indent: 8px !default;
$input-bigger-text-indent: 12px !default;
$input-small-text-indent: 8px !default;
$input-small-bigger-text-indent: 12px !default;
$input-font-size: $text-sm !default;
$input-bigger-font-size: $text-base !default;
$input-small-font-size: $text-xs !default;
$input-bigger-small-font-size: $text-sm !default;
$input-box-border-radius: 4px !default;
$input-small-border-radius: 2px !default;
$input-bigger-border-radius: 4px !default;
$input-bigger-small-border-radius: 4px !default;
$input-groupo-focus-border-radius: 4px !default;
$input-group-focus-box-shadow: $shadow-focus-ring1 !default;
$input-group-icon-border-radius: 3px !default;
$input-group-child-border-width: $zero-value !default;
$input-padding: $zero-value !default;
$float-input-padding: $zero-value !default;
$float-label-padding: $text-xxs !default;
$float-input-wrap-padding-top: $zero-value !default;
$input-padding-bottom: $zero-value !default;
$input-focus-padding-bottom: $zero-value !default;
$input-clear-icon: 14px !default;
$input-bigger-clear-icon: 16px !default;
$input-small-clear-icon: 12px !default;
$input-bigger-small-clear-icon: 14px !default;
$input-clear-icon-padding: $zero-value !default;
$input-clear-icon-position: center !default;
$input-bigger-padding: $zero-value !default;
$float-input-bigger-padding: $zero-value !default;
$float-input-bigger-wrap-padding-top: $zero-value !default;
$input-bigger-padding-bottom: $zero-value !default;
$input-focus-bigger-padding-bottom: $zero-value !default;
$input-child-padding: $zero-value !default;
$input-child-min-height: 30px !default;
$input-child-min-width: 30px !default;
$input-bigger-child-padding: $zero-value !default;
$input-bigger-child-min-height: 36px !default;
$input-bigger-child-min-width: 36px !default;
$input-margin-bottom: $zero-value !default;
$input-bigger-margin-bottom: $zero-value !default;
$input-small-margin-bottom: $zero-value !default;
$input-small-bigger-margin-bottom: $zero-value !default;
$input-margin-top: 24px !default;
$input-bigger-margin-top: 24px !default;
$input-small-margin-top: 20px !default;
$input-small-bigger-margin-top: 24px !default;
$input-small-padding: $zero-value !default;
$float-input-small-padding: $zero-value !default;
$float-input-small-wrap-padding-top: $zero-value !default;
$input-small-padding-bottom: $zero-value !default;
$input-focus-small-padding-bottom: $zero-value !default;
$input-bigger-small-padding: $zero-value !default;
$float-input-bigger-small-padding: $zero-value !default;
$float-input-bigger-small-wrap-padding-top: $zero-value !default;
$input-bigger-small-padding-bottom: $zero-value !default;
$input-focus-bigger-small-padding-bottom: $zero-value !default;
$input-bigger-small-child-padding: $zero-value !default;
$input-bigger-small-child-min-height: 32px !default;
$input-bigger-small-child-min-width: 32px !default;
$input-small-child-padding: $zero-value !default;
$input-small-child-min-height: 24px !default;
$input-small-child-min-width: 24px !default;
$input-font-family: $font-family !default;
$input-font-style: normal !default;
$input-font-normal: normal !default;
$input-border: $zero-value $border-type !default;
$float-input-border: $zero-value $border-type !default;
$input-box-border-width: 1px !default;
$float-input-border-width: 1px !default;
$input-focus-border-width: 1px !default;
$input-disable-border-type: solid !default;
$input-border-left-width: 1px !default;
$input-group-border: 1px $border-type !default;
$input-group-border-width: $zero-value !default;
$input-group-border-radius: $zero-value !default;
$input-valid-group-border-width: 2px !default;
$input-child-margin-bottom: $zero-value !default;
$input-child-margin-top: $zero-value !default;
$float-input-child-margin-top: $zero-value !default;
$input-child-margin-right: $zero-value !default;
$input-child-small-bigger-margin-bottom: $zero-value !default;
$input-child-small-bigger-margin-top: $zero-value !default;
$float-input-child-small-bigger-margin-top: $zero-value !default;
$input-child-small-bigger-margin-right: $zero-value !default;
$input-child-small-margin-bottom: $zero-value !default;
$input-child-small-margin-top: $zero-value !default;
$float-input-child-small-margin-top: $zero-value !default;
$input-child-small-margin-right: $zero-value !default;
$input-child-bigger-margin-bottom: $zero-value !default;
$input-child-bigger-margin-top: $zero-value !default;
$float-input-child-bigger-margin-top: $zero-value !default;
$input-child-bigger-margin-right:  $zero-value !default;
$input-disable-group-border-width: $one-value !default;
$input-opacity: 1 !default;
$input-opacity-filter: 100 !default;
$input-group-border-width-focus: $one-value !default;
$input-group-border-type-focus: $border-type !default;
$input-group-border-width-hover: 1px !default;
$input-group-full-border-width: $one-value !default;
$input-valid-border-bottom-width: 2px !default;
$input-right-border-width: $zero-value !default;
$float-label-font-size: $text-xs !default;
$float-label-bigger-font-size: $text-sm !default;
$float-label-small-font-size: $text-xxs !default;
$float-label-bigger-small-font-size: $text-xs !default;
$float-placeholder-font-size: $text-sm !default;
$float-placeholder-bigger-font-size: $text-base !default;
$float-placeholder-small-font-size: $text-xs !default;
$float-placeholder-bigger-small-font-size: $text-sm !default;
$input-border-size: 2px !default;
$input-normal-height: 30px !default;
$input-bigger-height: 36px !default;
$input-small-height: 24px !default;
$input-bigger-small-height: 34px - $input-border-size !default;
$float-input-normal-height: 32px !default;
$float-input-bigger-height: 38px !default;
$float-input-small-height: 26px !default;
$float-input-bigger-small-height: 34px !default;
$input-float-font-weight: $font-weight-medium !default;
$input-full-height: 100% !default;
$textarea-normal-height: auto !default;
$textarea-bigger-height: auto !default;
$textarea-small-height: auto !default;
$textarea-bigger-small-height: auto !default;
$textarea-full-height: 100% !default;
$input-icon-font-size: $text-sm !default;
$input-bigger-icon-font-size: $text-base !default;
$input-small-icon-font-size: $text-xs !default;
$input-bigger-small-icon-font-size: $text-xs !default;
$input-inner-wrap-margin-left: $zero-value !default;
$input-clear-icon-padding-bottom: $zero-value !default;
$input-clear-icon-padding-right: $zero-value !default;
$input-clear-icon-padding-left: $input-clear-icon-padding-right !default;
$input-clear-icon-padding-top: $zero-value !default;
$float-input-clear-icon-padding-top: $zero-value !default;
$input-clear-icon-small-bigger-padding-bottom: $zero-value !default;
$input-clear-icon-small-bigger-padding-right: $zero-value !default;
$input-clear-icon-small-bigger-padding-top: $zero-value !default;
$float-input-clear-icon-small-bigger-padding-top: $zero-value !default;
$input-clear-icon-small-padding-bottom: $zero-value !default;
$input-clear-icon-small-padding-right: $zero-value !default;
$input-clear-icon-small-padding-top: $zero-value !default;
$float-input-clear-icon-small-padding-top: $zero-value !default;
$input-clear-icon-bigger-padding-bottom: $zero-value !default;
$input-clear-icon-bigger-padding-right: $zero-value !default;
$input-clear-icon-bigger-padding-top: $zero-value !default;
$float-input-clear-icon-bigger-padding-top: $zero-value !default;
$input-clear-icon-min-height: $zero-value !default;
$input-clear-icon-min-width: 30px !default;
$input-bigger-clear-icon-min-height: $zero-value !default;
$input-bigger-clear-icon-min-width: 36px !default;
$input-bigger-small-clear-icon-min-height: $zero-value !default;
$input-bigger-small-clear-icon-min-width: 36px !default;
$input-small-clear-icon-min-height: $zero-value !default;
$input-small-clear-icon-min-width: 24px !default;
$input-left-icon-font-size: $zero-value !default;
$input-bigger-left-icon-font-size: $zero-value !default;
$input-small-left-icon-font-size: $zero-value !default;
$input-bigger-small-left-icon-font-size: $zero-value !default;
$input-left-child-min-height: $zero-value !default;
$input-left-child-min-width: $zero-value !default;
$input-bigger-left-child-min-height: $zero-value !default;
$input-bigger-left-child-min-width: $zero-value !default;
$input-small-left-child-min-height: $zero-value !default;
$input-small-left-child-min-width: $zero-value !default;
$input-bigger-small-left-child-min-height: $zero-value !default;
$input-bigger-small-left-child-min-width: $zero-value !default;
$input-icon-inner-width: $zero-value !default;
$input-icon-inner-height: $zero-value !default;
$input-error-border-shadow: 0 0 0 4px rgba($danger, .25) !default;
$input-success-border-shadow: 0 0 0 4px rgba($success, .25) !default;
$input-warning-border-shadow: 0 0 0 4px rgba($warning, .25) !default;
$input-group-border-shadow: $shadow-sm !default;

// color

$hover-border-color: $border-hover !default;
$pressed-border-color: $border-pressed !default;
$input-bg-color: $content-bg-color !default;
$input-box-border-focus: $primary-light !default;
$input-success-color: $border-success !default;
$input-warning-color:  $border-warning !default;
$input-error-color: $border-error !default;
$input-disable-bg-color: $content-bg-color-alt2 !default;
$input-disable-font-color: $content-text-color-alt1 !default;
$input-box-border-color: $border !default;
$input-font-color: $content-text-color !default;
$input-icon-font-color: $icon-color !default;
$input-clear-icon-color: $icon-color !default;
$input-clear-icon-hover-color: $icon-color-hover !default;
$input-error-color: $danger !default;
$input-accent:  $content-bg-color !default;
$input-placeholder: $placeholder-text-color !default;
$input-active-border-color: $input-box-border-focus !default;
$input-group-active-border-color: $border !default;
$input-disable-border-color: $border !default;
$input-disabled-group-bg-color: transparent !default;
$input-group-border-right-focus: transparent !default;
$input-group-border-left-focus: transparent !default;
$input-group-active-border: transparent !default;
$input-group-pressed-bg: $secondary-bg-color-pressed !default;
$input-hover-bg-color: $secondary-bg-color-hover !default;
$input-header-font-color: $placeholder-text-color !default;
$input-group-border-color-focus: $primary-light !default;
$input-group-border-color-hover: $border-hover !default;
$input-group-full-border-color: $input-box-border-color !default;
$input-group-success-color: $input-success-color !default;
$input-group-warning-color: $input-warning-color !default;
$input-group-error-color: $input-error-color !default;
$input-group-pressed-color: $secondary-text-color-pressed !default;
$input-select-font-color: $content-text-color-selected !default;
$float-label-font-color: $content-text-color-alt1 !default;
$float-label-disbale-font-color: $content-text-color-alt3 !default;
$input-placeholder-color: $placeholder-text-color !default;
$input-group-disabled-color: $icon-color-disabled !default;
$input-group-hovered-color: $secondary-text-color-hover !default;
$input-readonly-bg-color: $content-bg-color-alt2 !default;
$input-readonly-color: $content-text-color-alt1 !default;
$input-icon-shadow: inset  0 3px 5px rgba(0, 0, 0, .125) !default;
$input-border-shadow: 0 0 0 3px rgba($primary-light, .25) !default;
$input-transition-shadow: border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
$border-type: solid !default;
$float-label-rtl-value: -7px !default;

@mixin input-sizing {
  box-sizing: border-box;
}

@mixin input-height ($height) {
  height: $height;
}

@mixin input-state-color ($color) {
  border-color: $color;
}

@mixin input-selection {
  background: $primary;
  color: $primary-text-color;
}
/* stylelint-disable property-no-vendor-prefix */
@mixin float-label-alignment {
  -moz-transform: translate(-0%, -50%);
  -ms-transform: translate(-0%, -50%);
  -o-transform: translate(-0%, -50%);
  -webkit-transform: translate(-0%, -50%);
  box-sizing: border-box;
  left: 0%;
  top: 50%;
  transform: translate(-0%, -50%);
  user-select: none;
  width: 100%;
}

@mixin textarea-float-label-alignment {
  -moz-transform: translate(-0%, -50%);
  -ms-transform: translate(-0%, -50%);
  -o-transform: translate(-0%, -50%);
  -webkit-transform: translate(-0%, -50%);
  box-sizing: border-box;
  left: 0%;
  top: 13px;
  transform: translate(-0%, -50%);
  width: 100%;
}

@mixin input-group-animation {
  content: '';
}

@mixin input-group-animation-left {
  content: '';
}

@mixin input-group-animation-width {
  content: '';
}

@mixin input-group-animation-right {
  content: '';
}

@mixin input-group-animation-bg {
  content: '';
}

@mixin input-group-hover-bg {
  content: '';
}

@mixin input-group-success-animation-bg {
  content: '';
}

@mixin input-group-warning-animation-bg {
  content: '';
}

@mixin input-group-error-animation-bg {
  content: '';
}

@mixin input-ripple-parent {
  content: '';
}

@mixin input-ripple-style {
  content: '';
}

@mixin input-ripple-animation {
  content: '';
}
