@include export-module('dropdownlist-layout') {
  .e-bigger .e-input-group.e-ddl {
    #{if(&, '&', '*')} .e-input-filter,
    #{if(&, '&', '*')} .e-input-filter:focus {
      margin-left: -20px;
    }
  }

  .e-ddl#{&}.e-popup {
    #{if(&, '&', '*')} .e-input-group {
      @if ($skin-name == 'Material3') {
        margin-top: 4px;
      }
    }
  }

  .e-bigger .e-ddl#{&}.e-popup {
    #{if(&, '&', '*')} .e-list-item {
      font-size: $ddl-bigger-list-font-size;
    }

    #{if(&, '&', '*')} .e-list-group-item {
      @if $skin-name != 'tailwind' {
        font-size: $ddl-bigger-list-font-size;
      }
    }

    #{if(&, '&', '*')} .e-input-group {
      @if ($skin-name != 'bootstrap5' and $skin-name != 'tailwind' and $skin-name != 'FluentUI' and $skin-name != 'bootstrap4' and $skin-name != 'bootstrap4-dark' and $skin-name != 'Material3') {
        padding: 4px 0;
      }
      @if ($skin-name == 'Material3') {
        margin-top: 8px;
      }
    }
  }

  .e-bigger.e-ddl#{&}.e-popup {
    #{if(&, '&', '*')} .e-list-item {
      font-size: $ddl-bigger-list-font-size;
    }

    #{if(&, '&', '*')} .e-list-group-item {
      @if $skin-name != 'tailwind' {
        font-size: $ddl-bigger-list-font-size;
      }
    }

    #{if(&, '&', '*')} .e-input-group {
      @if ($skin-name != 'bootstrap5' and $skin-name != 'tailwind' and $skin-name != 'FluentUI' and $skin-name != 'bootstrap4' and $skin-name != 'bootstrap4-dark') {
        padding: 4px 0;
      }
    }
  }

  .e-popup-full-page {
    bottom: 0;
    left: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    right: 0;
    top: 0;

    #{&}.e-ddl.e-popup.e-ddl-device-filter {
      margin: $ddl-filter-margin;
    }
  }

  .e-ddl.e-control-wrapper .e-ddl-disable-icon {
    position: relative;
  }

  .e-ddl.e-control-wrapper .e-ddl-disable-icon::before {
    content: '';
  }

  .e-ddl-device-filter .e-filter-parent {
    background-color: $ddl-filter-background-color;
  }

  /* stylelint-disable property-no-vendor-prefix */
  .e-ddl input.e-input::-webkit-contacts-auto-fill-button {
    display: none;
    pointer-events: none;
    position: absolute;
    right: 0;
    visibility: hidden;
  }
  /* stylelint-enable property-no-vendor-prefix */

  .e-filter-parent {
    border: $ddl-filter-border;
    border-top-width: $ddl-filter-top-border;
    box-shadow: $ddl-filter-box-shadow;
    display: block;
    padding: $ddl-filter-padding;
    @if ($skin-name == 'FluentUI') {
      border-bottom: $ddl-filter-parent-border;
    }
  }

  .e-ddl.e-input-group:not(.e-disabled) {
    cursor: pointer;
  }

  .e-ddl#{&}.e-popup.e-ddl-device-filter {
    @at-root {
      #{if(&, '&', '*')} .e-input-group.e-input-focus::before,
      #{if(&, '&', '*')} .e-input-group.e-input-focus::after {
        width: 0;
      }
    }
  }

  .e-ddl#{&}.e-popup {
    background: $ddl-popup-background-color;
    @if $skin-name != 'material' and $skin-name != 'Material3' {
      border: 1px solid $ddl-default-border-color;
    }
    @if $skin-name == 'Material3' {
      border-radius: 4px;
    }
    position: absolute;
    @at-root {
      #{if(&, '&', '*')} .e-search-icon {
        margin: 0;
        opacity: .57;
        padding: $ddl-list-search-icon-padding;
      }

      #{if(&, '&', '*')} .e-filter-parent .e-back-icon {
        padding: $ddl-back-icon-padding;
      }

      #{if(&, '&', '*')}.e-rtl .e-filter-parent .e-input-group.e-control-wrapper .e-input-filter,
      #{if(&, '&', '*')} .e-filter-parent .e-input-filter,
      #{if(&, '&', '*')} .e-filter-parent .e-input-filter:focus,
      #{if(&, '&', '*')} .e-filter-parent .e-input-group.e-input-focus .e-input-filter,
      #{if(&, '&', '*')} .e-filter-parent .e-input-group.e-control-wrapper.e-input-focus .e-input-filter {
        @if ($skin-name != 'bootstrap4' and $skin-name != 'bootstrap4-dark' and $skin-name != 'FluentUI' and $skin-name != 'bootstrap5'  and $skin-name != 'tailwind') {
          padding: $ddl-list-filter-text-indent;
        }
      }

      #{if(&, '&', '*')} .e-input-group {
        margin-bottom: 0;
      }

      #{if(&, '&', '*')} .e-ddl-footer,
      #{if(&, '&', '*')} .e-ddl-header {
        cursor: default;
      }
    }
  }

  /* stylelint-disable property-no-vendor-prefix */
  .e-ddl.e-input-group .e-ddl-hidden,
  .e-ddl.e-float-input .e-ddl-hidden {
    -webkit-appearance: initial;
    border: 0;
    height: 0;
    padding: 0;
    visibility: hidden;
    width: 0;
  }

  .e-ddl.e-input-group,
  .e-ddl.e-input-group.e-input-focus:focus {
    outline: none;
  }

  .e-dropdownbase .e-list-item .e-highlight {
    display: inline;
    font-weight: bold;
    vertical-align: baseline;
  }

  .e-ddl.e-input-group input[readonly] ~ .e-clear-icon:not(.e-clear-icon-hide),
  .e-float-input input[readonly] ~ .e-clear-icon:not(.e-clear-icon-hide),
  .e-float-input.e-input-group input[readonly] ~ .e-clear-icon:not(.e-clear-icon-hide) {
    opacity: 1;
  }

  .e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon,
  .e-input-group input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon,
  .e-input-group.e-control-wrapper input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input.e-input-group input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input.e-input-group.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon {
    display: flex;
  }

  .e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-input-group input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-input-group.e-control-wrapper input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-input-group input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-input-group.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide {
    display: none;
  }

  .e-input-group.e-static-clear input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-input-group.e-static-clear.e-control-wrapper input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-static-clear input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-static-clear.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-static-clear.e-input-group input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-static-clear.e-input-group.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide {
    cursor: pointer;
    display: flex;
  }

  .e-ddl.e-input-group {
    .e-input-value,
    .e-input-value:focus {
      font-family: $ddl-input-font-family;
      font-size: $ddl-input-font-size;
      height: auto;
      margin: $ddl-zero-value;
      outline: none;
      width: 100%;
    }

    input[readonly].e-input,
    input[readonly],
    .e-dropdownlist {
      pointer-events: none;
    }
  }

  ejs-autocomplete,
  ejs-combobox,
  ejs-dropdownlist {
    display: block;
  }

  // Small Size

  .e-small .e-ddl#{&}.e-popup,
  .e-input-group.e-ddl.e-small {
    #{if(&, '&', '*')} .e-list-item {
      font-size: $ddl-small-list-font-size;
    }

    #{if(&, '&', '*')} .e-list-group-item {
      @if $skin-name != 'tailwind' {
        font-size: $ddl-small-list-font-size;
      }
    }
  }

  .e-small.e-ddl#{&}.e-popup,
  .e-input-group.e-ddl.e-small {
    #{if(&, '&', '*')} .e-list-item {
      font-size: $ddl-small-list-font-size;
    }

    #{if(&, '&', '*')} .e-list-group-item {
      @if $skin-name != 'tailwind' {
        font-size: $ddl-small-list-font-size;
      }
    }
  }

  //touch small
  .e-bigger.e-small .e-ddl#{&}.e-popup,
  .e-bigger .e-input-group.e-ddl.e-small {
    #{if(&, '&', '*')} .e-list-item {
      font-size: $ddl-bigger-small-list-font-size;
    }

    #{if(&, '&', '*')} .e-list-group-item {
      @if $skin-name != 'tailwind' {
        font-size: $ddl-bigger-small-list-font-size;
      }
    }
  }

  .e-bigger.e-small.e-ddl#{&}.e-popup,
  .e-bigger .e-input-group.e-ddl.e-small {
    #{if(&, '&', '*')} .e-list-item {
      font-size: $ddl-bigger-small-list-font-size;
    }

    #{if(&, '&', '*')} .e-list-group-item {
      @if $skin-name != 'tailwind' {
        font-size: $ddl-bigger-small-list-font-size;
      }
    }
  }

  .e-content-placeholder.e-ddl.e-placeholder-ddl,
  .e-content-placeholder.e-autocomplete.e-placeholder-autocomplete,
  .e-content-placeholder.e-combobox.e-placeholder-combobox {
    background-size: 300px 33px;
    min-height: 33px;
  }

  .e-bigger .e-content-placeholder.e-ddl.e-placeholder-ddl,
  .e-bigger.e-content-placeholder.e-ddl.e-placeholder-ddl,
  .e-bigger .e-content-placeholder.e-autocomplete.e-placeholder-autocomplete,
  .e-bigger.e-content-placeholder.e-autocomplete.e-placeholder-autocomplete,
  .e-bigger .e-content-placeholder.e-combobox.e-placeholder-combobox,
  .e-bigger.e-content-placeholder.e-combobox.e-placeholder-combobox {
    background-size: 300px 40px;
    min-height: 40px;
  }
}
