@include export-module('progress-button-layout') {

  /*! Progress Button Layout */
  .e-progress-btn {
    position: relative;

    .e-progress {
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity .3s linear;
    }

    .e-spinner {
      transition: $progress-btn-transition;
    }

    .e-btn-content {
      display: inline-block;
      position: relative;
      z-index: 1;
    }

    &.e-vertical {
      .e-progress {
        bottom: 0;
        height: 0%;
        top: auto;
        width: 100%;
      }
    }

    &.e-progress-top {
      .e-progress {
        height: 10%;
      }
    }

    &.e-progress-bottom {
      .e-progress {
        bottom: -1px;
        height: 10%;
        top: auto;
      }
    }

    &.e-progress-active {
      overflow: hidden;
      pointer-events: none;

      .e-progress {
        opacity: 1;
      }

      .e-animate-end {
        opacity: 0;
      }
    }

    &.e-spin-top,
    &.e-spin-bottom {
      transition: padding .3s linear;
    }

    &.e-progress-active:not(.e-hide-spinner) {
      &.e-spin-top,
      &.e-spin-bottom {
        .e-btn-content,
        .e-spinner {
          display: block;
          line-height: 1;
        }
      }

      &.e-spin-center {
        .e-spinner {
          display: flex;
          justify-content: center;
        }
      }

      &.e-spin-left:not(.e-round) {
        .e-spinner {
          padding-right: $progress-btn-spinner-padding;

          .e-spinner-inner {
            top: -1px;
          }
        }

        &.e-rtl {
          .e-spinner {
            padding-left: $progress-btn-spinner-padding;
            padding-right: 0;
          }
        }
      }

      &.e-spin-right:not(.e-round) {
        .e-spinner {
          padding-left: $progress-btn-spinner-padding;

          .e-spinner-inner {
            top: -1px;
          }
        }

        &.e-rtl {
          .e-spinner {
            padding-left: 0;
            padding-right: $progress-btn-spinner-padding;
          }
        }
      }

      &.e-spin-top {
        padding-bottom: $progress-btn-spin-btn-padding;
        padding-top: $progress-btn-spin-btn-padding;

        .e-spinner {
          padding-bottom: $progress-btn-spinner-padding;
        }
      }

      &.e-spin-bottom {
        padding-bottom: $progress-btn-spin-btn-padding;
        padding-top: $progress-btn-spin-btn-padding;

        .e-spinner {
          padding-top: $progress-btn-spinner-padding;
        }
      }

      &.e-small {
        &.e-spin-left:not(.e-round) {
          .e-spinner {
            padding-right: $progress-btn-spinner-padding-small;
          }

          &.e-rtl {
            .e-spinner {
              padding-left: $progress-btn-spinner-padding-small;
              padding-right: 0;
            }
          }
        }

        &.e-spin-right:not(.e-round) {
          .e-spinner {
            padding-left: $progress-btn-spinner-padding-small;
          }

          &.e-rtl {
            .e-spinner {
              padding-left: 0;
              padding-right: $progress-btn-spinner-padding-small;
            }
          }
        }

        &.e-spin-top {
          padding-bottom: $progress-btn-small-spin-btn-padding;
          padding-top: $progress-btn-small-spin-btn-padding;

          .e-spinner {
            padding-bottom: $progress-btn-spinner-padding-small;
          }
        }

        &.e-spin-bottom {
          padding-bottom: $progress-btn-small-spin-btn-padding;
          padding-top: $progress-btn-small-spin-btn-padding;

          .e-spinner {
            padding-top: $progress-btn-spinner-padding-small;
          }
        }
      }
    }

    &.e-round-corner {
      border-radius: 5em;
      outline: none;
      overflow: hidden;
    }

    &.e-rtl {
      .e-progress {
        left: auto;
        right: 0;
      }

      &.e-vertical {
        .e-progress {
          bottom: auto;
          top: 0;
        }
      }
    }

    .e-spinner-pane {
      height: auto;
      position: relative;
      width: auto;

      .e-spinner-inner {
        left: auto;
        position: relative;
        top: auto;
        transform: none;
      }
    }

    .e-cont-animate {
      align-items: center;
      display: inline-flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    @keyframes ProgressSlideLeft {
      from {
        left: 0%;
        opacity: 1;
      }

      to {
        left: -100%;
        opacity: 0;
      }
    }

    @keyframes ProgressSlideRight {
      from {
        left: 0%;
        opacity: 1;
      }

      to {
        left: 100%;
        opacity: 0;
      }
    }

    @keyframes ProgressSlideUp {
      from {
        height: auto;
        opacity: 1;
        top: 0;
      }

      to {
        height: auto;
        opacity: 0;
        top: -100px;
      }
    }

    @keyframes ProgressSlideDown {
      from {
        height: auto;
        opacity: 1;
        top: 0;
      }

      to {
        height: auto;
        opacity: 0;
        top: 100px;
      }
    }

    @keyframes ProgressZoomIn {
      from {
        opacity: 1;
        transform: translate(0, 0) scale(1);
      }

      to {
        opacity: 0;
        transform: translate(0, 0) scale(3);
      }
    }

    @keyframes ProgressZoomOut {
      from {
        transform: translate(0, 0) scale(1);
      }

      to {
        transform: translate(0, 0) scale(0);
      }
    }
  }

  #{if(&, '&', '*')}.e-bigger .e-progress-btn,
  #{if(&, '&', '*')}.e-bigger.e-progress-btn {
    &.e-progress-active:not(.e-hide-spinner) {
      &.e-spin-left:not(.e-round) {
        .e-spinner {
          padding-right: $progress-btn-spinner-padding-bigger;
        }

        &.e-rtl {
          .e-spinner {
            padding-left: $progress-btn-spinner-padding-bigger;
            padding-right: 0;
          }
        }
      }

      &.e-spin-right:not(.e-round) {
        .e-spinner {
          padding-left: $progress-btn-spinner-padding-bigger;
        }

        &.e-rtl {
          .e-spinner {
            padding-left: 0;
            padding-right: $progress-btn-spinner-padding-bigger;
          }
        }
      }

      &.e-spin-top {
        padding-bottom: $progress-btn-bigger-spin-btn-padding;
        padding-top: $progress-btn-bigger-spin-btn-padding;

        .e-spinner {
          padding-bottom: $progress-btn-spinner-padding-bigger;
        }
      }

      &.e-spin-bottom {
        padding-bottom: $progress-btn-bigger-spin-btn-padding;
        padding-top: $progress-btn-bigger-spin-btn-padding;

        .e-spinner {
          padding-top: $progress-btn-spinner-padding-bigger;
        }
      }

      &.e-small {
        &.e-spin-left:not(.e-round) {
          .e-spinner {
            padding-right: $progress-btn-spinner-padding-bigger-small;
          }

          &.e-rtl {
            .e-spinner {
              padding-left: $progress-btn-spinner-padding-bigger-small;
              padding-right: 0;
            }
          }
        }

        &.e-spin-right:not(.e-round) {
          .e-spinner {
            padding-left: $progress-btn-spinner-padding-bigger-small;
          }

          &.e-rtl {
            .e-spinner {
              padding-left: 0;
              padding-right: $progress-btn-spinner-padding-bigger-small;
            }
          }
        }

        &.e-spin-top {
          padding-bottom: $progress-btn-bigger-small-spin-btn-padding;
          padding-top: $progress-btn-bigger-small-spin-btn-padding;

          .e-spinner {
            padding-bottom: $progress-btn-spinner-padding-bigger-small;
          }
        }

        &.e-spin-bottom {
          padding-bottom: $progress-btn-bigger-small-spin-btn-padding;
          padding-top: $progress-btn-bigger-small-spin-btn-padding;

          .e-spinner {
            padding-top: $progress-btn-spinner-padding-bigger-small;
          }
        }
      }
    }
  }
}
