@use 'sass:math';

$pager-numericitem-border-radius: 0 !default;
$pager-border-type: solid !default;
$pager-mobile-icon-size: 11px !default;
$pager-icon-width: 'auto' !default;
$pager-icon-height: 'auto' !default;
$pager-navigationicon-padding: 11px 9px 8px !default;
$pager-navigationicon-right: 0 !default;
$pager-navigationicon-top: 0 !default;
$pager-numericitem-margin-right: 0 !default;
$pager-messagebar-padding-bottom: 0 !default;
$pager-bigger-messagebar-padding-bottom: 8px !default;
$pager-content-opacity: 1 !default;
$pager-bigger-new-container-rtl-margin: 0 0 -5px 0 !default;
$pager-next-icon-margin-left: 0 !default;
$pager-container-rtl-margin: 0 .3px -4px 0 !default;
$pager-rtl-navigation-icon-padding: 14px 12px 12px 11.5px !default;
$pager-rtl-numeric-margin: 0 !default;
$pager-item-hover-borders-width: 0 !default;
$pager-tripledot-font-size: 14px !default;
$pager-last-page-hover-border-radius:  0 3px 3px 0 !default;
$pager-first-page-hover-border-radius: 3px 0 0 3px !default;
$pager-rtl-parentmsgbar-margin-left: 6px !default;
$pager-rtl-parentmsgbar-padding-top: 8px !default;
$pager-bigger-nexticon-marign-left: math.div($pager-next-icon-margin-left, .75) !default;
$pager-bigger-current-item-border-radius: math.div($pager-numericitem-border-radius, .75) !default;
$pager-bigger-rtl-nexticon-marign-left: 0 !default;
$pager-bigger-rtl-nexticon-marign-right: math.div($pager-next-icon-margin-left, .75) !default;
$pager-device-padding: 19px 0 !default;
$pager-device-messabar-padding: 0 !default;
$pager-bigger-rtl-parentmsgbar-margin-left: 6px !default;
$pager-bigger-rtl-activeitem-padding: 7px 8px 7px 8px !default;
$pager-numericitem-min-width: 26px !default;
$pager-font-size: 14px !default;
$pager-font-line-height: 1 !default;
$pager-numeric-font-line-height: $pager-font-line-height !default;
$pager-bg-color: $content-bg-color !default;
$pager-border-color: $border-light !default;
$pager-color: $content-text-color-alt2 !default;
$pager-icon-size: 9px !default;
$pager-border-size: 1px !default;
$pager-numericitem-padding: 13px 12px 10px 12px !default;
$pager-numeric-hover-bg-color: $content-bg-color-hover !default;
$pager-numeric-txt-bg-color: $icon-color-disabled !default;
$pager-padding: 7px 8px 7px 8px !default;
$pager-numeric-font-weight: bold !default;
$pager-messagebar-padding-top: 8px !default;
$pager-number-hover-bg-color: $content-bg-color-hover !default;
$pager-font-family: $font-family !default;
$pager-font-weight: bold !default;
$pager-messagebar-padding-right: 0 !default;
$pager-tripledot-padding: 10.4px 10.5px 11.4px 10.5px !default;
$pager-numeric-icon-padding: 10px 12.5px 11.5px 12.5px !default;
$pager-pagecontainer-icon-padding: 14.2px 12.2px 12.7px 11.5px !default;
$pager-bigger-pagecontainer-icon-padding: 16px 14px 14px 14px !default;
$pager-hover-color: $primary !default;
$pager-mhover-color: $primary !default;
$pager-pagermessage-weight: $font-weight-normal !default;
$pager-active-bg-color: $content-bg-color-hover !default;
$pager-last-border-size: 1px !default;
$page-content-color: $primary !default;
$pager-container-margin: 0 0 -4px .3px !default;
$pager-bigger-numeric-item-margin: 0 !default;
$pager-bigger-icon-font-size: 11px !default;
$pager-bigger-font-size: 16px !default;
$pager-bigger-padding: 7px 8px 7px 8px !default;
$pager-bigger-pagercontainer-margin: 0 0 -6px .8px !default;
$pager-bigger-pagercontainer-icons-padding: 16px 14px 14px 14px !default;
$pager-bigger-pagercontainer-icons-marign-right: 0 !default;
$pager-bigger-current-item-padding: 12px 15px 13px 15px !default;
$pager-letter-spacing: 0 !default;
$pager-bigger-icons-align: middle !default;
$pager-bigger-messagebar-padding-right: 0 !default;
$pager-bigger-messagebar-padding-top: 10px !default;
$pager-last-content-icon: 'e235' !default;
$pager-next-content-icon: 'e236' !default;
$pager-prev-content-icon: 'e237' !default;
$pager-first-content-icon: 'e238' !default;
$pager-icon-color: $primary !default;
$pager-hover-bg-color: $content-bg-color-hover !default;
$pager-dropdown-margin-top: -24px !default;
$pager-dropdown-margin-left: 10px !default;
$pager-dropdown-margin-right: 8px !default;
$pager-dropdown-overflow: unset !default;
$pager-bigger-dropdown-constant-margin: 6px !default;
$pager-bigger-dropdown-margin: 0 10px 0 18px !default;
$pager-dropdown-height: 32px !default;
$pager-dropdown-width: 90px !default;
$pager-bigger-dropdown-width: 90px !default;
$pager-constant-margin: 0 0 6px 8px !default;
$pager-bigger-letter-spacing: 14px 14px 13px 14px !default;
$pager-tripledot-rtl-margin-top: 0 !default;
$pager-tripledot-rtl-padding: 10px 10.5px 11px 10.5px !default;
$pager-rtl-pagerconstant-margin-top: 0 8px 6px 0 !default;
$pager-rtl-pagerdropdown-margin: -24px 16px 0 0 !default;
$pager-tripledot-rtl-bigger-margin-top: 0 !default;
$pager-bigger-rtl-numeric-margin: 0 !default;
$pager-bigger-container-rtl-margin: 0 0 -4px 0 !default;
$pager-activeitem-padding: 10.4px 12.5px 11px 12.5px !default;
$pager-bigger-activeitem-padding: 12px 15px 13px !default;
$pager-rtl-pagerdropdown-bigger-margin: -26px 16px 0 0 !default;
$pager-rtl-pagerconstant-bigger-margin-top: 0 16px 7px 0 !default;
$pager-bigger-rtl-parentmsgbar-margin-top: 0 !default;
$pager-bigger-rtl-pagercontainer-margin-top: 5px !default;
$pager-bigger-device-rtl-parentmsgbar-margin-top: 5px !default;
$pager-rtl-pagermsgbar-device-margin-top: 10px !default;
$pager-bigger-constant-margin-left: 16px !default;
$pager-rtl-last-content-icon: 'e238' !default;
$pager-rtl-first-content-icon: 'e235' !default;
$pager-rtl-prev-content-icon: 'e236' !default;
$pager-rtl-next-content-icon: 'e237' !default;
$pager-rtl-first-last-icon-hover-border-radius: 0 4px 4px 0 !default;
$pager-item-hover-border-width: 0 1px 0 0 !default;
$pager-item-hover-border-color: $pager-border-color !default;
$pager-bigger-current-item-hover-padding: $pager-bigger-current-item-padding !default;
$pager-numeric-icon-hover-padding: 10.4px 12.5px 11.6px 12.5px !default;
$pager-bigger-rtl-activeitem-hover-padding:  12px 15px 13px !default;
$pager-activeitem-hover-padding: $pager-activeitem-padding !default;
$pager-bigger-dropdown-margin-top: -24px !default;
$pager-rtl-nxtpage-margin: 0 !default;
$pager-disabled-icons-opacity: 1 !default;
$pager-current-item-font-weight: $font-weight-normal !default;
$pager-bigger-dropdown-heigh: 38px !default;
$pager-external-msg-padding: 6px 6px 0 6px !default;
$pager-rtl-bigger-tripledot-font-size: 14px !default;
$pager-bigger-tripledot-padding: 13px 14px 11px 14px !default;
$pager-container-children-height: 31px !default;
$pager-div-chrome-margin-top: -.5px !default;
$pager-div-margin-top: -.3px !default;
$pager-container-height: 32px !default;
$pager-first-disabled-border-radious: 3px 0 0 3px !default;
$pager-last-disabled-border-radious: 0 3px 3px 0 !default;
$pager-bigger-container-height: 38px !default;
$pager-bigger-container-children-height: 37px !default;
$pager-bigger-container-margin-left: -.9px !default;
$pager-bigger-container-margin-right: -.9px !default;
$pager-rtl-padding: $pager-padding !default;
$pager-skin: 'bootstrap5' !default;
$pager-container-border-radius: 4px !default;
$pager-bigger-tripledot-font-size: $pager-tripledot-font-size !default;
$pager-active-border: 2px !default;
$pager-active-size: solid !default;
$pager-active-color: $primary !default;
$pager-numeric-icon-padding-with-margin: 7px 10px 7px 10px !default;
$pager-focus-bg-color: $table-bg-color-hover !default;
$pager-focus-tripledot-padding: $pager-tripledot-padding !default;
$pager-focus-tripledot-line-height: 1 !default;
$pager-focus-active-box-shadow: 0 0 0 1px $primary inset !default;
$pager-focus-shadow: 0 0 0 1px $primary inset !default;
