@import "theme";
@import "variables";
@import "mixins";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/_breakpoints";

@import "@syncfusion/ej2-base/styles/bootstrap5.scss";
@import "@syncfusion/ej2-buttons/styles/bootstrap5.scss";
@import "@syncfusion/ej2-calendars/styles/bootstrap5.scss";
@import "@syncfusion/ej2-dropdowns/styles/bootstrap5.scss";
@import "@syncfusion/ej2-inputs/styles/bootstrap5.scss";
@import "@syncfusion/ej2-navigations/styles/bootstrap5.scss";
@import "@syncfusion/ej2-popups/styles/bootstrap5.scss";
@import "@syncfusion/ej2-splitbuttons/styles/bootstrap5.scss";
@import "@syncfusion/ej2-grids/styles/bootstrap5.scss";
@import "@syncfusion/ej2-treegrid/styles/bootstrap5.scss";
@import "@syncfusion/ej2-diagrams/styles/bootstrap5.scss";

:root {
    --accent: 80, 227, 194;
    --accent-color: rgb(var(--accent));
    --accent-color-lighter: rgba(var(--accent), 0.4);
    --secondary-color: var(--text-color);

    --background: 255, 255, 255;
    --background-accent: 240, 240, 240;
    --background-color: rgb(var(--background));
    --background-accent-color: rgb(var(--background-accent));

    --text: 41, 41, 41;
    --text-color: rgb(var(--text));
}

html {
    overflow-x: hidden !important;
}

html,
body {
    height: 100%;
}

* {
    font-family: "Poppins", sans-serif;
}

/*
Fix global styling of font-family in process editor
The user can change the font-family of a shape in the process editor
*/
.graph-container {
    svg {
        * {
            font-family: unset;
        }
    }
}

input.form-control,
textarea.form-control,
button.form-control,
button.btn {
    box-shadow: none !important;
    border-color: #ccc;
}

$compactFS: 0.875rem;

.ng-select {
    font-size: $compactFS;

    .ng-select-container {
        height: inherit !important;
        min-height: inherit;

        .ng-value-label {
            white-space: normal !important;
        }
    }

    &.ng-select-single {
        .ng-select-container {
            padding: 4px 0 5px 0;

            .ng-value-container .ng-input {
                top: auto;
            }

            &:not(.ng-has-value) {
                padding: 5px 0 5px 0;
            }
        }
    }
}

.form-group {
    margin-bottom: $compactFS;

    > cerrix-checkbox {
        display: block;
        margin-top: 1px;
    }

    > ng-select {
        display: block;
        min-height: calc(1.8125rem + 4px);
    }
}

.form-group-separator {
    height: calc((3.75rem + 4px) / 2);
    display: block;
    margin-bottom: calc(#{$compactFS} / 2);
}

@include media-breakpoint-down(lg) {
    .form-group-separator {
        height: calc((3.75rem + 4px) / 4);
    }
}

.form-control-sm {
    height: calc(1.8125rem + 4px);
}

textarea.form-control-sm {
    height: calc(1.5 * (0.9em + 0.5rem) + 2 * (1.8125rem + 4px) + 0.875rem);
}

/*
Bootstrap sets box sizing to border box for all elements
This doesn't work well with Angular Material
We should choose one of these frameworks and replace/remove the other
*/
.mat-mdc-input-element {
    box-sizing: initial;
}

.no-mat-menu-padding > .mat-mdc-menu-content {
    padding: 0px !important;
}

.mat-menu-padding > .mat-mdc-menu-content {
    padding: 5px 15px 5px 5px !important;
}

.mat-menu-breadcrumb {
    max-height: 350px;
    min-width: 300px;
    .mat-mdc-icon-button {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.detail-page-action-bar {
    position: sticky;
    top: 0;
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background-color: var(--background-color);
    z-index: 5;

    .btn {
        font-size: 0.9rem;
    }

    .btn-cerrix {
        padding-left: 40px;
        padding-right: 40px;
    }

    .date-edited,
    .sub-message {
        font-style: italic;
        font-size: 0.7em;
        margin-left: 5px;
    }

    .workflow-area,
    .right-area {
        float: right;
        padding-left: 20px;
        border-left: 1px solid rgba(0, 0, 0, 0.2);

        .workflow-description,
        .description {
            font-size: 0.8em;
            margin-right: 15px;
        }

        button + button {
            margin-left: 15px;
        }
    }

    &.flex-action-bar {
        display: flex;
        justify-content: space-between;

        .date-edited {
            line-height: 3;
        }

        .workflow-area,
        .right-area {
            float: none;

            .workflow-description,
            .description {
                line-height: 3;
            }
        }
    }
}

.detail-page-summary {
    width: 100%;
    background-color: var(--background-accent-color);
    margin-bottom: 10px;
    font-size: 0.9em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px;

    .property {
        overflow-wrap: break-word;
    }

    @include media-breakpoint-up(xl) {
        .left-block {
            padding-right: 50px;
        }

        .right-block {
            padding-left: 50px;
        }
    }
}

.detail-page-card {
    display: flex;
    flex-direction: column;
    margin: 20px;

    .detail-page-card-title {
        font-size: 1.1em;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .detail-page-card-actions {
        display: flex;
        flex-flow: row;
        margin-bottom: 10px;

        .filler {
            flex: 1;
        }

        button + button {
            margin-left: 10px;
        }
    }

    .detail-page-card-body {
        flex: 1;
        flex-basis: auto;

        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        box-shadow: 0px 2px 10px -5px rgba(0, 0, 0, 0.9);
        margin-bottom: 10px;

        &.padded {
            padding: 1.25rem;
        }

        &.flush-expansion-panels {
            .mat-expansion-panel {
                box-shadow: none;

                &.mat-expansion-panel-spacing {
                    margin: 0;
                }
            }

            .mat-expansion-panel + .mat-expansion-panel {
                border-top: 1px solid rgba(0, 0, 0, 0.2);
            }
        }

        &.form-group-styling {
            .form-group label {
                font-size: 0.9em;
                font-weight: bold;
            }
        }

        &.overflow-auto {
            overflow: auto;
        }

        @include media-breakpoint-up(xl) {
            .left-block {
                padding-right: 50px;
            }

            .right-block {
                padding-left: 50px;
            }
        }
    }
}

.cerrix-compact {
    font-size: $compactFS;

    input,
    textarea,
    select,
    label,
    th,
    td {
        font-size: $compactFS;
    }

    .multi-tree button {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .form-group {
        margin-bottom: 0.3em;
    }

    .ng-select {
        .ng-select-container {
            height: auto;
            min-height: auto;
        }

        &.ng-select-single {
            .ng-select-container {
                padding: 4px 0 3px 0;

                .ng-value-container .ng-input {
                    top: auto;
                }

                &:not(.ng-has-value) {
                    padding: 5px 0 5px 0;
                }
            }
        }
    }
}

// Put Angular Material's model above Bootstrap's
// Need to replace Angular Material components
.cdk-overlay-container {
    // Bootstrap .modal's z-index of 1060 + 1
    z-index: 1061 !important; // does not work without important, and it is at root level, so no option to use strict specification
}

ng-select.ng-touched.ng-invalid .ng-select-container,
cerrix-select.ng-touched.ng-invalid ng-select .ng-select-container,
date-field.ng-touched.ng-invalid input,
date-input.ng-touched.ng-invalid input,
excel-column-input.ng-touched.ng-invalid input,
textarea.ng-touched.ng-invalid,
input.ng-touched.ng-invalid,
.cerrix-select-tree.ng-touched.ng-invalid,
timepicker .form-control.is-invalid {
    border-color: #ff5722 !important;
}

popover-container.calendarPopover {
    position: fixed;
    max-height: 85%;
    overflow: hidden;

    .popover-title {
        min-width: 276px;
    }

    .popover-content {
        max-height: calc(40rem - 36px);
        overflow: auto;
    }

    @media screen and (max-height: 400px) {
        .popover-content {
            max-height: calc(15rem - 36px);
        }
    }

    @media screen and (max-height: 760px) {
        .popover-content {
            max-height: calc(20rem - 36px);
        }
    }
}

.multi-tree button.form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.cerrix-disabled {
    opacity: 0.8;
    pointer-events: none;
}

.modal-backdrop.fade.show {
    display: none;
}

.modal.fade.show {
    background: rgba(0, 0, 0, 0.5);
}

.bg-extnet {
    border-color: #99bce8 !important;
    background: rgba(221, 232, 245, 1);
    background: -moz-linear-gradient(top, rgba(221, 232, 245, 1) 0%, rgba(203, 219, 239, 1) 100%);
    background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(221, 232, 245, 1)),
        color-stop(100%, rgba(203, 219, 239, 1))
    );
    background: -webkit-linear-gradient(
        top,
        rgba(221, 232, 245, 1) 0%,
        rgba(203, 219, 239, 1) 100%
    );
    background: -o-linear-gradient(top, rgba(221, 232, 245, 1) 0%, rgba(203, 219, 239, 1) 100%);
    background: -ms-linear-gradient(top, rgba(221, 232, 245, 1) 0%, rgba(203, 219, 239, 1) 100%);
    background: linear-gradient(to bottom, rgba(221, 232, 245, 1) 0%, rgba(203, 219, 239, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dde8f5', endColorstr='#cbdbef', GradientType=0);
}

.card.bg-extnet-header .card-header {
    background: rgba(112, 151, 219, 1);
    background: -moz-linear-gradient(top, rgba(112, 151, 219, 1) 0%, rgba(56, 112, 168, 1) 100%);
    background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(112, 151, 219, 1)),
        color-stop(100%, rgba(56, 112, 168, 1))
    );
    background: -webkit-linear-gradient(top, rgba(112, 151, 219, 1) 0%, rgba(56, 112, 168, 1) 100%);
    background: -o-linear-gradient(top, rgba(112, 151, 219, 1) 0%, rgba(56, 112, 168, 1) 100%);
    background: -ms-linear-gradient(top, rgba(112, 151, 219, 1) 0%, rgba(56, 112, 168, 1) 100%);
    background: linear-gradient(to bottom, rgba(112, 151, 219, 1) 0%, rgba(56, 112, 168, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7097db', endColorstr='#3870a8', GradientType=0);
    color: white;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
}

.card-header {
    background-color: var(--accent-color);
    color: $secondary-text-color;
}

.panel.bg-extnet-header .panel-body {
    padding: 0;
    background-color: white;
}

@import "@ng-select/ng-select/themes/default.theme.css";

body > .sortable-drag {
    display: none;
}

.sortingGhost {
    background-color: var(--accent-color);
    opacity: 0.5;
}

// Tab system area
.cerrix-tab-system {
    margin-top: 2px;

    .nav-item {
        padding: 0.1rem 0.5rem;
        cursor: pointer;
        margin-left: 3px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        &.active {
            background-color: var(--accent-color);
            color: white;
        }

        a.nav-link {
            padding: 0.1rem 0.5rem;

            &:hover {
                border: 0;
            }
        }
    }
}

.main-page.tucked .tabcontent .navbar-background {
    width: calc(100% - 30px);
}

.navbar-background {
    @extend .fasttransition;
    position: fixed;
    width: calc(100% - #{$menu-width});
    background-color: white;
    z-index: 10;

    .form-actions {
        margin: 5px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.03);
        border: 1px solid rgba(0, 0, 0, 0.125);
        padding: 0;

        .nav-link {
            cursor: pointer;

            &:hover {
                color: var(--accent-color);
            }
        }

        .dropdown-item {
            cursor: pointer;
        }
    }
}

.action-filler {
    height: 55px;
    width: 100%;
}

.mat-expansion-panel.mat-expanded {
    overflow: visible;
}

.disable-selection {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.toast-container {
    pointer-events: initial;
    cursor: pointer;

    .ngx-toastr,
    .toast {
        border: 0px;
        background-image: none;
        padding: 10px;
    }

    .toast-message {
        white-space: pre-line;
    }

    .toast-info {
        background-color: var(--accent-color);
    }

    .toast-warning,
    .toast-info {
        color: white;
    }

    .toast-html-message {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }
}

.well {
    min-height: 20px;
    padding: 8px;
    border: 1px solid var(--accent-color);
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);

    .legend {
        display: block;
        font-size: 14px;
        width: auto;
        padding: 2px 7px 2px 5px;
        margin-bottom: 0px;
        line-height: inherit;
        color: #333;
    }
}

.cerrix-action-bar {
    background-color: $background-accent;
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    width: 100%;
    line-height: 2;

    > *:not(.left):not(.middle):not(.right) {
        display: none;
    }

    .left,
    .middle,
    .right {
        flex-grow: 1;
    }

    .left {
        > * {
            margin-right: 10px;
        }
    }

    .middle {
        text-align: center;

        > * {
            margin-right: 5px;
            margin-left: 5px;
        }
    }

    .right {
        text-align: right;

        > * {
            margin-left: 10px;
        }
    }
}

.table-cerrix,
.cerrix-table {
    width: 100%;
    border-color: #ced4da;
    cursor: default;
    padding: 0 15px;

    thead {
        th {
            background-color: $background-accent;
        }
    }

    tr {
        td,
        th {
            padding: 5px;
        }
    }

    &.bordered {
        border-collapse: separate;
        border-spacing: 0;

        tr:first-of-type th,
        tr:first-of-type td {
            border-top: 1px solid #ced4da;
        }

        tr:last-of-type td {
            border-bottom: 1px solid #ced4da;
        }

        tr {
            th:first-of-type,
            td:first-of-type {
                border-left: 1px solid #ced4da;
            }

            th:last-of-type,
            td:last-of-type {
                border-right: 1px solid #ced4da;
            }
        }
    }

    &.bordered-columns {
        td:not(:first-of-type) {
            border-left: 1px solid rgba(0, 0, 0, 0.2);
        }
    }

    &.rounded {
        thead th:first-child,
        tbody + tbody tr:first-child td:first-child {
            border-top-left-radius: 0.25rem;
        }

        thead th:last-child,
        tbody + tbody tr:first-child td:last-child {
            border-top-right-radius: 0.25rem;
        }

        tbody tr:last-child td:first-child {
            border-bottom-left-radius: 0.25rem;
        }

        tbody tr:last-child td:last-child {
            border-bottom-right-radius: 0.25rem;
        }
    }

    &.centered {
        td,
        th,
        input {
            text-align: center;
        }
    }

    &.hoverable {
        tbody {
            tr:hover td {
                color: var(--accent-color);
                text-decoration: underline;
                cursor: pointer;
            }

            tr.highlighted:hover td {
                color: white;
            }
        }
    }

    &.striped {
        tbody {
            tr:nth-child(even) {
                background-color: $background-accent;
            }
        }
    }

    tr.highlighted {
        td {
            color: white;
            background-color: var(--accent-color);
            // font-weight: bolder;
            cursor: default;
        }
    }
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

// Filedrop area styling
.file-drop-area {
    &.highlight {
        border: 1px dashed var(--accent-color);

        .drop-area-indicator {
            display: block;
        }
    }

    .drop-area-indicator {
        display: none;
        width: 100%;
        height: 100%;

        &.fullscreen {
            transition: all 1s ease;
            background-color: rgba(0, 0, 0, 0.75);
            position: absolute;
            top: 0;
            left: 0;
        }

        h4 {
            color: white;
            font-weight: bolder;
            text-transform: uppercase;

            &.fullscreen {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.growing-dropzone {
    .file-drop-area {
        height: auto;
    }
}

.fr-box {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    max-height: 100%;

    .fr-wrapper {
        min-height: 100px;
        flex: 1;
        overflow: auto;
    }

    // Styling to make the editor more inline with bootstrap.
    .fr-toolbar {
        border-top-left-radius: 0.2rem !important;
        border-top-right-radius: 0.2rem !important;
    }

    .second-toolbar {
        border-bottom-left-radius: 0.2rem !important;
        border-bottom-right-radius: 0.2rem !important;
    }
}

div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img {
    display: none !important;
}

div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
    -webkit-transform: translateY(-50%) translateX(-50%) !important;
    -moz-transform: translateY(-50%) translateX(-50%) !important;
    -ms-transform: translateY(-50%) translateX(-50%) !important;
    -o-transform: translateY(-50%) translateX(-50%) !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
    background-color: #e9ecef;
}

.ng-select.ng-select-multiple.ng-select-disabled
    > .ng-select-container
    .ng-value-container
    .ng-value {
    background-color: #e9ecef;
    border: 0;
}

.modal {
    overflow-y: auto;
}

.fancy-loader {
    position: absolute;
    display: block;
    left: calc(50% - 75px);
    top: calc(50% - 75px);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--accent-color);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    &.submodule {
        position: relative;
    }

    &.md:not(.sm) {
        left: calc(50% - 50px);
        top: calc(50% - 50px);
        width: 100px;
        height: 100px;
    }

    &.sm {
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        width: 50px;
        height: 50px;
    }
}

.fancy-loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--accent-color);
    filter: contrast(50%);
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

.fancy-loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--accent-color);
    filter: saturate(200%) brightness(50%);
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.comments-dialog,
.documents-dialog {
    min-width: 700px;
}

.page-section {
    min-height: 150px;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 2px solid rgba(0, 0, 0, 0.1);

    .title {
        width: 50px;
        position: relative;
        border-left: 2px solid rgba(0, 0, 0, 0.1);

        h5 {
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            white-space: nowrap;
        }
    }
}

.datepicker-today {
    border: 1px #ced4da solid;
    border-radius: 50%;
}

.cerrix-contextmenu {
    background-color: #fafafa;
    padding: 4pt;
    font-size: 10pt;
    z-index: 1000;
    box-shadow: 0 0 12pt rgba(0, 0, 0, 0.25);
    border-radius: 4pt;
    padding: 0.5em 0 0.5em 0;
    animation: fadeIn 0.1s ease-out;
    opacity: 1;
    display: block;

    hr {
        border: none;
        border-bottom: 1px solid #eee;
    }

    div {
        cursor: pointer;
        display: block;
        text-decoration: none;
        color: #333;
        padding: 0.5em 2em 0.5em 0.75em;
        max-width: 18em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    div:hover {
        background-color: #333;
        color: #fff;
    }

    div::before {
        content: "";
        float: left;
        margin-right: 0.75em;
        width: 0.5em;
        height: 1em;
        display: inline-block;
    }

    /* Animatinons */
    @-webkit-keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    .is-fadingIn {
        -webkit-animation: fadeIn 0.1s ease-out;
        animation: fadeIn 0.1s ease-out;
        opacity: 1;
        display: block;
    }

    .is-fadingOut {
        -webkit-animation: fadeOut 0.1s ease-out;
        animation: fadeOut 0.1s ease-out;
        opacity: 0;
        display: block;
    }
}

.overflow-dialog {
    overflow: visible !important;
}

.overflow-dialog .mat-mdc-tab-body-wrapper {
    overflow: visible;

    .mat-mdc-tab-body {
        &.mat-mdc-tab-body-active,
        .mat-mdc-tab-body-content {
            overflow: visible;
        }
    }
}

// This selector is targeted at 'a' tags with an onclick event targeting 'window.postMessage' instead of a href.
// Bootstrap removes styling by default if a 'a' tag does not have a href attribute.
a[onclick^="window.postMessage"] {
    color: var(--accent-color);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

ngx-masonry {
    .masonry-item {
        width: 100%;
        margin-bottom: 10px;
    }
}

@include media-breakpoint-up(lg) {
    ngx-masonry {
        .masonry-item {
            width: calc((100% - 10px) / 2);
        }
    }
}

@include media-breakpoint-up(xxl) {
    ngx-masonry {
        .masonry-item {
            width: calc((100% - 20px) / 3);
        }
    }
}

.cerrix-manual-formatting {
    h2,
    h3,
    h5 {
        color: var(--accent-color);
    }

    h3 {
        margin-bottom: 20px;
    }

    h5 {
        margin-top: 30px;
        margin-bottom: 15px;
    }

    hr {
        margin: 2rem 0;
    }

    p {
        strong {
            font-size: 1.05rem;
            margin-bottom: 10px;
        }

        span:not(.badge) {
            display: block;
        }

        strong + span,
        span + span {
            margin-top: 10px;
        }

        &:not(.no-indent) {
            span {
                margin-left: 20px;
            }
        }
    }
}

.cerrix-release-notes-formatting {
    h3,
    .detail-page-card-title {
        color: var(--accent-color);
    }

    h3 {
        margin-bottom: 20px;
    }

    hr {
        margin: 2rem 0;
    }

    .detail-page-card-body {
        strong,
        span {
            display: block;
        }

        strong {
            font-size: 1.05rem;
        }

        strong + span {
            margin-top: 5px;
        }

        span + span {
            margin-top: 10px;
        }

        span + strong {
            margin-top: 20px;
        }
    }

    h3 {
        margin-left: 20px;
    }
}

cerrix-textfield {
    flex: 1 1 auto;
}

.inline-form-group {
    cerrix-textfield > .form-group {
        margin-top: 0;
        margin-bottom: 0;
    }
}

// New pdf selector
a[data-pdfid] {
    color: var(--accent-color) !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}
