@import "variables";

$blue: #021e46;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #c51b2c;
$orange: #f47c3c;
$yellow: #ffc107;
$green: #33a24c;
$teal: #20c997;
$cyan: #29abe0;

// TODO: Enable this once we have bootstrap 5, since that version will have support voor css variables.
// Currently not supported because of incompatibility in IE 10 & 11.
// $primary: var(--accent-color);

@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";

// This will override some breakpoints, to add more like xxs, xxl and xxxl.
$grid-breakpoints: (
    xxxs: 0,
    xxs: 320px,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1600px,
);
$container-max-widths: (
    xxxs: 0,
    xxs: 320px,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1600px,
);

$modal-xxl: 1440px;
$modal-xxxl: 1440px;

.modal-xxl {
    max-width: $modal-xxl !important;
}
.modal-xxxl {
    max-width: $modal-xxxl !important;
}

// Leave this at the end, so the overrides get priority.
@import "bootstrap/scss/bootstrap";
@import "ngx-toastr/toastr";

// TODO: Remove following section once we can use css variables in bootstrap.
// Section: Bootstrap 4 Primary color overrides

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled,
.dropdown-item.active,
.dropdown-item:active,
.progress-bar {
    background-color: var(--accent-color);
}

// End Section: Bootstrap 4 Primary color overrides

.btn-cerrix {
    background: $primary-button-gradient;
    color: white;
    background-color: var(--accent-color); // fallback
    padding-left: 20px;
    padding-right: 20px;

    i ~ span {
        margin-left: 10px;
    }

    span {
        padding-right: 5px;
    }

    &:hover {
        color: white;
    }
}

.btn-success {
    background: #7fb275;
    color: white;
    transition: all 2s;
    padding-left: 20px;
    padding-right: 20px;

    i ~ span {
        margin-left: 10px;
    }

    span {
        padding-right: 5px;
    }

    &:hover {
        background-color: #597d52;
        color: white;
    }
}

.btn-danger {
    background-color: #f9575c;
    color: white;
    transition: all 2s;
    padding-left: 20px;
    padding-right: 20px;

    i ~ span {
        margin-left: 10px;
    }

    span {
        padding-right: 5px;
    }

    &:hover {
        background-color: #ae3d40;
        color: white;
    }
}

.btn.btn-raised {
    font-size: 0.9em;
    font-weight: bolder;
    color: var(--accent-color);
    border-radius: 25px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 8px -5px rgba(0, 0, 0, 0.75) !important;
    transition: box-shadow 2s;

    &:not(:disabled):hover {
        box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.75) !important;
    }

    span {
        margin: 0 10px;
    }

    &.dark {
        color: #6c757d;
    }
}

.fa-border {
    border-color: lightgray !important;
}

// Bootstrap added styling on the tag .placeholder, which breaks some styling within our application.
// So this will reset the styling it adds.
.placeholder {
    display: initial;
    min-height: initial;
    vertical-align: initial;
    cursor: initial;
    background-color: initial;
    opacity: initial;
}

// Added by bootstrap in the _tables.scss. This adds a 2px solid black line under the workspace table,
// and possibly any other tables we have in the application.
.table > :not(:first-child) {
    border-top: initial;
}

// Allows \n to work in the popover body.
.popover-content.popover-body {
    white-space: pre-wrap;
}
