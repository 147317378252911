@include export-module('treeview-bootstrap5-icons') {

  /*! TreeView icons */
  .e-treeview {

    .e-list-item {

      div.e-icons::before {
        content: '\e75c';
      }
    }

    .e-sibling::before {
      content: '';
    }

    .e-popup {

      .e-icons::before {
        content: '\e76a';
      }
    }

    &.e-drag-item {

      .e-icons.e-drop-in::before {
        content: '\e768';
      }

      .e-icons.e-drop-out::before {
        content: '\e839';
      }

      .e-icons.e-drop-next::before {
        content: '\e736';
      }

      .e-icons.e-no-drop::before {
        content: '\e839';
      }
    }
  }
}
