.control-testing-details-tab-page {
    display: flex;
    flex-direction: column;
    overflow-y: auto !important;
    flex: 1;
    tab-pane,
    eff-detail,
    .cerrix-detail-page,
    .control-testing-detail-page-submenu,
    .control-testing-detail-page-new-wrapper,
    .control-testing-detail-page-new,
    .eff-step-page {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: auto !important;
    }

    .eff-step-page {
        .main-page-area,
        .main-page-content,
        .side-page-area {
            overflow-y: auto;
        }

        .main-page-content,
        .side-page-area {
            height: 100%;
        }

        .side-page-area {
            padding-left: 3px;
            padding-right: 3px;
            padding-top: 3px;
        }

        .main-page-content {
            padding-right: 5px;
        }
    }
}

.score-selector {
    width: 190px;
    height: unset !important;
    border: none !important;
    .mat-mdc-form-field-infix {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .mat-mdc-text-field-wrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .mat-mdc-select-arrow-wrapper {
        display: none !important;
    }

    .selected-option-content {
        height: 30px;
        border: 1px solid;
        border-radius: 10px;
        padding-left: 10px;
        padding-right: 5px;
        color: white;
        div {
            font-weight: var(--semiBold-weight);
        }
        .separator {
            width: 1px;
            height: 23px;
            background-color: white;
            margin-right: 2px;
        }

        .arrow-icon {
            font-size: 20px;
            width: 20px;
            height: 20px;
        }
    }
}

.score-selector-panel {
    box-shadow: none !important;
    background: #f5faff !important;
    //min-width: 250px;
    .mdc-list-item--selected {
        background: transparent !important;
    }
    .mat-mdc-option {
        min-height: unset !important;
    }

    .hide {
        display: none;
    }

    mat-option {
        padding-top: 0;
        padding-bottom: 3px;
        padding-left: 0;
        padding-right: 0;
        font-weight: var(--semiBold-weight);
        &.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
            background: transparent !important;
        }
        div {
            font-weight: var(--semiBold-weight);
        }
        mat-icon {
            margin-right: 0 !important;
        }
        .icon-text-container {
            gap: 3px;
        }
    }
}

.eff-calendar-celll {
    .mat-calendar-body-cell-content {
        color: #001a41 !important;
        background-color: white !important;
    }
}

.eff-calendar-cell:hover {
    .mat-calendar-body-cell-content {
        color: white !important;
        background-color: #4a90e2 !important;
    }
}

.eff-calendar-cell.mat-calendar-body-active {
    .mat-calendar-body-cell-content {
        color: white !important;
        background-color: #001a41 !important;
    }
}

.control-testing-detail-page-new,
.eff-popup-wrapper,
.eff-popover-container-wrapper,
.score-selector-panel {
    --default-blue: #001a41;
    --title-blue: #001257;
    --ocean-blue: #4a90e2;
    --grey: #b7babf;
    --normal-blue: #0c66e4;
    --default-orange: #f39a14;
    --default-red: #b72121;
    --default-green: #66c664;

    //fonts weight
    --normal-weight: 550;
    --semiBold-weight: 650;
    --bold-weight: 750;
    *:not(mat-icon, .fas, .far, .fad, .fal) {
        font-family: "Montserrat", sans-serif;
        letter-spacing: normal;
        font-weight: var(--normal-weight);
        color: var(--default-blue);
    }

    .h1-title {
        font-size: 28px;
        font-weight: var(--semiBold-weight);
        color: var(--title-blue);
    }

    .h2-title {
        font-size: 21px;
        font-weight: var(--semiBold-weight);
        color: var(--title-blue);
    }

    .h3-title {
        font-size: 18px;
        font-weight: var(--semiBold-weight);
        color: var(--title-blue);
    }

    .subtitle {
        font-size: 24px;
        font-weight: var(--semiBold-weight);
        color: var(--title-blue);
        line-height: 32px;
    }

    .regular-text,
    .regular-text-big,
    .regular-text-small {
        font-weight: var(--normal-weight);
        color: var(--default-blue);
    }

    .regular-text {
        font-size: 16px;
        line-height: 24px;
    }

    .regular-text-big {
        font-size: 18px;
        line-height: 26px;
    }

    .regular-text-small {
        font-size: 14px;
        line-height: 24px;
    }

    .bold-text,
    .bold-text-small {
        font-weight: var(--bold-weight);
        color: var(--title-blue);
        line-height: 24px;
    }

    .bold-text {
        font-size: 16px;
    }

    .bold-text-small {
        font-size: 14px;
    }

    .link-button {
        mat-icon,
        .button-label {
            color: var(--ocean-blue);
        }
        .button-label {
            font-weight: var(--normal-weight);
            font-size: 16px;
        }

        .button-container {
            background-color: white;
            height: unset;
            padding: 0;
        }
    }

    .dialog-footer,
    eff-popup-header,
    .dialog-body {
        padding-left: 30px;
        padding-right: 30px;
    }

    eff-popup-header {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e6e6e6;
    }

    .dialog-body {
        padding-top: 25px;
        padding-bottom: 15px;
    }

    .dialog-footer {
        padding-top: 25px;
        padding-bottom: 25px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 15px;
        border-top: 1px solid #e6e6e6;

        eff-button {
            .button-container {
                height: 48px;
                border: 1px solid transparent;
                border-radius: 3px;
                background-color: var(--default-green);
                padding: 0 25px;
            }

            .button-label {
                font-weight: var(--semiBold-weight);
                color: white;
            }

            &.confirm-button {
                .button-container {
                    background-color: var(--default-green);
                    .button-label {
                        color: white;
                    }
                }
            }

            &.warning-button {
                .button-container {
                    background-color: var(--default-red);
                    .button-label {
                        color: white;
                    }
                }
            }

            &.cancel-button {
                .button-container {
                    background-color: #edf4fc !important;
                    .button-label {
                        color: #69729f !important;
                    }
                }
            }
        }
    }

    eff-button {
        &.bottom-button {
            .button-container {
                border: 1px solid transparent;
                border-radius: 9px;
            }

            .button-label {
                color: white;
                font-weight: var(--semiBold-weight);
            }
        }

        .button-container.disabled-button {
            background-color: #f0f2f4 !important;
            cursor: default;

            .button-label {
                color: #b7babf !important;
            }
        }
    }

    eff-icon.action-icon {
        mat-icon {
            color: var(--ocean-blue);
        }
        cursor: pointer;
    }

    eff-step-header:not(.is-step-rejected) {
        margin-bottom: 15px;
    }

    eff-step-header.is-step-rejected {
        margin-bottom: 50px;
    }

    .multiple-whitespaces {
        white-space: pre-wrap;
    }

    .eff-details-panel {
        .header {
            font-size: 18px;
        }
        .rows-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            .row-container {
                display: flex;
                flex-direction: row;
                gap: 20px;
                .row-label {
                    min-width: 170px;
                    max-width: 170px;
                }
            }
        }

        .steps-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .step-rows,
            .step-row {
                display: flex;
                flex-direction: column;
            }

            .step-rows {
                gap: 5px;
            }
        }

        .test-step-panel {
            mat-expansion-panel-header {
                padding: 0 7px;
                height: 33px;
                mat-panel-title {
                    font-size: 16px !important;
                    color: var(--default-blue) !important;
                }
            }
            .mat-expansion-indicator::after {
                color: var(--default-blue) !important;
                margin-bottom: 5px;
            }

            .mat-expansion-panel-body {
                padding: 0 12px 7px 12px;
            }

            .mat-expanded {
                background-color: #edf4fc;
            }

            .bold-text-small {
                font-weight: 650;
            }
        }
    }

    .cerrix-slide-over .title-area .header {
        color: white;
    }

    .documets-list-small-droparea {
        eff-drop-files-area {
            height: unset;
        }
        .drop-label {
            font-weight: var(--normal-weight);
            color: var(--default-blue);
            font-size: 14px;
        }
    }

    .bottom-buttons-wrapper {
        display: flex;
        flex-flow: row;
        gap: 0 15px;
        justify-content: flex-end;

        padding: 30px 0 30px 0;
        margin-top: 20px;
    }

    .eff-circle {
        margin-right: 10px;
    }

    .eff-circle {
        display: inline-block;
        background-color: #00d53b;
        border-radius: 50%;
    }

    .eff-circle-inner {
        color: white;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        text-decoration: none;
        height: 50px;
        width: 50px;
        font-size: 20px;
    }

    .page-area {
        margin: var(--page-margin);
    }

    .workflow-area {
        margin: var(--page-margin);
    }

    .breadcrumb-area {
        font-size: 12px;
        margin: var(--page-margin);

        .section {
            &.bold {
                font-weight: bold;
            }

            span:not(:first-child) {
                margin-left: 5px;
            }

            span:not(:last-child) {
                margin-right: 5px;
            }
        }

        .separator {
            margin: 0 10px;
        }

        &.wide-separators {
            .separator {
                margin: 0 20px;
            }
        }
    }

    .main-page-area {
        margin: var(--page-margin);

        display: flex;
        flex-direction: row;
        flex: 1;

        .main-page-content {
            padding-right: 5vw;
            display: flex;
            flex-direction: column;
            scrollbar-width: none;

            // This ensures that the main content area is always at least 800px wide and takes up 2/3 of the available space
            flex: 2;
            min-width: 800px;
        }

        .main-page-content::-webkit-scrollbar,
        .side-page-area::-webkit-scrollbar {
            width: 0; /* Remove scrollbar space */
            background: transparent; /* Optional: just make scrollbar invisible */
        }
    }

    .side-page-area {
        display: flex;
        flex-direction: column;
        gap: var(--default-gap);
        scrollbar-width: none;

        // This ensures that the side area is always at least 400px wide and takes up 1/3 of the available space
        flex: 1;
        height: 100%;
        overflow-y: auto;
        min-width: 400px;
    }

    .text-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .table-style {
        display: table;
    }

    .table-row {
        display: table-row;
    }

    .table-cell {
        display: table-cell;
        vertical-align: top;
        padding-left: 10px;
        padding-right: 10px;
    }

    .grid-textarea-style {
        border-radius: 10px;
        border: 1px solid #b7babf;
        .mat-mdc-text-field-wrapper {
            background-color: transparent !important;
            padding-left: 11px !important;
            padding-right: 11px !important;
        }
        .mat-mdc-form-field-infix {
            padding-top: 15px !important;
            padding-bottom: 15px !important;
        }

        textarea,
        mat-label {
            font-size: 14px;
            line-height: 24px;
        }
    }

    .grid-header {
        border-bottom: 1px solid #d7dce0;
        padding-bottom: 15px;
        margin-bottom: 15px;
        font-size: 18px;
        display: flex;
        eff-inline-text-editor {
            margin-left: 10px;
        }
    }

    .main-grid {
        gap: 30px;
        .add-comment-label {
            color: #4990e2;
        }
    }

    .expand-collapse-all {
        margin-bottom: 25px;
    }

    .status-cell {
        gap: 10px;
        white-space: nowrap;
    }

    .status-bullet {
        width: 14px;
        height: 14px;
        border: 1px solid black;
        border-radius: 3px;
    }

    .no-score {
        background: #b7babf;
        border-color: #b7babf;
    }

    .score-correct {
        background: var(--default-green);
        border-color: var(--default-green);
    }

    .score-incorrect {
        background: #fb3636;
        border-color: #fb3636;
    }

    .score-inapplicable {
        background: #b7babf;
        border-color: #b7babf;
    }

    .score-missing-evidence {
        background: #f39a14;
        border-color: #f39a14;
    }
}
