@include export-module('datetimepicker-bootstrap5-icons') {

  /*! component icons */
  .e-datetime-wrapper {

    #{if(&, '&', '*')} .e-time-icon.e-icons::before {
      content: '\e705';
      font-family: 'e-icons';
    }
  }
}
