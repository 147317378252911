@include export-module('dialog-bootstrap5-icons') {
  .e-dialog {
    .e-icon-dlg-close::before {
      content: '\e7e7';
      position: relative;
    }

    .e-icon-dlg-close {
      opacity: $dialog-close-icon-normal-opacity;
    }

    .e-icon-dlg-close:active {
      opacity: $dialog-close-icon-active-opacity;
    }

    .e-icon-dlg-close:hover {
      opacity: $dialog-close-icon-active-opacity;
    }

    .e-south-east::before,
    .e-south-west::before,
    .e-north-east::before,
    .e-north-west::before {
      content: '\e832';
    }
  }
}
