@import "./_variables.scss";

.cerrix-wizard {
    display: flex;
    flex-direction: column;
    height: 100%;

    .wizard-steps {
        align-self: flex-start;

        display: flex;
        text-align: center;

        width: 100%;
        padding: 0 25px;
        border-bottom: 1px solid lightgray;

        .wizard-step {
            flex: 1;

            display: inline-flex;
            flex-direction: column;

            padding: 10px 0;
            color: darkgray;

            cursor: pointer;

            &.active {
                border-bottom: 2px solid var(--accent-color);
                color: black;

                i.validation.fa-lock {
                    @extend .orange;
                }
            }

            i.validation {
                font-size: 1.5em;

                &.fa-times-circle {
                    @extend .red;
                }

                &.fa-check-circle {
                    @extend .green;
                }
            }

            span.step-icon {
                font-size: 1em;
                margin-top: 10px;

                .fas {
                    color: var(--accent-color);
                }

                &::after,
                &::before {
                    content: "";
                    display: inline-block;
                    width: calc((100% / 2) - 8px);
                    height: 2px;
                    background-color: darkgray;
                    vertical-align: middle;
                    margin-top: -3px;
                }
            }

            &:first-of-type span.step-icon::before,
            &:last-of-type span.step-icon::after {
                background-color: white;
            }

            span.step-name {
                font-size: 0.8em;
                margin-top: 10px;
            }
        }
    }

    @media (min-width: 1200px) {
        .wizard-pages.padded {
            padding: 0 5%;
        }
    }

    @media (min-width: 1440px) {
        .wizard-pages.padded {
            padding: 0 10%;
        }
    }

    @media (min-width: 1600px) {
        .wizard-pages.padded {
            padding: 0 15%;
        }
    }

    .wizard-pages {
        display: flex;
        flex-grow: 1;
        overflow: auto;
        height: 100%;

        &.summary {
            flex-direction: column;

            .wizard-page:not(.summary-section) {
                .header-row {
                    font-size: 1.5em;
                }
            }
        }

        &:not(.summary) .wizard-page {
            width: 100%;
        }

        .wizard-page {
            padding: 25px 50px;

            .header-row {
                font-size: 2em;
                margin-bottom: 10px;
                text-align: center;

                .left-block {
                    padding-right: 50px;
                    border-right: 1px solid darkgray;
                    text-align: right;
                }

                .right-block {
                    padding-left: 50px;
                    border-left: 1px solid darkgray;
                    text-align: left;
                }
            }

            .detail-page-card {
                margin: 0;
            }

            .centered-row {
                text-align: center;

                .form-group {
                    text-align: left;

                    label {
                        font-weight: bold;
                    }
                }

                .left-block {
                    padding-right: 50px;
                    border-right: 1px solid darkgray;
                }

                .right-block {
                    padding-left: 50px;
                    border-left: 1px solid darkgray;
                }
            }
        }
    }

    .wizard-footer {
        align-self: flex-end;

        width: 100%;
        padding: 10px;
        border-top: 1px solid lightgray;

        button {
            float: left;

            &.right-button {
                float: right;
            }
        }
    }
}

.wizard-complete-modal {
    .modal-body {
        padding: 50px 100px;
        text-align: center;

        i {
            font-size: 5em;
            margin-bottom: 25px;
        }

        p {
            margin-top: 25px;
            text-align: left;
            white-space: pre-line;
        }
    }

    .modal-footer {
        display: flex;
        justify-content: space-between;
    }
}

.wizard-modal-dialog {
    max-width: 100%;
    height: 90%;

    .modal-content {
        height: 100%;
    }
}

@media (min-width: 667px) {
    .wizard-modal-dialog {
        width: 90%;
    }
}

@media (min-width: 1440px) {
    .wizard-modal-dialog {
        width: 80%;
    }
}
