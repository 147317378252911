﻿@include export-module('dropdowntree-theme') {

  .e-ddt {

    .e-chips {
      @if $ddt-skin-name == 'Material3' {
        background: $ddt-chip-bg-color;
        border-color: $ddt-chip-border-color;
        border: 1px solid;
      }
      @else {
        background-color: $ddt-chip-bg-color;
      }

      .e-chips-close::before {
        color: $ddt-chip-close;
      }

      >.e-chipcontent {
        color: $ddt-chip-font-color;
      }

      &:hover {
        @if $ddt-skin-name == 'Material3' {
          background: $ddt-chip-hover-bg-color;
        }
        @else {
          background-color: $ddt-chip-hover-bg-color;
        }

        @if $ddt-skin-name == 'highcontrast' {
          .e-chips-close::before {
            color: $ddt-chip-hover-font-color;
          }
        }

        @if $ddt-skin-name == 'tailwind' {
          .e-chips-close::before {
            color: $icon-color-hover;
          }
        }

        >.e-chipcontent {
          color: $ddt-chip-hover-font-color;
        }
      }
    }

    @if $ddt-skin-name == 'material' or $ddt-skin-name == 'material-dark' {
      &.e-filled {
        .e-chips {
          background-color: $ddt-filled-chip-bg-color;
        }
      }
    }

    @if $ddt-skin-name == 'tailwind' {
      &.e-disabled {
        .e-chips {
          background-color: $content-bg-color-alt3;

          >.e-chipcontent {
            color: $content-text-color-disabled;
          }
        }
      }
    }

    .e-overflow {

      @if $skin-name == 'bootstrap4' {
        color: $ddt-remains-font-color;
      }

      .e-remain {
        color: $ddt-remains-font-color;
      }
    }

    &.e-input-group.e-control-wrapper,
    &.e-float-input.e-control-wrapper {
      .e-clear-icon {
        @if $ddt-skin-name != 'tailwind' and $ddt-skin-name != 'bootstrap5' and $ddt-skin-name != 'material' and  $ddt-skin-name != 'material-dark' and $ddt-skin-name != 'FluentUI' and $ddt-skin-name != 'Material3' {
          background-color: $ddt-icon-bg-color;
        }
      }
    }

    &.e-input-group.e-control-wrapper .e-input[readonly],
    &.e-float-input.e-control-wrapper input[readonly] {
      @if $ddt-skin-name == 'bootstrap4' or $ddt-skin-name == 'bootstrap5' or $ddt-skin-name == 'FluentUI' {
        @if $ddt-skin-name == 'FluentUI' {
          background: transparent;
        }
        @else {
          background: $ddt-readonly-input-bg-color;
        }

        @if $ddt-skin-name == 'bootstrap5' {
          ~ span.e-input-group-icon.e-ddt-icon {
            background: transparent;
            color: $icon-color;
          }
        }
      }

      &.e-disabled {
        @if $ddt-skin-name == 'tailwind' {
          background-color: $content-bg-color-alt1;
          color: $content-text-color-disabled;
        }
      }
    }

    &.e-popup {
      background: $ddt-popup-background-color;
      border-color: $ddt-popup-border-color;

      .e-popup-content.e-no-data {
        color: $ddt-nodata-font-color;
      }

      .e-selectall-parent {
        border-bottom-color: $ddt-popup-reorder-border;

        .e-all-text {
          color: $ddt-select-all-font-color;
        }
      }
    }
  }
}
