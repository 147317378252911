@include export-module('timepicker-bootstrap5-icons') {

  /*! component icons */

  .e-time-wrapper,
  #{if(&, '&', '*')}.e-control-wrapper.e-time-wrapper {

    #{if(&, '&', '*')} .e-time-icon.e-icons::before {
      content: '\e705';
    }
  }

  .e-timepicker-mob-popup-wrap .e-timepicker.e-popup-expand,
  .e-datetimepicker.e-popup-expand {

    #{if(&, '&', '*')} .e-model-header {

      #{if(&, '&', '*')} .e-popup-close::before {
        content: '\e7e7';
        font-family: 'e-icons';
      }
    }
  }
}
