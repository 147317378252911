// layout variables
$slider-height: 4px !default;
$slider-handle-height: 12px !default;
$slider-handle-width: 12px !default;
$slider-handle-border-radius: 50% !default;
$slider-handle-top: -7px !default;
$slider-h-handle-margin: 0 !default;
$rtl-slider-h-handle-margin: 0 -6px 0 0 !default;
$slider-v-handle-margin: -6px !default;
$rtl-slider-v-handle-margin: 0 !default;
$slider-container-height: 6px !default;
$slider-handle-box-shadow: $shadow !default;
$slider-tooltip-padding: 2px 4px !default;
$tick-after-height: 6px !default;
$tick-after-top: -10px !default;
$largetick-before-top: -10px !default;
$largetick-before-height: 10px !default;
$largetick-after-height: 10px !default;
$largetick-after-top: 5px !default;
$tick-before-height: 6px !default;
$tick-before-top: 1px !default;
$slider-vertical-width: 4px !default;
$slider-border-radius-handle: 10px !default;
$slider-scale-font-family: $font-family !default;
$slider-scale-font-size: $text-xs !default;
$slider-scale-tick-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AztTa/whsA3yqMpmsAAAAAElFTkSuQmCC');
$slider-scale-tick-image-dark: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AbHV9/wUNAl7Qm4m+AAAAAElFTkSuQmCC');
$slider-disabled-handle-show: none !default;
$slider-button-shadow: $shadow-sm !default;
$slider-button-horizon-padding: 0 28px !default;
$slider-button-vertical-padding: 28px 0 !default;
$tick-after-left: 5px !default;
$largetick-before-left: 1px !default;
$tick-before-left: 17px !default;
$largetick-after-left: 17px !default;
$slider-wrap-height: 26px !default;
$slider-center-align: -5px !default;
$slider-btn-center-align: -10px !default;
$slider-range-height: $slider-container-height !default;
$slider-range-width: $slider-vertical-width !default;
$slider-range-border-radius: 4px !default;
$slider-cursor: pointer !default;
$slider-handle-hover-cursor: pointer !default;
$slider-scale-top: 16px !default;
$slider-tab-border: 1px solid $content-bg-color !default;
$slider-tab-active-box-shadow: $shadow-focus-ring2 !default;
$slider-limit-bar-horizontal-height: 6px !default;
$slider-limit-bar-top: calc(50% - 3px) !default;
$slider-limit-bar-vertical-width: 6px !default;
$slider-limit-bar-left: calc(50% - 3px) !default;

$slider-handle-mobile-height: 16px !default;
$slider-handle-mobile-width: 16px !default;
$slider-handle-mobile-margin-left: -8px !default;
$slider-handle-mobile-handle-top: calc(50% - 8px) !default;
$slider-handle-mobile-top: -7px !default;
$slider-h-handle-mobile-margin: 0 !default;
$rtl-slider-h-handle-mobile-margin: 0 !default;
$slider-v-handle-mobile-margin: 0 !default;
$rtl-slider-v-handle-mobile-margin: 0 !default;
$slider-mobile-scale-font-size: $text-sm !default;
$tick-after-mobile-height: 8px !default;
$tick-after-mobile-left: 18px !default;
$tick-before-mobile-left: 2px !default;
$largetick-before-mobile-height: 12px !default;
$largetick-before-mobile-left: -2px !default;
$tick-before-mobile-top: -12px !default;
$largetick-after-mobile-top: 8px !default;
$tick-after-mobile-top: 4px !default;

//color variables
$slider-button-icon-color: $secondary-text-color !default;
$slider-range-bar-color: $primary !default;
$slider-range-bar-hover-color: $primary-bg-color-hover !default;
$slider-track-color: $content-bg-color-alt2 !default;
$slider-button-bg-color: $secondary-bg-color !default;
$slider-button-icon-bg-color: $secondary-text-color !default;
$slider-normal-handle-border-color: $primary !default;
$slider-handle-bg-color: $primary !default;
$slider-hover-color: $content-bg-color-alt2 !default;
$slider-handle-hover-border-color: $slider-normal-handle-border-color !default;
$slider-handle-start-border-color: $content-bg-color-alt2 !default;
$slider-button-border-color: $secondary-border-color !default;
$slider-tab-background: $primary-bg-color-hover !default;
$slider-tab-range-color: $primary !default;
$slider-tab-track-color: $content-bg-color-alt2 !default;
$slider-limit-bar-color: $overlay-bg-color !default;
$slider-tick-font-color: $content-text-color-alt1 !default;
$slider-hover-handle-color: $primary-bg-color-hover !default;
$slider-hover-handle-border-color: $primary-border-color-hover !default;
