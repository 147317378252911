@include export-module('calendar-bootstrap5-icons') {

  /*! component icons */
  .e-calendar .e-header {
    #{if(&, '&', '*')} .e-date-icon-prev::before {
      content: '\e776';
    }

    #{if(&, '&', '*')} .e-date-icon-next::before {
      content: '\e729';
    }
  }
}
