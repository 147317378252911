$grid-column-chosser-searchdiv-opactiy: .6 !default;
$grid-column-chosser-searchdiv-fopactiy: 1 !default;
$grid-columnchooser-search-border-color: $border-light !default;
$grid-columnchooser-search-border-fcolor: $primary !default;
$grid-columnchooser-footer-border-opacity: 1 !default;
$grid-columnchooser-cancel-icon-content-f-opactiy: 1 !default;
$grid-column-chooser-dlg-footer-padding: 8px !default;
$grid-xl-match-margin-left: 6px !default;
$grid-bigger-xl-match-margin-left: 8px !default;
$grid-xl-rtl-radio-padding-left: 0 !default;
$grid-xl-rtl-radio-padding-right: 25px !default;
$grid-columnchooser-footer-border-color: rgba($content-text-color-alt1, .12) !default;
$grid-column-chooser-searchdiv: 1px !default;
$grid-bigger-cc-checkbox-padding-left: 8px !default;
$grid-bigger-searchdiv-padding-left: 4px !default;
$grid-cc-checkbox-padding-left: 8px !default;
$grid-edit-checkbox-bigger-padding-left: 8px !default;
$grid-edit-checkbox-bigger-padding-top: 8px !default;
$grid-xlfl-fieldset-padding: 9px !default;
$grid-xlfltd-padding: 11.25px !default;
$grid-xlfltable-border-spacing: 0 !default;
$grid-xlfl-dropdown-padding-right: 11.25px !default;
$grid-xlfl-min-height: 318px !default;
$grid-exlfltr-ctxtmnu-max-height: 298px !default;
$grid-bigger-exlfltr-ctxtmnu-max-height: 355px !default;
$grid-xlfl-li-left-padding: 25px !default;
$grid-xlfl-li-icon-margin-right: 10px !default;
$grid-xlfl-dlg-padding-bottom: 5px !default;
$grid-xlfltd-radio-mtcase-padding: 12px !default;
$grid-xlfl-fieldset-font-size: $font-icon-13 !default;
$grid-xlfl-dlgfields-font-size: $font-icon-15 !default;
$grid-xlfl-li-height: 36px !default;
$grid-xl-radio-padding-left: 25px !default;
$grid-xlfl-radiodiv-width: 120px !default;
$grid-bigger-xlfl-li-height: 40px !default;
$grid-bigger-xlfl-fieldset-font-size: $text-base !default;
$grid-bigger-xlfl-dlgfields-font-size: $text-lg !default;
$grid-bigger-xlfltd-radio-mtcase-padding: 10px !default;
$grid-bigger-xlfl-dlg-padding-bottom: 10px !default;
$grid-bigger-xlfl-li-icon-margin-right: 13px !default;
$grid-bigger-xlfl-li-left-padding: 26px !default;
$grid-bigger-xlfl-min-height: 362px !default;
$grid-bigger-xlfl-fieldset-padding: 12px !default;
$grid-bigger-xlfltable-border-spacing: 0 !default;
$grid-bigger-xlfltd-padding: 15px !default;
$grid-bigger-xlfl-radio-margin-left: 18px !default;
$grid-bigger-xlfl-dropdown-padding-right: 10px !default;
$grid-bigger-xlfl-radiodiv-width: 141px !default;
$grid-xlfl-radio-margin-left: 2px !default;
$grid-xl-radio-padding-right: 9px !default;
$grid-bigger-xl-radio-padding-left: 27px !default;
$grid-bigger-xl-radio-padding-right: 6px !default;
$grid-checkboxfiltertext-width: 150px !default;
$grid-rtl-textwrap-column-menu-margin: -17px -13px -23px -25px !default;
$grid-bigger-checkbox-padding-topbottom: 9px !default;
$grid-column-chooser-footdiv: 1px 0 0 !default;
$grid-checkbox-padding-topbottom: 8px !default;
$grid-checkbox-content-padding-left: 6px !default;
$grid-rtl-checkbox-content-padding-left: 14px !default;
$grid-bigger-checkbox-content-padding-left: 7px !default;
$grid-rtl-bigger-checkbox-content-padding-left: 16px !default;
$grid-autofill-color: $primary !default;
$grid-filtered-color: $primary !default;
$sfgrid-xlfl-dlg-padding: 14px !default;
$grid-excel-filter-separator: 0 !default;
$grid-dialog-footer-color: $flyout-bg-color !default;
$grid-xlfl-dlg-content-top-padding: 12px !default;
$grid-xlfl-context-menu-border-radious: 5px 5px 0 0 !default;
$grid-excel-filter-bottom-padding: 0 !default;
$grid-xlfl-li-icon-margin-arrow-icon: 13px !default;
$grid-bigger-xlfl-li-icon-margin-arrow-icon: 6.2px !default;
$grid-xl-radio-margin-top: 0 !default;
$grid-xlfl-skin: 'bootstrap5' !default;
