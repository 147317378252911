@include export-module('list-box-bootstrap5-icons') {
  .e-listbox-tool .e-moveup::before {
    content: '\e776';
  }

  .e-listbox-tool .e-movedown::before {
    content: '\e729';
  }

  .e-listbox-tool .e-moveto::before {
    content: '\e748';
  }

  .e-listbox-tool .e-movefrom::before {
    content: '\e765';
  }

  .e-listbox-tool .e-moveallto::before {
    content: '\e7a9';
  }

  .e-listbox-tool .e-moveallfrom::before {
    content: '\e744';
  }
}
