// $diagram-endpoint-handle-color: #046ae5 ;
// $diagram-endpoint-handle-connected-border: #0f0;
// $diagram-endpoint-handle-connected-color: #0f0;;
// $diagram-resize-handle-color: #046ae5 ;
// $diagram-resize-handle-stroke: #046ae5 ;
// $diagram-resize-handle-disable-color: #aaa;
// $diagram-resize-handle-disable-stroke: #aaa;
// $diagram-border-stroke-disabled: #aaa;
// $diagram-rotate-handle-color: #046ae5 ;
// $diagram-helper-stroke: #0e7490;
// $diagram-border-stroke: #000;
// $diagram-highlighter-stroke: #6a5acd ;
// $diagram-selected-region-stroke: #046ae5;
// $diagram-pivot-line-stroke: #ff8c00;
// $diagram-text-edit-color: #f00;
// $diagram-text-edit-selection-color: #fff;
// $diagram-text-edit-selection-background: #aaa;
// $diagram-text-edit-background: #ff0;
// $diagram-symbolpalette-hover: #f00;
// $diagram-symbolpalette-selected: #f00;
// $diagram-symbolpalette-shape-radius: 00px;
// $diagram-tooltip: none;
// $diagram-ruler-color: #979797 ;
// $diagram-ruler-font: Roboto-Regular;
// $diagram-ruler-font-size: 10px;
// $diagram-bezier-handle-color: #f00;
// $diagram-bezier-line-color: #00f;
// $diagram-ruler-marker-stroke: #0f0;
// $diagram-ruler-marker-background-color: #ffa500;
// $diagram-ruler-label-fill: #f00;
// $diagram-ruler-tick-stroke: #0f0;
// $diagram-background-color: #fff;
// $diagram-editbox-background: #fff;
// $diagram-editbox-font-color: #000;
// $diagram-border-strokes: #000;
// $diagram-lane-border-strokes: #0078ff;

.e-icons {
  font-family: 'e-icons';
  font-style: normal;
  font-variant: normal;
  font-weight: $font-weight;
  line-height: 1;
  text-transform: none;
}

@include export-module('diagram-definition-icons') {
  .e-diagram-menu {
    .e-bringforward::before{ content: '\e574'; }
    .e-bringfront::before{ content: '\e575'; }
    .e-sendback::before{ content: '\e576'; }
    .e-sendbackward::before{ content: '\e577'; }
    .e-delete::before{ content: '\e578'; }
    .e-ungroup::before{ content: '\e579'; }
    .e-group::before{ content: '\e57a'; }
    .e-undo::before{ content: '\e57c'; }
    .e-cut::before{ content: '\e57d'; }
    .e-save::before{ content: '\e57e'; }
    .e-paste::before{ content: '\e57f'; }
    .e-copy::before{ content: '\e580'; }
    .e-redo::before{ content: '\e581'; }
    .e-zoomin::before{ content: '\e582'; }
    .e-italic::before{ content: '\e583'; }
    .e-close::before{ content: '\e584'; }
    .e-pan::before{ content: '\e585'; }
    .e-bold::before{ content: '\e586'; }
    .e-underline::before{ content: '\e587'; }
    .e-zoomout::before{ content: '\e588'; }
    .e-export::before{ content: '\e589'; }
    .e-print::before{ content: '\e58a'; }
    .e-order::before{ content: '\e58b'; }
  }

  .e-symbolpalette {
    .e-clear-searchtext::before{ content: '\e58c'; }
  }
}
