﻿@include export-module('numerictextbox-theme') {
  .e-numeric.e-control-wrapper {
    #{if(&, '&', '*')}.e-input-group:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-focus) {
      @if $numeric-skin-name == 'bootstrap' {
        border-color: $input-group-full-border-color;
        box-shadow: none;
      }
    }

    #{if(&, '&', '*')}.e-input-group .e-input-group-icon {
      font-size: $numeric-input-icon-size;
    }
  }

  .e-bigger {
    #{if(&, '&', '*')} .e-control-wrapper.e-numeric.e-input-group .e-input-group-icon,
    #{if(&, '&', '*')}.e-control-wrapper.e-numeric.e-input-group .e-input-group-icon {
      font-size: $numeric-input-bigger-icon-size;
    }
  }

  .e-small {
    #{if(&, '&', '*')} .e-control-wrapper.e-numeric.e-input-group .e-input-group-icon,
    #{if(&, '&', '*')}.e-control-wrapper.e-numeric.e-input-group .e-input-group-icon {
      @if ($input-skin-name == 'FluentUI') {
        font-size: $numeric-input-small-icon-size;
      }
    }
  }

  .e-small.e-bigger .e-control-wrapper.e-numeric.e-input-group .e-input-group-icon,
  .e-small.e-bigger.e-control-wrapper.e-numeric.e-input-group .e-input-group-icon,
  .e-small .e-bigger.e-control-wrapper.e-numeric.e-input-group .e-input-group-icon,
  .e-bigger .e-small.e-control-wrapper.e-numeric.e-input-group .e-input-group-icon {
    @if ($input-skin-name == 'FluentUI') {
      font-size: $numeric-input-bigger-small-icon-size;
    }
  }
}
