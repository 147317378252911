@include export-module('multiselect-bootstrap5-icons') {
  .e-multiselect.e-input-group .e-ddl-icon::before {
    content: '\e729';
    font-family: 'e-icons';
  }

  .e-multi-select-wrapper .e-chips .e-chips-close::before {
    content: '\e7e7';
    cursor: pointer;
    left: $ddl-chip-close-left;
    position: relative;
    top: $ddl-chip-close-top;
  }

  .e-multi-select-wrapper .e-close-hooker::before {
    content: '\e7e7';
    cursor: pointer;
    left: $ddl-overall-close-left;
    position: relative;
    top: $ddl-overall-close-top;
  }

  .e-multiselect.e-input-group .e-ddl-disable-icon::before {
    content: '';
  }
}
