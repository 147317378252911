/*! Accordion's bootstrap5 theme wise override definitions and variables */
/* stylelint-disable */
$acrdn-skin: 'bootstrap5' !default;

// font definitions
$acrdn-icon-font-size: $text-lg !default;
$acrdn-slct-header-font-weight: $font-weight-normal;
$acrdn-header-font: $content-text-color !default;
$acrdn-nested-header-font: $content-text-color !default;
$acrdn-header-font-weight: $font-weight-normal !default;
$acrdn-header-font-size: $text-sm !default;
$acrdn-nest-header-font-size: $text-sm !default;
$acrdn-nest-header-content-font-size: $acrdn-nest-header-font-size !default;
$acrdn-nest-header-content-font-weight: $font-weight-normal !default;
$acrdn-content-font-size: $text-sm !default;
$acrdn-header-font-bgr-size: $text-base !default;
$acrdn-content-font-bgr-size: $text-base !default;
$acrdn-bgr-arrow-icon-font-size: $text-xl;
$acrdn-bgr-item-header-content-font-size: $text-base !default;
$acrdn-icon-font-size: $text-lg !default;
$acrdn-selected-header-font-color: $primary !default;

// Header, Nested header and RTL mode Padding
$acrdn-nest-nrml-header-padding: 10px 0 10px 26px !default;
$acrdn-nest-bgr-header-padding: 12px 0 12px 28px !default;
$acrdn-nest-second-nrml-header-padding: 8px 0 8px 38px !default;
$acrdn-nest-second-bgr-header-padding: 12px 0 12px 40px !default;
$acrdn-nrml-header-padding: 10px 16px !default;
$acrdn-bgr-header-padding: 13px 20px !default;
$acrdn-rtl-nrml-header-padding: 7px 12px !default;
$acrdn-rtl-bgr-header-padding: 11px 17px !default;
$acrdn-rtl-nest-nrml-header-padding: 8px 26px 8px 0 !default;
$acrdn-rtl-nest-bgr-header-padding: 12px 28px 12px 0 !default;
$acrdn-rtl-nest-second-nrml-header-padding: 8px 38px 8px 0 !default;
$acrdn-rtl-nest-second-bgr-header-padding: 12px 40px 12px 0 !default;
$acrdn-nested-item-header-focus-box-shadow: inset $shadow-focus-ring1 !default;

// Content, Nested content and RTL mode Padding
$acrdn-nrml-content-padding: 12px !default;
$acrdn-bgr-content-padding: 16px !default;
$acrdn-nest-nrml-content-padding: 16px 16px 16px 26px !default;
$acrdn-nest-bgr-content-padding: 20px 20px 20px 28px !default;
$acrdn-nest-second-nrml-content-padding: 20px 20px 20px 38px !default;
$acrdn-nest-second-bgr-content-padding: 24px 24px 24px 40px !default;
$acrdn-rtl-nest-nrml-content-padding: 16px 26px 16px 16px !default;
$acrdn-rtl-nest-bgr-content-padding: 20px 28px 20px 20px !default;
$acrdn-rtl-nest-second-nrml-content-padding: 20px 38px 20px 20px !default;
$acrdn-rtl-nest-second-bgr-content-padding: 24px 40px 24px 24px !default;

// dimension definitions
$acrdn-border-radius: 4px !default;
$acrdn-nrml-header-minheight: 38px !default;
$acrdn-bgr-header-minheight: 48px !default;
$acrdn-nrml-header-lineheight: 22px !default;
$acrdn-bgr-header-lineheight: 24px !default;
$acrdn-nrml-header-paddingpix: 16px !default;
$acrdn-bgr-header-paddingpix: 16px !default;
$acrdn-content-line-height: 1.5 !default;
$acrdn-nrml-header-icon-padding: 8px !default;
$acrdn-bgr-header-icon-padding: 12px !default;
$acrdn-nrml-icn-minwidth: 22px !default;
$acrdn-bgr-icn-minwidth: 24px !default;
$acrdn-selected-border-size: 1px !default;
$acrdn-item-padding-size: 0 !default;
$acrdn-selected-border-type: none !default;
$acrdn-border-type: solid !default;
$acrdn-border-size: 1px !default;

$acrdn-item-hdr-con-lineheight: 22px !default;
$acrdn-item-selected-first-child-border-top: 1px none $border-light !default;
$acrdn-item-selected-last-child-border-bottom: 1px solid $border-light !default;
$acrdn-item-border-radius: 0 !default;
$acrdn-item-margin-top: 0 !default;
$acrdn-item-nested-panel-item-select-margin: 0 !default;
$acrdn-item-overflow: visible !default;
$acrdn-item-hover-focus-hdr-con-text-decoration: none !default;
$acrdn-item-hdr-border-radius: 0 !default;
$acrdn-item-tgl-icon-display: table !default;
$acrdn-item-arrow-icon-font-size: $text-lg !default;
$acrdn-opacity: .5 !default;

// color definitions
$acrdn-tab-highlight-color:  rgba(0, 0, 0, 0) !default;
$acrdn-active-bg: $content-bg-color !default;
$acrdn-bg-color: $content-bg-color !default;
$acrdn-default-bg-color: $acrdn-bg-color !default;
$acrdn-active-bg-color: $acrdn-active-bg !default;
$acrdn-selected-header-color: $content-bg-color !default;
$acrdn-icon-color: $icon-color !default;
$acrdn-selected-icon-color: $primary !default;
$acrdn-content-color: $content-text-color !default;
$acrdn-selected-border-color: $border-light !default;
$acrdn-header-focus-bg: $primary-lighter !default;
$acrdn-header-hover-bg: $primary-lighter !default;
$acrdn-header-active-bg: $primary-lighter !default;
$acrdn-item-header-focus-border-color: $primary !default;
$acrdn-item-icon-disable-color: $icon-color-disabled;
$acrdn-text-disable-color: $content-text-color-disabled;
$acrdn-default-border: $acrdn-selected-border-color !default;

$acrdn-item-border-color: $acrdn-default-border !default;
$acrdn-item-border-nav-type: solid !default;
$acrdn-item-border-size: 0 0 1px 0 !default;
$acrdn-item-select-last-child-border-bottom: 0 !default;
$acrdn-item-select-last-child-border-radius: 0 !default;
$acrdn-item-first-child-hdr-focus-border-radius: 4px 4px 0 0 !default;
$acrdn-item-last-child-border-bottom: 0 !default;
$acrdn-item-last-child-not-exp-hdr-focus-border-radius: 0 0 4px 4px !default;
$acrdn-item-focus-border-color: $acrdn-default-border !default;
$acrdn-item-focus-border-size: 0 0 1px 0 !default;
$acrdn-item-focus-selected-exp-border-top: 1px none $acrdn-default-border !default;
$acrdn-item-focus-selected-exp-hdr-focus-border-color: $acrdn-default-border !default;
$acrdn-item-focus-selected-exp-hdr-focus-border-type: solid !default;
$acrdn-item-focus-selected-exp-hdr-focus-border-size:  0 0 1px 0 !default;
$acrdn-item-focus-exp-hdr-hover-focus-bg-color: $primary-lighter !default;
$acrdn-item-focus-exp-hdr-hover-focus-color: $acrdn-selected-header-font-color !default;
$acrdn-item-focus-selected-hdr-icons-font: $acrdn-selected-header-font-color !default;
$acrdn-item-focus-exp-hdricon-hover-font: $acrdn-icon-color !default;
$acrdn-item-focus-selected-exp-border-color: $acrdn-default-border !default;
$acrdn-item-exp-select-border-color: $acrdn-default-border !default;
$acrdn-item-exp-select-border-size: 0 0 1px 0 !default;
$acrdn-item-header-border: 0 !default;
$acrdn-item-select-exp-hdr-con-disable-font: $content-text-color-disabled !default;
$acrdn-item-header-content-disable-color: $content-text-color !default;
$acrdn-item-hdr-con-text-disabled-font: $content-text-color-disabled !default;
$acrdn-item-selected-exp-hdr-focus-border-color: $border-light !default;
$acrdn-item-selected-exp-hdr-focus-border-type: solid !default;
$acrdn-item-selected-exp-hdr-focus-border-size: 0 0 1px 0 !default;
$acrdn-item-selected-exp-hdr-focus-bg: $primary-lighter !default;
$acrdn-item-selected-exp-hdr-hover-bg: $primary-lighter !default;
$acrdn-item-selected-exp-hdr-active-bg: $primary-lighter !default;
$acrdn-item-nes-pan-con-last-child-border-bottom: 1px none $acrdn-selected-border-color !default;
$acrdn-item-select-bg: none !default;
$acrdn-item-select-border-color: $border-light !default;
$acrdn-item-select-border-size: 0 0 1px 0 !default;
$acrdn-item-panel-content-select-bg: none !default;
$acrdn-item-panel-content-select-border-top: 0 !default;
$acrdn-item-panel-content-select-border-bottom: 0 !default;
$acrdn-item-header-hover-border: 0 !default;
$acrdn-item-header-hover-bg: none !default;
$acrdn-item-header-hover-border-color: initial !default;
$acrdn-item-header-active-border: 0 !default;
$acrdn-item-header-active-border-color: initial !default;
$acrdn-item-header-focus-border: 0 !default;
$acrdn-item-header-focus-box-shadow: $shadow-focus-ring1 !default;
$acrdn-item-header-focus-bg: none !default;
$acrdn-item-header-focus-active-color: $content-text-color !default;
$acrdn-item-select-exp-hdr-hover-color: $primary !default;
$acrdn-item-selected-exp-hdr-border-color: $border-light !default;
$acrdn-item-selected-exp-hdr-border-size: 0 0 1px 0 !default;
$acrdn-item-selected-exp-hdr-bg: $primary-lighter !default;
$acrdn-item-selected-exp-hdr-hover-color: $primary !default;
$acrdn-item-selected-exp-hdr-hover-focus-color: $primary !default;
$acrdn-item-selected-exp-hdr-icon-color: $icon-color !default;
$acrdn-item-selected-exp-hdr-hover-border-color: $border-light !default;
$acrdn-item-selected-tgl-icon-color: $primary !default;
$acrdn-item-selected-hdr-font: $primary !default;
$acrdn-item-select-hdr-focus-border-color: $border-light !default;
$acrdn-item-selected-border-color: $border-light !default;
$acrdn-item-selected-border-size: 0 0 1px 0 !default;
$acrdn-item-selected-select-last-border-bottom: 1px none $border-light !default;
$acrdn-item-selected-selected-border-color: $border-light !default;
$acrdn-item-selected-selected-border-size: 0 0 1px 0 !default;
$acrdn-item-selected-header-background: $primary-lighter !default;
$acrdn-item-selected-hdr-border-color: $border-light !default;
$acrdn-item-selected-hdr-border-size:  0 0 1px 0 !default;
$acrdn-item-selected-header-border-radius: 0 !default;
$acrdn-item-selected-hdr-con-font: $primary !default;
$acrdn-item-selected-select-border-color: $border-light !default;
$acrdn-item-selected-select-border-size: 0 0 1px 0 !default;
$acrdn-item-selected-hdr-icon-font: $primary !default;
$acrdn-item-selected-exp-hdr-con-hover-color: $primary !default;
$acrdn-item-selected-exp-hdr-icon-hover-color: $icon-color !default;
$acrdn-item-selected-select-active-bg: $content-bg-color !default;
$acrdn-item-nes-pan-exp-hdr-color: $content-text-color !default;
$acrdn-item-selected-hdr-hover-border-color: $border-light !default;
$acrdn-item-selected-hdr-tgl-icon-hover-font: $primary !default;
$acrdn-item-nes-selected-exp-hover-hdr-icons-font: $primary !default;
$acrdn-item-nes-selected-exp-hover-hdr-con-font: $content-text-color !default;
$acrdn-item-exp-not-selected-hdr-focus-bg: none !default;
$acrdn-item-exp-not-selected-hdr-focus-border: 0 !default;
$acrdn-item-exp-not-selected-hdr-focus-border-color: initial !default;
