﻿$secondary-text-color: white;

$primary-button-gradient: linear-gradient(135deg, var(--accent-color), #000000e6);

$background-accent: var(--background-accent-color);

$navbar-height: 50px;
$tabbar-height: 40px;
$topbar-height: #{$navbar-height + $tabbar-height};
$navbar-height-short: 55px;
$topbar-height-short: #{$navbar-height-short + $tabbar-height};
$menu-width: 250px;

.fasttransition {
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}

.flex-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.dark,
.black {
    color: #6c757d;
}

.yellow {
    color: yellow;
}

.primary-color,
.accent-color {
    color: var(--accent-color);
}

.blue {
    color: #0c66e4;
}

.red {
    color: #f9575c !important;
}

.green {
    color: #7fb275 !important;
}

.orange {
    color: orange;
}

.btn-cerrix {
    background: $primary-button-gradient;
    color: white;
}
