@include export-module('split-button-theme') {

  /*! splitbutton theme */
  .e-split-btn-wrapper {
    .e-split-btn {
      @if $skin-name == 'Material3' {
        border-image: $split-btn-seperator-border-color;
        border-width: 0 1px 0 0;
      }
      @else {
        border-right-color: $split-btn-seperator-border-color;
      }

      &:focus {
        border-right-color: $split-btn-focus-border-color;
      }

      &:active {
        border-right-color: $split-btn-active-border-color;
      }

      &:disabled {
        border-right-color: $btn-disabled-bgcolor;
      }

      &.e-inherit {
        border-color: transparent;
      }
    }

    &.e-splitbtn-disabled {
      @if $skin-name == 'Material3' {
        box-shadow: none;
      }
    }

    &:hover :not(e-active) {
      & .e-split-btn {
        border-right-color: $split-btn-hover-border-color;

        &:disabled {
          border-right-color: $btn-disabled-border-color;
        }
      }
    }

    &.e-rtl {
      .e-split-btn {
        @if $skin-name == 'material' or $skin-name == 'fabric' {
          border-color: transparent;
        }
        @else if $skin-name == 'Material3' {
          border-image: $split-btn-seperator-border-color-rtl;
          border-width: 0 0 0 1px;
        }
        @else {
          border-left-color: $split-btn-seperator-border-color;
          border-right-color: $split-btn-seperator-default-border-color;
        }

        &:focus {
          @if $skin-name == 'material' or $skin-name == 'fabric' {
            border-color: transparent;
          }
          @else {
            border-left-color: $split-btn-focus-border-color;
            border-right-color: $split-btn-focus-vertical-border-color;
          }
        }

        &:active {
          @if $skin-name == 'material' or $skin-name == 'fabric' {
            border-color: transparent;
          }
          @else {
            border-left-color: $split-btn-active-border-color;
            border-right-color: $split-btn-active-vertical-border-color;
          }
        }

        &:disabled {
          border-left-color: $btn-disabled-border-color;
          border-right-color: $split-btn-disabled-vertical-border-color;
        }

        &.e-inherit {
          border-color: transparent;
        }
      }

      &:hover {
        & .e-split-btn :not(e-active) {
          border-left-color: $split-btn-hover-border-color;
          border-right-color: $split-btn-hover-vertical-border-color;

          &:disabled {
            border-left-color: $btn-disabled-border-color;
          }
        }
      }
    }

    &.e-vertical {
      .e-split-btn {
        @if $skin-name == 'Material3' {
          border-image: $split-btn-seperator-border-color-vertical;
          border-width: 0 0 2px;
        }
        border-bottom-color: $split-btn-seperator-vertical-border-color;
        border-right-color: $split-btn-seperator-default-border-color;

        &.e-inherit,
        &.e-inherit:focus,
        &.e-inherit:active,
        &.e-inherit:disabled {
          border-color: transparent;
        }

        &:focus {
          border-bottom-color: $split-btn-focus-border-color;
          border-right-color: $split-btn-focus-vertical-border-color;
        }

        &:active {
          border-bottom-color: $split-btn-active-border-color;
          border-right-color: $split-btn-active-vertical-border-color;
        }

        &:disabled {
          border-bottom-color: $btn-disabled-border-color;
          border-right-color: $split-btn-disabled-vertical-border-color;
        }
      }

      &:hover {
        & .e-split-btn {
          border-bottom-color: $split-btn-hover-border-color;
          border-right-color: $split-btn-hover-vertical-border-color;

          &.e-inherit,
          &.e-inherit:disabled {
            border-color: transparent;
          }

          &:disabled {
            border-bottom-color: $btn-disabled-border-color;
          }
        }
      }

      &.e-rtl {
        .e-split-btn {
          border-left-color: $split-btn-seperator-default-border-color;

          &.e-inherit,
          &.e-inherit:focus,
          &.e-inherit:active,
          &.e-inherit:disabled {
            border-color: transparent;
          }

          &:focus {
            border-left-color: $split-btn-focus-left-border-color;
          }

          &:active {
            border-left-color: $split-btn-active-left-border-color;
          }

          &:disabled {
            border-left-color: $split-btn-disabled-left-border-color;
          }
        }

        &:hover {
          & .e-split-btn {
            border-left-color: $split-btn-hover-left-border-color;

            &.e-inherit {
              border-color: transparent;
            }
          }
        }
      }
    }
  }
}
