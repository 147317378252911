@include export-module('pager-bootstrap5-icons') {

  /* !componenticons */

  #{&}.e-pager,
  .e-grid-menu {

    .e-icon-last::before {
      content: '\e7a9';
    }

    .e-icon-first::before {
      content: '\e744';
    }

    .e-icon-prev::before {
      content: '\e765';
    }

    .e-icon-next::before {
      content: '\e748';
    }

    &.e-rtl {
      & .e-icon-last.e-lastpage::before,
      & .e-icon-last.e-lastpage:hover,
      & .e-icon-last.e-lastpagedisabled::before {
        content: '\e744';
      }

      & .e-icon-first.e-firstpage::before,
      & .e-icon-first.e-firstpage:hover,
      & .e-icon-first.e-firstpagedisabled::before {
        content: '\e7a9';
      }

      & .e-icon-prev.e-prevpage::before,
      & .e-icon-prev.e-prevpage:hover,
      & .e-icon-prev.e-prevpagedisabled::before {
        content: '\e748';
      }

      & .e-icon-next.e-nextpage::before,
      & .e-icon-next.e-nextpage:hover,
      & .e-icon-next.e-nextpagedisabled::before {
        content: '\e765';
      }
    }
  }
}
