//layout variables
$switch-wrapper-width: 50px !default;
$switch-wrapper-height: 20px !default;
$switch-inner-width: 100% !default;
$switch-inner-height: 100% !default;
$switch-handle-top: 2px !default;
$switch-handle-left: 2px !default;
$switch-handle-margin: auto 0 !default;
$switch-inner-top: 0 !default;
$switch-inner-left: 0 !default;
$switch-off-text-indent: 18px !default;
$switch-on-text-indent: -16px !default;
$switch-handle-active: 100% !default;
$switch-handle-margin-left: -18px !default;
$switch-active-on-opacity: 1 !default;
$switch-off-opacity: 1 !default;
$switch-handle-height: 16px !default;
$switch-handle-width: 16px !default;
$switch-transition: all .2s ease-in-out .1s !default;
$switch-inner-transition: all .2s ease-in-out !default;
$switch-handle-transition: all .2s linear !default;
$switch-active-on-position-left: 0 !default;
$switch-off-active-left: 100% !default;
$switch-on-position-left: -100% !default;
$switch-on-position-left-rtl: 0 !default;
$switch-off-position-left: 0 !default;
$switch-off-position-left-rtl: -100% !default;
$switch-on-rtl: 100% !default;
$switch-off-rtl: 0 !default;
$switch-handle-radius: 50% !default;
$switch-handle-bottom: 2px !default;
$switch-border-radius: 20px !default;
$switch-small-ripple-height: 36px !default;
$switch-small-ripple-left: -10px !default;
$switch-small-ripple-top: -10px !default;
$switch-small-ripple-width: 42px !default;
$switch-small-wrapper-height: 18px !default;
$switch-small-wrapper-width: 42px !default;
$switch-small-handle-width: 14px !default;
$switch-small-handle-height: 14px !default;
$switch-small-handle-active-left: 100% !default;
$switch-small-handle-margin-left: -16px !default;
$switch-small-handle-active-left-rtl: 18px !default;
$switch-small-font-size: $text-xxs !default;
$small-switch-text-indent: 14px !default;
$switch-small-on-rtl: 100% !default;
$switch-small-off-rtl: 0 !default;
$switch-small-on-position-left-rtl: 0 !default;
$switch-small-off-position-left-rtl: -100% !default;
$switch-small-on-text-indent: -11px !default;
$switch-small-off-text-indent: 14px !default;
$switch-bigger-wrapper-height: 24px !default;
$switch-bigger-wrapper-width: 58px !default;
$switch-bigger-handle-width: 20px !default;
$switch-bigger-handle-height: 20px !default;
$switch-bigger-handle-active-left: 100% !default;
$switch-bigger-handle-margin-left: -23px !default;
$switch-bigger-handle-active-left-rtl: 25px !default;
$switch-bigger-font-size: $text-sm !default;
$switch-bigger-on-text-indent: -15px !default;
$switch-bigger-off-text-indent: 18px !default;
$switch-bigger-on-rtl: 100% !default;
$switch-bigger-off-rtl: 0 !default;
$switch-bigger-on-position-left-rtl: 0 !default;
$switch-bigger-off-position-left-rtl: -100% !default;
$switch-bigger-handle-top: 2px !default;
$switch-bigger-handle-left: 2px !default;
$switch-bigger-ripple-height: 52px !default;
$switch-bigger-ripple-left: -16px !default;
$switch-bigger-ripple-top: -16px !default;
$switch-bigger-ripple-width: 58px !default;
$switch-focused-outline: none !default;
$switch-focused-outline-offset: initial !default;
$switch-focused-outline-active: none !default;
$switch-bigger-small-wrapper-height: 22px !default;
$switch-bigger-small-wrapper-width: 54px !default;
$switch-bigger-small-handle-width: 18px !default;
$switch-bigger-small-handle-height: 18px !default;
$switch-bigger-small-handle-active-left: 100% !default;
$switch-bigger-small-handle-margin-left: -20px !default;
$switch-bigger-small-handle-active-left-rtl: 22px !default;
$switch-bigger-small-font-size: $text-xs !default;
$bigger-small-switch-text-indent: 14px !default;
$switch-bigger-small-on-rtl: 100% !default;
$switch-bigger-small-off-rtl: 0 !default;
$switch-bigger-small-on-position-left-rtl: 0 !default;
$switch-bigger-small-off-position-left-rtl: -100% !default;
$switch-bigger-small-on-text-indent: -14px !default;
$switch-bigger-small-off-text-indent: 14px !default;
$switch-bigger-small-handle-to: 2px !default;
$switch-bigger-small-handle-left: 2px !default;
$switch-bigger-small-ripple-height: 36px !default;
$switch-bigger-small-ripple-left: -10px !default;
$switch-bigger-small-ripple-top: -10px !default;
$switch-bigger-small-ripple-width: 54px !default;
$switch-on-off-disabled-bg-color-opacity: .5 !default;
$switch-active-handle-bg: $content-bg-color !default;
$switch-inner-border-style: 1px solid $border !default;
$switch-inner-active: $primary !default;
$switch-inner-active-bg: $primary !default;
$switch-focus-inner-box-shadow: $shadow-focus-ring2 !default;
$switch-inner-active-border-color: $primary !default;
$switch-active-background: transparent !default;
$switch-handle-shadow: $shadow-sm !default;
$switch-handle-shadow-disabled: none !default;
$switch-off-bg-color: $content-bg-color !default;
$switch-inner-bg: $content-bg-color !default;
$switch-handle-bg-color: $border !default;
$switch-hover-bg-color: $content-bg-color-alt3 !default;
$switch-font-family: $font-family !default;
$switch-font-size: $text-xs !default;
$switch-inner-hover-bg-color: $primary !default;
$switch-inner-hover-border-color: $primary !default;
$switch-inner-on-font-color: $primary-text-color !default;
$switch-inner-off-font-color: $content-text-color !default;
$switch-inner-focus-border: $content-bg-color-alt3 !default;
$switch-on-hover-bg-color: $primary !default;
$switch-on-off-disabled-bg-color: $content-bg-color !default;
$switch-handle-disabled-bg-color: $border-light !default;
$switch-handle-on-disabled-bg-color: $content-bg-color !default;
$switch-border-disabled-bg-color: $content-bg-color-alt3 !default;
$switch-disabled-font-color: $content-text-color-disabled !default;
$switch-on-off-hover-bg-color: $content-bg-color !default;
$switch-inner-focus-bg-color: $content-bg-color !default;
$switch-inner-focus-off-bg: $content-bg-color !default;
$switch-focus-border-color-active: $primary !default;
$switch-hover-border: $border !default;
$switch-hover-active-border: $primary !default;
$switch-handle-hover-bg-color: $content-bg-color !default;
$switch-small-disabled-rtl-active-bg-color: transparent !default;
$switch-on-bg-color: $primary !default;
$switch-checked-ripple-bg-color: rgba(0, 0, 0, .12) !default;
$switch-ripple-bg-color: rgba(255, 64, 129, .12) !default;
$switch-on-disabled-bg-color: $content-bg-color !default;
$switch-inner-hover-on-font-color: $primary-text-color !default;
$switch-handle-off-hover-bg-color: $border !default;
