/* stylelint-disable */
$ddt-skin-name: $skin-name !default;
$ddt-box-shadow: none !default;
$ddt-close-icon-bottom: 10px !default;
$ddt-dd-icon-bottom: 0 !default;
$ddt-dd-icon-width: 30px !default;
$ddt-close-icon-width: 20px !default;
$ddt-dd-icon-bigger-bottom: 0 !default;
$ddt-filter-border: 1px solid $border-light !default;
$ddt-filter-top-border: 0 !default;
$ddt-filter-padding: 4px !default;
$ddt-chip-margin: 3px 4px !default;
$ddt-chip-padding: 0 8px 0 8px !default;
$ddt-chip-radius: 4px !default;
$ddt-chip-height: 24px !default;
$ddt-chip-bigger-height: 30px !default;
$ddt-close-icon-bigger-min-height: 36px !default;
$ddt-chip-content-padding: 0 4px 0 0 !default;
$ddt-rtl-chip-content-padding: 0 0 0 4px !default;
$ddt-big-chip-content-padding: 0 8px 0 0 !default;
$ddt-rtl-big-chip-content-padding: 0 0 0 8px !default;
$ddt-chip-close-font: 10px !default;
$ddt-chip-close-height: 16px !default;
$ddt-chip-close-width: 16px !default;
$ddt-last-chip-right-margin: 48px !default;
$ddt-last-chip-bigger-right-margin: 52px !default;
$ddt-select-all-height: 34px !default;
$ddt-select-all-checkbox-margin: 0 8px !default;
$ddt-select-all-text-indent: 0 !default;
$ddt-select-all-bigger-text-indent: 0 !default;
$ddt-select-all-text-font-size: $text-sm !default;
$ddt-popup-reorder-border: $border-light !default;
$ddt-treeview-padding: 6px 8px !default;
$ddt-chip-width: calc(100% - 2px) !default;
$ddt-chip-ddi-width: calc(100% - 31px) !default;
$ddt-chip-ci-width: calc(100% - 31px) !default;
$ddt-chip-ddi-ci-width: calc(100% - 62px) !default;
$ddt-big-chip-ddi-width: calc(100% - 37px) !default;
$ddt-big-chip-ci-width: calc(100% - 37px) !default;
$ddt-big-chip-ddi-ci-width: calc(100% - 73px) !default;
$ddt-remains-padding: 0 0 0 8px !default;
$ddt-rtl-remains-padding: 0 8px 0 0 !default;
$ddt-chip-font-size: $text-sm !default;
$ddt-overflow-count-padding: 5px 4px !default;
$ddt-delim-padding : 0 8px !default;
$ddt-delim-bigger-padding: 0 12px !default;
$ddt-remains-count-padding-top: 0 !default;

// color variables
$ddt-chip-bg-color: $secondary-bg-color !default;
$ddt-popup-background-color: $flyout-bg-color !default;
$ddt-popup-border-color: $border-light !default;
$ddt-chip-close: $secondary-text-color !default;
$ddt-chip-font-color: $secondary-text-color !default;
$ddt-chip-hover-bg-color: $secondary-border-color-hover !default;
$ddt-chip-hover-font-color: $secondary-text-color-hover !default;
$ddt-nodata-font-color: $content-text-color !default;
$ddt-select-all-font-color: $content-text-color !default;
$ddt-remains-font-color: $content-text-color-alt3 !default;
$ddt-readonly-input-bg-color: $content-bg-color !default;