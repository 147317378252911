$theme: 'Bootstrap5';

//Layout Variables Start
$spin-padding: 10px;
$spin-label-margin-top: 10px;
$spin-label-font-size: $text-sm;
$spin-label-font-family: $font-family;
$spin-boot4-stroke-width: 4;
$spin-fabric-stroke-width: 1.5;
$spin-boot5-stroke-width: 4;

//Layout Variables End

//Theme based other styles start
$spin-label-color: $primary;
$spin-overlay-background: $overlay-bg-color;
$spin-overlay-stroke-color: $white;
$spin-overlay-font-color: $white;

$spin-material-stroke-color: $primary;
$spin-boot4-stroke-color: $primary;
$spin-fabric-stroke-color: $primary-light;
$spin-boot5-stroke-color: $primary;
$spin-fabric-arc-color: $primary;
$spin-bootstrap-stroke-color: $primary;
$spin-tailwind-stroke-width: 4.5;
$spin-tailwind-arc-color: $primary;
$spin-tailwind-stroke-color: $primary-light;

@mixin tw-spinner-rotate {
  animation: fabric-spinner-rotate 1.3s infinite cubic-bezier(.21, .21, .21, .21);
}

@mixin mat-spinner-rotate {
  animation: material-spinner-rotate 1568.63ms linear infinite;
}

@mixin fb-spinner-rotate {
  animation: fabric-spinner-rotate 1.3s infinite cubic-bezier(.53, .21, .29, .67);
}

@mixin boot4-spinner-rotate {
  animation: material-spinner-rotate .75s linear infinite;
}

@mixin boot5-spinner-rotate {
  animation: material-spinner-rotate .75s linear infinite;
}

/* stylelint-disable property-no-vendor-prefix */
@keyframes material-spinner-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fabric-spinner-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

//Theme based other styles end
