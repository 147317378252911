/* For use in src/lib/core/theming/_palette.scss */
$cerrix-warn-palette: (
  50: #ffeded,
  100: #ffd1d1,
  200: #ffb3b3,
  300: #ff9494,
  400: #ff7d7d,
  500: #ff6666,
  600: #ff5e5e,
  700: #ff5353,
  800: #ff4949,
  900: #ff3838,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffe1e1,
  A700: #ffc8c8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
