@include export-module('button-group-layout') {
/* stylelint-disable */
  #{if(&, '&', '*')}.e-btn-group,
  #{if(&, '&', '*')}.e-css.e-btn-group {
    display: -webkit-inline-flex;
    display: inline-flex;
    border-radius: $btn-grp-wrapper-border;
    -webkit-flex-direction: row;
    flex-direction: row;
    position: relative;
    @if $skin-name == 'Material3' {
      border-radius: $btn-border-radius;
      box-shadow: $btn-active-box-shadow;
    }

    input:focus+label.e-btn,
    .e-btn:focus,
    .e-btn:hover {
      @if $skin-name == 'tailwind' or $skin-name == 'bootstrap5' {
        z-index: 2;
      }
    }

    input + label.e-btn {
      margin-bottom: 0;
    }

    input {
      -moz-appearance: none;
      height: 1px;
      margin: 0 0 0 $btn-grp-margin;
      opacity: 0;
      position: absolute;
      width: 1px;
    }

    // Disabled
    input:disabled+label.e-btn,
    :disabled {
      cursor: default;
      pointer-events: none;
    }

    .e-btn-icon {
      font-size: $btn-grp-icon-font-size;
    }

    // Sharp corner for sides individual Button and SplitButton
    &:not(.e-rtl):not(.e-vertical) {
      .e-btn {
        @if $skin-name != 'Material3' {
          margin-left: $btn-grp-margin-left;
        }

        &:not(:first-of-type):not(:last-of-type) {
          border-radius: 0;
        }

        &:first-of-type {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        &:last-of-type {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }

        &:last-of-type:not(:last-child) {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      .e-split-btn-wrapper {
        +.e-btn {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }

        &:not(:first-child):not(:last-child) {
          .e-btn:last-child {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .e-btn:first-child {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }
        }

        &:first-child {
          .e-btn:last-child {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &:last-child {
          .e-btn:first-child {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }
        }
      }
    }

    // RTL with Sharp corner combinations
    &.e-rtl {
      .e-btn {
        margin-left: $btn-grp-margin-left;

        &:not(:first-of-type):not(:last-of-type) {
          border-radius: 0;
        }

        &:first-of-type {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }

        &:last-of-type {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        &:last-of-type:not(:last-child) {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }

      .e-split-btn-wrapper {
        +.e-btn {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        &:not(:first-child):not(:last-child) {
          .e-btn:last-child {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }

          .e-btn:first-child {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &:first-child {
          .e-btn:last-child {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }
        }

        &:last-child {
          .e-btn:first-child {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }

    // Vertical Orientation
    &.e-vertical {
      -webkit-flex-direction: column;
      flex-direction: column;

      input {
        margin: $btn-grp-margin 0 0;
      }

      // Round corner
      .e-btn {
        &:not(.e-outline) {
          margin-top: $btn-grp-margin;
        }

        &:not(:first-of-type):not(:last-of-type) {
          border-radius: 0;
        }

        &:first-of-type {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
        }

        &:last-of-type {
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }

    // Rounded Corner
    &.e-round-corner:not(.e-vertical) {
      &:not(.e-rtl) {
        border-radius: $btn-grp-round-radius;

        .e-btn {
          &:first-of-type {
            border-bottom-left-radius: $btn-grp-round-radius;
            border-top-left-radius: $btn-grp-round-radius;
          }

          &:last-of-type {
            border-bottom-right-radius: $btn-grp-round-radius;
            border-top-right-radius: $btn-grp-round-radius;
          }

          &:last-of-type:not(:last-child) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }

        .e-split-btn-wrapper {
          +.e-btn {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }

          &:first-child {
            .e-btn:first-child {
              border-bottom-left-radius: $btn-grp-round-radius;
              border-top-left-radius: $btn-grp-round-radius;
            }

            .e-btn:last-child {
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
          }

          &:last-child {
             .e-btn:first-child {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
            }

            .e-btn:last-child {
              border-bottom-right-radius: $btn-grp-round-radius;
              border-top-right-radius: $btn-grp-round-radius;
            }
          }
        }
      }

      // Rounded corner with RTL
      &.e-rtl {
        border-radius: $btn-grp-round-radius;

        .e-btn {
          &:first-of-type {
            border-bottom-right-radius: $btn-grp-round-radius;
            border-top-right-radius: $btn-grp-round-radius;
          }

          &:last-of-type {
            border-bottom-left-radius: $btn-grp-round-radius;
            border-top-left-radius: $btn-grp-round-radius;
          }

          &:last-of-type:not(:last-child) {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }

          &:first-of-type:not(:first-child) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }
  }

  .e-bigger .e-btn-group,
  .e-bigger.e-btn-group,
  .e-bigger .e-css.e-btn-group,
  .e-bigger.e-css.e-btn-group  {
    .e-btn-icon {
      font-size: $btn-grp-icon-font-size-bigger;
    }
  }
}
