//layout variables
$cmenu-back-icon: '\e977' !default;
$cmenu-back-icon-margin: 8px !default;
$cmenu-bigger-caret-font-size: $text-base !default;
$cmenu-bigger-font-size: $text-base !default;
$cmenu-bigger-li-height: 36px !default;
$cmenu-bigger-li-padding: 0 16px !default;
$cmenu-bigger-max-width: 280px !default;
$cmenu-bigger-min-width: 112px !default;
$cmenu-caret-blank-icon: 14px !default;
$cmenu-caret-font-size: $text-base !default;
$cmenu-caret-icon: '\e956' !default;
$cmenu-caret-icon-rtl: '\e937' !default;
$cmenu-caret-li-padding: 36px !default;
$cmenu-caret-right: 7px !default;
$cmenu-icon-margin-right: 8px !default;
$cmenu-li-height: 30px !default;
$cmenu-li-padding: 0 12px !default;
$cmenu-li-right-padding: 12px !default;
$cmenu-li-border-width: 1px !default;
$cmenu-max-width: 240px !default;
$cmenu-icon-font-size: $text-lg !default;
$cmenu-icon-bigger-font-size: 22px !default;
$cmenu-min-width: 120px !default;
$cmenu-parent-ul-box-shadow: $shadow-lg !default;
$cmenu-seperator-bigger-padding: 4px 0 !default;
$cmenu-seperator-padding: 4px 0 !default;
$cmenu-ul-border: 1px solid $border-light !default;
$cmenu-ul-border-radius: 4px !default;
$cmenu-ul-padding: 0 !default;
$cmenu-ul-bigger-padding: 4px 0 !default;
$cmenu-caret-color: $icon-color !default;
$cmenu-color: $content-text-color !default;
$cmenu-disable-text: rgba($content-text-color, .65) !default;
$cmenu-font-size: $text-sm !default;
$cmenu-font-weight: $font-weight-normal !default;
$cmenu-li-bgcolor: $flyout-bg-color-hover !default;
$cmenu-li-border-color: $border-light !default;
$cmenu-li-border-style: solid !default;
$cmenu-li-selection-bgcolor: $flyout-bg-color-pressed !default;
$cmenu-li-selection-font-color: $content-text-color-focus !default;
$cmenu-li-hover-outline-offset: 0 !default;
$cmenu-li-selected-outline: 0 solid $cmenu-li-selection-bgcolor !default;
$cmenu-li-selected-outline-offset: 0 !default;
$cmenu-icon-color: $icon-color !default;
$cmenu-icon-disabled-color: rgba($icon-color, .65) !default;
$cmenu-selected-color: $flyout-text-color-pressed !default;
$cmenu-sub-ul-box-shadow: $shadow-lg !default;
$cmenu-ul-bgcolor: $flyout-bg-color !default;
$cmenu-li-hover-outline: 0 solid $cmenu-li-border-color !default;
$cmenu-caret-hover-color: $cmenu-caret-color !default;
$cmenu-hover-icon-color: $cmenu-icon-color !default;
