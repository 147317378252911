//layout variables
$cbox-bigger-check-fontsize: 10px !default;
$cbox-bigger-font-size: $text-base !default;
$cbox-bigger-height: 16px !default;
$cbox-bigger-indeterminate-fontsize: 10px !default;
$cbox-bigger-indeterminate-lineheight: 14px !default;
$cbox-bigger-lineheight: 14px !default;
$cbox-bigger-margin: 12px !default;
$cbox-bigger-small-check-fontsize: 10px !default;
$cbox-bigger-small-font-size: 15px !default;
$cbox-bigger-small-height: 16px !default;
$cbox-bigger-small-indeterminate-fontsize: 10px !default;
$cbox-bigger-small-indeterminate-lineheight: 15px !default;
$cbox-bigger-small-lineheight: 15px !default;
$cbox-bigger-small-width: 16px !default;
$cbox-bigger-width: 16px !default;
$cbox-border: 1px solid !default;
$cbox-font-size: $text-sm !default;
$cbox-height: 14px !default;
$cbox-border-radius: 4px !default;
$cbox-check-fontsize: 8px !default;
$cbox-indeterminate-fontsize: 8px !default;
$cbox-indeterminate-lineheight: 13px !default;
$cbox-lineheight: 12px !default;
$cbox-margin: 8px !default;
$cbox-padding: 0 !default;
$cbox-ripple-size: -9px !default;
$cbox-ripple-height: 36px !default;
$cbox-ripple-width: 36px !default;
$cbox-ripple-small-size: -13px !default;
$cbox-ripple-small-height: 30px !default;
$cbox-ripple-small-width: 30px !default;
$cbox-ripple-bigger-size: -15px !default;
$cbox-ripple-bigger-height: 38px !default;
$cbox-ripple-bigger-width: 38px !default;
$cbox-ripple-bigger-small-size: -16px !default;
$cbox-ripple-bigger-small-height: 38px !default;
$cbox-ripple-bigger-small-width: 38px !default;
$cbox-small-check-fontsize: 8px !default;
$cbox-small-font-size: $text-xs !default;
$cbox-small-height: 14px !default;
$cbox-small-indeterminate-fontsize: 8px !default;
$cbox-small-indeterminate-lineheight: 13px !default;
$cbox-small-lineheight: 13px !default;
$cbox-small-width: 14px !default;
$cbox-width: 14px !default;
$cbox-focus-outline-offset: 0 !default;
$cbox-focus-outline:  rgba($primary, .25) !default;
$cbox-border-color: $border !default;
$cbox-bgcolor: $content-bg-color !default;
$cbox-checkmark-bgcolor: $primary !default;
$cbox-checkmark-border-color: $primary !default;
$cbox-checkmark-color: $primary-text-color !default;
$cbox-checked-ripple-bgcolo: transparent !default;
$cbox-checkmark-disabled-bgcolor: $primary-light !default;
$cbox-checkmark-disabled-border-color: $primary-light !default;
$cbox-checkmark-disabled-color: $primary-text-color !default;
$cbox-checkmark-hover-bgcolor: $primary !default;
$cbox-checkmark-hover-border-color: $primary !default;
$cbox-checkmark-hover-color: $primary-text-color !default;
$cbox-color: $content-text-color !default;
$cbox-disabled-bgcolor: $content-bg-color !default;
$cbox-disabled-border-color: $border-light !default;
$cbox-disabled-color: $content-text-color-disabled !default;
$cbox-focussed-box-shadow: $shadow-focus-ring2 !default;
$cbox-hover-bgcolor: $content-bg-color !default;
$cbox-hover-border-color: $border !default;
$cbox-hover-color: $content-text-color !default;
$cbox-indeterminate-bgcolor: $primary !default;
$cbox-indeterminate-border-color: $primary !default;
$cbox-indeterminate-color: $primary-text-color !default;
$cbox-indeterminate-content: '\e7d6' !default;
$cbox-indeterminate-disabled-bgcolor: $primary-light !default;
$cbox-indeterminate-disabled-border-color: $primary-light !default;
$cbox-indeterminate-disabled-color: $primary-text-color !default;
$cbox-indeterminate-hover-color: $primary-text-color !default;
$cbox-key-focussed-bgcolor: transparent !default;
$cbox-ripple-bgcolor: transparent !default;
$cbox-focussed-bgcolor: $cbox-hover-bgcolor !default;
$cbox-focussed-border-color: $primary-border-color-pressed !default;
$cbox-focussed-checkmark-bgcolor: $primary !default;
$cbox-focussed-checkmark-border-color: $primary-border-color-hover !default;
$cbox-focussed-checkmark-color: $primary-text-color !default;
$cbox-border-style: solid !default;

//enddefault
