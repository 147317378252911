$rating-li-font-size: $font-icon-22 !default;
$rating-li-padding: 4px !default;
$rating-li-line-height: 13px !default;
$rating-li-min-width-height: 30px !default;
$rating-focus-border-radius: 4px !default;
$rating-label-bottom-margin: 4px !default;
$rating-label-right-margin: 8px !default;
$rating-label-top-margin: 8px !default;
$rating-label-left-margin: 11px !default;
$rating-label-font-size: $text-xs !default;
$rating-label-line-height: 18px !default;
$rating-reset-font-size : $font-icon-18 !default;
$rating-tooltip-tip-bottom-height: 8px !default;

$rating-small-li-font-size: $font-icon-16 !default;
$rating-small-li-line-height: 6px !default;
$rating-small-li-min-width-height: 24px !default;
$rating-small-label-font-size: $text-xxs !default;
$rating-small-label-line-height: 16px !default;
$rating-small-reset-font-size : $font-icon-12 !default;

$rating-bigger-li-font-size: $font-icon-32 !default;
$rating-bigger-li-line-height: 13px !default;
$rating-bigger-li-min-width-height: 40px !default;
$rating-bigger-label-font-size: $text-sm !default;
$rating-bigger-label-line-height: 22px !default;
$rating-bigger-reset-font-size : $font-icon-28 !default;

$rating-bigger-small-li-font-size: $font-icon-26 !default;
$rating-bigger-small-li-line-height: 13px !default;
$rating-bigger-small-li-min-width-height: 34px !default;
$rating-bigger-small-label-font-size: $text-xs !default;
$rating-bigger-small-label-line-height: 18px !default;
$rating-bigger-small-reset-font-size : $font-icon-22 !default;

$rating-border-width: 1px !default;

$rating-selected-bg-color: $rating-selected-color !default;
$rating-unrated-bg-color: $rating-unrated-color !default;
$rating-hover-bg-color: $rating-selected-hover-color !default;
$rating-pressed-bg-color: $rating-pressed-color !default;
$rating-selected-disabled-bg-color: $rating-selected-disabled-color !default;
$rating-unrated-disabled-bg-color: $rating-unrated-disabled-color !default;
$rating-li-focus: $rating-selected-color !default;
$rating-reset-icon-color: $rating-unrated-bg-color !default;
$rating-reset-hover-icon-color: $rating-selected-hover-color !default;
$rating-reset-disabled-icon-color: $rating-selected-disabled-color !default;
