$grid-content-padding: 6px !default;
$grid-headecell-height: 32px !default;
$grid-font-size: $text-sm !default;
$grid-icon-color: $icon-color !default;
$grid-command-icon-color: $icon-color !default;
$grid-border-type: solid !default;
$grid-vertical-border-size: 1px !default;
$grid-horizontal-border-size: 1px !default;
$grid-font-family: $font-family !default;
$grid-maskedcell-virtual-background-color: rgba(0, 0, 0, .1) !default;

$grid-header-font-size: $text-sm !default;
$grid-bigger-header-font-size: $text-base !default;
$grid-header-icon-color: $icon-color !default;
$grid-sorted-header-color: $content-text-color !default;

$grid-popup-bg-color: $content-bg-color !default;

$grid-sortnumber-bg-color: $content-bg-color-alt3 !default;
$grid-sortnumber-color: $content-text-color-alt2 !default;
$grid-table-background-color: $content-bg-color !default;

$grid-row-selection-color: $icon-color-pressed !default;
$grid-cell-selection-color: $content-bg-color-selected !default;

$grid-drag-clone-color: $content-text-color-alt1 !default;

$group-droparea-hover-color: $black !default;

$grid-group-broder-color:  $border-light !default;
$grid-group-hover-broder-color: $border-hover !default;
$grid-group-text-line-height: 22px !default;
$grid-wrap-line-height: 3px !default;
$grid-wrap-margin-bottom: 2px !default;
$grid-wrap-margin-top: 0 !default;

$grid-header-bottom-padding: 8px !default;
$grid-header-top-padding: 8px !default;
$grid-headerconent-font-opacity: 1 !default;
$grid-conent-font-opacity: 1 !default;
$grid-filtercell-line-height: 27px !default;
$grid-group-captioncell-line-height: 22px !default;
$group-text-padding-right: 3px !default;
$group-sorticon-margin-right: 0 !default;
$group-ungroupicon-padding: 5px 3px !default;
$grid-rtl-group-sorticon-margin-left: 0 !default;
$grid-ungroup-icon-opactiy: 1 !default;
$grid-default-color: '' !default;
$grid-stackedheadercell-border-size: 1px 0 1px 1px !default;
$grid-stackedheadercell-botttom-padding: 13px !default;
$group-text-align: center !default;
$grid-right-border: 1px !default;
$grid-headercelldiv-line-height: 18px !default;
$grid-bigger-headercelldiv-line-height: 20px !default;
$grid-filterbarcell-text-indent: 1px !default;
$grid-groupdroparea-rtl-text-align: center !default;
$grid-rtl-th-firstcell-border-left: 0 !default;
$grid-checkbox-cell-padding-top-bottom: 8px !default;
$grid-rowselect-text-indent: 1px !default;
$grid-toolbar-border-width: 1px solid !default;
$grid-filterdiv-padding: 0 !default;
$grid-headercelldiv-height: 29px !default;
$grid-filterbarcell-input-height: 26px !default;
$grid-sortnumber-border-radius: 65% !default;
$grid-textwrap-srotnumber-rightalign-margin: 5px 2px 0 10px !default;
$grid-textwrap-srotnumber-margin: 5px 5px 0 2px !default;
$grid-textwrap-sorticon-margin: -9px 10px !default;
$grid-columnchooser-header-padding: 11px 18px 15px !default;
$grid-columnchooser-toolbar-button-padding: 0 12px !default;
$grid-columnchooser-ul-padding: 0 !default;
$grid-column-chooser-footdiv: 1px 0 0 !default;
$grid-column-chosser-searchdiv-opactiy: .6 !default;
$grid-column-chosser-searchdiv-fopactiy: 1 !default;
$grid-columnchooser-search-border-color: $border-light !default;
$grid-columnchooser-search-border-fcolor: $border-focus !default;
$grid-columnchooser-footer-border-opacity: 1 !default;
$grid-columnchooser-toolbar-div-padding: 0 10px !default;
$grid-columnchooser-toolbar-icon-line-height: 1.9 !default;
$grid-columnchooser-toolbar-icon-font-size: $font-icon-15 !default;
$grid-columnchooser-btn-font-size: $text-sm !default;
$grid-columnchooser-toolbardiv-padding: 3px !default;
$grid-columnchooser-toolbardiv-margin-top: 0 !default;
$grid-columnchooser-toolbar-icon-vertical-align: middle !default;
$grid-columnchooser-cancel-icon-content-f-opactiy: 1 !default;
$grid-columnchooser-toolbar-div-margin-top: -1px !default;
$grid-columnchooser-input-border: 0 !default;
$grid-columnchooser-input-padding-top: 6px !default;
$grid-column-chooser-search-icon-padding: 6px 5px !default;
$grid-column-chooser-cancel-icon-padding: 5px 5px 6px !default;
$grid-columnchooser-input-padding-bottom: 6px !default;
$grid-columnchooser-input-padding-left: 4px !default;
$grid-columnchooser-ul-margin: 13px 0 !default;
$grid-column-chooser-dlg-footer-padding: 8px !default;
$grid-rtl-bigger-sortnumber-margin: 4px 0 0 -20px !default;
$grid-rtl-bigger-sortnumber-withfilter-margin: 2px 0 0 4px !default;
$grid-rtl-bigger-rightalign-sortnumber-margin: 3px 0 0 8px !default;
$grid-rtl-group-text-marign-right: 0 !default;
$grid-rtl-group-text-margin-right: 0 !default;
$grid-rtl-textwrap-srotnumber-margin: 3px 5px 0 2px !default;
$grid-rtl-headercell-both-border-width:  0 1px 0 0 !default;
$grid-rtl-textwrap-srotnumber-rightalign-margin: 3px 5px 0 2px !default;
$grid-rtl-stackedheadercell-border-size: 0 1px 1px 0 !default;
$grid-rtl-stackedhader-firstcell-left-border-size: 0 !default;
$grid-rtl-stackedhader-firstcell-right-border-size: 1px !default;
$grid-rtl-bothlines-stackedhader-firstcell-left-border-size: 1px !default;
$grid-rtl-bothlines-stackedhader-firstcell-right-border-size: 0 !default;
$grid-bigger-headercell-bootom-padding: 10px !default;
$grid-bigger-grightarrow-icon-font-size: $text-base !default;
$group-bigger-sorticon-margin-right: 0 !default;
$grid-bigger-groupdroparea-padding: 15px 15px !default;
$grid-bigger-group-headercell-height: 38px !default;
$grid-bigger-ungroup-button-font-size: $font-icon-22 !default;
$grid-bigger-group-text-line-height: 24px !default;
$grid-bigger-groupcaption-line-height: 20px !default;
$grid-bigger-rtl-group-headercell-margin: 8px 8px 0 0 !default;
$grid-bigger-rtl-group-headercell-padding: 7px 6px 7px 9px !default;
$grid-bigger-device-rtl-group-headercell-padding: 7px 6px 7px 9px !default;
$grid-bigger-rtl-ungroup-icon-margin-right: -10px !default;
$grid-bigger-textwrap-srotnumber-rightalign-margin: 3px 2px 0 5px !default;
$grid-bigger-textwrap-sorticon-rightalign-margin: -9px 5px !default;
$grid-bigger-textwrap-sorticon-margin: -9px 10px !default;
$grid-bigger-textwrap-srotnumber-margin: 3px 5px 0 2px !default;
$grid-bigger-columnchooser-header-padding: 24px 24px 38px !default;
$grid-bigger-columnchooser-ul-padding: 0 !default;
$grid-bigger-columnchooser-li-padding: 8px 0 !default;
$grid-bigger-columnchooser-toolbar-div-margin-top: 0 !default;
$grid-device-headercell-padding: 0 12px $grid-header-bottom-padding !default;
$grid-device-headercell-fistchild-padding: 0 12px $grid-header-bottom-padding 16px !default;
$grid-device-headercell-lastchild-padding: 0 16px $grid-header-bottom-padding 12px !default;
$grid-device-rowcell-padding: $grid-content-padding 12px !default;
$grid-device-rowcell-firstchild-padding: $grid-content-padding 12px $grid-content-padding 16px !default;
$grid-device-rowcell-lastchild-padding: $grid-content-padding 16px $grid-content-padding 12px !default;
$grid-device-filterbarcell-padding: $grid-content-padding 12px !default;
$grid-device-filterbarcell-firstchild-padding: $grid-content-padding 12px $grid-content-padding 16px !default;
$grid-device-filterbarcell-lastchild-padding: $grid-content-padding 16px $grid-content-padding 12px !default;
$grid-device-gridheader-row-span-padding: 20px !default;
$grid-touch-device-hdrcell-span-line-height: 21px !default;
$grid-touch-device-hdrcell-ungroup-line-height: 20px !default;
$grid-device-groupsort-margin-top: 0;
$grid-edit-input-bigger-padding-bottom: 0 !default;
$grid-edit-input-bigger-padding-top: 0 !default;
$grid-edit-input-bigger-device-padding-bottom: 0 !default;
$grid-edit-input-bigger-device-padding-top: 0 !default;
$frozen-border: 3px solid !default;
$grid-fltrdiv-float: right !default;
$grid-fltrdiv-text-align: right !default;
$grid-fltrdiv-padding: 2px !default;
$grid-rtl-fltrdiv-padding: 2px !default;
$grid-flmenu-padding: 14px 0 0 !default;
$grid-flmenu-boolean-content-padding: 18px 18px 0 !default;
$grid-flmenu-boolean-button-width: 110px !default;
$grid-flmenu-device-padding: 24px 0 0 !default;
$grid-fltrmnu-dd-max-height: 298px !default;
$grid-rtlfltrdiv-float: left !default;
$grid-checkbox-wrapper-height: 20px !default;
$grid-checkbox-wrapper-line-height: 20px !default;
$grid-checkbox-wrapper-top: -2px !default;
$grid-rtl-headercelldiv-fltricon-padding: 0 1.6em 0 10px !default;
$grid-sortnumber-filtericon-rightalign-margin: 8px 2px 0 5px !default;
$grid-xl-match-margin-left: 6px !default;
$grid-bigger-xl-match-margin-left: 8px !default;
$grid-rows-bg-color: $content-bg-color !default;
$grid-resize-border-width: 1px !default;
$grid-xl-rtl-radio-padding-left: 0 !default;
$grid-xl-rtl-radio-padding-right: 25px !default;
$grid-filterbarcell-input-padding: 0 26px 0 10px !default;
$grid-icon-default-color: $grid-icon-color !default;
$grid-resize-helper-color: $border-dark !default;
$grid-header-first-last-cell-padding: 8px !default;
$grid-header-bg-color: $content-bg-color !default;
$grid-header-color: $content-text-color !default;
$grid-headercell-both-border-width: 0 0 0 1px !default;
$grid-headercelldiv-margin: -7px -7px -7px -8px !default;
$grid-icon-font-size: 14px !default;
$grid-border-size: 1px !default;
$grid-header-padding: 8px !default;
$grid-header-wrap-sortfilter-div-margin: -20px 3px !default;
$grid-rtl-textwrap-sorticon-margin: -30px 5px !default;
$grid-rtl-textwrap-sorticon-rightalign-margin: -30px 17px !default;
$grid-headercelldiv-right-align-padding: 0 8px 0 20px !default;
$grid-headercell-line-height: 34px !default;
$grid-header-font-weight: $font-weight-bold !default;
$grid-filtercell-both-border-width: 1px 0 0 1px !default;
$grid-header-border-color: $border-light !default;
$grid-rowcell-line-height: 22px !default;
$grid-content-font-color: $content-text-color !default;
$grid-hover-content-font-color: $content-text-color-hover !default;
$grid-cell-border-color: $border-light !default;
$grid-content-bg-color: $content-bg-color !default;
$grid-row-selection-bg-color: $table-bg-color-selected !default;
$grid-cell-selection-bg-color: $table-bg-color-selected !default;
$grid-hover-bg-color: $table-bg-color-hover !default;
$grid-content-font-size: $text-sm !default;
$grid-header-height: 18px !default;
$grid-bigger-header-height: 20px !default;
$grid-rowcell-broder-width: 1px 0 0 !default;
$grid-content-right-padding: 8px !default;
$grid-first-last-rowcell-padding: 8px !default;
$grid-toolbar-search-margin-bottom: 0 !default;
$grid-toolbar-search-width: 230px !default;
$grid-bigger-toolbar-search-width: $grid-toolbar-search-width !default;
$grid-toolbar-search-icon-min-width: 32px !default;
$grid-toolbar-search-wrapper-padding-bottom: 3px !default;
$grid-toolbar-search-wrapper-padding-top: 3px !default;
$grid-toolbar-search-clear-icon-min-width: 32px !default;
$grid-toolbar-search-clear-icon-font-size: $text-sm !default;
$grid-toolbar-search-clear-icon-padding: 0 !default;
$grid-toolbar-search-clear-icon-margin-right: 0 !default;
$grid-rtl-toolbar-search-clear-icon-padding: 0 !default;
$grid-rtl-toolbar-search-clear-icon-margin: 0 !default;
$grid-bigger-toolbar-search-clear-icon-padding: 0 !default;
$grid-bigger-toolbar-search-clear-icon-min-width: 38px !default;
$grid-bigger-toolbar-search-clear-icon-margin: 0 !default;
$grid-bigger-toolbar-search-clear-icon-font-size: $text-base !default;
$grid-rtl-bigger-toolbar-search-clear-icon-padding: 0 !default;
$grid-rtl-bigger-toolbar-search-clear-icon-min-width: 38px !default;
$grid-rtl-bigger-toolbar-search-clear-icon-margin: 0 !default;
$grid-responsive-toolbar-search-clear-icon-margin: 0 !default;
$grid-responsive-toolbar-search-clear-icon-font-size: $text-base !default;
$grid-rtl-responsive-toolbar-search-clear-icon-margin: 0 !default;
$grid-res-toolbar-clear-icon-width: 100% !default;
$grid-res-toolbar-clear-icon-margin: 0 !default;
$grid-res-toolbar-search-clear-icon-font-size: $text-base !default;
$grid-res-toolbar-search-icon-font-size: 16px !default;
$grid-res-toolbar-search-wrapper-padding: 5px 25px 5px 0 !default;
$grid-res-toolbar-search-icon-padding-left: 0 !default;
$grid-bigger-toolbar-search-icon-min-width: 38px !default;
$grid-bigger-toolbar-search-wrapper-padding-bottom: 5px !default;
$grid-bigger-toolbar-search-wrapper-padding-top: 5px !default;
$grid-bigger-command-column-padding-bottom: 1.2px !default;
$grid-bigger-command-column-padding-top: 1.2px !default;
$grid-summary-cell-font-size: $text-sm !default;
$grid-filterbarcell-border-width: 1px 0 0 !default;
$grid-filterbarcell-first-last-child-padding-left: 2px !default;
$grid-filterbarcell-padding: 1px 2px !default;
$grid-filterbar-input-border-width: 0 !default;
$grid-border-radius: 1px !default;
$grid-bottom-border-size: 1px !default;
$group-droparea-bg-color: $content-bg-color-alt1 !default;
$group-header-bg-color: $content-bg-color-alt3 !default;
$group-row-bg-color: $content-bg-color-alt1 !default;
$grid-summary-template-row-bg-color: '' !default;
$grid-summary-row-bg-color: $content-bg-color-alt1 !default;
$grid-sortdiv-width: 18px !default;
$grid-sortdiv-height: 18px !default;
$grid-sortnumber-margin: 2px 10px 1px 8px !default;
$grid-fltr-sortnumber-margin: 2px 8px 1px 8px !default;
$grid-fltr-sortnumber-rightalign-margin: 2px 8px 0 10px !default;
$grid-sortnumber-rightalign-margin: 2px 8px 0 10px !default;
$grid-sortnumer-line-height: 14px !default;
$grid-bigger-sortnumer-line-height: 16px !default;
$grid-sortdiv-right-align-margin: -10px 8px -12px -2px !default;
$grid-sortdiv-margin:  -10px -2px -12px 8px !default;
$grid-sortdiv-padding: 2px !default;
$grid-ungroup-button-line-height: 20px !default;
$grid-ungroup-button-font-size: $text-sm !default;
$grid-grouparea-first-cell-margin: 0 6px 0 12px !default;
$grid-grouparea-font-size: $grid-content-font-size !default;
$grid-grouparea-margin: 4px 0 0 5px !default;
$grid-group-headercell-line-height: 25px !default;
$grid-grouped-headercell-padding: 5px 0 !default;
$grid-grouped-headercell-span-padding: 0 8px !default;
$grid-group-unpgroupicon-padding-right: 8px !default;
$group-droparea-hover-bg-color: $content-bg-color !default;
$group-header-hover-bg-color: $border-light !default;
$grid-grouped-padding: 0 0 4px !default;
$group-area-padding: 9px 0 !default;
$grid-grouptext-margin-right: 0 !default;
$group-header-color: $content-text-color-alt1 !default;
$group-grouped-droparea-bg-color: $content-bg-color !default;
$group-border-width: 1px !default;
$grouped-text-font-size: $text-sm !default;
$group-header-border-radius: 0 !default;
$grid-hover-icon-color: $icon-color-hover !default;
$grid-headecell-span-height: 22px !default;
$grid-drag-clone-opacity: 1 !default;
$grouped-droparea-bg-color: $content-bg-color-alt1 !default;
$grid-drag-clone-border-color: $border-light !default;
$grid-drag-clone-bg-color: $content-bg-color-alt3 !default;
$group-caption-font-color: $content-text-color-alt1 !default;
$grid-group-down-arrow-icon-font-size: $text-sm !default;
$grid-group-down-arrow-icon-padding: 12px 7px 11px 8px !default;
$group-expand-icon-text-indent: 11px !default;
$group-droparea-color: $content-text-color-alt3 !default;
$group-sorticon-font-size: $text-sm !default;
$grid-rowcell-both-border-width: 1px 0 0 1px !default;
$grid-indentcell-border-width: 0 1px 0 0 !default;
$grid-group-right-arrow-icon-font-size: $text-sm !default;
$grid-group-right-arrow-icon-padding: 11px 7px 12px 8px !default;
$grid-group-unpgroupicon-padding-top: 2px !default;
$grid-summary-cell-line-height: 14px !default;
$grid-batch-updated-bg-color: $success-light !default;
$grid-val-error-color: $danger !default;
$grid-val-error-bg-color: $danger-light !default;
$grid-edit-input-padding-bottom: 1px !default;
$grid-edit-input-padding-top: 2px !default;
$grid-edit-input-margin: 1px !default;
$grid-edit-cell-padding: 2px !default;
$grid-group-height: 36px !default;
$grid-ungroup-rtl-padding-top: 2px !default;
$grid-bigger-ungroup-rtl-padding-top: $grid-ungroup-rtl-padding-top !default;
$grid-rtl-group-headercell-margin-right: 5px !default;
$grid-rtl-group-headercell-margin-left: 0 !default;
$grid-fltr-rtl-sortnumber-margin: 1px 0 0 12px !default;
$grid-rtl-sortnumber-margin: 1px 0 0 -10px !default;
$grid-fltr-rtl-sortnumber-rightalign-margin: 1px 0 0 12px !default;
$grid-rtl-sortnumber-rightalign-margin: 1px 0 0 10px !default;
$grid-rtl-sortcelldiv-margin: -10px 8px -12px -2px !default;
$grid-rtl-sortcelldiv-right-align-margin: -10px 8px -12px -2px !default;
$grid-rtl-bigger-sortcelldiv-right-align-margin: -12px 4px -12px -2px !default;
$grid-rtl-bigger-sortcelldiv-margin: -12px 4px -12px -4px !default;
$grid-rtl-grouped-headercell-padding: 5px 0 !default;
$grid-rtl-group-text-margin-left: 0 !default;
$grid-rtl-group-sorticon-margin-right: -7px !default;
$grid-rtl-headercell-border-width: 0 !default;
$grid-rtl-headercelldiv-padding: 0 .4em 0 2.8em !default;
$grid-rtl-headercelldiv-chkbox-padding: 0 .45em 0 .5em !default;
$grid-bigger-rtl-headercelldiv-chkbox-padding: 0 .45em 0 .5em !default;
$grid-ungroup-rtl-padding-right: -9px !default;
$grid-headercelldiv-padding: 0 20px 0 5px !default;
$grid-headercell-div-padding: 0 .4em !default;
$grid-group-text-width: auto !default;
$grid-grouptopleftcell-border-top: 2px solid !default;
$grid-group-caption-font-size: $text-sm !default;
$grid-group-caption-header-padding: .5em !default;
$group-sorticon-margin-left: -6px !default;
$group-sorticon-margin-top: -.5px !default;
$grid-bigger-headercell-line-height: 40px !default;
$grid-bigger-rhandler-height: $grid-bigger-headercell-line-height !default;
$grid-bigger-rowcell-line-height: 24px !default;
$grid-bigger-font-size: $text-base !default;
$group-unpgrouicon-margin-left: -10px !default;
$grid-group-ungroupicon-margin-left: -12px !default;
$grid-group-clone-text-align: left !default;
$grid-clone-padding: 2px 8px 1px !default;
$grid-bigger-first-last-rowcell-padding: 8px !default;
$grid-bigger-content-padding: 8px !default;
$grid-bigger-group-line-height: round($grid-group-height*1.5) !default;
$grid-bigger-content-right-padding: 12px !default;
$grid-bigger-headercell-font-size: $text-base !default;
$grid-bigger-content-font-size: $text-base !default;
$grid-filterbar-border-radius: 4px !default;
$grid-bigger-sorticon-margin-top: 1px !default;
$grid-device-group-sorticon-margin-top: 0 !default;
$grid-header-border-width: 0 !default;
$grid-bigger-groupedcell-padding: 0 0 8px !default;
$grid-bigger-group-headercell-margin: 8px 0 0 8px !default;
$grid-bigger-group-headercell-padding: 7px 0 !default;
$grid-device-group-headercell-padding: 0 !default;
$grid-bigger-grouptext-marign: 0 !default;
$grid-bigger-headercell-padding: 12px !default;
$grid-bigger-icons-font-size: $text-base !default;
$group-collapse-icon-text-indent: 11px !default;
$grid-bigger-grouparea-font-size: $grid-bigger-content-font-size !default;
$grid-bigger-grouped-headercell-border-radius: 0 !default;
$grid-bigger-groupedcell-icons-marign-left: -6px !default;
$grid-bigger-ungroupbutton-icons-font-size: $text-base !default;
$grid-bigger-groupcell-span-padding: 0 8px !default;
$grid-device-grouped-headercell-span-padding: 0 8px !default;
$grid-bigger-ungroupbutton-icon-margin-left: -10px !default;
$grid-bigger-ungroupbutton-icon-margin-top: 1px !default;
$grid-device-ungroupbutton-icon-margin-top: 0 !default;
$grid-bigger-groupcell-span-line-height: 24px !default;
$grid-bigger-groupcell-span-height: $grid-bigger-groupcell-span-line-height !default;
$grid-bigger-gdownarrow-icon-font-size: $text-base !default;
$grid-gd-clone-border-radius: 4px !default;
$grid-bigger-group-clone-broder-radius: 6px !default;
$grid-gd-clone-padding: 7px 4px 4px !default;
$grid-bigger-group-clone-padding: 10px 6px 6px !default;
$grid-columnchooser-footer-border-color: rgba($border-light, .12) !default;
$grid-column-chooser-searchdiv: 1px !default;
$grid-bigger-columnchooser-toolbartxt-padding: 0 !default;
$grid-columnchooser-toolbartxt-padding: 0 !default;
$grid-bigger-header-first-last-cell-padding: 8px !default;
$grid-columnchooser-content-padding: 14px 14px 14px 14px !default;
$grid-bigger-columnchooser-content-padding: 16px 16px 16px 16px !default;
$grid-bigger-cc-checkbox-padding-left: 8px !default;
$grid-bigger-searchdiv-padding-left: 4px !default;
$grid-cc-checkbox-padding-left: 8px !default;
$grid-column-chooser-searchdiv-left: 1px !default;
$grid-columnchooser-content-margin: 40px 0 0 !default;
$grid-bigger-columnchooser-content-margin: 40px 0 0 !default;
$grid-bigger-columnchooser-btn-font-size: $text-base !default;
$grid-bigger-sortdiv-rightalign-margin: -12px 4px -12px -2px !default;
$grid-bigger-sortdiv-margin: -12px -2px -12px 4px !default;
$grid-bigger-header-icons-font-size: $font-icon-22 !default;
$grid-bigger-grouptext-width: auto !default;
$grid-edit-input-margin-top: 1px !default;
$grid-td-checkbox-margin-top: 0 !default;
$grid-filterbarcell-text-input: 32px !default;
$grid-bigger-filterbarcell-text-input: 39px !default;
$grid-columnchooser-li-padding: 7px 0 !default;
$grid-edit-input-bigger-margin: 2px !default;
$grid-device-ungroupbutton-line-height: 24px !default;
$grid-device-groupheadercell-span-line-height: 24px !default;
$frozen-border-color: $border-light !default;
$grid-frozenborder-disabled-color: $content-bg-color !default;
$grid-freezeline-right-border: rgba($primary-light, 1) !default;
$grid-freezeline-border: 2px solid !default;
$grid-edit-checkbox-bigger-padding-left: 8px !default;
$grid-edit-checkbox-bigger-padding-top: 8px !default;
$grid-cell-focus-shadow: 0 0 0 1px $primary inset !default;
$grid-headercelldiv-fltricon-margin: -5px 20px -5px -5px !default;
$grid-headercelldiv-rightalign-fltricon-margin: -5px 20px -5px 8px !default;
$grid-rtl-headercelldiv-fltricon-margin: -5px !default;
$grid-rtl-headercelldiv-rightalign-fltricon-margin: -5px !default;
$grid-filtered-color: $primary !default;
$grid-autofill-color: $primary !default;
$grid-e-firstrow-dragborder-bcolor: $primary !default;
$grid-checkbox-padding-topbottom: 8px !default;
$grid-checkbox-content-padding-left: 6px !default;
$grid-rtl-checkbox-content-padding-left: 14px !default;
$grid-bigger-checkbox-padding-topbottom: 9px !default;
$grid-bigger-checkbox-content-padding-left: 7px !default;
$grid-rtl-bigger-checkbox-content-padding-left: 16px !default;
$grid-fltrdiv-margin: -12px -2px -12px 8px !default;
$grid-rtl-fltrdiv-margin: -12px 8px -12px -2px !default;
$grid-rtl-bigger-fltrdiv-margin: -14px 4px -14px -2px !default;
$grid-bigger-rtl-headercelldiv-fltricon-padding: -5px -10px -5px 0 !default;
$grid-bigger-fltrdiv-margin: -14px -2px -14px 4px !default;
$grid-sortdiv-filtericon-margin: -12px 24px -12px 8px !default;
$grid-sortdiv-filtericon-rightalign-margin: -12px 8px -12px 13px !default;
$grid-rtl-sortdiv-filtericon-rightalign-margin: -12px 8px -12px 24px !default;
$grid-rtl-sortdiv-filtericon-margin: -12px 8px -13px 24px !default;
$grid-bigger-fltricon-sortdiv-rightalign-margin: -14px 4px -14px -2px !default;
$grid-bigger-fltricon-sortdiv-margin: -14px 22px -14px 4px !default;
$grid-rtl-bigger-fltricon-sortdiv-margin: -14px 4px -14px 24px !default;
$grid-rtl-bigger-fltricon-sortdiv-rightalign-margin: -14px 4px -14px 22px !default;
$grid-column-menu-sub-menu-box-shadow: $shadow !default;
$grid-bigger-checkbox-filter-min-height: 360px !default;
$grid-checkbox-filter-min-height: 307px !default;
$grid-column-menu-margin: -13px -18px -12px 8px !default;
$grid-column-menu-padding: 2px !default;
$grid-textwrap-column-menu-margin: -19px -20px -20px -20px !default;
$grid-bigger-column-menu-margin: -12px -18px -12px 8px !default;
$grid-bigger-device-column-menu-margin: -16px -16px !default;
$grid-column-menu-top-touch: 21px !default;
$grid-column-menu-bottom-touch: 23px !default;
$grid-column-menu-right-touch: 23px !default;
$grid-selection-font-weight: $font-weight-normal !default;
$group-hover-border: $group-border-width !default;
$grid-grouped-headercell-hover-padding: $grid-grouped-headercell-padding !default;
$grid-drag-clone-font-weight: $font-weight-normal !default;
$grid-content-batch-font-color: $success !default;
$grid-bigger-group-headercell-hover-padding: $grid-bigger-group-headercell-padding !default;

$grid-header-wrap-rightalign-sortfilter-div-margin: -20px -5px !default;
$grid-header-wrap-filtericon-sortfilter-div-margin: -20px 20px -29px 14px !default;
$grid-bigger-wrap-sortdiv-margin: -31px 8px !default;
$grid-bigger-wrap-rightalign-sortdiv-margin: -30px -5px !default;
$grid-bigger-wrap-rightalign-fltricon-sortdiv-margin: -26px -20px 0 0 !default;
$grid-bigger-wrap-fltricon-sortdiv-margin: -30px 28px -31px 0 !default;
$grid-header-wrap-filtericon-rightalign-sortfilter-div-margin: -20px 14px !default;
$grid-drag-font-size: $text-sm !default;
$grid-drag-icon-font-weight: $font-weight-bold !default;
$grid-drag-move-icon: 10px !default;
$grid-drag-area-border: 1px solid !default;
$grid-dragdrop-padding: 2px !default;
$grid-dragborder-box-shadow: 0 2px 0 0 $primary-border-color !default;
$grid-dragborder-position: relative !default;
$grid-dragborder-z-index: 5 !default;
$grid-dragicon-cell-border: 1px 0 0 0 !default;
$grid-border-bottom-width: 1px !default;
$grid-dragcell-border-style: solid !default;
$grid-dragcell-border-color: $border-light !default;
$grid-drap-drop-icon-color: $icon-color !default;
$grid-clone-dropitemscount-bg-color: $primary !default;
$grid-clone-dropitemscount-color: $content-text-color-alt2 !default;
$grid-clone-row-border-color: $border-light !default;
$grid-dropitemscount-color: $white !default;
$grid-clone-prop-box-shadow: $shadow !default;
$grid-drag-icon-opacity: .54 !default;
$grid-clone-dropitemscount-border: $border-light !default;
$grid-clone-dragdrop-bg-color: $content-bg-color-alt3 !default;
$grid-group-clone-box-shadow: $shadow !default;
$grid-unboundcelldiv-margin: 0 4px !default;
$grid-bigger-flmenu-padding: 16px 0 0 !default;
$grid-rowcell-wrap-height: 21px !default;
$grid-rowcell-wrap-max-height: 36px !default;
$grid-bigger-fltr-sortnumber-rightalign-margin: 2px 4px 0 -6px !default;
$grid-bigger-sortnumber-rightalign-margin: 2px 4px 0 6px !default;
$grid-bigger-columnmenu-bottom: 13px !default;
$grid-bigger-sortnumber-margin: 2px 4px 0 4px !default;
$grid-bigger-fltr-sortnumber-margin: 2px 4px 0 -6px !default;
$grid-rtl-searchclear: 0 !default;
$grid-column-chosser-searchdiv-top: -41px !default;
$grid-reorderarrow-font-size: $text-xxs !default;
$grid-bigger-wrap-line-height: 24px !default;
$grid-bigger-clone-padding: 3px 0 0 8px !default;
$grid-bigger-draganddrop-clone-padding: 0 !default;
$grouped-bigger-text-font-size: $text-base !default;
$grid-bigger-ungroupbutton-icon-margin-right: 0 !default;
$grid-bigger-ungroupbutton-icon-padding-top: 0 !default;
$grid-bigger-font-size: $text-base !default;
$grid-rtl-bigger-rightalign-fltrdiv-margin: -14px 4px -14px -2px !default;
$grid-bigger-rtl-ungroup-icon-margin-left: -8px !default;
$grid-grouparea-font-weight: $font-weight-normal !default;
$grid-ungroup-rtl-margin-left: 0 !default;
$grid-rtl-group-sorticon-margin-left: -5px !default;
$grid-rtl-column-menu-left: 23px !default;
$grid-checkboxfiltertext-width: 150px !default;
$grid-rtl-column-menu-margin: -12px 8px -12px -20px !default;
$grid-rtl-textwrap-column-menu-margin: -17px -13px -23px -25px !default;
$grid-filter-checkbox-width: 0 !default;
$grid-filter-checkbox-white-space: nowrap !default;

// group animator
$grid-group-animator-bigger-cell-margin: 15px 0 15px 0 !default;
$grid-group-animator-bigger-drop-height: 54px !default;
$grid-animator-bigger-area-margin: 22px !default;
$grid-group-animator-drop-height: 48px !default;
$grid-group-animator-cell-margin: 12px 0 12px 0 !default;
$grid-animtor-border-bottom-width: 1px !default;
$grid-animator-area-padding: 0 !default;
$grid-animtor-area-border-width: 1px !default;
$grid-animator-area-border: 0 !default;
$grid-animator-area-margin: 9px !default;
$grid-animator-chips-display: inline-block !default;
$grid-animator-drag-icon: 24px !default;
$grid-animator-drag-icon-line-height: 19px !default;
$grid-animator-margin-left: 8px !default;
$grid-animator-padding-bottom: 10px !default;
$grid-animator-line-height: 21px !default;
$grid-animator-line-height-next: 32px !default;
$grid-animator-font-size-drag: $text-xs !default;
$grid-animator-opacity: 1 !default;
$grid-animator-drag-margin-left: 8px !default;
$grid-animator-first-group: 12px 0 12px 12px !default;
$grid-animator-rtl-first-group: 12px 12px 12px 0 !default;
$grid-animator-margin-top-rtl: 15px !default;
$grid-filterbar-dropdown: 30px !default;

$grid-responsive-close-icon-margin: 9px 8px 8px 8px !default;
$grid-responsive-custom-header-margin: 18px 8px 18px 8px !default;
$grid-responsive-custom-header-btn-color: $grid-filtered-color !default;
$grid-responsive-custom-header-right-margin: 6px 4px 6px 4px !default;
$grid-responsive-filter-clear-font: 18px !default;
$grid-responsive-search-icon-margin: 0 !default;
$grid-responsive-search-icon-right: 0 !default;
$grid-responsive-search-padding-right: 18px !default;
$grid-responsive-search-padding-left: 18px !default;
$grid-responsive-header-background: $content-bg-color !default;
$grid-bigger-res-dlg-hdr-padding: 16px !default;
$grid-responsive-header-search: 0 !default;
$grid-responsive-filter-reset-color: $grid-filtered-color !default;
$grid-responsive-toolbar-icon-color: $grid-icon-color !default;
$grid-responsive-close-icon-color: $grid-icon-color !default;
$grid-responsive-btn-background: $content-text-color-alt3 !default;
$grid-responsive-res-apply-btn-font-size: $text-base !default;
$grid-responsive-res-apply-btn-margin-top: 0 !default;
$grid-responsive-sort-clear-btn-font-size: $text-base !default;
$grid-responsive-sort-clear-margin-top: 0 !default;
$grid-responsive-icon-filter-clear: $grid-bigger-icons-font-size !default;
$grid-responsive-icon-filter-clear-bottom: 10px !default;
$grid-responsive-btn-icon-font-size: $grid-bigger-icons-font-size !default;
$grid-responsive-back-btn-bottom: 12px !default;
$grid-responsive-header-padding-top: 6px !default;
$grid-responsive-search-place-holder-margin-top: 0 !default;
$grid-responsive-search-width: 0 !default;
$grid-responsive-search-left-padding: 0 !default;
$grid-responsive-filter-clear-margin: 6px !default;
$grid-responsive-toolbar-filter-font-size: $grid-bigger-icons-font-size !default;
$grid-responsive-tbar-btn-bgr-padding: 0 !default;
$grid-responsive-btn-top: -10px !default;
$grid-responsive-apply-btn-top: 4px !default;

$grid-rtl-headercelldiv-left-align-margin: 10px !default;
$grid-rtl-filtermenudiv-left-align-margin: -2px !default;
$grid-rtl-sort-num-left-align-margin: 2px 8px 0 0 !default;
$grid-frozen-mac-scrollbar-background: rgba(0, 0, 0, .5) !default;
$grid-rowcell-color: $content-text-color !default;
$grid-header-text-transform: none !default;
$grid-headerclone-font-size: $grid-content-font-size !default;
$grid-filter-icon-color: $icon-color !default;
$grid-group-drop-area-border: dashed !default;
$grid-group-drop-area-border-width: 1px !default;
$grid-group-drop-area-hover-border-color: $border-dark !default;
$grid-adatptive-apply-btn-disable: $primary-bg-color-disabled !default;
$grid-sortnumber-font-size: $text-sm !default;
$grid-reorderarrow-margin-top: -7px !default;
$grid-reorderdownarrow-margin-top: 4px !default;
$grid-reorder-arrow-top-margin: 0 !default;
$grid-reorder-downarrow-top-margin: -2px !default;
$grid-reorder-virtualarrow-top-margin: -1px !default;
$grid-reorder-virtualdownarrow-top-margin: -1px !default;
$grid-bigger-toolbar-icon-size: $grid-bigger-icons-font-size !default;
$grid-reorderarrow-margin-left: -3px !default;
$grid-column-chooser-cancel-icon-size: $text-sm !default;

// bootstrap 5 support
$grid-outter-border: $border-light !default;
$grid-btn-disabled-bgcolor: $secondary-bg-color-disabled !default;
$grid-sort-number-size: 16px !default;
$grid-bigger-sort-number-size: 18px !default;
$grid-sort-number-font-size: $text-base !default;
$grid-filter-border-radius: 6px !default;
$grid-bigger-filter-border-radius: $grid-filter-border-radius !default;
$grid-adaptive-toolbar-icon-hover-bottom: 0 !default;
$grid-adaptive-cell-padding-bottom: 5px !default;
$grid-adaptive-cell-padding-top: 5px !default;
$grid-bigger-adaptive-cell-position-top: 6px !default;
$grid-bigger-sort-margin: -10px 2px !default;
$grid-bigger-sort-right-margin: 1px 8px 0 0 !default;
$grid-skin: 'bootstrap5' !default;
$grid-biggerreorderarrow-font-size: $text-sm !default;
$grid-bigger-drag-count-padding: 3px 8px 4px !default;
$grid-bigger-drag-count-top: -14px !default;
$grid-bigger-check-select-all-font-size: $text-xxs !default;
$grid-adaptive-sort-clear-button-padding: 4px !default;
$grid-cc-margin-top: 4px !default;
$grid-bigger-rtl-cm-margin: -12px 4px -12px -22px !default;
$grid-bigger-device-rtl-cm-margin: -16px 4px -12px -22px !default;
$grid-bigger-rtl-group-span-padding: 0 8px !default;
$grid-device-rtl-grouped-headercell-span-padding: $grid-device-grouped-headercell-span-padding !default;
$grid-rtl-group-span-padding: 0 8px !default;
$grid-ungroup-rtl-padding-left: 8px !default;
$grid-column-reorder-icon-color: $primary !default;
$grid-bigger-headercell-top-padding: 10px !default;
$grid-bigger-icon-font-size: $text-base !default;
$grid-rtl-mselect-dd-indicator-left: -6px !default;
$grid-toolbar-text-opacity: .6 !default;
$grid-toolbar-searchbar-text-opacity: 1 !default;
$grid-dropitems-count-margin-left: -4px !default;
