//layout variables
$radio-btn-border: 1px solid !default;
$radio-btn-bigger-font-size: $text-base !default;
$radio-btn-bigger-height: 16px !default;
$radio-btn-bigger-line-height: 1 !default;
$radio-btn-bigger-padding: 28px !default;
$radio-btn-bigger-small-height: 16px !default;
$radio-btn-bigger-small-line-height: 1 !default;
$radio-btn-bigger-small-padding: 28px !default;
$radio-btn-bigger-small-width: 16px !default;
$radio-btn-bigger-width: 16px !default;
$radio-btn-bigger-ripple-position: -11px !default;
$radio-btn-bigger-ripple-size: 48px !default;
$radio-btn-bigger-small-ripple-size: 36px !default;
$radio-btn-height: 14px !default;
$radio-btn-width: 14px !default;
$radio-btn-small-height: 14px !default;
$radio-btn-small-width: 14px !default;
$radio-btn-icon-left: 4px !default;
$radio-btn-icon-top: 4px !default;
$radio-btn-icon-right: 4px !default;
$radio-btn-ripple-position: -8px !default;
$radio-btn-ripple-size: 34px !default;
$radio-btn-small-icon-left: 4px !default;
$radio-btn-small-icon-top: 4px !default;
$radio-btn-small-icon-right: 4px !default;
$radio-btn-small-ripple-position: -10px !default;
$radio-btn-bigger-icon-left: 4px !default;
$radio-btn-bigger-icon-top: 4px !default;
$radio-btn-bigger-icon-right: 4px !default;
$radio-btn-bigger-small-icon-left: 4px !default;
$radio-btn-bigger-small-icon-top: 4px !default;
$radio-btn-bigger-small-icon-right: 4px !default;
$radio-btn-icon-height: 6px !default;
$radio-btn-icon-width: 6px !default;
$radio-btn-small-icon-height: 6px !default;
$radio-btn-small-icon-width: 6px !default;
$radio-btn-bigger-icon-height: 8px !default;
$radio-btn-bigger-icon-width: 8px !default;
$radio-btn-bigger-small-icon-height: 8px !default;
$radio-btn-bigger-small-icon-width: 8px !default;
$radio-btn-line-height: 1 !default;
$radio-btn-padding-left: 22px !default;
$radio-btn-small-line-height: 1 !default;
$radio-btn-small-padding: 22px !default;
$radio-btn-focus-outline-offset: 0 !default;
$radio-btn-font-size: $text-sm !default;
$radio-btn-background-color: $content-bg-color !default;
$radio-btn-border-color: $border !default;
$radio-btn-checked-border-color: $primary !default;
$radio-btn-checked-color: $primary-text-color !default;
$radio-btn-checked-background-color: $primary !default;
$radio-btn-checked-ripple-bgcolor: transparent !default;
$radio-btn-check-transition: none !default;
$radio-btn-disabled-border-color: $border-light !default;
$radio-btn-disabled-checked-border-color: transparent !default;
$radio-btn-disabled-background-color: $primary-light !default;
$radio-btn-disabled-color: $content-text-color-disabled !default;
$radio-btn-disabled-checked-color: $primary-text-color !default;
$radio-btn-font-color: $content-text-color !default;
$radio-btn-focus-ripple-bgcolor: $black !default;
$radio-btn-focussed-box-shadow: $shadow-focus-ring2 !default;
$radio-btn-hover-bgcolor: $content-bg-color !default;
$radio-btn-hover-border-color: $border !default;
$radio-btn-hover-check-bg-color: $primary-text-color !default;
$radio-btn-hover-check-border-color: $primary !default;
$radio-btn-ripple-bgcolor: transparent !default;
$radio-btn-focus-check-bg-color: $radio-btn-hover-check-bg-color !default;
$radio-btn-focus-check-border-color: $radio-btn-hover-check-border-color !default;
$radio-btn-focus-outline: $radio-btn-background-color 0 solid !default;

//enddefault
