@import "variables";

.cerrix-tree {
    max-height: 350px;
    overflow: auto;
    font-weight: 400;
    font-size: 14px;

    ul,
    li {
        margin-top: 0;
        margin-bottom: 0;
        list-style-type: none;
    }

    .mat-mdc-icon-button {
        margin-top: -10px;

        &:focus {
            outline: none;
        }
    }

    .node-row {
        min-height: auto;

        .mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background,
        .mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle {
            background-color: var(--accent-color);
        }

        .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle {
            border-color: var(--accent-color);
        }

        .mat-mdc-checkbox .mdc-label,
        .mat-mdc-radio-button .mdc-label {
            margin-bottom: 0;
        }
    }
}

// Ng-Select changes
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: var(--accent-color);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background-color: var(--accent-color-lighter);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: rgba(var(--accent), 0.2);
}
