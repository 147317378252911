// layout variables
$chip-list-padding: 0 !default;
$chip-height: 24px !default;
$chip-border-radius: 4px !default;
$chip-box-shadow: none !default;
$chip-avatar-wrapper-border-radius: 0 !default;
$chip-avatar-border-radius: 0 !default;
$chip-leading-icon-border-radius: 50% !default;
$chip-delete-icon-border-radius: 50% !default;
$chip-padding: 0 8px !default;
$chip-margin: 4px !default;
$chip-overflow: hidden !default;
$chip-border-size: 1px !default;
$chip-font-size: $text-sm !default;
$chip-line-height: $leading-none !default;
$chip-font-weight: $font-weight-normal !default;
$chip-avatar-size: 24px !default;
$chip-avatar-margin: 0 3px 0 -9px !default;
$chip-avatar-content-font-size: $text-xs !default;
$chip-leading-icon-font-size: $text-xs !default;
$chip-leading-icon-size: 16px !default;
$chip-leading-icon-margin: 0 8px 0 -4px !default;
$chip-multi-selection-icon-margin: 0 4px 0 -4px !default;
$chip-multi-selection-icon-margin-top: 0 !default;
$chip-delete-icon-font-size: $text-sm !default;
$chip-delete-icon-size: 16px !default;
$chip-delete-icon-margin: 0 -4px 0 4px !default;

//RTL
$chip-rtl-avatar-wrapper-border-radius: 0 !default;
$chip-rtl-avatar-margin: 0 -9px 0 3px !default;
$chip-rtl-leading-icon-margin: 0 -4px 0 8px !default;
$chip-rtl-delete-icon-margin: 0 4px 0 -4px !default;

//Touch
$chip-touch-border-radius: 4px !default;
$chip-touch-avatar-wrapper-border-radius: 0 !default;
$chip-touch-font-size: $text-base !default;
$chip-touch-height: 32px !default;
$chip-touch-text-line-height: $leading-none !default;
$chip-touch-text-height: 19px !default;
$chip-touch-padding: 4px 12px !default;
$chip-touch-avatar-size: 32px !default;
$chip-touch-avatar-margin: 0 4px 0 -13px !default;
$chip-touch-avatar-content-font-size: $text-base !default;
$chip-touch-leading-icon-font-size: $text-base !default;
$chip-touch-leading-icon-size: 20px !default;
$chip-touch-leading-icon-margin: 0 8px 0 -4px !default;
$chip-touch-multi-selection-icon-margin: 0 4px 0 -4px !default;
$chip-touch-delete-icon-font-size: $text-base !default;
$chip-touch-delete-icon-size: 24px !default;
$chip-touch-delete-icon-margin: 0 -4px 0 8px !default;

//Touch-RTL
$chip-touch-rtl-avatar-wrapper-border-radius: 0 !default;
$chip-touch-rtl-avatar-margin: 0 -13px 0 8px !default;
$chip-touch-rtl-leading-icon-margin: 0 -4px 0 8px !default;
$chip-touch-rtl-delete-icon-margin: 0 8px 0 -4px !default;

$chip-text-height: 17px !default;

//normal
$chip-bg-color: $secondary-border-color !default;
$chip-hover-bg-color: $secondary-border-color-hover !default;
$chip-focus-bg-color: $secondary-bg-color !default;
$chip-choice-active-bg-color: $primary !default;
$chip-active-bg-color: $secondary-border-color-pressed !default;
$chip-focus-active-bg-color: $secondary-border-color-pressed !default;
$chip-choice-focus-active-bg-color: $primary !default;
$chip-pressed-bg-color: $secondary-border-color-pressed !default;
$chip-disabled-bg-color: $secondary-border-color-disabled !default;
$chip-pressed-active-bg-color: $primary !default;
$chip-avatar-bg-color: $secondary-bg-color !default;
$chip-avatar-hover-bg-color: $secondary-bg-color !default;
$chip-avatar-focus-bg-color: $secondary-bg-color !default;
$chip-avatar-active-bg-color: $secondary-bg-color !default;
$chip-avatar-choice-active-bg-color: $primary !default;
$chip-avatar-focus-active-bg-color: $content-bg-color-alt4 !default;
$chip-avatar-choice-focus-active-bg-color: $chip-choice-focus-active-bg-color !default;
$chip-avatar-pressed-bg-color: $secondary-bg-color !default;
$chip-avatar-pressed-active-bg-color: $secondary-bg-color !default;
$chip-avatar-disabled-bg-color: $chip-disabled-bg-color !default;
$chip-font-color: $secondary-text-color !default;
$chip-hover-font-color: $secondary-text-color-hover !default;
$chip-focus-font-color: $secondary-text-color !default;
$chip-active-font-color: $secondary-text-color-pressed !default;
$chip-choice-active-font-color: $primary-text-color !default;
$chip-focus-active-font-color: $secondary-text-color-hover !default;
$chip-pressed-font-color: $secondary-text-color-pressed !default;
$chip-disabled-font-color: $secondary-text-color-disabled !default;
$chip-close-icon-font-color: $secondary-text-color !default;
$chip-close-icon-hover-font-color: $secondary-text-color-hover !default;
$chip-close-icon-pressed-font-color: $secondary-text-color-pressed !default;
$chip-icon-font-color: $secondary-text-color !default;
$chip-icon-hover-font-color: $secondary-text-color-hover !default;
$chip-icon-focus-font-color: $secondary-text-color-hover !default;
$chip-icon-active-font-color: $secondary-text-color-pressed !default;
$chip-icon-focus-active-font-color: $secondary-text-color-pressed !default;
$chip-choice-icon-focus-active-font-color: $primary-text-color !default;
$chip-icon-pressed-font-color: $secondary-text-color-pressed !default;
$chip-avatar-font-color: $secondary-text-color !default;
$chip-avatar-hover-font-color: $secondary-text-color !default;
$chip-avatar-focus-font-color: $secondary-text-color !default;
$chip-avatar-active-font-color: $secondary-text-color !default;
$chip-avatar-choice-active-font-color: $primary-text-color !default;
$chip-avatar-focus-active-font-color: $chip-icon-focus-active-font-color !default;
$chip-avatar-choice-focus-active-font-color: $chip-choice-icon-focus-active-font-color !default;
$chip-avatar-pressed-font-color: $secondary-text-color !default;
$chip-avatar-pressed-active-font-color: $secondary-text-color !default;
$chip-avatar-disabled-font-color: $chip-disabled-font-color !default;
$chip-border-color: $secondary-border-color !default;
$chip-hover-border-color: $secondary-border-color-hover !default;
$chip-focus-border-color: $secondary-bg-color !default;
$chip-choice-active-border-color: $chip-choice-active-bg-color !default;
$chip-active-border-color: $chip-active-bg-color !default;
$chip-choice-focus-active-border-color: $primary !default;
$chip-choice-focus-active-font-color: $primary-text-color !default;
$chip-pressed-border-color: $secondary-border-color-pressed !default;
$chip-disabled-border-color: $secondary-border-color-disabled !default;
$chip-focus-box-shadow: $secondary-shadow-focus !default;
$chip-pressed-box-shadow: $secondary-shadow-focus !default;
$chip-focus-active-border-color: $secondary-border-color-pressed !default;

//outline
$chip-outline-bg-color: transparent !default;
$chip-outline-hover-bg-color: $secondary-border-color-hover !default;
$chip-outline-focus-bg-color: $secondary-bg-color !default;
$chip-outline-choice-active-bg-color: $primary !default;
$chip-outline-active-bg-color: $secondary-border-color-pressed !default;
$chip-outline-focus-active-bg-color: $chip-outline-active-bg-color !default;
$chip-outline-choice-focus-active-bg-color: $primary !default;
$chip-outline-pressed-bg-color: $secondary-border-color-pressed !default;
$chip-outline-pressed-active-bg-color: $secondary-border-color-pressed !default;
$chip-outline-disabled-bg-color: transparent !default;
$chip-outline-avatar-bg-color: $secondary-bg-color !default;
$chip-outline-avatar-hover-bg-color: $secondary-bg-color !default;
$chip-outline-avatar-focus-bg-color: $secondary-bg-color !default;
$chip-outline-avatar-active-bg-color: $secondary-bg-color !default;
$chip-outline-avatar-choice-active-bg-color: $primary !default;
$chip-outline-avatar-focus-active-bg-color: $secondary-bg-color !default;
$chip-outline-avatar-choice-focus-active-bg-color: $primary !default;
$chip-outline-avatar-pressed-bg-color: $secondary-bg-color !default;
$chip-outline-avatar-pressed-active-bg-color: $secondary-bg-color !default;
$chip-outline-avatar-disabled-bg-color: $chip-outline-disabled-bg-color !default;
$chip-outline-font-color: $secondary-bg-color !default;
$chip-outline-hover-font-color: $secondary-text-color-hover !default;
$chip-outline-focus-font-color: $secondary-text-color !default;
$chip-outline-active-font-color: $secondary-text-color-pressed !default;
$chip-outline-choice-active-font-color: $primary-text-color !default;
$chip-outline-focus-active-font-color: $chip-outline-active-font-color !default;
$chip-outline-choice-focus-active-font-color: $chip-outline-choice-active-font-color !default;
$chip-outline-pressed-font-color: $secondary-text-color-pressed !default;
$chip-outline-disabled-font-color:  $content-text-color-disabled !default;
$chip-outline-close-icon-font-color: $secondary-bg-color !default;
$chip-outline-close-icon-hover-font-color: $secondary-text-color-hover !default;
$chip-outline-close-icon-pressed-font-color: $secondary-text-color-pressed !default;
$chip-outline-icon-font-color: $icon-color !default;
$chip-outline-icon-hover-font-color: $secondary-text-color-hover !default;
$chip-outline-icon-focus-font-color: $secondary-text-color-pressed !default;
$chip-outline-icon-active-font-color: $secondary-text-color-pressed !default;
$chip-outline-icon-focus-active-font-color: $secondary-text-color-pressed !default;
$chip-outline-choice-icon-focus-active-font-color: $primary-text-color !default;
$chip-outline-icon-pressed-font-color: $secondary-text-color-pressed !default;
$chip-outline-avatar-font-color: $secondary-text-color !default;
$chip-outline-avatar-hover-font-color: $secondary-text-color !default;
$chip-outline-avatar-focus-font-color: $secondary-text-color !default;
$chip-outline-avatar-active-font-color: $secondary-text-color !default;
$chip-outline-avatar-choice-active-font-color: $chip-outline-choice-active-font-color !default;
$chip-outline-avatar-focus-active-font-color: $secondary-text-color !default;
$chip-outline-avatar-choice-focus-active-font-color: $chip-outline-choice-icon-focus-active-font-color !default;
$chip-outline-avatar-pressed-font-color: $secondary-text-color !default;
$chip-outline-avatar-pressed-active-font-color: $secondary-text-color !default;
$chip-outline-avatar-disabled-font-color: $content-text-color-disabled !default;
$chip-outline-border-color: $secondary-bg-color !default;
$chip-outline-hover-border-color: $secondary-border-color-hover !default;
$chip-outline-focus-border-color: $secondary-bg-color !default;
$chip-outline-choice-active-border-color: $primary !default;
$chip-outline-active-border-color: $secondary-border-color-pressed !default;
$chip-outline-focus-active-border-color: $secondary-border-color-pressed !default;
$chip-outline-choice-focus-active-border-color: $primary !default;
$chip-outline-pressed-border-color: $secondary-border-color-pressed !default;
$chip-outline-disabled-border-color: $content-bg-color-alt3 !default;
$chip-outline-border-size: 1px !default;
$chip-outline-avatar-margin: 0 4px 0 -13px !default;
$chip-outline-icon-margin: 0 4px 0 4px !default;
$chip-outline-delete-icon-margin: 0 3px 0 4px !default;

//outline-rtl
$chip-outline-rtl-avatar-margin: 0 -13px 0 4px !default;
$chip-outline-rtl-icon-margin: 0 4px 0 4px !default;
$chip-outline-rtl-delete-icon-margin: 0 4px 0 3px !default;

//theme
//primary
$chip-primary-bg-color: $primary !default;
$chip-primary-hover-bg-color: $primary-bg-color-hover !default;
$chip-primary-focus-bg-color: $primary-bg-color-hover !default;
$chip-primary-active-bg-color: $primary-bg-color-pressed !default;
$chip-primary-focus-active-bg-color: $chip-primary-active-bg-color !default;
$chip-primary-pressed-bg-color: $primary-bg-color-pressed !default;
$chip-primary-disabled-bg-color: $primary-bg-color-disabled !default;
$chip-primary-avatar-bg-color: $chip-primary-bg-color !default;
$chip-primary-avatar-hover-bg-color: $chip-primary-hover-bg-color !default;
$chip-primary-avatar-focus-bg-color: $chip-primary-focus-bg-color !default;
$chip-primary-avatar-active-bg-color: $chip-primary-active-bg-color !default;
$chip-primary-avatar-focus-active-bg-color: $chip-primary-focus-active-bg-color !default;
$chip-primary-avatar-pressed-bg-color: $chip-primary-pressed-bg-color !default;
$chip-primary-avatar-disabled-bg-color: $chip-primary-disabled-bg-color !default;
$chip-primary-font-color: $primary-text-color !default;
$chip-primary-hover-font-color: $primary-text-color !default;
$chip-primary-focus-font-color: $primary-text-color !default;
$chip-primary-active-font-color: $primary-text-color !default;
$chip-primary-focus-active-font-color: $primary-text-color !default;
$chip-primary-pressed-font-color: $primary-text-color !default;
$chip-primary-disabled-font-color: $primary-text-color !default;
$chip-primary-close-icon-font-color: $primary-text-color !default;
$chip-primary-close-icon-hover-font-color: $primary-text-color !default;
$chip-primary-close-icon-pressed-font-color: $primary-text-color !default;
$chip-primary-avatar-font-color: $chip-primary-font-color !default;
$chip-primary-avatar-hover-font-color: $chip-primary-hover-font-color !default;
$chip-primary-avatar-focus-font-color: $chip-primary-focus-font-color !default;
$chip-primary-avatar-active-font-color: $chip-primary-active-font-color !default;
$chip-primary-avatar-focus-active-font-color: $chip-primary-focus-active-font-color !default;
$chip-primary-avatar-pressed-font-color: $chip-primary-pressed-font-color !default;
$chip-primary-avatar-disabled-font-color: $chip-primary-disabled-font-color !default;
$chip-primary-border-color: $primary !default;
$chip-primary-hover-border-color: $primary-bg-color-hover !default;
$chip-primary-focus-border-color: $primary-bg-color-hover !default;
$chip-primary-active-border-color: $primary-bg-color-pressed !default;
$chip-primary-focus-active-border-color: $primary-bg-color-pressed !default;
$chip-primary-pressed-border-color: $primary-bg-color-pressed !default;
$chip-primary-disabled-border-color: $primary-bg-color-disabled !default;

//success
$chip-success-bg-color: $success-bg-color !default;
$chip-success-hover-bg-color: $success-bg-color-hover !default;
$chip-success-focus-bg-color: $success-bg-color-hover !default;
$chip-success-active-bg-color: $success-bg-color-pressed !default;
$chip-success-focus-active-bg-color: $success-bg-color-pressed !default;
$chip-success-pressed-bg-color: $success-bg-color-pressed !default;
$chip-success-disabled-bg-color: $success-bg-color-disabled !default;
$chip-success-avatar-bg-color: $chip-success-bg-color !default;
$chip-success-avatar-hover-bg-color: $chip-success-hover-bg-color !default;
$chip-success-avatar-focus-bg-color: $chip-success-focus-bg-color !default;
$chip-success-avatar-active-bg-color: $chip-success-active-bg-color !default;
$chip-success-avatar-focus-active-bg-color: $chip-success-focus-active-bg-color !default;
$chip-success-avatar-pressed-bg-color: $chip-success-pressed-bg-color !default;
$chip-success-avatar-disabled-bg-color: $chip-success-disabled-bg-color !default;
$chip-success-font-color: $success-text !default;
$chip-success-hover-font-color: $success-text-hover !default;
$chip-success-focus-font-color: $success-text-hover !default;
$chip-success-active-font-color: $success-text-pressed !default;
$chip-success-focus-active-font-color: $success-text-pressed !default;
$chip-success-pressed-font-color: $success-text-pressed !default;
$chip-success-disabled-font-color: $success-text-disabled !default;
$chip-success-close-icon-font-color: $success-text !default;
$chip-success-close-icon-hover-font-color: $chip-success-hover-font-color !default;
$chip-success-close-icon-pressed-font-color: $chip-success-pressed-font-color !default;
$chip-success-avatar-font-color: $chip-success-font-color !default;
$chip-success-avatar-hover-font-color: $chip-success-hover-font-color !default;
$chip-success-avatar-focus-font-color: $chip-success-focus-font-color !default;
$chip-success-avatar-active-font-color: $chip-success-active-font-color !default;
$chip-success-avatar-focus-active-font-color: $chip-success-focus-active-font-color !default;
$chip-success-avatar-pressed-font-color: $chip-success-pressed-font-color !default;
$chip-success-avatar-disabled-font-color: $chip-success-disabled-font-color !default;
$chip-success-border-color: $success-bg-color !default;
$chip-success-hover-border-color: $success-bg-color-hover !default;
$chip-success-focus-border-color: $success-bg-color-pressed !default;
$chip-success-active-border-color: $success-bg-color-pressed !default;
$chip-success-focus-active-border-color: $success-bg-color-pressed !default;
$chip-success-pressed-border-color: $success-bg-color-pressed !default;
$chip-success-disabled-border-color: $success-bg-color-disabled !default;

//info
$chip-info-bg-color: $info-bg-color !default;
$chip-info-hover-bg-color: $info-bg-color-hover !default;
$chip-info-focus-bg-color: $info-bg-color-hover !default;
$chip-info-active-bg-color: $info-bg-color-pressed !default;
$chip-info-focus-active-bg-color: $info-bg-color-pressed !default;
$chip-info-pressed-bg-color: $info-bg-color-pressed !default;
$chip-info-disabled-bg-color: $info-bg-color-disabled !default;
$chip-info-avatar-bg-color: $chip-info-bg-color !default;
$chip-info-avatar-hover-bg-color: $chip-info-hover-bg-color !default;
$chip-info-avatar-focus-bg-color: $chip-info-focus-bg-color !default;
$chip-info-avatar-active-bg-color: $chip-info-active-bg-color !default;
$chip-info-avatar-focus-active-bg-color: $chip-info-focus-active-bg-color !default;
$chip-info-avatar-pressed-bg-color: $chip-info-pressed-bg-color !default;
$chip-info-avatar-disabled-bg-color: $chip-info-disabled-bg-color !default;
$chip-info-font-color: $info-text !default;
$chip-info-hover-font-color: $info-text !default;
$chip-info-focus-font-color: $info-text !default;
$chip-info-active-font-color: $info-text-pressed !default;
$chip-info-focus-active-font-color: $info-text-pressed !default;
$chip-info-pressed-font-color: $info-text-pressed !default;
$chip-info-disabled-font-color: $info-text-disabled !default;
$chip-info-close-icon-font-color: $info-text !default;
$chip-info-close-icon-hover-font-color: $chip-info-hover-font-color !default;
$chip-info-close-icon-pressed-font-color: $chip-info-pressed-font-color !default;
$chip-info-avatar-font-color: $chip-info-font-color !default;
$chip-info-avatar-hover-font-color: $chip-info-hover-font-color !default;
$chip-info-avatar-focus-font-color: $chip-info-focus-font-color !default;
$chip-info-avatar-active-font-color: $chip-info-active-font-color !default;
$chip-info-avatar-focus-active-font-color: $chip-info-focus-active-font-color !default;
$chip-info-avatar-pressed-font-color: $chip-info-pressed-font-color !default;
$chip-info-avatar-disabled-font-color: $chip-info-disabled-font-color !default;
$chip-info-border-color: $info-bg-color !default;
$chip-info-hover-border-color: $info-bg-color-hover !default;
$chip-info-focus-border-color: $info-bg-color-hover !default;
$chip-info-active-border-color: $info-bg-color-pressed !default;
$chip-info-focus-active-border-color: $info-bg-color-pressed !default;
$chip-info-pressed-border-color: $info-bg-color-pressed !default;
$chip-info-disabled-border-color: $info-bg-color-disabled !default;

//warning
$chip-warning-bg-color: $warning-bg-color !default;
$chip-warning-hover-bg-color: $warning-bg-color-hover !default;
$chip-warning-focus-bg-color: $warning-bg-color-hover !default;
$chip-warning-active-bg-color: $warning-bg-color-pressed !default;
$chip-warning-focus-active-bg-color: $warning-bg-color-pressed !default;
$chip-warning-pressed-bg-color: $warning-bg-color-pressed !default;
$chip-warning-disabled-bg-color: $warning-bg-color-disabled !default;
$chip-warning-avatar-bg-color: $chip-warning-bg-color !default;
$chip-warning-avatar-hover-bg-color: $chip-warning-hover-bg-color !default;
$chip-warning-avatar-focus-bg-color: $chip-warning-focus-bg-color !default;
$chip-warning-avatar-active-bg-color: $chip-warning-active-bg-color !default;
$chip-warning-avatar-focus-active-bg-color: $chip-warning-focus-active-bg-color !default;
$chip-warning-avatar-pressed-bg-color: $chip-warning-pressed-bg-color !default;
$chip-warning-avatar-disabled-bg-color: $chip-warning-disabled-bg-color !default;
$chip-warning-font-color: $warning-text !default;
$chip-warning-hover-font-color: $warning-text !default;
$chip-warning-focus-font-color: $warning-text !default;
$chip-warning-active-font-color: $warning-text-pressed !default;
$chip-warning-focus-active-font-color: $warning-text-pressed !default;
$chip-warning-pressed-font-color: $warning-text-pressed !default;
$chip-warning-disabled-font-color: $warning-text-disabled !default;
$chip-warning-close-icon-font-color: $warning-text !default;
$chip-warning-close-icon-hover-font-color: $chip-warning-hover-font-color !default;
$chip-warning-close-icon-pressed-font-color: $chip-warning-pressed-font-color !default;
$chip-warning-avatar-font-color: $chip-warning-font-color !default;
$chip-warning-avatar-hover-font-color: $chip-warning-hover-font-color !default;
$chip-warning-avatar-focus-font-color: $chip-warning-focus-font-color !default;
$chip-warning-avatar-active-font-color: $chip-warning-active-font-color !default;
$chip-warning-avatar-focus-active-font-color: $chip-warning-focus-active-font-color !default;
$chip-warning-avatar-pressed-font-color: $chip-warning-pressed-font-color !default;
$chip-warning-avatar-disabled-font-color: $chip-warning-disabled-font-color !default;
$chip-warning-border-color: $warning-bg-color !default;
$chip-warning-hover-border-color: $warning-bg-color-hover !default;
$chip-warning-focus-border-color: $warning-bg-color-hover !default;
$chip-warning-active-border-color: $warning-bg-color-pressed !default;
$chip-warning-focus-active-border-color: $warning-bg-color-pressed !default;
$chip-warning-pressed-border-color: $warning-bg-color-pressed !default;
$chip-warning-disabled-border-color: $warning-bg-color-disabled !default;

//danger
$chip-danger-bg-color: $danger-bg-color !default;
$chip-danger-hover-bg-color: $danger-bg-color-hover !default;
$chip-danger-focus-bg-color: $danger-bg-color-hover !default;
$chip-danger-active-bg-color: $danger-bg-color-pressed !default;
$chip-danger-focus-active-bg-color: $danger-bg-color-pressed !default;
$chip-danger-pressed-bg-color: $danger-bg-color-pressed !default;
$chip-danger-disabled-bg-color: $danger-border-color-disabled !default;
$chip-danger-avatar-bg-color: $danger-bg-color !default;
$chip-danger-avatar-hover-bg-color: $chip-danger-hover-bg-color !default;
$chip-danger-avatar-focus-bg-color: $chip-danger-focus-bg-color !default;
$chip-danger-avatar-active-bg-color: $chip-danger-active-bg-color !default;
$chip-danger-avatar-focus-active-bg-color: $chip-danger-focus-active-bg-color !default;
$chip-danger-avatar-pressed-bg-color: $chip-danger-pressed-bg-color !default;
$chip-danger-avatar-disabled-bg-color: $chip-danger-disabled-bg-color !default;
$chip-danger-font-color: $success-text !default;
$chip-danger-hover-font-color: $success-text !default;
$chip-danger-focus-font-color: $success-text !default;
$chip-danger-active-font-color: $danger-text-pressed !default;
$chip-danger-focus-active-font-color: $danger-text-pressed !default;
$chip-danger-pressed-font-color: $danger-text-pressed !default;
$chip-danger-disabled-font-color: $success-text !default;
$chip-danger-close-icon-font-color: $success-text !default;
$chip-danger-close-icon-hover-font-color: $chip-danger-hover-font-color !default;
$chip-danger-close-icon-pressed-font-color: $chip-danger-pressed-font-color !default;
$chip-danger-avatar-font-color: $chip-danger-font-color !default;
$chip-danger-avatar-hover-font-color: $chip-danger-hover-font-color !default;
$chip-danger-avatar-focus-font-color: $chip-danger-focus-font-color !default;
$chip-danger-avatar-active-font-color: $chip-danger-active-font-color !default;
$chip-danger-avatar-focus-active-font-color: $chip-danger-focus-active-font-color !default;
$chip-danger-avatar-pressed-font-color: $chip-danger-pressed-font-color !default;
$chip-danger-avatar-disabled-font-color: $chip-danger-disabled-font-color !default;
$chip-danger-border-color: $danger-bg-color !default;
$chip-danger-hover-border-color: $danger-bg-color-hover !default;
$chip-danger-focus-border-color: $danger-bg-color-hover !default;
$chip-danger-active-border-color: $danger-bg-color-pressed !default;
$chip-danger-focus-active-border-color: $danger-bg-color-pressed !default;
$chip-danger-pressed-border-color: $danger-bg-color-pressed !default;
$chip-danger-disabled-border-color: $danger-border-color-disabled !default;

//outline-theme
//primary
$chip-outline-primary-bg-color: transparent !default;
$chip-outline-primary-hover-bg-color: $primary-bg-color-hover !default;
$chip-outline-primary-focus-bg-color: $primary-bg-color-hover !default;
$chip-outline-primary-active-bg-color: $primary-bg-color-pressed !default;
$chip-outline-primary-focus-active-bg-color: $primary-bg-color-pressed !default;
$chip-outline-primary-pressed-bg-color: $primary-bg-color-pressed !default;
$chip-outline-primary-disabled-bg-color: transparent !default;
$chip-outline-primary-avatar-bg-color: $primary !default;
$chip-outline-primary-avatar-hover-bg-color: $chip-outline-primary-avatar-bg-color !default;
$chip-outline-primary-avatar-focus-bg-color: $chip-outline-primary-focus-bg-color !default;
$chip-outline-primary-avatar-active-bg-color: $chip-outline-primary-active-bg-color !default;
$chip-outline-primary-avatar-focus-active-bg-color: $chip-outline-primary-pressed-bg-color !default;
$chip-outline-primary-avatar-pressed-bg-color: $chip-outline-primary-pressed-bg-color !default;
$chip-outline-primary-avatar-disabled-bg-color: $chip-outline-primary-disabled-bg-color !default;
$chip-outline-primary-font-color: $primary !default;
$chip-outline-primary-hover-font-color: $primary-text-color !default;
$chip-outline-primary-focus-font-color: $primary-text-color !default;
$chip-outline-primary-active-font-color: $primary-text-color !default;
$chip-outline-primary-focus-active-font-color: $primary-text-color !default;
$chip-outline-primary-pressed-font-color: $primary-text-color !default;
$chip-outline-primary-disabled-font-color: $content-text-color-disabled !default;
$chip-outline-primary-close-icon-font-color: $primary !default;
$chip-outline-primary-close-icon-hover-font-color: $chip-outline-primary-hover-font-color !default;
$chip-outline-primary-close-icon-pressed-font-color: $chip-outline-primary-pressed-font-color !default;
$chip-outline-primary-avatar-font-color: $primary-text-color !default;
$chip-outline-primary-avatar-hover-font-color: $chip-outline-primary-focus-font-color !default;
$chip-outline-primary-avatar-focus-font-color: $chip-outline-primary-focus-font-color !default;
$chip-outline-primary-avatar-active-font-color: $chip-outline-primary-active-font-color !default;
$chip-outline-primary-avatar-focus-active-font-color: $chip-outline-primary-focus-active-font-color !default;
$chip-outline-primary-avatar-pressed-font-color: $chip-outline-primary-pressed-font-color !default;
$chip-outline-primary-avatar-disabled-font-color: $chip-outline-primary-disabled-font-color !default;
$chip-outline-primary-border-color: $primary !default;
$chip-outline-primary-hover-border-color: $primary-bg-color-hover !default;
$chip-outline-primary-focus-border-color: $primary-bg-color-hover !default;
$chip-outline-primary-focus-active-border-color: $primary-bg-color-pressed !default;
$chip-outline-primary-active-border-color: $primary-bg-color-pressed !default;
$chip-outline-primary-pressed-border-color: $primary-bg-color-pressed !default;
$chip-outline-primary-disabled-border-color: $content-bg-color-alt3 !default;

//success
$chip-outline-success-bg-color: transparent !default;
$chip-outline-success-hover-bg-color: $success-bg-color-hover !default;
$chip-outline-success-focus-bg-color: $success-bg-color-hover !default;
$chip-outline-success-active-bg-color:  $success-bg-color-pressed !default;
$chip-outline-success-focus-active-bg-color: $success-bg-color-pressed !default;
$chip-outline-success-pressed-bg-color: $success-bg-color-pressed !default;
$chip-outline-success-disabled-bg-color: transparent !default;
$chip-outline-success-avatar-bg-color: $success !default;
$chip-outline-success-avatar-hover-bg-color: $chip-outline-success-hover-bg-color !default;
$chip-outline-success-avatar-focus-bg-color: $chip-outline-success-focus-bg-color !default;
$chip-outline-success-avatar-active-bg-color: $chip-outline-success-active-bg-color !default;
$chip-outline-success-avatar-focus-active-bg-color: $chip-outline-success-focus-active-bg-color !default;
$chip-outline-success-avatar-pressed-bg-color: $chip-outline-success-pressed-bg-color !default;
$chip-outline-success-avatar-disabled-bg-color: $chip-outline-success-disabled-bg-color !default;
$chip-outline-success-font-color: $success !default;
$chip-outline-success-hover-font-color: $success-text-hover !default;
$chip-outline-success-focus-font-color: $success-text-hover !default;
$chip-outline-success-active-font-color: $success-text-pressed !default;
$chip-outline-success-focus-active-font-color: $success-text-pressed !default;
$chip-outline-success-pressed-font-color: $success-text-pressed !default;
$chip-outline-success-disabled-font-color: $content-text-color-disabled !default;
$chip-outline-success-close-icon-font-color: $success !default;
$chip-outline-success-close-icon-hover-font-color: $chip-outline-success-hover-font-color !default;
$chip-outline-success-close-icon-pressed-font-color: $chip-outline-success-pressed-font-color !default;
$chip-outline-success-avatar-font-color: $success-text !default;
$chip-outline-success-avatar-hover-font-color: $chip-outline-success-hover-font-color !default;
$chip-outline-success-avatar-focus-font-color: $chip-outline-success-focus-font-color !default;
$chip-outline-success-avatar-active-font-color: $chip-outline-success-active-font-color !default;
$chip-outline-success-avatar-focus-active-font-color: $chip-outline-success-focus-active-font-color !default;
$chip-outline-success-avatar-pressed-font-color: $chip-outline-success-pressed-font-color !default;
$chip-outline-success-avatar-disabled-font-color: $chip-outline-success-disabled-font-color !default;
$chip-outline-success-border-color: $success !default;
$chip-outline-success-hover-border-color: $success-bg-color-hover !default;
$chip-outline-success-focus-border-color: $success-bg-color-hover !default;
$chip-outline-success-active-border-color: $success-bg-color-pressed !default;
$chip-outline-success-focus-active-border-color: $success-bg-color-pressed !default;
$chip-outline-success-pressed-border-color: $success-bg-color-pressed !default;
$chip-outline-success-disabled-border-color: $content-bg-color-alt3 !default;

//info
$chip-outline-info-bg-color: transparent !default;
$chip-outline-info-hover-bg-color: $info-bg-color-hover !default;
$chip-outline-info-focus-bg-color: $info-bg-color-hover !default;
$chip-outline-info-active-bg-color: $info-bg-color-pressed !default;
$chip-outline-info-focus-active-bg-color: $info-bg-color-pressed !default;
$chip-outline-info-pressed-bg-color: $info-bg-color-pressed !default;
$chip-outline-info-disabled-bg-color: transparent !default;
$chip-outline-info-avatar-bg-color: $info !default;
$chip-outline-info-avatar-hover-bg-color: $chip-outline-info-hover-bg-color !default;
$chip-outline-info-avatar-focus-bg-color: $chip-outline-info-focus-bg-color !default;
$chip-outline-info-avatar-active-bg-color: $chip-outline-info-active-bg-color !default;
$chip-outline-info-avatar-focus-active-bg-color: $chip-outline-info-focus-active-bg-color !default;
$chip-outline-info-avatar-pressed-bg-color: $chip-outline-info-pressed-bg-color !default;
$chip-outline-info-avatar-disabled-bg-color: $chip-outline-info-disabled-bg-color !default;
$chip-outline-info-font-color: $info-dark !default;
$chip-outline-info-hover-font-color: $info-text !default;
$chip-outline-info-focus-font-color: $info-text !default;
$chip-outline-info-active-font-color: $info-text-pressed !default;
$chip-outline-info-focus-active-font-color: $info-text-pressed !default;
$chip-outline-info-pressed-font-color: $info-text-pressed !default;
$chip-outline-info-disabled-font-color: $content-text-color-disabled !default;
$chip-outline-info-close-icon-font-color: $info-dark !default;
$chip-outline-info-close-icon-hover-font-color: $chip-outline-info-hover-font-color !default;
$chip-outline-info-close-icon-pressed-font-color: $chip-outline-info-pressed-font-color !default;
$chip-outline-info-avatar-font-color: $info-text !default;
$chip-outline-info-avatar-hover-font-color: $chip-outline-info-hover-font-color !default;
$chip-outline-info-avatar-focus-font-color: $chip-outline-info-focus-font-color !default;
$chip-outline-info-avatar-active-font-color: $chip-outline-info-active-font-color !default;
$chip-outline-info-avatar-focus-active-font-color: $chip-outline-info-focus-active-font-color !default;
$chip-outline-info-avatar-pressed-font-color: $chip-outline-info-pressed-font-color !default;
$chip-outline-info-avatar-disabled-font-color: $content-text-color-disabled !default;
$chip-outline-info-border-color: $info !default;
$chip-outline-info-hover-border-color: $info-bg-color-hover !default;
$chip-outline-info-focus-border-color: $info-bg-color-hover !default;
$chip-outline-info-active-border-color: $info-bg-color-pressed !default;
$chip-outline-info-focus-active-border-color: $info-bg-color-pressed !default;
$chip-outline-info-pressed-border-color: $info-bg-color-pressed !default;
$chip-outline-info-disabled-border-color: $content-bg-color-alt3 !default;

//warning
$chip-outline-warning-bg-color: transparent !default;
$chip-outline-warning-hover-bg-color: $warning-bg-color-hover !default;
$chip-outline-warning-focus-bg-color: $warning-bg-color-hover !default;
$chip-outline-warning-active-bg-color: $warning-bg-color-pressed !default;
$chip-outline-warning-focus-active-bg-color: $warning-bg-color-pressed !default;
$chip-outline-warning-pressed-bg-color: $warning-bg-color-pressed !default;
$chip-outline-warning-disabled-bg-color: transparent !default;
$chip-outline-warning-avatar-bg-color: $warning !default;
$chip-outline-warning-avatar-hover-bg-color: $chip-outline-warning-hover-bg-color !default;
$chip-outline-warning-avatar-focus-bg-color: $chip-outline-warning-focus-bg-color !default;
$chip-outline-warning-avatar-active-bg-color: $chip-outline-warning-active-bg-color !default;
$chip-outline-warning-avatar-focus-active-bg-color: $chip-outline-warning-focus-active-bg-color !default;
$chip-outline-warning-avatar-pressed-bg-color: $chip-outline-warning-pressed-bg-color !default;
$chip-outline-warning-avatar-disabled-bg-color: $chip-outline-warning-disabled-bg-color !default;
$chip-outline-warning-font-color: $warning-dark !default;
$chip-outline-warning-hover-font-color: $warning-text !default;
$chip-outline-warning-focus-font-color: $warning-text !default;
$chip-outline-warning-active-font-color: $warning-text-pressed !default;
$chip-outline-warning-focus-active-font-color: $warning-text-pressed !default;
$chip-outline-warning-pressed-font-color: $warning-text-pressed !default;
$chip-outline-warning-disabled-font-color: $content-text-color-disabled !default;
$chip-outline-warning-close-icon-font-color: $warning-dark !default; //not added
$chip-outline-warning-close-icon-hover-font-color: $chip-outline-warning-hover-font-color !default;
$chip-outline-warning-close-icon-pressed-font-color: $chip-outline-warning-pressed-font-color !default;
$chip-outline-warning-avatar-font-color: $warning-text !default;
$chip-outline-warning-avatar-hover-font-color: $chip-outline-warning-hover-font-color !default;
$chip-outline-warning-avatar-focus-font-color: $chip-outline-warning-focus-font-color !default;
$chip-outline-warning-avatar-active-font-color: $chip-outline-warning-active-font-color !default;
$chip-outline-warning-avatar-focus-active-font-color: $chip-outline-warning-focus-active-font-color !default;
$chip-outline-warning-avatar-pressed-font-color: $chip-outline-warning-pressed-font-color !default;
$chip-outline-warning-avatar-disabled-font-color: $chip-outline-warning-disabled-font-color !default; //not added
$chip-outline-warning-border-color: $warning !default;
$chip-outline-warning-hover-border-color: $warning-bg-color-hover !default;
$chip-outline-warning-focus-border-color: $warning-bg-color-hover !default;
$chip-outline-warning-active-border-color: $warning-bg-color-pressed !default;
$chip-outline-warning-focus-active-border-color: $warning-bg-color-pressed !default;
$chip-outline-warning-pressed-border-color: $warning-bg-color-pressed !default;
$chip-outline-warning-disabled-border-color: $content-bg-color-alt3 !default;

//danger
$chip-outline-danger-bg-color: transparent !default;
$chip-outline-danger-hover-bg-color: $danger-bg-color-hover !default;
$chip-outline-danger-focus-bg-color: $danger-bg-color-hover !default;
$chip-outline-danger-active-bg-color: $danger-bg-color-pressed !default;
$chip-outline-danger-focus-active-bg-color: $danger-bg-color-pressed !default;
$chip-outline-danger-pressed-bg-color: $danger-bg-color-pressed !default;
$chip-outline-danger-disabled-bg-color: transparent !default;
$chip-outline-danger-avatar-bg-color: $danger !default;
$chip-outline-danger-avatar-hover-bg-color: $chip-outline-danger-hover-bg-color !default;
$chip-outline-danger-avatar-focus-bg-color: $chip-outline-danger-focus-bg-color !default;
$chip-outline-danger-avatar-active-bg-color: $chip-outline-danger-active-bg-color !default;
$chip-outline-danger-avatar-focus-active-bg-color: $chip-outline-danger-focus-active-bg-color !default;
$chip-outline-danger-avatar-pressed-bg-color: $chip-outline-danger-pressed-bg-color !default;
$chip-outline-danger-avatar-disabled-bg-color: $chip-outline-danger-disabled-bg-color !default;
$chip-outline-danger-font-color: $danger !default;
$chip-outline-danger-hover-font-color: $success-text !default;
$chip-outline-danger-focus-font-color: $success-text !default;
$chip-outline-danger-active-font-color: $danger-text-pressed !default;
$chip-outline-danger-focus-active-font-color: $danger-text-pressed !default;
$chip-outline-danger-pressed-font-color: $danger-text-pressed !default;
$chip-outline-danger-disabled-font-color: $content-text-color-disabled !default;
$chip-outline-danger-close-icon-font-color: $danger !default;
$chip-outline-danger-close-icon-hover-font-color: $chip-outline-danger-hover-font-color !default;
$chip-outline-danger-close-icon-pressed-font-color: $chip-outline-danger-pressed-font-color !default;
$chip-outline-danger-avatar-font-color: $danger-text !default;
$chip-outline-danger-avatar-hover-font-color: $chip-outline-danger-hover-font-color !default;
$chip-outline-danger-avatar-focus-font-color: $chip-outline-danger-focus-font-color !default;
$chip-outline-danger-avatar-active-font-color: $chip-outline-danger-active-font-color !default;
$chip-outline-danger-avatar-focus-active-font-color: $chip-outline-danger-focus-active-font-color !default;
$chip-outline-danger-avatar-pressed-font-color: $chip-outline-danger-pressed-font-color !default;
$chip-outline-danger-avatar-disabled-font-color: $chip-outline-danger-disabled-font-color !default;
$chip-outline-danger-border-color: $danger !default;
$chip-outline-danger-hover-border-color: $danger-bg-color-hover !default;
$chip-outline-danger-focus-border-color: $danger-bg-color-hover !default;
$chip-outline-danger-active-border-color: $danger-bg-color-pressed !default;
$chip-outline-danger-focus-active-border-color: $danger-bg-color-pressed !default;
$chip-outline-danger-pressed-border-color: $danger-bg-color-pressed !default;
$chip-outline-danger-disabled-border-color: $content-bg-color-alt3 !default;

// box shadows
// primary
$chip-primary-box-shadow: $shadow-focus-ring1 !default;

// success
$chip-success-box-shadow: $success-shadow-focus !default;

// danger
$chip-danger-box-shadow: $danger-shadow-focus !default;

// warning
$chip-warning-box-shadow: $warning-shadow-focus !default;

// info
$chip-info-box-shadow: $info-shadow-focus !default;
