@include export-module('toolbar-bootstrap5-icons') {

  /*! toolbar icons */
  #{&}.e-toolbar {

    .e-popup-down-icon::before {
      content: '\e729';
      line-height: normal;
    }

    .e-popup-up-icon::before {
      content: '\e776';
      line-height: normal;
    }
  }
}
