/*! calendar bootstrap5 theme variables */
//month view
$calendar-normal-month-view-height: 32px !default;
$calendar-normal-month-view-line-height: $calendar-normal-month-view-height !default;
$calendar-normal-month-view-width: 32px !default;
$calendar-normal-month-cell-padding: 0 !default;
$calendar-bigger-table-month-padding: 0 12px 12px 12px !default;
$calendar-bigger-month-font-size: $text-base !default;
$calendar-bigger-month-view-padding: 0 !default;
$calendar-bigger-month-view-height: 38px !default;
$calendar-bigger-month-view-line-height: $calendar-bigger-month-view-height !default;
$calendar-bigger-month-view-width: 38px !default;

//header dimension
$calendar-header-font-size: $text-sm !default;
$calendar-bigger-header-padding: 10px 0 10px 15px !default;
$calendar-bigger-day-header-height: 40px !default;
$calendar-bigger-header-height: 28px !default;
$calendar-normal-header-padding: 8px 0 8px 12px !default;
$calendar-normal-day-header-height: 32px !default;
$calendar-normal-day-header-text: none !default;
$calendar-normal-header-height: 24px !default;
$calendar-bigger-header-font-size: $text-lg !default;
$calendar-normal-day-header-font-weight: $font-weight-medium !default;
$calendar-title-font-size: $text-base !default;
$calendar-title-margin-left-style: 2px !default;
$calendar-title-font-weight-style: $font-weight-bold !default;
$calendar-title-decoration-style: none !default;

// common size properties
$calendar-wrapper-border-radius: 4px !default;
$calendar-full-width: 100% !default;
$calendar-lg-day-header-format-max-width: 100% !default;
$calendar-lg-day-header-format-min-width: 540px !default;
$calendar-icon-padding-top: 0 !default;
$calendar-zero-value: 0 !default;
$calendar-disable-opacity: 1 !default;

//popup styles
$calendar-normal-max-width: 242px !default;
$calendar-normal-min-width: 240px !default;
$calendar-small-max-width: 200px !default;
$calendar-small-min-width: 200px !default;
$calendar-bigger-max-width: 292px !default;
$calendar-bigger-min-width: 292px !default;
$calendar-weeknumber-min-width: 300px !default;
$calendar-weeknumber-bigger-width: 325px !default;
$calendar-popup-padding: 0 !default;
$calendar-popup-bigger-padding: 0 !default;

// today button
$calendar-bigger-today-button-height: 48px !default;
$calendar-normal-today-button-height: 36px !default;

//  year decade view
//dimension
$calendar-normal-year-decade-height: 56px !default;
$calendar-normal-year-decade-width: 56px !default;
$calendar-small-year-decade-width: 46px !default;
$calendar-normal-year-decade-padding: 2px !default;
$calendar-yeardeacde-span-padding: 0 !default;
$calendar-yeardecade-padding: 0 8px 8px !default;
$calendar-bigger-year-decade-padding: 0 !default;
$calendar-bigger-yeardecade-font-size: $calendar-bigger-month-font-size !default;
$calendar-bigger-table-yeardecade-padding: 0 12px 12px 12px !default;
$calendar-bigger-yeardecade-font-weight: $font-weight-normal !default;
$calendar-yeardecade-font-weight: $font-weight-normal !default;
$calendar-bigger-year-decade-height: 66px !default;
$calendar-bigger-year-decade-width: 66px !default;
$calendar-yeardecade-header-padding: 8px 0 8px 12px !default;
$calendar-bigger-yeardecade-header-padding: 10px !default;
$calendar-decade-title-left-margin-style: 15px !default;

// table related styles
$calendar-table-padding: 0 8px 8px 8px !default;
$calendar-rtl-text-indent: 12px !default;
$calendar-day-bigger-small-cell-size: 34px !default;
$calendar-rtl-bigger-small-text-indent: 12px !default;
$calendar-rtl-bigger-text-indent: 15px !default;
$calendar-title-margin-bigger-small-left-style: 5px !default;
$calendar-title-margin-small-left-style: 0 !default;

//font icons
$calendar-prev-icon: '\e910' !default;
$calendar-next-icon: '\e916' !default;
$calendar-next-prev-icon-size: 14px !default;
$calendar-icon-line-height: 1 !default;
$calendar-bigger-icon-size: 24px !default;
$calendar-normal-icon-size: 24px !default;
$calendar-spanicon-font-weight-style: $font-weight-normal !default;

//pending
$calendar-icon-font-size-style: $font-icon-14 !default;
$calendar-bigger-icon-font-size: $font-icon-16 !default;
$calendar-footer-container-padding: 8px !default;
$calendar-bigger-footer-container-padding: 16px !default;
$calendar-week-number-font-weight: $font-weight-medium !default;

// small size
$calendar-small-icon-size: 24px !default;
$calendar-small-icon-font-size-style: $font-icon-12 !default;
$calendar-small-title-font-size: $text-xs !default;
$calendar-small-icon-padding: 0 !default;
$calendar-small-header-height: 22px !default;
$calendar-small-day-header-height: 24px !default;
$calendar-small-month-view-height: 26px !default;
$calendar-small-month-view-line-height: $calendar-small-month-view-height !default;
$calendar-small-date-font-size: $text-xs !default;
$calendar-small-month-view-width: 26px !default;
$calendar-small-popup-padding: 0 !default;
$calendar-bigger-small-table-padding: 0 12px 12px 12px !default;
$calendar-bigger-small-yeardecade-font-size: $text-sm !default;
$calendar-small-font-size: $text-xs !default;

//week number
$calendar-week-number-font-size-style: $text-sm !default;
$calendar-week-normal-max-width: 294px !default;
$calendar-week-normal-min-width: 288px !default;
$calendar-week-bigger-max-width: 320px !default;
$calendar-week-bigger-min-width: 314px !default;
$calendar-icon-padding: 5px !default;
$calendar-icon-button-margin: 8px !default;
$calendar-bigger-icon-padding: 0 !default;
$calendar-bigger-icon-margin: 12px !default;

//thead style
$calendar-thead-padding: 0 0 6px !default;

// common styles
$calendar-none-style: none !default;
$calendar-cursor-default-style: default !default;
$calendar-cursor-pointer-style: pointer !default;
$calendar-block-style: block !default;
$calendar-inline-block-style: inline-block !default;
$calendar-display-style: inline-block !default;
$calendar-pointer-events: initial !default;
$calendar-float-right-style: right !default;
$calendar-float-left-style: left !default;
$calendar-other-month-display-style: inline-block !default;
$calendar-other-month-row-display-style: none !default;
$calendar-footer-border: 1px solid $border-light !default;
$calendar-bigger-footer-border: 1px solid $border-light !default;
$calendar-week-number-font-style: italic !default;

//dimension
$calendar-date-font-size: $text-sm !default;
$calendar-bigger-day-font-size: $text-base !default;
$calendar-link-font-weight-style: $font-weight-normal !default;
$calendar-disable-font-weight-style: $font-weight-normal !default;
$calendar-border-radius: 4px !default;

//week header font size
$calendar-bigger-week-header-font-size: $text-sm !default;

//header color
$calendar-header-font-color: $content-text-color-alt1 !default;
$calendar-header-icon-color: $icon-color !default;
$calendar-title-font-color: $content-text-color-alt1 !default;
$calendar-icon-font-color: $content-text-color-alt1 !default;
$calendar-active-icon-color: $secondary-text-color !default;
$calendar-title-hover-color: $content-text-color-alt3 !default;
$calendar-icon-hover-color: $secondary-text-color !default;
$calendar-icon-hover-bg-color: $secondary-bg-color-hover !default;
$calendar-device-icon-hover-bg-color: $secondary-bg-color-hover !default;
$calendar-default-border-color: none !default;
$calendar-icon-hover-border-color: $content-bg-color-hover !default;
$calendar-active-state-icon-bg-color: $secondary-border-color-pressed !default;

//popup styles
$calendar-border-style: 1px solid $border-light !default;
$calendar-bg-color: $flyout-bg-color !default;
$calendar-box-shadow: none !default;

//  year decade view
//color
$calendar-yeardecade-bg-color: none !default;
$calendar-yeardecade-hover-bg: $content-bg-color-hover !default;
$calendar-yeardecade-selected-hover-bg: darken($primary-bg-color, 10%) !default;

//decade view color
$calendar-other-decade-cell-color: $content-text-color-alt2 !default;

// end year decade
//other months
$calendar-other-month-date: $content-text-color-alt3 !default;
$calendar-other-month-date-hover-bg: $calendar-other-month-date !default;

// td styles
//color
$calendar-light-font: $content-text-color-alt1 !default;
$calendar-active-font-color: $secondary-text-color !default;
$calendar-active-hover-font-color: $primary-text-pressed !default;
$calendar-active-today-font-color: $primary-text-pressed !default;
$calendar-active-today-hover-font-color: $primary-text-pressed !default;
$calendar-active-bg-color: $primary-bg-color !default;
$calendar-active-bg-box-shadow: inset 1px 0 $primary-bg-color, inset 0 1px $primary-bg-color, inset -1px 0 $primary-bg-color, inset 0 -1px $primary-bg-color !default;
$calendar-active-bg-border-color: none !default;
$calendar-active-hover-bg-color: darken($primary-bg-color, 10%) !default;
$calendar-hover-color: $flyout-bg-color-hover !default;
$calendar-text-color: $content-text-color !default;
$calendar-hover-text: $calendar-text-color !default;
$calendar-focus-bg-color: $content-bg-color-hover !default;
$calendar-focused-today-bg-style: $content-bg-color-hover !default;
$calendar-focused-today-box-shadow: inset 1px 0 $primary-bg-color, inset 0 1px $primary-bg-color, inset -1px 0 $primary-bg-color, inset 0 -1px $primary-bg-color !default;
$calendar-focused-today-border-style: none !default;
$calendar-focus-box-shadow: none !default;
$calendar-focus-border-color: none !default;
$calendar-hover-border-color: none !default;
$calendar-disable-font-color: $content-text-color-disabled !default;
$calendar-focused-date-bg-style: $calendar-hover-color !default;
$calendar-small-title-font-color: $content-text-color-alt1 !default;

// end td styles
//today date
$calendar-today-bg-style: none !default;
$calendar-today-bg-hover-color: $content-bg-color-alt1 !default;
$calendar-today-box-shadow: inset 2px 0 $primary-bg-color, inset 0 2px $primary-bg-color, inset -2px 0 $primary-bg-color, inset 0 -2px $primary-bg-color !default;
$calendar-today-border-color:  none !default;
$calendar-selected-border-color: none !default;

//pending
$calendar-today-color: $primary !default;
$calendar-today-focused-font-color: $content-text-color-alt1 !default;
$calendar-today-focus-color: $content-text-color-alt1 !default;

//week header
$calendar-week-header-font-color: $content-text-color-alt2 !default;
$calendar-week-header-bg-style: none !default;

//week number
$calendar-week-number-color-style: $content-text-color-disabled !default;
$calendar-week-number-font-color: $calendar-text-color !default;

//today button
$calendar-footer-background: $transparent !default;
$calendar-today-disabled-background-style: $primary-bg-color-disabled !default;
$calendar-today-disabled-border-style: $primary-border-color-disabled !default;
$calendar-today-disabled-box-shadow: none !default;
$calendar-today-disabled-color: $primary-text-disabled !default;

//today button flat button override style
$today-button-bg: $primary-bg-color !default;
$today-button-bg-border-color: $primary-border-color !default;
$today-button-text-color: $primary-text-color !default;
$today-button-hover-bg: $primary-bg-color-hover !default;
$today-button-bg-border-hover-color: $primary-border-color-hover !default;
$today-button-text-hover-color: $primary-text-hover !default;
$today-button-active-bg: $primary-bg-color-pressed !default;
$today-button-bg-border-active-color: $primary-border-color-pressed !default;
$today-button-text-active-color: $primary-text-pressed !default;
