@include export-module('calendar-theme') {
  #{&}.e-calendar,
  .e-bigger.e-small #{&}.e-calendar {
    @if $skin-name == 'Material3' {
      background: $calendar-bg-color;
      border-radius: 8px;
    }
    @if $skin-name != 'Material3' {
      background-color: $calendar-bg-color;
    }
    border: $calendar-border-style;
    box-shadow: $calendar-box-shadow;
    #{if(&, '&', '*')} .e-date-icon-prev,
    #{if(&, '&', '*')} .e-date-icon-next {
      color: $calendar-header-icon-color;
    }
    #{if(&, '&', '*')} th {
      border-bottom: 0;
      color: $calendar-week-header-font-color;
    }
    @at-root {
      #{if(&, '&', '*')} .e-header {
        border-bottom: 0;
        #{if(&, '&', '*')} a {
          #{if(&, '&', '*')} span {
            border: $calendar-default-border-color;
            color: $calendar-icon-font-color;
          }
        }
        #{if(&, '&', '*')} .e-title {
          color: $calendar-title-font-color;
        }
        #{if(&, '&', '*')} .e-title:hover {
          color: $calendar-title-hover-color;
          cursor: pointer;
          text-decoration: $calendar-title-decoration-style;
        }

        #{if(&, '&', '*')} .e-prev:hover > span,
        #{if(&, '&', '*')} .e-next:hover > span {
          border: $calendar-icon-hover-border-color;
          color: $calendar-icon-hover-color;
          cursor: pointer;
        }

        #{if(&, '&', '*')} .e-prev:hover,
        #{if(&, '&', '*')} .e-next:hover {
          background: $calendar-icon-hover-bg-color;
        }

        #{if(&, '&', '*')} .e-prev:active,
        #{if(&, '&', '*')} .e-next:active {
          background: $calendar-active-state-icon-bg-color;
          color: $calendar-active-font-color;
        }
        #{if(&, '&', '*')} button.e-prev:active span,
        #{if(&, '&', '*')} button.e-next:active span {
          border: $calendar-selected-border-color;
          color: $calendar-active-icon-color;
        }
        #{if(&, '&', '*')}.e-decade .e-title {
          color: $calendar-light-font;
          cursor: default;
        }
        #{if(&, '&', '*')} .e-next.e-disabled span,
        #{if(&, '&', '*')} .e-prev.e-disabled span {
          color: $calendar-disable-font-color;
          font-weight: $calendar-disable-font-weight-style;
        }
        #{if(&, '&', '*')} .e-next.e-disabled,
        #{if(&, '&', '*')} .e-prev.e-disabled {
          opacity: $calendar-disable-opacity;
        }
      }
      #{if(&, '&', '*')} .e-content {
        #{if(&, '&', '*')}.e-decade tr:first-child .e-cell:first-child span.e-day,
        #{if(&, '&', '*')}.e-decade tr:last-child .e-cell:last-child span.e-day {
          color: $calendar-other-decade-cell-color;
        }
        #{if(&, '&', '*')}.e-decade tr:first-child .e-cell:first-child.e-selected span.e-day,
        #{if(&, '&', '*')}.e-decade tr:last-child .e-cell:last-child.e-selected span.e-day {
          color: $calendar-active-font-color;
        }
        #{if(&, '&', '*')}.e-decade tr:first-child .e-cell.e-disabled:first-child span.e-day,
        #{if(&, '&', '*')}.e-decade tr:last-child .e-cell.e-disabled:last-child span.e-day {
          color: $calendar-disable-font-color;
        }
        #{if(&, '&', '*')}.e-year td:hover span.e-day,
        #{if(&, '&', '*')}.e-decade td:hover span.e-day {
          @if $skin-name != 'Material3' {
            background-color: $calendar-hover-color;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-hover-color;
          }
        }
        #{if(&, '&', '*')}.e-year td.e-selected:hover span.e-day,
        #{if(&, '&', '*')}.e-decade td.e-selected:hover span.e-day {
          @if $skin-name != 'Material3' {
            background-color: $calendar-yeardecade-selected-hover-bg;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-yeardecade-selected-hover-bg;
          }
        }

        #{if(&, '&', '*')}.e-year td > span.e-day,
        #{if(&, '&', '*')}.e-decade td > span.e-day {
          background: $calendar-yeardecade-bg-color;
        }

        #{if(&, '&', '*')} .e-week-number span {
          color: $calendar-week-number-color-style;
        }

        #{if(&, '&', '*')} td.e-focused-date span.e-day,
        #{if(&, '&', '*')} td.e-focused-date:hover span.e-day,
        #{if(&, '&', '*')} td.e-focused-date:focus span.e-day {
          background: $calendar-focused-date-bg-style;
          border: $calendar-focus-border-color;
          border-radius: $calendar-border-radius;
          box-shadow: $calendar-focus-box-shadow;
          @if ($skin-name == 'FluentUI') {
            background: none;
            border-radius: $calendar-focused-state-border-radius;
            box-shadow: $calendar-focused-state-box-shadow;
          }
        }
        #{if(&, '&', '*')} td.e-focused-date:hover span.e-day {
          @if $skin-name != 'Material3' {
            background-color: $calendar-hover-color;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-hover-color;
          }
          border: $calendar-hover-border-color;
          border-radius: $calendar-border-radius;
          color: $calendar-text-color;
        }
        #{if(&, '&', '*')} td.e-today span.e-day,
        #{if(&, '&', '*')} td.e-focused-date.e-today span.e-day {
          background: $calendar-today-bg-style;
          border: $calendar-today-border-color;
          border-radius: $calendar-border-radius;
          box-shadow: $calendar-today-box-shadow;
          color: $calendar-today-color;
          @if ($skin-name == 'FluentUI') {
            border-radius: $calendar-border-selected-radius;
          }
        }
        #{if(&, '&', '*')} td.e-focused-date.e-today span.e-day {
          background: $calendar-focused-today-bg-style;
          border: $calendar-focused-today-border-style;
          box-shadow: $calendar-focused-today-box-shadow;
          color: $calendar-today-focused-font-color;
        }
        #{if(&, '&', '*')} td.e-today:focus span.e-day,
        #{if(&, '&', '*')} td.e-focused-date.e-today:focus span.e-day {
          @if $skin-name != 'Material3' {
            background-color: $calendar-focus-bg-color;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-focus-bg-color;
          }
          border: $calendar-focus-border-color;
          border-radius: $calendar-border-radius;
          color: $calendar-today-focus-color;
          @if ($skin-name == 'FluentUI') {
            background-color: $calendar-focused-today-bg-style;
            border-radius: $calendar-border-selected-radius;
          }
        }
        #{if(&, '&', '*')} td.e-today:hover span.e-day,
        #{if(&, '&', '*')} td.e-focused-date.e-today:hover span.e-day,
        #{if(&, '&', '*')} td.e-focused-date.e-today:focus span.e-day {
          @if $skin-name != 'Material3' {
            background-color: $calendar-hover-color;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-hover-color;
          }
          border: $calendar-today-border-color;
          color: $calendar-today-focus-color;
          @if ($skin-name == 'FluentUI') {
            background-color: $calendar-today-bg-style;
            border-radius: $calendar-border-selected-radius;
          }
        }
        #{if(&, '&', '*')} td.e-today.e-selected span.e-day {
          @if $skin-name != 'Material3' {
            background-color: $calendar-active-bg-color;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-active-bg-color;
          }
          border: $calendar-active-bg-border-color;
          @if ($skin-name == 'material' or $skin-name == 'material-dark' or $skin-name == 'Material3') {
            box-shadow: $calendar-selected-box-shadow;
          }
          @else {
            box-shadow: $calendar-active-bg-box-shadow;
          }
          color: $calendar-active-today-font-color;
        }
        #{if(&, '&', '*')} td.e-today.e-selected:hover span.e-day,
        #{if(&, '&', '*')} td.e-selected:hover span.e-day,
        #{if(&, '&', '*')} td.e-selected.e-focused-date span.e-day {
          @if $skin-name != 'Material3' {
            background-color: $calendar-active-hover-bg-color;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-active-hover-bg-color;
          }
          color: $calendar-active-today-hover-font-color;
          @if ($skin-name == 'FluentUI') {
            background-color: $calendar-focused-today-bg-style;
            border-radius: $calendar-border-selected-radius;
            color: $calendar-today-color;
          }
        }
        #{if(&, '&', '*')} span {
          color: $calendar-text-color;
        }
        #{if(&, '&', '*')} .e-disabled span.e-day:hover {
          background: $calendar-none-style;
          border: 0;
          color: $calendar-disable-font-color;
        }
        #{if(&, '&', '*')} .e-other-month:hover span.e-day {
          @if ($skin-name != 'fabric-dark') {
            color: $calendar-other-month-date-hover-bg;
          }
        }
        #{if(&, '&', '*')} .e-other-month span.e-day,
        #{if(&, '&', '*')} .e-other-month.e-today span.e-day {
          color: $calendar-other-month-date;
        }
        #{if(&, '&', '*')} .e-other-month.e-today:hover span.e-day {
          @if $skin-name != 'Material3' {
            background-color: $calendar-hover-color;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-hover-color;
          }
          color: $calendar-other-month-date;
          @if ($skin-name == 'FluentUI') {
            background: $calendar-today-bg-style;
            border: $calendar-today-border-color;
            border-radius: $calendar-border-selected-radius;
            box-shadow: $calendar-today-box-shadow;
            color: $calendar-today-color;
          }
        }
        #{if(&, '&', '*')} thead {
          background: $calendar-week-header-bg-style;
          border-bottom: 0;
        }
        #{if(&, '&', '*')} td:hover span.e-day,
        #{if(&, '&', '*')} td:focus span.e-day {
          @if $skin-name != 'Material3' {
            background-color: $calendar-hover-color;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-hover-color;
          }
          border: $calendar-hover-border-color;
          border-radius: $calendar-border-radius;
          color: $calendar-hover-text;
        }
        #{if(&, '&', '*')} td:focus span.e-day {
          @if $skin-name != 'Material3' {
            background-color: $calendar-focus-bg-color;
            color: $calendar-text-color;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-focus-bg-color;
            color: $calendar-text-color-focus;
          }
          border: $calendar-focus-border-color;
          border-radius: $calendar-border-radius;
          @if ($skin-name == 'FluentUI') {
            border-radius: $calendar-border-selected-radius;
          }
        }
        #{if(&, '&', '*')} td.e-disabled span.e-day,
        #{if(&, '&', '*')} td.e-disabled:hover span.e-day,
        #{if(&, '&', '*')} td.e-disabled:focus span.e-day {
          background: $calendar-none-style;
          border: $calendar-none-style;
          color: $calendar-disable-font-color;
        }
        #{if(&, '&', '*')} td.e-today.e-disabled.e-overlay span.e-day,
        #{if(&, '&', '*')} td.e-today.e-disabled.e-overlay:hover span.e-day,
        #{if(&, '&', '*')} td.e-today.e-disabled.e-overlay:focus span.e-day {
          @if ($skin-name == 'FluentUI') {
            background-color: $calendar-focused-today-bg-style;
            border-radius: $calendar-border-selected-radius;
            color: $calendar-today-color;
          }
        }
        #{if(&, '&', '*')} td.e-selected span.e-day {
          @if $skin-name != 'Material3' {
            background-color: $calendar-active-bg-color;
          }
          @if $skin-name == 'Material3' {
            background: $calendar-active-bg-color;
          }
          border: $calendar-selected-border-color;
          border-radius: $calendar-border-radius;
          color: $calendar-active-font-color;
        }
        #{if(&, '&', '*')} td.e-selected:not(.e-focused-date) span.e-day {
          @if ($skin-name == 'FluentUI') {
            box-shadow: $calendar-selected-box-shadow;
          }
        }
        #{if(&, '&', '*')} .e-footer {
          color: $calendar-active-bg-color;
        }
        #{if(&, '&', '*')} td.e-today.e-selected.e-focused-date span.e-day,
        #{if(&, '&', '*')} td.e-today.e-selected:hover span.e-day,
        #{if(&, '&', '*')} td.e-today.e-selected span.e-day {
          @if ($skin-name == 'FluentUI') {
            background-color: $calendar-focused-today-bg-style;
            border-radius: $calendar-border-selected-radius;
            color: $calendar-today-color;
          }
        }
        #{if(&, '&', '*')} td.e-today.e-selected.e-focused-date {
          @if ($skin-name == 'FluentUI') {
            background-color: $calendar-active-bg-color;
            border-radius: $calendar-focused-state-border-radius;
            box-shadow: $calendar-focused-state-box-shadow;
          }
        }
        #{if(&, '&', '*')} td.e-today.e-selected {
          @if ($skin-name == 'FluentUI') {
            background-color: $calendar-active-bg-color;
            border-radius: $calendar-wrapper-border-radius;
            box-shadow: $calendar-selected-box-shadow;
          }
        }
        #{if(&, '&', '*')} td.e-selected:hover span.e-day {
          @if ($skin-name == 'FluentUI') {
            background-color: $calendar-active-bg-color;
            border: $calendar-selected-border-color;
            border-radius: $calendar-border-radius;
            color: $calendar-active-font-color;
          }
        }
        #{if(&, '&', '*')} td.e-selected.e-focused-date span.e-day {
          @if ($skin-name == 'FluentUI') {
            background-color: $calendar-active-bg-color;
            border: $calendar-selected-border-color;
            border-radius: $calendar-focused-state-border-radius;
            color: $calendar-active-font-color;
          }
        }
        #{if(&, '&', '*')}.e-month td.e-today span.e-day {
          @if ($skin-name == 'FluentUI') {
            color: $calendar-today-focused-font-color;
          }
        }
        #{if(&, '&', '*')}.e-year td.e-selected > span.e-day,
        #{if(&, '&', '*')}.e-decade td.e-selected > span.e-day,
        #{if(&, '&', '*')}.e-year td.e-selected:hover > span.e-day,
        #{if(&, '&', '*')}.e-decade td.e-selected:hover > span.e-day {
          @if ($skin-name == 'FluentUI') {
            background-color: $calendar-selected-month-bg-color;
            color: $calendar-week-header-font-color;
          }
        }
        #{if(&, '&', '*')}.e-year td.e-focused-date > span.e-day,
        #{if(&, '&', '*')}.e-decade td.e-focused-date > span.e-day {
          @if ($skin-name == 'FluentUI') {
            background: $calendar-none-style;
            border-radius: 0;
            box-shadow: $calendar-focused-state-month-box-shadow;
          }
        }
        #{if(&, '&', '*')} td.e-focused-date.e-today {
          @if ($skin-name == 'FluentUI') {
            border-radius: $calendar-focused-state-border-radius;
            box-shadow: $calendar-focused-state-box-shadow;
          }
        }
        #{if(&, '&', '*')} td.e-focused-date:hover span.e-day {
          @if ($skin-name == 'FluentUI') {
            border-radius: $calendar-focused-state-border-radius;
          }
        }
        #{if(&, '&', '*')}.e-year td.e-focused-date:hover > span.e-day,
        #{if(&, '&', '*')}.e-decade td.e-focused-date:hover > span.e-day {
          @if ($skin-name == 'FluentUI') {
            background: $calendar-hover-color;
            border-radius: 0;
            box-shadow: $calendar-focused-state-month-box-shadow;
          }
        }
      }
    }
    #{if(&, '&', '*')}.e-device {
      #{if(&, '&', '*')} .e-prev:hover,
      #{if(&, '&', '*')} .e-next:hover,
      #{if(&, '&', '*')} .e-prev:active,
      #{if(&, '&', '*')} .e-next:active,
      #{if(&, '&', '*')} .e-prev:focus,
      #{if(&, '&', '*')} .e-next:focus {
        background: $calendar-device-icon-hover-bg-color;
      }
      #{if(&, '&', '*')} button.e-prev:active span,
      #{if(&, '&', '*')} button.e-next:active span {
        color: $calendar-header-icon-color;
      }
    }

    #{if(&, '&', '*')} .e-footer-container {
      #{if(&, '&', '*')} .e-btn.e-today.e-flat.e-primary:not(:hover) {
        @if $skin-name == 'tailwind' {
          /* stylelint-disable property-no-vendor-prefix */
          -webkit-tap-highlight-color: transparent;
          background-color: $calendar-today-btn-primary-bgcolor;
          border-color: $calendar-today-btn-primary-border-color;
          color: $calendar-today-btn-primary-color;
        }
      }

      #{if(&, '&', '*')} .e-btn.e-today.e-flat.e-primary.e-disabled {
        @if $skin-name == 'tailwind' {
          -webkit-tap-highlight-color: transparent;
          background-color: $calendar-today-btn-primary-disabled-bgcolor;
          border-color: $calendar-today-btn-primary-disabled-border-color;
          color: $calendar-today-btn-primary-disabled-color;
        }
      }
    }
  }

  .e-small#{&}.e-calendar,
  .e-small #{&}.e-calendar {
    #{if(&, '&', '*')} .e-header {
      #{if(&, '&', '*')} .e-title {
        color: $calendar-small-title-font-color;
      }
    }
  }

  #{&}.e-calendar.e-disabled {
    #{if(&, '&', '*')} .e-footer-container {
      #{if(&, '&', '*')} .e-btn.e-today.e-flat.e-primary {
        @if $skin-name == 'tailwind' {
          -webkit-tap-highlight-color: transparent;
          background-color: $calendar-today-btn-primary-disabled-bgcolor;
          border-color: $calendar-today-btn-primary-disabled-border-color;
          color: $calendar-today-btn-primary-disabled-color;
        }
      }
    }
  }

  .e-zoomin {
    animation: animatezoom .3s;
  }

  @keyframes animatezoom {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }

  .e-calendar .e-btn.e-today.e-flat.e-primary,
  .e-calendar .e-css.e-btn.e-today.e-flat.e-primary {
    @if $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' {
      background-color: $today-button-bg;
      border: 0;
      border-color: $today-button-bg-border-color;
      color: $today-button-text-color;
    }
  }

  .e-calendar .e-btn.e-today.e-flat.e-primary:hover,
  .e-calendar .e-css.e-btn.e-today.e-flat.e-primary:hover {
    @if $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' {
      background-color: $today-button-hover-bg;
      border-color: $today-button-bg-border-hover-color;
      color: $today-button-text-hover-color;
    }
  }

  .e-calendar .e-btn.e-today.e-flat.e-primary:active,
  .e-calendar .e-btn.e-today.e-flat.e-primary.e-active,
  .e-calendar .e-css.e-btn.e-today.e-flat.e-primary:active,
  .e-calendar .e-css.e-btn.e-today.e-flat.e-primary.e-active {
    @if $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' {
      background-color: $today-button-active-bg;
      border-color: $today-button-bg-border-active-color;
      color: $today-button-text-active-color;
    }
    @if $skin-name == 'FluentUI' {
      border: $calendar-none-style;
      outline: $calendar-none-style;
    }
  }

  .e-calendar .e-btn.e-today.e-flat.e-primary:focus,
  .e-calendar .e-btn.e-today.e-flat.e-primary.e-focus,
  .e-calendar .e-css.e-btn.e-today.e-flat.e-primary:focus,
  .e-calendar .e-css.e-btn.e-today.e-flat.e-primary.e-focus {
    @if $skin-name == 'FluentUI' {
      background-color: $today-button-active-bg;
      border: $calendar-none-style;
      border-color: $today-button-bg-border-active-color;
      color: $today-button-text-active-color;
      outline: $calendar-none-style;
    }
  }

  .e-calendar .e-btn.e-today.e-flat.e-primary.e-disabled,
  .e-calendar .e-btn.e-today.e-flat.e-primary.e-disabled,
  .e-calendar .e-css.e-btn.e-today.e-flat.e-primary.e-disabled,
  .e-calendar .e-css.e-btn.e-today.e-flat.e-primary.e-disabled {
    @if $skin-name == 'bootstrap5' {
      background-color: $calendar-today-disabled-background-style;
      border-color: $calendar-today-disabled-border-style;
    }
    @if $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' {
      color: $calendar-today-disabled-color;
    }
  }
}
