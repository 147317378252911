// uploader bootstrap theme
$zero-value: 0 !default;
$upload-root-font-family: $font-family !default;
$upload-element-opacity: $zero-value !default;
$upload-element-width: $zero-value !default;
$select-file-margin: $zero-value !default;
$select-file-padding: $zero-value !default;
$list-items-font-size: $text-sm !default;
$list-item-height: 35px !default;
$list-item-margin-right: 90px !default;
$list-item-name-width: 75% !default;
$progress-bar-wrapper-height: 4px !default;
$progress-bar-height: 90% !default;
$delete-icon-opacity: 1 !default;
$float-right: right !default;
$float-left: left !default;
$right: 100% !default;
$file-status-rtl-top-margin: 7% !default;
$remove-icon-height: 20px !default;
$remove-icon-width: 20px !default;
$remove-bigger-icon-height: 24px !default;
$remove-bigger-icon-width: 24px !default;
$remove-icon-before: 4px !default;
$file-size-top: $zero-value !default;
$action-buttons-margin: 10px !default;
$file-status-rtl: 30px !default;
$progress-inner-wrap-height: 4px !default;
$progress-inner-wrap-top: 3px !default;
$progress-bar-wrap-top: 34px !default;
$file-status-top-bigger: 35px !default;
$file-drop-rtl-smaller: -10px !default;
$file-drop-rtl-bigger: -10px !default;
$footer-height: 50px !default;
$footer-height-smaller: 30px !default;
$footer-buttons-height: 46px !default;
$footer-buttons-height-bigger: 62px !default;
$file-name-top: $zero-value !default;
$file-container-top-bigger: $zero-value !default;
$remove-icon-rtl: 20px !default;
$reload-btn-right: 36px !default;
$icons-small-ie-padding: 18px 13px 18px 23px !default;
$icons-bigger-ie-padding: 20px 17px 20px 26px !default;

// Smaller values
$list-items-font-size-smaller: $text-sm !default;
$list-items-height-smaller: 60px !default;
$list-item-status-margin-smaller: 8px !default;
$progress-bar-top-smaller: $zero-value !default;
$progress-bar-wrapper-height-smaller: 10px !default;
$file-status-top-smaller: 19px !default;
$list-item-status-top-smaller: 10px !default;
$file-size-top-smaller: 10px !default;
$file-size-left-smaller: 10px !default;
$file-type-top-smaller: $zero-value !default;
$file-size-rtl-right: 10px !default;
$list-item-size-top: 30px !default;
$file-container-height: 100% !default;
$progress-bar-paddding: 6px 6px !default;
$remove-icon-before-bigger: 15px !default;

// Smaller RTL values
$file-status-rtl-smaller: 21px !default;
$progress-bar-wrap-top-smaller: 23px !default;
$rtl-progress-left-smaller: -23px !default;
$remove-icon-top-bigger: 10px !default;
$file-container-top: $zero-value !default;

// themes files
$progress-bar-transition: width 2s !default;
$progress-text-rtl-smaller: 0 !default;
$pause-play-button-right-value: 36px !default;
$pause-play-button-right-value-bigger: 45px !default;
$pause-play-button-right-value-bigger-rtl: 41px !default;
$file-container-left: 60px !default;
$ie-icons-position-value: 10px !default;
$ie-abort-icon-position-value: 12px !default;

$upload-font-family: inherit !default;
$drop-area-font-size-smaller: $text-sm !default;
$drop-area-font-size: $text-base !default;
$drop-zone-margin: 3px !default;
$header-padding: 12px 0 12px 12px !default;
$drop-area-padding-left: 16px !default;
$drop-area-padding-left-smaller: 12px !default;
$file-name-font-size-smaller: $text-sm !default;
$file-name-font-size: $text-base !default;
$list-item-status-font-size-smaller: $text-xs !default;
$list-item-status-font-size: $text-xs !default;
$list-item-status-bigger-font-size: $text-sm !default;
$list-item-margin-left-smaller: 12px !default;
$list-item-margin-left: 15px !default;
$list-line-height: 22px !default;
$remove-icon-margin: 15px !default;
$remove-icon-margin-smaller: 15px !default;
$action-buttons-margin-smaller: 8px !default;
$footer-buttons-margin: 15px !default;
$delete-icon-padding-smaller: 15px !default;
$delete-icon-padding: 24px !default;
$remove-icon-right: $zero-value !default;
$remove-icon-top-smaller: 50% !default;
$remove-icon-before-left: 9px !default;
$remove-icon-before-top: 9px !default;
$remove-icon-margin-top: -24px !default;
$file-name-padding-top-smaller: 12px !default;
$file-name-padding-top: 15px !default;
$file-size-padding: 10px $zero-value !default;
$file-size-padding-smaller: $zero-value !default;
$progress-bar-wrapper-width: 95% !default;
$progress-bar-top-paddding: 6px !default;
$progress-bar-bottom-paddding: 11px !default;
$progress-bar-text-right-smaller: $zero-value !default;
$progress-bar-text-top-smaller: -33px !default;
$li-min-height: 82px !default;
$inner-wrap-radius: 4px !default;
$progress-bar-radius: 4px !default;
$progress-inner-wrap-height-smaller: 4px !default;
$progress-bar-height-smaller: 4px !default;
$remove-icon-before-left-bigger: 12px !default;
$remove-icon-before-top-bigger: 12px !default;
$list-items-height: 108px !default;
$progress-bar-text-right: 16px !default;
$progress-bar-text-top: -36px !default;
$progress-bar-wrapper-width-bigger: 95% !default;
$header-padding-rtl-bigger: 20px 15px 20px $zero-value !default;
$header-padding-bigger: 16px 0 16px 16px !default;
$margin-rtl-header: $zero-value !default;
$margin-rtl: 15px !default;
$remove-icon-rtl-bigger: $zero-value !default;
$progress-bar-width-rtl: 86% !default;
$header-padding-rtl-smaller: 16px 12px 16px $zero-value !default;
$margin-rtl-header-smaller: $zero-value !default;
$margin-rtl-smaller: 11px !default;
$left: $zero-value !default;
$rtl-progress-top: -32px !default;
$remove-icon-rtl-top-smaller: 50% !default;
$file-name-font-family: inherit !default;
$remove-icon-top: 50% !default;
$remove-icon-font-size-smaller: $font-icon-18 !default;
$remove-icon-font-size: $font-icon-22 !default;
$delete-icon-font-size: $font-icon-22 !default;
$abort-icon-font-size: $font-icon-22 !default;
$delete-icon-font-size-smaller: $font-icon-18 !default;
$abort-icon-font-size-smaller: $font-icon-18 !default;
$upload-border-radius: 6px !default;
$remove-icon-padding-smaller: 10px !default;
$remove-icon-margin-top-smaller: -9px !default;
$uploader-icons-hover-radius: 50% !default;

// mouse small
$header-padding-small: 12px 0 12px 12px !default;
$drop-area-small-font-size: $text-xs !default;
$file-small-name-font-size: $text-xs !default;
$file-small-name-padding-top: 12px !default;
$list-small-item-status-font-size: $text-xs !default;
$file-small-size-padding: 6px 0 !default;
$remove-small-icon-font-size: $text-xxs !default;
$remove-small-icon-height: 24px !default;
$delete-small-icon-padding: 12px !default;
$remove-small-icon-width: 24px !default;
$progress-bar-bottom-paddding-small: 2px !default;
$drop-small-area-padding-left: 10px !default;
$li-min-height-small: 79px !default;
$header-padding-rtl-small: 12px 12px 12px 0 !default;

// form upload
$form-upload-file-name-line-height: 1.5 !default;
$form-upload-file-list-height: $list-item-height !default;
$form-upload-file-list-padding-top: $file-name-padding-top - 4 !default;
$form-upload-file-list-padding-bottom: $file-name-padding-top !default;
$form-upload-file-name-padding: 4px !default;
$form-upload-file-status-font-size: $list-item-status-font-size-smaller !default;
$form-upload-remove-icon-top: 42px !default;
$form-upload-bigger-remove-icon-top: 38px !default;
$form-upload-small-remove-icon-top: 42px !default;

// form upload - bigger
$form-upload-bigger-file-name-line-height: 1.5 !default;
$form-upload-bigger-file-list-height: $list-item-height !default;
$form-upload-bigger-file-list-padding-top: $file-name-padding-top - 4 !default;
$form-upload-bigger-file-list-padding-bottom: $file-name-padding-top !default;
$form-upload-bigger-file-name-padding: 4px !default;
$form-upload-bigger-file-status-font-size: $list-item-status-font-size !default;

// form upload - small
$form-upload-small-file-name-line-height: 1.5 !default;
$form-upload-small-file-list-height: $list-item-height !default;
$form-upload-small-file-list-padding-top: $file-small-name-padding-top - 4 !default;
$form-upload-small-file-list-padding-bottom: $file-small-name-padding-top !default;
$form-upload-small-file-name-padding: 4px !default;
$form-upload-small-file-status-font-size: $list-item-status-font-size-smaller !default;

// color

$remove-icon-color: $icon-color !default;
$upload-hover-icon-color: $icon-color-hover !default;
$disabled-state: $icon-color-disabled !default;
$remove-icon-disabled-color:  $zero-value !default;
$drop-text-color: $content-text-color !default;
$file-drop-text-color: $content-text-color-alt2 !default;
$progress-text-color: $content-text-color-alt3 !default;
$file-size-color: $content-text-color-alt3 !default;
$upload-success-color: $success !default;
$upload-failure-color: $danger !default;
$upload-in-progress-color: $primary !default;
$progress-background-color: $content-bg-color-alt3 !default;
$progress-text-color: $content-text-color-alt2 !default;
$clear-icon-focus-color: rgba($black, .12) !default;
$list-bottom-border: 1px solid $border-light !default;
$upload-border: 1px dashed $border-dark !default;
$drag-hover-border: 1px dashed $primary !default;

.e-upload .e-upload-actions,
.e-bigger.e-small .e-upload .e-upload-actions {
  background-color: $content-bg-color-alt1;
  border-radius: 0 0 6px 6px;
}
