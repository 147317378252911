.darkmode:root {
    &,
    ::before,
    ::after {
        --background: 24, 26, 27;
        --background-accent: 31, 34, 35;
        --text: 255, 255, 255;
    }
}

.darkmode:root {
    body {
        background-color: var(--background-color);
        color: var(--text-color);
    }

    cerrix-workspace {
        .table-body {
            .table.workspace-table {
                color: var(--text-color);

                thead tr th {
                    background-color: var(--background-color) !important;
                }

                tbody tr {
                    &:hover {
                        color: var(--text-color);
                    }

                    &:not(.highlighted) td {
                        background-color: var(--background-accent-color) !important;
                    }

                    td {
                        border-color: var(--background-color) !important;
                    }
                }
            }
        }
    }

    .detail-page-card-body {
        box-shadow: 0px 0px 5px 0px black;

        .item {
            background-color: var(--background-color) !important;

            &:nth-child(even) {
                background-color: var(--background-accent-color) !important;
            }
        }
    }

    gridster-item {
        background: var(--background-color) !important;
        color: var(--text-color) !important;
        border-color: var(--background-accent-color) !important;

        .title {
            border-color: var(--background-accent-color) !important;
        }
    }

    .dropdown-menu {
        background: var(--background-color) !important;
        color: var(--text-color) !important;

        .dropdown-item {
            color: var(--text-color) !important;

            &:hover {
                background: var(--background-accent-color) !important;
            }
        }

        .dropdown-divider {
            border-top-color: var(--text-color);
        }
    }

    .form-control,
    .form-select {
        color: var(--text-color);
        background-color: var(--background-color);
        border-color: var(--background-accent-color);

        &:disabled,
        &[readonly] {
            color: rgba(var(--text), 0.8);
            background-color: var(--background-accent-color);
            border-color: var(--background-accent-color);
        }
    }

    ng-select {
        .ng-select-container {
            color: var(--text-color);
            background-color: var(--background-color);
            border-color: var(--background-accent-color);
        }

        ng-dropdown-panel {
            border-color: var(--background-accent-color);

            .ng-dropdown-panel-items {
                .ng-option {
                    background-color: var(--background-color);
                    color: var(--text-color);

                    &.ng-option-selected {
                        background-color: var(--accent-color);
                    }

                    &.ng-option-marked:not(.ng-option-selected) {
                        background-color: var(--background-accent-color);
                    }
                }
            }
        }
    }

    .bs-datepicker {
        background: var(--background-color);

        .bs-datepicker-body table td {
            color: var(--text-color);

            span.is-highlighted:not(.disabled):not(.selected) {
                background-color: var(--accent-color);
            }

            span.is-other-month {
                background-color: transparent !important;
                color: var(--text-color);
            }
        }
    }

    button,
    .btn {
        color: var(--text-color);
        border-color: var(--background-accent-color);

        &:hover {
            background: var(--background-accent-color);
        }

        &[disabled] {
            pointer-events: none;
        }
    }

    .card {
        background-color: var(--background-accent-color);
    }

    .mat-mdc-dialog-container {
        background: var(--background-accent-color);
        color: var(--text-color);

        .mdc-tab__text-label,
        .mat-mdc-tab-link,
        .mat-mdc-select-placeholder,
        .mat-form-field-appearance-legacy .mat-mdc-form-field-label {
            color: var(--text-color);
        }
    }

    .modal-dialog {
        .modal-content {
            background: var(--background-color);
            color: var(--text-color);
        }

        .modal-header {
            border-bottom-color: var(--background-accent-color);
        }

        .modal-footer {
            border-top-color: var(--background-accent-color);
        }
    }

    .list-group {
        .list-group-item {
            color: var(--text-color);
            background-color: transparent;
            border-color: var(--background-color);
        }
    }

    .mat-expansion-panel {
        background-color: var(--background-accent-color);

        .mat-expansion-panel-header-title {
            color: var(--text-color);
            font-weight: bold;
        }

        .mat-expansion-panel-header-description,
        .mat-expansion-panel-body,
        .mat-expansion-panel-body table {
            color: var(--text-color);
        }

        .mat-expansion-indicator:after {
            color: var(--text-color);
        }
    }

    .mat-mdc-tab-labels .mdc-tab__text-label {
        color: var(--text-color);
    }

    .mat-mdc-table {
        background-color: var(--background-accent-color);

        .mat-mdc-header-cell,
        .mat-mdc-cell {
            color: var(--text-color);
        }
    }

    .highcharts-container {
        color: red;

        .highcharts-background,
        .highcharts-button rect {
            fill: var(--background-color);
        }

        text {
            fill: var(--text-color) !important;
        }

        .highcharts-tooltip span {
            color: var(--text-color);
        }

        .highcharts-menu {
            background: var(--background-color) !important;
        }

        .highcharts-subtitle,
        .highcharts-credits,
        .highcharts-axis-title {
            fill-opacity: 0.7;
        }
        .highcharts-grid-line {
            stroke: var(--text-color);
            stroke-opacity: 0.2;
        }
        .highcharts-tooltip-box {
            fill: var(--background-color);
        }
        .highcharts-column-series rect.highcharts-point {
            stroke: var(--background-color);
        }

        .highcharts-data-label {
            .highcharts-text-outline {
                display: none;
            }
        }
    }

    hr {
        background-color: var(--background-accent-color);
        opacity: 1;
    }

    .darkmode-icon {
        color: var(--text-color) !important;
    }

    // Component specific styling.

    .cerrix-select-tree {
        .multi-select-actions {
            border-bottom-color: var(--background-accent-color);
        }
    }

    .custom-pagination {
        background-color: var(--background-accent-color);
    }

    .manual-body {
        pre.example-block {
            background: var(--background-accent-color);
        }
    }

    dashboard-widget link-widget .quick-link:hover {
        box-shadow: 0px 0px 5px 0px black;
    }

    #process-editor {
        background: var(--background-color);

        .editor {
            .graph {
                background: #fbfbfb;
            }
        }

        #process-menu,
        #toolbar {
            li:not(.vertical-ruler):hover {
                background-color: var(--background-accent-color);
            }
        }

        #properties {
            .mdc-tab__text-label {
                color: var(--text-color);
            }

            #properties-sidebar {
                generic-field-editor {
                    label {
                        color: var(--text-color);
                    }
                }
            }
        }

        #shapes-sidebar {
            .panel {
                background-color: var(--background-color);

                > .header:hover {
                    background-color: var(--background-accent-color);
                }

                .shape {
                    background: var(--text-color);
                    margin: 5px 7px;
                    cursor: pointer;

                    &:hover {
                        background-color: var(--background-accent-color);
                    }
                }
            }
        }

        #contextmenu {
            background: var(--background-color);
        }

        .footer {
            background: var(--background-color) !important;
        }
    }
}
