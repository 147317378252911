//layout variables
$progress-btn-circle-opacity: .3 !default;
$progress-btn-transition: all .3s linear !default;
$progress-btn-spinner-padding: 12px !default;
$progress-btn-spinner-padding-small: 10px !default;
$progress-btn-spinner-padding-bigger: 14px !default;
$progress-btn-spinner-padding-bigger-small: 12px !default;
$progress-btn-spin-btn-padding: 16px !default;
$progress-btn-small-spin-btn-padding: 14px !default;
$progress-btn-bigger-spin-btn-padding: 20px !default;
$progress-btn-bigger-small-spin-btn-padding: 16px !default;
$progress-btn-color: $secondary-text-color !default;
$progress-btn-bgcolor: rgba($icon-color, .2) !default;
$progress-btn-bgcolor-normal: rgba($white, .2) !default;
$progress-btn-warning-progress-color: $progress-btn-bgcolor !default;
$progress-btn-flat-primary-progress-color: rgba($primary-text-color, 1) !default;
$progress-btn-flat-success-progress-color: rgba($success-text, 1) !default;
$progress-btn-flat-info-progress-color: rgba($info-text, 1) !default;
$progress-btn-flat-warning-progress-color: rgba($warning-text, 1) !default;
$progress-btn-flat-danger-progress-color: rgba($danger-text, 1) !default;
$btn-flat-primary-path-arc: $white;
