@include export-module('hscroll-bootstrap5-icons') {

  /*! hscroll icons */
  #{&}.e-hscroll {

    &.e-rtl {

      &.e-scroll-device {

        .e-nav-right-arrow::before {
          content: '\e765';
        }

        .e-nav-left-arrow::before {
          content: '\e748';
        }
      }

      .e-nav-left-arrow::before {
        content: '\e748';
      }

      .e-nav-right-arrow::before {
        content: '\e765';
      }
    }

    &.e-scroll-device {

      .e-nav-right-arrow::before {
        content: '\e748';
      }

      .e-nav-left-arrow::before {
        content: '\e765';
      }
    }

    .e-nav-left-arrow::before {
      content: '\e765';
      line-height: normal;
    }

    .e-nav-right-arrow::before {
      content: '\e748';
      line-height: normal;
    }
  }
}
