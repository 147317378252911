@include export-module('breadcrumb-bootstrap5-icons') {

  /*! breadcrumb icons */
  .e-breadcrumb {
    .e-breadcrumb-collapsed::before,
    .e-breadcrumb-menu::before {
      content: '\e71c';
    }

    .e-home::before {
      content: '\e87b';
    }

    .e-home {
      height: 18px;
    }
  }

  .e-bigger .e-breadcrumb,
  .e-bigger.e-breadcrumb {
    .e-home {
      height: 21px;
    }
  }
}
