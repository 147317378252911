/*! TreeView's bootstrap5 theme wise override definitions and variables */
/* stylelint-disable */
$treeview-font-size: $text-sm !default;
$treeview-icon-font-size: $text-xl !default;
$treeview-image-font-size: $text-xl !default;
$treeview-item-height: 36px !default;
$treeview-text-height: 34px !default;
$treeview-input-height: 30px !default;
$treeview-root-ul-padding: 0 0 0 12px !default;
$treeview-rtl-root-ul-padding: 0 12px 0 0 !default;
$treeview-child-ul-padding: 0 0 0 20px !default;
$treeview-rtl-child-ul-padding: 0 20px 0 0 !default;
$treeview-text-wrap-padding: 0 0 0 20px !default;
$treeview-rtl-text-wrap-padding: 0 20px 0 0 !default;
$treeview-icon-size: 20px !default;
$treeview-icon-margin: 0 4px 0 -25px !default;
$treeview-rtl-icon-margin: 0 -20px 0 0 !default;
$treeview-icon-padding: 0px !default;
$treeview-text-padding: 0 8px !default;
$treeview-text-margin: 0 !default;
$treeview-image-size: 20px !default;
$treeview-image-margin: 0 0 0 8px !default;
$treeview-navigable-icon-image-anchor-margin-bigger: 0 12px 0 8px !default;
$treeview-navigable-icon-image-anchor-margin-reverse-bigger: 0 6px 0 10px !default;
$treeview-navigable-icon-image-anchor-margin: 0 8px 0 0 !default;
$treeview-navigable-icon-image-anchor-margin-reverse: 0 0 0 8px !default;
$treeview-navigable-rtl-margin-reverse: 0 12px 0 0 !default;
$treeview-rtl-image-margin: 0 8px 0 0 !default;
$treeview-image-text-padding: 0 8px !default;
$treeview-icon-image-margin: 0 0 0 8px !default;
$treeview-rtl-icon-image-margin: 0 8px 0 0 !default;
$treeview-check-margin: 0 0 0 12px !default;
$treeview-rtl-check-margin: 0 12px 0 0 !default;
$treeview-check-text-padding: 0 8px !default;
$treeview-check-image-margin: 0 0 0 8px !default;
$treeview-rtl-check-image-margin: 0 8px 0 0 !default;
$treeview-drop-count-border-size: 1px !default;
$treeview-drop-count-font-size: $text-xs !default;
$treeview-edit-wrap-width: calc(100% - 2px) !default;
$treeview-check-wrap-width: calc(100% - 21px) !default;
$treeview-check-icon-wrap-width: calc(100% - 54px) !default;
$treeview-check-icon-img-wrap-width: calc(100% - 82px) !default;
$treeview-icon-wrap-width: calc(100% - 32px) !default;
$treeview-icon-img-wrap-width: calc(100% - 60px) !default;

$treeview-big-font-size: $text-base !default;
$treeview-big-icon-font-size: $text-2xl !default;
$treeview-big-item-height: 42px !default;
$treeview-big-text-height: 40px !default;
$treeview-big-input-height: 40px !default;
$treeview-big-text-padding: 0 12px !default;
$treeview-big-input-padding: 0 9px !default;
$treeview-big-icon-padding: 0px !default;
$treeview-big-image-margin: 0 0 0 12px !default;
$treeview-big-rtl-image-margin: 0 12px 0 0 !default;
$treeview-big-icon-image-margin: 0 0 0 12px !default;
$treeview-big-rtl-icon-image-margin: 0 12px 0 0 !default;
$treeview-big-check-margin: 0 0 0 12px !default;
$treeview-big-rtl-check-margin: 0 12px 0 0 !default;
$treeview-big-check-image-margin: 0 0 0 12px !default;
$treeview-big-rtl-check-image-margin: 0 12px 0 0 !default;
$treeview-big-root-ul-padding: 0 0 0 16px !default;
$treeview-big-rtl-root-ul-padding: 0 16px 0 0 !default;
$treeview-big-child-ul-padding: 0 0 0 20px !default;
$treeview-big-rtl-child-ul-padding: 0 20px 0 0 !default;
$treeview-big-image-text-padding: 0 12px !default;
$treeview-big-icon-margin: 0 0 0 -24px !default;
$treeview-big-rtl-icon-margin: 0 -24px 0 0 !default;
$treeview-big-text-wrap-padding: 0 0 0 24px !default;
$treeview-big-rtl-text-wrap-padding: 0 24px 0 0 !default;
$treeview-big-drag-icon-font-size: $text-2xl !default;
$treeview-big-drag-item-text-padding-left: 24px !default;
$treeview-big-drag-before-icon-padding: 6px !default;
$treeview-big-check-text-padding: 0 12px !default;
$treeview-big-check-wrap-width: calc(100% - 29px) !default;
$treeview-big-check-icon-wrap-width: calc(100% - 65px) !default;
$treeview-big-check-icon-img-wrap-width: calc(100% - 101px) !default;
$treeview-big-icon-wrap-width: calc(100% - 35px) !default;
$treeview-big-icon-img-wrap-width: calc(100% - 71px) !default;

$treeview-font-family: $font-family !default;
$treeview-drag-icon-font-size: $text-xl !default;
$treeview-drag-icon-padding: 12px !default;

//treeview ripple effect variables
$ripple-size: -7px !default;
$ripple-height: 32px !default;
$ripple-width: 32px !default;

//color variables
$treeview-icon-color: $icon-color !default;
$treeview-text-color: $content-text-color !default;
$treeview-item-border-color: $transparent !default;
$treeview-item-active-bg: $content-bg-color-selected !default;
$treeview-icon-active-color: $content-text-color-selected !default;
$treeview-text-active-color: $content-text-color-selected !default;
$treeview-item-active-border-color: $content-bg-color-selected !default;
$treeview-item-hover-bg: $content-bg-color-hover !default;
$treeview-icon-hover-color: $icon-color-hover !default;
$treeview-text-hover-color: $content-text-color-hover !default;
$treeview-item-hover-border-color: $content-bg-color-hover !default;
$treeview-item-active-hover-bg: darken($treeview-item-active-bg, 5%) !default;
$treeview-icon-active-hover-color: $content-text-color-selected !default;
$treeview-text-active-hover-color: $content-text-color-selected !default;
$treeview-item-active-hover-border-color: $content-bg-color-selected !default;
$treeview-text-disable-color: $content-text-color-disabled !default;
$treeview-icon-disable-color: $icon-color-disabled !default;
$treeview-drag-line-bg: $primary !default;
$treeview-drag-line-color: $primary !default;
$treeview-popup-bg-color: $content-bg-color-hover !default;
$treeview-popup-border-color: $content-bg-color-hover !default;
$treeview-drop-count-bg: $primary !default;
$treeview-drop-count-border: $primary-text-color !default;
$treeview-drop-count-color: $primary-text-color !default;
$treeview-drag-item-bg: $content-bg-color-alt3 !default;
$treeview-drag-item-color: $content-text-color-alt1 !default;
$treeview-drag-icon-color: $content-text-color-alt1 !default;
$treeview-item-active-check-bg: $primary-text-color !default;
$treeview-item-active-check-border-color: $primary-text-color !default;
$treeview-item-active-check-color: $primary !default;
