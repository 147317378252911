// size variables
$datetime-default-text-indent: 12px !default;
$datetime-list-bigger-line-height: 32px !default;
$datetime-list-normal-line-height: 30px !default;
$datetime-list-normal-font-size: $text-sm !default;
$datetime-list-bigger-font-size: $text-base !default;
$datetime-list-normal-text-indent: $datetime-default-text-indent !default;
$datetime-list-bigger-text-indent: 16px !default;
$datetime-popup-border-radius: 6px !default;
$datetime-list-font-weight: $font-weight-normal !default;
$datetime-list-normal-padding: 8px !default;
$datetime-list-bigger-padding: 8px !default;
$datetime-time-font-icon: '\e20c' !default;
$datetime-icon-normal-font-size: $text-base !default;
$datetime-icon-bigger-font-size: $text-lg !default;
$datetime-date-icon-margin: 0 !default;
$datetime-time-icon-margin: 0 !default;
$datetime-time-bigger-icon-margin: 0 !default;
$datetime-time-rtl-icon-margin: 0 !default;
$datetime-time-rtl-bigger-icon-margin: 0 !default;
$datetime-normal-min-height: 24px !default;
$datetime-normal-min-width: 24px !default;
$datetime-bigger-min-height: 26px !default;
$datetime-bigger-min-width: 26px !default;
$datetime-time-icon-border: 0 !default;
$datetime-time-icon-border-style: none !default;
$datetime-list-default-border-style: none !default;

//mouse small
$datetime-list-small-line-height: 26px !default;
$datetime-list-small-text-indent: 8px !default;
$datetime-list-small-font-size: $text-xs !default;

// mouse small icon
$datetime-icon-small-font-size: $font-icon-14 !default;
$datetime-small-min-width: 16px !default;

//touch small
$datetime-list-bigger-small-line-height: 30px !default;
$datetime-list-bigger-small-text-indent: 16px !default;
$datetime-list-bigger-small-font-size: $text-sm !default;

// touch small icon
$datetime-icon-bigger-small-font-size: $text-base !default;

// color variables
$datetime-popup-shadow: none !default;
$datetime-list-default-font-color: $content-text-color !default;
$datetime-list-border-color: 1px solid $border-light !default;
$datetime-list-bg-color: $content-bg-color !default;
$datetime-list-active-bg-color: $flyout-bg-color-pressed !default;
$datetime-list-active-font-color: $flyout-text-color-pressed !default;
$datetime-list-active-icon-color: $content-text-color-pressed !default;
$datetime-list-hover-bg-color: $content-bg-color-hover !default;
$datetime-list-hover-font-color: $content-text-color-hover !default;
$datetime-list-popup-icon-active-color: $icon-color-pressed !default;
$datetime-list-active-hover-bg-color: $flyout-bg-color-hover !default;
$datetime-list-active-hover-font-color: $flyout-text-color-hover !default;
$datetime-list-hover-border-color: none !default;
$datetime-default-overlay: $content-bg-color-alt2 !default;

// modal full-screen popup
$modal-datetime-position: fixed !default;
$modal-datetime-wrapper-position: relative !default;
$modal-datetime-wrapper-width: 100% !default;
