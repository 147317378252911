@include export-module('toolbar-layout') {

  /*! toolbar layout */
  /* stylelint-disable property-no-vendor-prefix */
  .e-bigger .e-toolbar,
  .e-toolbar.e-bigger {
    height: $tbar-bgr-size;
    min-height: $tbar-bgr-size;

    .e-toolbar-items {

      &:not(.e-tbar-pos):not(.e-toolbar-multirow),
      .e-toolbar-left {

        .e-toolbar-item:first-child {
          margin-left: $tbar-item-bgr-mrgn;
        }
      }

      &:first-child:not(.e-toolbar-multirow) {

        > .e-toolbar-item:last-child,
        > .e-toolbar-right .e-toolbar-item:last-child {
          margin-right: $tbar-item-bgr-mrgn;
        }
      }

      .e-hscroll-bar .e-hscroll-content {

        > .e-toolbar-item:last-child {
          margin-right: $tbar-item-bgr-mrgn;
        }

        .e-toolbar-right .e-toolbar-item {

          &:last-child {
            margin-right: $tbar-item-bgr-mrgn;
          }
        }
      }

      &.e-toolbar-multirow {
        margin-left: $tbar-multirow-items-mrgn-bigger;
        margin-right: $tbar-multirow-items-mrgn-bigger;

        .e-toolbar-item:not(.e-separator) {
          margin: $tbar-multirow-item-top-btm-mrgn-bigger;
        }
      }

      .e-toolbar-item {

        .e-tbar-btn.e-btn,
        .e-tbar-btn.e-btn.e-control {

          &:focus,
          &:hover,
          &:active {
            padding: $tbar-btn-bgr-focus-padding;
          }
        }
      }
    }

    .e-toolbar-item {
      min-height: $tbar-bgr-items-size;

      .e-tbar-btn.e-btn,
      .e-tbar-btn.e-btn.e-control {
        min-height: $tbar-btn-bgr-minheight;
        min-width: $tbar-btn-bgr-minwidth;
        padding: $tbar-btn-bgr-padding;
        line-height: $tbar-btn-bgr-line-height;

        .e-tbar-btn-text {
          padding: $btn-txt-bgr-padding;
          font-size: $tbar-bgr-btn-text-font-size;
        }

        .e-icons {
          font-size: $tbar-icons-bgr-font-size;

          &.e-btn-icon {
            font-size: $tbar-bgr-btn-icon-font-size;
            line-height: $tbar-btn-icon-bgr-line-height;
          }
        }

        &.e-tbtn-txt {

          .e-icons.e-btn-icon {
            padding: $tbar-btn-icn-bgr-padding;
          }

          .e-icons.e-icon-right.e-btn-icon {
            padding: $tbar-btn-icn-right-bgr-padding;
          }
        }
      }

      &:not(.e-separator) {
        min-width: $tbar-bgr-item-size;
        padding: $tbar-item-bgr-padding;
      }

      &.e-separator {
        height: $tbar-separator-bgr-height;
        margin: $tbar-separator-bgr-mrgn;
        min-height: $tbar-separator-bgr-minheight;
      }

      &.e-popup-text {

        .e-tbar-btn.e-btn,
        .e-tbar-btn.e-btn.e-control {

          &.e-tbtn-txt {

            .e-icons.e-btn-icon {
              padding: $tbar-popup-bgr-text-btn-icon-padding;
            }
          }
        }
      }
    }

    .e-hor-nav {
      min-height: $tbar-bgr-items-size;
      min-width: $tbar-nav-bgr-width;
    }

    &.e-vertical {

      .e-toolbar-items {

        &:not(.e-tbar-pos) .e-toolbar-item {

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .e-toolbar-item {

        &:not(.e-separator) {
          min-height: 38px;
        }

        &.e-separator {
          height: auto;
          margin: $tbar-separator-vertical-bgr-mrgn;
          min-height: auto;
        }
      }

      .e-hor-nav {
        min-height: 40px;
        min-width: 50px;
      }
    }

    .e-toolbar-pop {

      .e-toolbar-item {
        height: $tbar-popup-bgr-height;

        &:not(.e-separator) {
          min-width: 48px;
          padding: $tbar-item-pop-bgr-padding;
          min-height: $tbar-btn-pop-bgr-minheight;
        }

        .e-tbar-btn.e-btn {
          min-height: $tbar-btn-pop-bgr-minheight;
          padding: $tbar-pop-btn-bgr-padding;
          min-width: 100%;

          .e-icons.e-btn-icon {
            margin-left: $tbar-zero-value;
            padding: $tbar-pop-icon-bgr-padding;
            font-size: $tbar-popup-bgr-btn-icon-font-size;
          }

          .e-tbar-btn-text {
            padding: $tbar-pop-btn-txt-bgr-pad;
            font-size: $tbar-popup-bgr-btn-text-font-size;
          }
        }
      }
    }

    &.e-extended-toolbar {

      .e-toolbar-extended {
        min-height: $tbar-bgr-size;
        padding-bottom: 0;
        padding-left: $tbar-item-bgr-mrgn;
        padding-right: $tbar-item-bgr-mrgn;
        padding-top: 0;

        .e-toolbar-item {
          min-height: $tbar-bgr-items-size;

          .e-tbar-btn.e-btn,
          .e-tbar-btn.e-btn.e-control {
            min-height: $tbar-btn-bgr-minheight;
            min-width: $tbar-btn-bgr-minwidth;
            padding: $tbar-btn-bgr-padding;

            .e-tbar-btn-text {
              padding: $btn-txt-bgr-padding;
            }

            &:hover,
            &:focus,
            &:active {
              padding: $tbar-bgr-btn-focus-padding;
            }
          }

          &:not(.e-separator) {
            min-width: $tbar-bgr-item-size;
            padding: $tbar-item-bgr-padding;
          }

          &.e-separator {
            height: $tbar-separator-bgr-height;
            margin: $tbar-separator-bgr-mrgn;
            min-height: $tbar-separator-bgr-minheight;
          }
        }
      }

      &.e-tbar-extended {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
  
        .e-toolbar-extended {
          border-top: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }

    &.e-rtl {

      .e-toolbar-item {

        .e-tbar-btn.e-btn {

          &.e-tbtn-txt {

            .e-icons.e-btn-icon {
              padding: $tbar-rtl-btn-icn-bgr-padding;

              &.e-icon-right {
                padding: $tbar-rtl-btn-icn-right-bgr-padding;
              }
            }
          }

          .e-tbar-btn-text {
            padding: $btn-rtl-txt-bgr-padding;
          }
        }
      }

      .e-toolbar-items {

        .e-hscroll-bar .e-hscroll-content {

          > .e-toolbar-item:last-child {
            margin-left: $tbar-item-bgr-mrgn;
            margin-right: initial;
          }

          .e-toolbar-center .e-toolbar-item {
            margin: 0;
          }

          .e-toolbar-right .e-toolbar-item {
            margin: 0;

            &:last-child {
              margin-left: $tbar-item-bgr-mrgn;
              margin-right: 0;
            }
          }
        }

        &:not(.e-tbar-pos) {

          .e-toolbar-item:first-child {
            margin-left: 0;
            margin-right: $tbar-item-bgr-mrgn;
          }
        }
      }
    }

    &.e-extended-toolbar.e-rtl {

      .e-toolbar-extended {
        padding-right: $tbar-item-bgr-mrgn;
      }
    }
  }

  .e-toolbar {
    border-radius: $tbar-radius;
    display: block;
    height: $tbar-nrml-size;
    min-height: $tbar-nrml-size;
    position: relative;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;

    .e-blazor-toolbar-items {
      position: absolute;
      top: -9999px;
      visibility: hidden;
    }

    &.e-control[class *= 'e-toolbar'] {
      box-sizing: content-box;
    }

    &.e-corner {
      border-radius: $tbar-border-radius;
    }

    &.e-hidden {
      display: none;
    }

    .e-toolbar-items {
      border-radius: $tbar-radius 0 0 $tbar-radius;
      display: inline-flex;
      height: 100%;
      vertical-align: middle;
      align-items: center;

      &.e-toolbar-multirow {
        margin-bottom: 1px;
        margin-left: $tbar-multirow-items-mrgn-small;
        margin-right: $tbar-multirow-items-mrgn-small;
        white-space: normal;
        flex-wrap: wrap;

        .e-toolbar-item {

          &:not(.e-separator) {
            margin: $tbar-multirow-item-top-btm-mrgn-small;
          }

          &.e-separator {

            &.e-multirow-separator,
            &.e-hidden {
              display: none;
            }
          }
        }
      }

      &.e-multirow-pos {

        .e-toolbar-left,
        .e-toolbar-center,
        .e-toolbar-right {
          display: inline;
        }
      }

      &.e-tbar-pos {
        display: block;

        .e-toolbar-left,
        .e-toolbar-center,
        .e-toolbar-right {
          display: table;
          height: 100%;
          top: 0;
        }

        .e-toolbar-right,
        .e-toolbar-left {
          position: absolute;
        }

        .e-toolbar-right {
          right: 0;
        }

        .e-toolbar-left {
          left: 0;
        }

        .e-toolbar-center {
          margin: 0 auto;
        }
      }

      .e-toolbar-left,
      .e-toolbar-center,
      .e-toolbar-right {
        display: inline-block;
      }

      .e-toolbar-left,
      &:not(.e-tbar-pos):not(.e-toolbar-multirow) {

        .e-toolbar-item:first-child {
          margin-left: $tbar-item-nrml-mrgn;
        }
      }

      &:first-child:not(.e-toolbar-multirow) {

        > .e-toolbar-item:last-child,
        > .e-toolbar-right .e-toolbar-item:last-child {
          margin-right: $tbar-item-nrml-mrgn;
        }
      }

      .e-hscroll-bar .e-hscroll-content {
        touch-action: pan-y pinch-zoom;

        > .e-toolbar-item:last-child {
          margin-right: $tbar-item-nrml-mrgn;
        }

        .e-toolbar-center .e-toolbar-item {
          margin: 0;
        }

        .e-toolbar-right .e-toolbar-item {
          margin: 0;

          &:last-child {
            margin-right: $tbar-item-nrml-mrgn;
          }
        }
      }
    }

    .e-toolbar-item {
      align-content: center;
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      min-height: $tbar-nrml-item-size;
      vertical-align: middle;
      width: auto;

      .e-tbar-btn {
        display: flex;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        margin: $tbar-btn-nrml-mrgn;
        min-height: $tbar-btn-nrml-minheight;
        min-width: $tbar-btn-nrml-minwidth;
        padding: $tbar-btn-nrml-padding;
        border-radius: $tbar-nrml-btn-border-radius;
        line-height: $tbar-btn-nrml-line-height;
        border: $tbar-btn-border;
        cursor: pointer;
        font-size: $tbar-btn-font-size;
        font-weight: $tbar-btn-weight;
        overflow: hidden;
        text-align: center;
        text-decoration: none;
        text-transform: none;

        &.e-tbtn-txt {

          .e-icons.e-btn-icon {
            padding: $tbar-btn-icn-nrml-padding;

            &.e-icon-right {
              padding: $tbar-btn-icn-right-nrml-padding;
            }
          }
        }

        .e-icons.e-btn-icon {
          margin: $tbar-zero-value;
          min-width: $tbar-btn-icon-nrml-width;
          width: auto;
          font-size: $tbar-btn-font-size;
          line-height: $tbar-btn-icon-nrml-line-height;
        }

        &:hover,
        &:focus,
        &:active {
          padding: $tbar-nrml-btn-focus-padding;
        }

        &:focus {
          outline: $tbar-nrml-btn-focus-outline;
        }

        div {
          vertical-align: middle;
        }
  
        .e-tbar-btn-text {
          font-size: $tbar-btn-txt-font-size;
          padding: $btn-txt-nrml-padding;
        }
      }

      &:not(.e-separator) {
        height: inherit;
        min-width: $tbar-item-nrml-minwidth;
        padding: $tbar-item-nrml-padding;
      }

      &.e-separator {
        margin: $tbar-separator-nrml-mrgn;
        min-height: $tbar-separator-nrml-minheight;
        min-width: 1px;
        height: $tbar-separator-nrml-height;

        + .e-separator,
        &:last-of-type,
        &:first-of-type {
          display: none;
        }
      }

      &.e-popup-text {

        .e-tbar-btn.e-btn.e-tbtn-txt {

          .e-icons.e-btn-icon {
            padding: $tbar-popup-text-btn-icon-padding;
          }
        }
      }

      > * {
        text-overflow: ellipsis;
      }

      &.e-hidden {
        display: none;
      }

      input[type = 'checkbox'] {
        height: auto;
      }
    }

    &.e-vertical {
      display: flex;
      flex-direction: column;

      .e-toolbar-items {

        .e-vscroll-bar .e-vscroll-content {
          touch-action: pan-x pinch-zoom;
        }

        &.e-tbar-pos {

          .e-toolbar-left,
          .e-toolbar-center,
          .e-toolbar-right {
            height: auto;
          }

          .e-toolbar-left {
            left: auto;
            right: auto;
            top: 0;
          }

          .e-toolbar-right {
            bottom: 0;
            left: auto;
            right: auto;
          }
        }

        &:not(.e-tbar-pos) .e-toolbar-item {

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        .e-toolbar-item {
          display: flex;
          height: auto;

          &:not(.e-separator) {
            min-width: 33px;
          }

          &.e-separator {
            height: auto;
            margin: $tbar-separator-vertical-nrml-mrgn;
            min-height: auto;
          }
        }
      }

      .e-hor-nav {
        bottom: 0;
        height: auto;
        left: 0;
        min-height: 40px;
        min-width: 50px;
        right: auto;
        top: auto;
        width: auto;
      }

      &.e-rtl.e-tbar-pos {

        .e-toolbar-left {
          bottom: 0;
          top: auto;
        }

        .e-toolbar-right {
          bottom: auto;
          top: 0;
        }
      }
    }

    .e-hor-nav {
      align-items: center;
      border-radius: 0 $tbar-radius $tbar-radius 0;
      cursor: pointer;
      display: flex;
      height: 100%;
      min-height: $tbar-nrml-items-size;
      overflow: hidden;
      position: absolute;
      right: $tbar-zero-value;
      top: $tbar-zero-value;
      width: $tbar-nav-nrml-width;

      &.e-ie-align {
        display: table;
      }
    }

    .e-popup-down-icon.e-icons,
    .e-popup-up-icon.e-icons {
      color: $tbar-default-icon-color;
      display: flex;
      text-align: center;
      vertical-align: middle;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: $tbar-popup-icon-font-size;
    }

    &.e-toolpop {
      overflow: visible;

      .e-toolbar-items {

        .e-toolbar-item {

          &.e-popup-text .e-tbar-btn-text {
            display: none;
          }
        }
      }
    }

    .e-toolbar-pop {
      border-radius: $tbar-pop-radius;
      overflow: hidden;
      padding: $tbar-popup-padding;
      position: absolute;

      .e-toolbar-item  {
        display: flex;
        height: $tbar-item-height;
        justify-content: center;
        min-height: $tbar-btn-pop-nrml-minheight;

        &.e-toolbar-popup.e-hidden {
          display: none;
        }

        .e-tbar-btn.e-btn {
          min-height: $tbar-btn-pop-nrml-minheight;
          min-width: 100%;
          padding: $tbar-pop-btn-nrml-padding;
          border: $tbar-popup-btn-border;
          border-radius: $tbar-popup-btn-border-radius;
          justify-content: flex-start;

          .e-icons.e-btn-icon {
            margin: $tbar-zero-value;
            padding: $tbar-pop-icon-nrml-padding;
            width: auto;
          }

          .e-tbar-btn-text {
            padding: $tbar-pop-btn-txt-nrml-pad;
          }
        }

        &:not(.e-separator) {
          min-width: 34px;
          padding: $tbar-item-pop-nrml-padding;
        }

        > * {
          height: 100%;
          min-width: 100%;
          text-overflow: ellipsis;
        }

        &.e-tbtn-align .e-btn.e-control .e-icons.e-btn-icon {
          min-width: 100%;
        }
      }

      .e-toolbar-text .e-tbar-btn-text {
        display: none;
      }

      .e-toolbar-popup,
      .e-toolpopup {
        text-align: center;
      }
    }

    &.e-extended-toolbar {
      overflow: visible;

      &.e-tbar-extended {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .e-toolbar-extended {
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        min-height: $tbar-nrml-size;
        padding-bottom: 0;
        padding-left: $tbar-item-nrml-mrgn;
        padding-right: $tbar-item-nrml-mrgn;
        padding-top: 0;
        margin-left: -1px;
        box-shadow: none;
        display: inline;
        white-space: normal;

        .e-toolbar-item {
          display: inline-flex;
          min-height: $tbar-nrml-item-size;

          .e-tbar-btn.e-btn,
          .e-tbar-btn.e-btn.e-control {
            min-height: $tbar-btn-nrml-minheight;
            min-width: $tbar-btn-nrml-minwidth;
            padding: $tbar-btn-nrml-padding;

            .e-tbar-btn-text {
              padding: $btn-txt-nrml-padding;
              font-size: $tbar-btn-txt-font-size;
            }

            .e-icons.e-btn-icon:not(.e-toolbar-pop) {
              display: flex;
              align-items: center;
              vertical-align: middle;
              justify-content: center;
              padding: $tbar-ext-btn-icon-padding;
              font-size: $tbar-ext-btn-icon-font-size;
            }

            &.e-tbtn-txt {

              .e-icons.e-btn-icon:not(.e-toolbar-pop) {
                padding: $tbar-btn-icn-nrml-padding;
              }

              .e-icons.e-icon-right.e-btn-icon:not(.e-toolbar-pop) {
                padding: $tbar-btn-icn-right-nrml-padding;
              }
            }

            &:hover,
            &:focus,
            &:active {
              padding: $tbar-ext-btn-focus-padding;
            }
          }

          &:not(.e-separator) {
            min-width: $tbar-item-nrml-minwidth;
            padding: $tbar-item-nrml-padding;
          }

          &.e-separator {
            min-height: $tbar-btn-icon-nrml-height;

            &.e-extended-separator {
              display: none;
            }
          }

          &.e-toolbar-text .e-tbar-btn-text {
            display: none;
          }
        }

        &.e-popup-close {
          display: none;
        }
  
        &.e-popup-open {
          display: inline;
        }

        &.e-toolbar-pop {
          width: inherit;

          .e-toolbar-item {
  
            .e-tbar-btn {
              cursor: pointer;
              font-size: $tbar-btn-font-size;
              overflow: hidden;
              padding: $tbar-btn-nrml-padding;

              .e-icons.e-btn-icon {
                font-size: 14px;
              }
            }

            &:not(.e-separator) {
              height: auto;
            }
          }

          .e-toolbar-item > * {
            align-self: center;
            text-overflow: ellipsis;
          }
        }
      }

      .e-toolbar-items {

        .e-toolbar-item {

          &.e-popup-text .e-tbar-btn-text {
            display: none;
          }
  
          &.e-separator:last-of-type {
            display: inline-flex;
          }
        }
      }

      .e-hor-nav.e-ie-align {
        display: table;
      }
    }

    &.e-rtl {

      .e-toolbar-item {

        .e-tbar-btn {

          &.e-tbtn-txt {

            .e-icons.e-btn-icon {
              padding: $tbar-rtl-btn-icn-nrml-padding;

              &.e-icon-right {
                padding: $tbar-rtl-btn-icn-right-nrml-padding;
              }
            }
          }

          .e-tbar-btn-text {
            padding: $btn-rtl-txt-nrml-padding;
          }
        }
      }

      .e-hscroll-bar .e-hscroll-content {

        > .e-toolbar-item:last-child {
          margin-left: $tbar-item-nrml-mrgn;
          margin-right: initial;
        }

        .e-toolbar-center .e-toolbar-item {
          margin: 0;
        }

        .e-toolbar-right .e-toolbar-item {
          margin: 0;

          &:last-child {
            margin-right: $tbar-item-nrml-mrgn;
          }
        }
      }

      .e-toolbar-items {

        &.e-tbar-pos {

          .e-toolbar-left {
            left: auto;
            right: 0;
          }

          .e-toolbar-right {
            left: 0;
            right: auto;
          }
        }

        .e-toolbar-left {

          .e-toolbar-item {

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-left: $tbar-item-nrml-mrgn;
            }
          }
        }

        &:not(.e-tbar-pos) {

          .e-toolbar-item:first-child {
            margin-left: 0;
            margin-right: $tbar-item-nrml-mrgn;
          }
        }

        &:first-child {

          > .e-toolbar-item:last-child,
          > .e-toolbar-right .e-toolbar-item {

            &:last-child {
              margin-right: 0;
            }

            &:first-child {
              margin-right: $tbar-item-nrml-mrgn;
            }
          }
        }
      }

      .e-hor-nav {
        left: $tbar-zero-value;
        right: auto;
        border-radius: $tbar-radius 0 0 $tbar-radius;
      }
    }

    &.e-toolpop.e-rtl {

      .e-toolbar-items:not(.e-tbar-pos) {

        .e-toolbar-center .e-toolbar-item {
          margin: 0;
        }

        .e-toolbar-right .e-toolbar-item {
          margin: 0;

          &:last-child {
            margin: 0;
            margin-left: $tbar-item-nrml-mrgn;
          }
        }
      }
    }

    &.e-extended-toolbar.e-rtl {

      .e-hor-nav {
        left: 0;
        right: auto;
      }

      .e-toolbar-extended {
        padding-right: $tbar-item-nrml-mrgn;
        margin-left: 0;

        .e-toolbar-item .e-tbar-btn.e-btn .e-icon-left {
          padding-left: 0;
        }
      }
    }
  }
}
