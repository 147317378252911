//layout variables
$menu-bigger-caret-font-size: $cmenu-bigger-caret-font-size !default;
$menu-bigger-caret-li-padding: 32px !default;
$menu-bigger-caret-right: 12px !default;
$menu-bigger-font-size: $text-base !default;
$menu-bigger-li-height: 38px !default;
$menu-bigger-li-icon-height: 40px !default;
$menu-bigger-li-padding: 0 16px !default;
$menu-bigger-li-text-indent: 15px !default;
$menu-bigger-li-right-padding: 12px !default;
$menu-bigger-icon-margin-right: 12px !default;
$menu-icon-width: 1em !default;
$menu-blank-icon: 34px !default;
$menu-blank-icon-bigger: 41px !default;
$menu-caret-font-size: $text-sm !default;
$menu-caret-icon: '\e706' !default;
$menu-caret-icon-rtl: '\e70f' !default;
$menu-caret-right: 8px !default;
$menu-caret-li-padding: 26px !default;
$menu-ul-padding: 0 !default;
$menu-separator-bigger-padding: 0 !default;
$menu-ul-bigger-header-padding: 0 !default;
$menu-caret-padding-left: 8px !default;
$menu-disable-opacity: .65 !default;
$menu-font-size: $text-sm !default;
$menu-icon-margin-right: 8px !default;
$menu-li-hover-outline-offset: 0 !default;
$menu-li-border-style: solid !default;
$menu-li-border-width: 1px !default;
$menu-li-height: 32px !default;
$menu-li-padding: 0 12px !default;
$menu-li-text-indent: 12px !default;
$menu-li-right-padding: 12px !default;
$menu-separator-padding: 0 !default;
$menu-icon-font-size: $text-lg !default;
$menu-icon-bigger-font-size: 22px !default;
$menu-li-selected-outline-offset: 0 !default;
$menu-hscroll-nav-size: 28px !default;
$menu-vscroll-nav-size: 28px !default;
$menu-scroll-nav-icon-size: 16px !default;
$menu-bigger-hscroll-nav-size: 40px !default;
$menu-bigger-vscroll-nav-size: 32px !default;
$menu-bigger-scroll-nav-icon-size: 18px !default;
$menu-header-icon-padding: 0 16px !default;
$menu-icon-li-height: 35px !default;
$menu-li-bgcolor: $content-bg-color-alt3 !default;
$menu-li-border-color: $border-light !default;
$menu-li-selection-bgcolor: $content-bg-color-alt3 !default;
$menu-li-selected-outline: 0 solid $menu-li-selection-bgcolor !default;
$menu-li-selection-font-color: $content-text-color !default;
$menu-ul-header-padding: $menu-ul-padding !default;
$menu-color: $content-text-color !default;
$menu-seperator-border-color: $border-light !default;
$menu-selected-color: $content-text-color !default;
$menu-sub-ul-bgcolor: $content-bg-color-alt2 !default;
$menu-sub-li-border-color: $border-light !default;
$menu-ul-bgcolor: $content-bg-color-alt1 !default;
$menu-ul-border: none !default;
$menu-li-hover-outline: 0 solid $menu-li-border-color !default;
$menu-srollbar-ul-border: none !default;
$submenu-caret-font-size: $menu-caret-font-size !default;
$submenu-bigger-caret-font-size: $text-base !default;
$submenu-color: $content-text-color !default;
$menu-icon-color: $icon-color !default;
$menu-icon-hover-color: $content-text-color !default;
$menu-icon-focussed-color: $cmenu-caret-color !default;
$menu-caret-color: $cmenu-caret-color !default;
