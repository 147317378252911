@use "@angular/material" as mat;
@use "@angular/material/core/m2" as m2;

@import "variables";

@include mat.core();

$md-custom-primary: (
    50: var(--accent-color),
    100: var(--accent-color),
    200: var(--accent-color),
    300: var(--accent-color),
    400: var(--accent-color),
    500: var(--accent-color),
    600: var(--accent-color),
    700: var(--accent-color),
    800: var(--accent-color),
    900: var(--accent-color),
    A100: var(--accent-color),
    A200: var(--accent-color),
    A400: var(--accent-color),
    A700: var(--accent-color),
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-custom-accent: (
    50: #e5e5e5,
    100: #bfbfbf,
    200: #949494,
    300: #696969,
    400: #494949,
    500: #292929,
    600: #242424,
    700: #1f1f1f,
    800: #191919,
    900: #0f0f0f,
    A100: #ee6767,
    A200: #e93a3a,
    A400: #f00000,
    A700: #d60000,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$my-app-primary: m2.define-palette($md-custom-primary);
$my-app-accent: m2.define-palette($md-custom-accent);
$my-app-warn: m2.define-palette(m2.$deep-orange-palette);

$cerrix-theme: m2.define-light-theme(
    (
        color: (
            primary: $my-app-primary,
            accent: $my-app-accent,
            warn: $my-app-warn,
        ),
        typography: m2.define-typography-config(),
        density: 0,
    )
);

@include mat.all-component-themes($cerrix-theme);

.mdc-checkbox {
    // Revert new primary checkmark color to old one to fix clash with "Application wide accent color" setting
    // https://material.angular.io/guide/mdc-migration#checkbox
    --mdc-checkbox-selected-checkmark-color: #fafafa !important;
}

.unpadded-matdialog {
    & > mat-dialog-container {
        padding: 0px;
    }
}

.mat-mdc-slide-toggle.mat-primary.mat-mdc-slide-toggle-checked .mdc-switch__track {
    background-color: var(--accent-color-lighter);
}

.mat-mdc-tooltip {
    white-space: pre-wrap;
}

.mat-mdc-tooltip-panel {
    .mat-mdc-tooltip {
        &.cerrix-tooltip {
            &:before {
                content: "";
                position: absolute;
                top: unset;
                bottom: unset;
                right: unset;
                left: unset;
                padding: 5px;
                background-color: #f0f2f4;
                transform: rotate(45deg);
            }
            .mat-mdc-tooltip-surface {
                background-color: #f0f2f4;
                color: #001257;
                font-size: "15px";
            }
        }
    }
    &-below {
        .mat-mdc-tooltip.cerrix-tooltip::before {
            left: calc(50% - 5px);
            top: -5px;
        }
    }
    &-above {
        .mat-mdc-tooltip.cerrix-tooltip::before {
            left: calc(50% - 5px);
            bottom: -5px;
        }
    }
    &-left {
        .mat-mdc-tooltip.cerrix-tooltip::before {
            top: calc(50% - 5px);
            right: -5px;
        }
    }
    &-right {
        .mat-mdc-tooltip.cerrix-tooltip::before {
            top: calc(50% - 5px);
            left: -5px;
        }
    }
}

// Hack so modal-ngselect-holder hack doesn't take up space
.mat-mdc-dialog-container .mdc-dialog__surface {
    position: static;
}
