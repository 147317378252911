//layout variables
$split-btn-zindex: 2 !default;
$split-btn-border-radius: 4px !default;
$split-btn-focus-outline-offset: 0 !default;
$split-btn-vertical-secondary-icon-line-height: .334em !default;
$split-btn-seperator-border: 1px solid !default;
$split-btn-sec-btn-margin-left: -1px !default;
$split-btn-icon-btn-padding: 10px !default;
$split-btn-icon-btn-padding-bigger: 12px !default;
$split-btn-focus-border-color: $secondary-border-color !default;
$split-btn-focus-vertical-border-color: $secondary-border-color !default;
$split-btn-seperator-border-color: $secondary-border-color !default;
$split-btn-seperator-default-border-color: $secondary-border-color !default;
$split-btn-seperator-vertical-border-color: $secondary-border-color !default;
$split-btn-hover-border-color: $secondary-border-color !default;
$split-btn-hover-vertical-border-color: $secondary-border-color !default;
$split-btn-active-border-color: $secondary-bg-color-pressed !default;
$split-btn-active-vertical-border-color: $secondary-bg-color-pressed !default;
$split-btn-disabled-vertical-border-color: $secondary-border-color !default;
$split-btn-active-box-shadow: $secondary-shadow-focus !default;
$split-btn-disabled-left-border-color: $secondary-border-color !default;
$split-btn-hover-left-border-color: $secondary-border-color !default;
$split-btn-focus-left-border-color: $secondary-border-color !default;
$split-btn-active-left-border-color: $secondary-border-color !default;
