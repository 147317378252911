//layout variables
$drop-down-btn-arrow-content: '\e70d' !default;
$drop-down-btn-vertical-btn-padding: 6px 12px !default;
$drop-down-btn-vertical-bigger-padding: 8px 16px !default;
$drop-down-btn-bigger-font-size: $text-base !default;
$drop-down-btn-bigger-li-height: 32px !default;
$drop-down-btn-bigger-max-width: 202px !default;
$drop-down-btn-bigger-min-width: 112px !default;
$drop-down-btn-icon-font-size: $text-sm !default;
$drop-down-btn-icon-margin-right: 11px !default;
$drop-down-btn-bigger-icon-margin-right: 12px !default;
$drop-down-btn-li-border-width: 1px !default;
$drop-down-btn-li-height: 30px !default;
$drop-down-btn-li-padding: 0 12px !default;
$drop-down-btn-bigger-li-padding: 0 16px !default;
$drop-down-btn-small-li-padding: 0 8px !default;
$drop-down-btn-max-width: 178px !default;
$drop-down-btn-menu-icon-bigger-font-size: 22px !default;
$drop-down-btn-menu-icon-font-size: $text-lg !default;
$drop-down-btn-min-width: 120px !default;
$drop-down-btn-seperator-padding: 3px 0 !default;
$drop-down-btn-sub-ul-box-shadow: none !default;
$drop-down-btn-ul-border-radius: 4px !default;
$drop-down-btn-ul-padding: 4px 0 !default;
$drop-down-btn-ul-bigger-padding: 8px 0 !default;
$drop-down-btn-ul-small-padding: 4px 0 !default;
$drop-down-btn-caret-icon-font-size: $text-xxs !default;
$drop-down-btn-icon-font-size-bigger: $text-base !default;
$drop-down-btn-caret-icon-font-size-bigger: $text-xs !default;
$drop-down-btn-box-shadow: $secondary-shadow-focus !default;
$drop-down-btn-popup-margin-top: 2px !default;
$drop-down-btn-bigger-popup-margin-top: 4px !default;
$drop-down-btn-ul-border: 1px solid $border-light !default;
$drop-down-btn-li-box-shadow: none !default;
$drop-down-btn-li-border-style: solid !default;
$drop-down-btn-color: $content-text-color !default;
$drop-down-btn-disable-text: $secondary-text-color-disabled !default;
$drop-down-btn-font-size: $text-sm !default;
$drop-down-btn-font-weight: $font-weight-normal !default;
$drop-down-btn-li-bgcolor: $content-bg-color-hover !default;
$drop-down-btn-ul-bgcolor: $flyout-bg-color !default;
$drop-down-btn-li-border-color: $border-light !default;
$drop-down-btn-selected-color: $content-text-color-selected !default;
$drop-down-btn-parent-ul-box-shadow: none !default;
$drop-down-btn-li-focus-bgcolor: $content-bg-color-hover !default;
$drop-down-btn-li-selection-bgcolor: $content-bg-color-selected !default;
$drop-down-btn-li-selection-font-color: $content-text-color !default;
$drop-down-btn-menu-icon-color: $icon-color !default;
$drop-down-btn-menu-icon-disabled-color: $secondary-text-color-disabled !default;
$drop-down-btn-li-hover-bgcolor: $flyout-bg-color-hover !default;
